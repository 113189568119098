import React from "react";

import {
    // Customizable Area Start
    Box,
    Modal,
    Typography,
    Button
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider, withStyles, Theme } from "@material-ui/core/styles";
import { Close } from "@material-ui/icons";
import { Formik, Form } from 'formik';
import * as Yup from "yup";
import CustomTextField from "../../../components/src/CustomTextField";
import { primaryColor } from "../../../components/src/theme";
import ResponseStatusModal from "../../../components/src/ResponseStatusModal";
import { phoneValidate } from "../../../framework/src/Utilities";
import i18next from 'i18next';


const theme = createTheme({
    overrides: {
        MuiTypography: {
            gutterBottom: {
                marginBottom: '8px'
            }
        }
    },
    typography: {
        body1: {
            color: '#212426',
            fontFamily: 'Inter',
            fontStyle: "normal",
            fontWeight: 400,
            letterSpacing: 0,
            fontSize: '16px',
            lineHeight: '150%'
        }
    }
});

const validationSchema = Yup.object().shape({
    first_name: Yup.string().required(()=>{i18next.t('contactUs.firstNameIsRequired')}),
    last_name: Yup.string().required(()=>{i18next.t('contactUs.lastNameIsRequired')}),
    email: Yup.string().email(()=>{i18next.t('contactUs.invalidEmailAddress')}).required(()=>{i18next.t('contactUs.emailIsRequired')}),
    phoneNumber: Yup.string()
      .test('phone-validation', ()=>{i18next.t('contactUs.enterValidPhoneNumber')}, function (value) {
        const { path } = this;
        const validationResult = phoneValidate(path, value);
        return validationResult.status;
      })
      .required(()=>{i18next.t('contactUs.enterValidPhoneNumber')}),
    comments: Yup.string().required(()=>{i18next.t('contactUs.commentsAreRequired')})
});

// Customizable Area End

import ContactusController, {
    Props,
    configJSON,
} from "./ContactusController";


export default class ContactusModal extends ContactusController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const {
            localModal,
            first_name,
            last_name,
            email,
            phoneNumber,
            comments,
            responseModalMsg,
            responseStatsModal,
            isSuccessIcon
        } = this.state;
        // Customizable Area End
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <Modal open={localModal}>
                    <Box className={classes.paperMainWrappercum}>
                        <Box className={classes.modalEditContentcum}>
                            <Box className={classes.textAndCloseStylecum}>
                                <Typography className={classes.headingTxtcum}>{i18next.t('contactUs.contactUs')}</Typography>
                                <Box onClick={this.handleCloseContactusModal} className={classes.closeButtoncum}><Close style={{ fontSize: '20px' }} /></Box>
                            </Box>
                            <Typography className={`${classes.distanceBtwncum} ${classes.bodyTxtcum}`}>{i18next.t('contactUs.assistanceMessage')}</Typography>
                            <Formik
                                enableReinitialize={true}
                                initialValues={{ first_name, last_name, email, phoneNumber, comments }}
                                test-id="submitButtonId"
                                onSubmit={this.contactUsAPIRequest}
                                validationSchema={validationSchema}
                            >
                                {({ values, setFieldValue, errors, touched }) => {
                                    return (
                                        <Form style={{ marginBottom: '0px' }}>
                                            <Box className={classes.fieldOverflow}>
                                            <Box className={`${classes.distanceBtwncum}`}>
                                                <Box className={classes.textAndCloseStylecum}>
                                                <CustomTextField
                                                    fieldTitle={i18next.t('contactUs.firstName')}
                                                    placeholder={i18next.t('contactUs.enterFirstName')}
                                                    value={values.first_name}
                                                    fieldName="first_name"
                                                    onChange={setFieldValue}
                                                    error={errors.first_name as string}
                                                    touched={touched.first_name as boolean}
                                                    onlyText={true}
                                                   
                                                />
                                                <CustomTextField
                                                    fieldTitle={i18next.t('contactUs.lastName')}
                                                    placeholder={i18next.t('contactUs.enterLastName')}
                                                    value={values.last_name}
                                                    fieldName="last_name"
                                                    onChange={setFieldValue}
                                                    error={errors.last_name as string}
                                                    touched={touched.last_name as boolean}
                                                    onlyText={true}
                                                />
                                                </Box>
                                            </Box>
                                            <Box className={`${classes.distanceBtwncum}`}>
                                                <CustomTextField
                                                    fieldTitle={i18next.t('contactUs.email')}
                                                    placeholder={i18next.t('contactUs.enterEmail')}
                                                    value={values.email}
                                                    fieldName="email"
                                                    onChange={setFieldValue}
                                                    error={errors.email as string}
                                                    touched={touched.email as boolean}
                                                    onlyText={true}
                                                />
                                            </Box>
                                            <Box className={`${classes.distanceBtwncum}`}>
                                                <CustomTextField
                                                    fieldTitle={i18next.t('contactUs.phone')}
                                                    placeholder={i18next.t('contactUs.enterPhoneNumber')}
                                                    value={values.phoneNumber}
                                                    fieldName="phoneNumber"
                                                    onChange={setFieldValue}
                                                    error={errors.phoneNumber as string}
                                                    touched={touched.phoneNumber as boolean}
                                                    onlyText={true}
                                                />
                                            </Box>
                                            <Box className={`${classes.distanceBtwncum}`}>
                                                <CustomTextField
                                                    fieldTitle={i18next.t('contactUs.message')}
                                                    placeholder={i18next.t('contactUs.writeSomething')}
                                                    value={values.comments}
                                                    fieldName="comments"
                                                    onChange={setFieldValue}
                                                    error={errors.comments as string}
                                                    touched={touched.comments as boolean}
                                                    multiline={true}
                                                    maxRows={7}
                                                    minRows={4}
                                                    onlyText={true}
                                                />
                                            </Box>
                                            </Box>
                                            <Box
                                                className={`${classes.textAndCloseStylecum} ${classes.distanceBtnFld}`}
                                            >
                                                <Button
                                                    className={classes.submitBtnStylecum}
                                                    type="submit"
                                                >
                                                    {i18next.t('contactUs.submit')}
                                                </Button>
                                            </Box>
                                        </Form>
                                    )
                                }}
                            </Formik>
                        </Box>
                    </Box>
                </Modal>
                <ResponseStatusModal
                    open={responseStatsModal}
                    isSuccess={isSuccessIcon}
                    message={responseModalMsg}
                    onClose={this.handleCloseResponseStatsModal}
                />
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const stylesContactus = (theme: Theme) => ({
    paperMainWrappercum: {
        position: 'absolute' as const,
        width: '451px',
        backgroundColor: theme.palette.background.paper,
        boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.18)',
        border: '1px solid #C3CAD9',
        // padding: '16px 20px',
        borderRadius: '32px',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'initial' as const,
    },
    modalEditContentcum: {
        padding: '32px 32px 40px 32px',
        maxWidth: '100%',
        maxHeight: '100%',
        overflow: 'auto',
    },
    textAndCloseStylecum: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '20px'
    },
    fieldOverflow: {
        maxHeight: '66vh',
        overflowY: 'scroll' as const
    },
    headingTxtcum: {
        fontFamily: 'Inter',
        fontSize: '20px',
        fontWeight: 600,
        lineHeight: '121%',
        fontStyle: 'normal',
        color: '#212426',
    },
    bodyTxtcum: {
        color: 'rgba(33, 36, 38, 0.80)',
        fontFamily: 'Inter',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '150%'
    },
    closeButtoncum: {
        cursor: 'pointer',
    },
    submitBtnStylecum: {
        backgroundColor: primaryColor,
        color: '#ffffff',
        textTransform: 'initial' as const,
        borderRadius: '49px',
        padding: '10px 50px',
        fontSize: '16px',
        fontFamily: "Inter",
        fontWeight: 400,
        width: '170px',
        height: '48px',
        lineHeight: '150%',
        whiteSpace: 'nowrap' as const,
        '&:hover': {
            backgroundColor: primaryColor,
            color: '#ffffff',
        }
    },
    distanceBtwncum: {
        marginTop: '20px'
    },
    distanceBtnFld: {
        marginTop: '32px'
    },
    [theme.breakpoints.down('md')]: {
        paperMainWrappercum: {
            position: 'absolute' as const,
            width: '468px',
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            padding: '12px 16px',
            borderRadius: '16px',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            textAlign: 'initial' as const,
        },
        modalEditContentcum: {
            padding: '32px 32px 40px 32px',
            maxWidth: '100%',
            maxHeight: '100%',
            overflow: 'auto',
        },
    },
    [theme.breakpoints.down('sm')]: {
        paperMainWrappercum: {
            position: 'absolute' as const,
            width: '370px',
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            padding: '8px 12px',
            borderRadius: '16px',
            top: '30%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            textAlign: 'initial' as const,
        },
        modalEditContentcum: {
            padding: '1rem 1.2rem',
            maxWidth: '100%',
            maxHeight: '50vh',
            overflowY: 'scroll',
        },
    }
});

const ContactusModalWeb = withStyles(stylesContactus)(ContactusModal)
export { ContactusModalWeb }
// Customizable Area End
