import React from 'react';
import { Grid, Typography, Divider, makeStyles } from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import i18next from 'i18next';


// Define individual CSS styles using makeStyles
const useStyles = makeStyles((theme) => ({
  rating: {
    color: "#FE7F2D",
    marginBottom: '20px'
  },
  fontWeight600: {
    fontWeight: 600
  },
  blockTitle1: {
    // Define your styles for blockTitle1
    fontWeight: 600
  },
  marginBottom16: {
    marginBottom: '16px'
  },
  textOverflow: {
    // Define your styles for textOverflow
  }
}));

// Define TypeScript typings for the props
interface ReviewListProps {
  reviews: Array<{
    attributes: {
      id: number;
      trip_id: number;
      rating: number;
      title: string | null;
      comment: string | null;
      created_at: string;
      commenter?: {
        id: number;
        name: string;
      };
      reviewer?: {
        id: number;
        name: string;
      };
    };
  }>;
}

const ReviewList: React.FC<ReviewListProps> = ({ reviews }) => {

  // Use makeStyles hook to get the defined styles
  const classes = useStyles();

  
    const formatMonthDateYear = (dateString: string): string => {
        const date = new Date(dateString);
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const month = months[date.getMonth()];
        const day = date.getDate();
        const year = date.getFullYear();
        return `${month} ${day}, ${year}`;
    };

  return (
    <div>
      {reviews.length > 0 ? (
        reviews.map((review, index, array) => {
          const { rating, commenter, reviewer, title, comment, created_at } = review.attributes;
          const reviewerName = commenter?.name ?? reviewer?.name;
          return (
            <React.Fragment key={index}>
              <Grid container>
                <Grid item md={3}>
                  <Rating
                    name={`rating-${index}`}
                    value={rating}
                    precision={0.1}
                    readOnly
                    size="small"
                    className={classes.rating} // Use className prop to apply styles
                  />
                  <Typography className={classes.fontWeight600} gutterBottom>
                    {reviewerName}
                  </Typography>
                </Grid>
                <Grid item md={9}>
                  <Typography className={`${classes.blockTitle1} ${classes.marginBottom16}`}>
                    {title}
                  </Typography>
                  <Typography className={`${classes.marginBottom16} ${classes.textOverflow}`} style={{ color: 'rgba(33, 36, 38, 0.80)' }}>
                    {comment}
                  </Typography>
                </Grid>
              </Grid>
              <Typography style={{ color: 'rgba(33, 36, 38, 0.80)', textAlign: 'end', fontFamily: '12px' }}>
                {formatMonthDateYear(created_at)}
              </Typography>
              {index !== array.length - 1 && <Divider style={{ margin: '16px 0 50px' }} />}
            </React.Fragment>
          );
        })
      ) : (
        <Typography className={`${classes.fontWeight600}`} style={{ textAlign: 'center' }}>{i18next.t('travelAgency.reviews.noReviewsYet')}</Typography>
      )}
    </div>
  );
};

export default ReviewList;
