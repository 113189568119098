import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getAuthToken } from "../../../components/src/utils";
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: any;
  token: string;
  errorMsg: string;
  loading: boolean;
  tripList:  Record<string, any>[]
  orders: [];
  loader: boolean,

  // Customizable Area End
}
interface SS {
  id: any;
}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  apiGetQueryStrinurl: string = "";
  apiTopTripsCallId: string = "";
  getOrdersAPICallId: string = "";
  // Customizable Area End
  
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.getDashboardData = this.getDashboardData.bind(this)
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      dashboardData:{
        "upcoming_trip_schedules": 0,
        "total_booking": 0,
        "total_rating": 0,
        "average_rating": 0,
        "total_review": 0
      },
      errorMsg: "",
      token: "",
      loading: false,
      tripList: [],
      orders: [],
      loader : false
    };
    this.getOrdersDataRequest=this.getOrdersDataRequest.bind(this);
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener('willFocus', () => {
        this.getToken();
      });
    }
    // Customizable Area Start
        const result = await getAuthToken()
        const subscripitonPlansget = await getStorageData("subscriptionPlan")
        if (subscripitonPlansget === "false") {
          this.subscriptionPlan();
      }
    
       this.setState({ token: result, loading: true }, () => {
      this.getDashboardData();
      this.getTopTripsdata();
      this.getOrdersDataRequest()
    });
    // Customizable Area End
  }
  
  getToken=()=>{
    const msg: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(msg);
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      "Authorization": `Bearer ${this.state.token}`
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDashboardItemCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.tripsOverviewEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const sessionResponseMessage = getName(MessageEnum.SessionResponseMessage);
    const sessionSaveMessage = getName(MessageEnum.SessionSaveMessage);
    const apiResponseMessage = getName(MessageEnum.RestAPIResponceMessage);

    if(message.id == sessionSaveMessage || message.id === sessionResponseMessage){
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token });
    } else if (message.id === apiResponseMessage) {
      this.handleApiResponseMessage(message);
    }
    // Customizable Area End
  }

  // Customizable Area Start

  subscriptionPlan = () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "SubscriptionPlan"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  
  handleApiResponseMessage = (message: Message) => {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    switch (apiRequestCallId) {
      case this.apiDashboardItemCallId:
        this.getDashboardDataResponse(responseJson, errorReponse)
        break;
      case this.apiTopTripsCallId:
        this.getTopTripsResponse(responseJson, errorReponse)
        break;
      case this.getOrdersAPICallId:
          this.getOrderDataResponse(responseJson, errorReponse)
          break;
      default:
        break;
    }
  }
  
  seeAllBooking = () => {
    this.props.navigation.navigate('MyBookings')
  } 

  seeAllTrips = () => {
    this.props.navigation.navigate('TripList')
  }

  getDashboardDataResponse = (responseJson: any, errorReponse: any) => {
    const {errors = null, data = null} = responseJson
    if (responseJson && !errors && data) {
      if (data.attributes) {

        this.setState({
          dashboardData: data.attributes,
          errorMsg: "",
          loading: false
        });
      }
    } else {
      this.setState({
        errorMsg: errorReponse ? errorReponse : "Something went wrong",
        loading: false
      });
    }
  }

  getOrderDataResponse = (responseJson: any, errorReponse: any) => {
    if (responseJson) {
      this.setState({
        orders: responseJson?.bookings?.data,
        errorMsg: "",
        loading: false,
        loader:false
      });
     
    } else {
      const errorMessage = errorReponse ? errorReponse : "Something went wrong";
      this.setState({
        errorMsg: errorMessage,
        loading: false
      });
    }
  }

  getTopTripsResponse = (responseJson: any, errorReponse:any) => {
    const { errors = null, data = null, error = null} = responseJson
    if (data && Array.isArray(data)) {
      this.setState({
        tripList: data,
        loading: false
      })
    } else if( errors && Array.isArray(errors) ){
      this.setState({
        errorMsg: errors[0].token,
        loading: false
      });
    } else {
      this.setState({
        errorMsg: error ? error : errorReponse,
        loading: false
      });
    }
  }

  getOrdersDataRequest() {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      "Authorization": `Bearer ${this.state.token}`
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getOrdersAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.ordersAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({loader: true})
    return true;
  }
  
  getTopTripsdata = () => {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      "Authorization": `Bearer ${this.state.token}`
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiTopTripsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.topTripsApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goToBookingDetails = (id:number) => {
    this.props.navigation.navigate("BookingDetails",
      {
        id: id,
      })
  }
  hanldeNavigateToTrips = (id : number)=>{
    this.props.navigation.navigate('SingleTripDetails', {
     id :id
    })
  }

  // Customizable Area End

}
