import React from "react";

import {
    // Customizable Area Start
    Box,
    Typography,
    Divider,
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider, withStyles, Theme } from "@material-ui/core/styles";
import Breadcrumbs from "../../../components/src/Breadcrumbs";
import Rating from '@material-ui/lab/Rating';
import ReviewList from "../../../components/src/ReviewList.web";
import RatingSlider from "../../../components/src/RatingSlider.web";
import { Pagination } from '@material-ui/lab';
import i18next from 'i18next';


const theme = createTheme({
    overrides: {
        MuiTypography: {
            gutterBottom: {
                marginBottom: '8px'
            }
        }
    },
    typography: {
        body1: {
            color: '#212426',
            fontFamily: 'Inter',
            fontStyle: "normal",
            fontWeight: 400,
            letterSpacing: 0,
            fontSize: '16px',
            lineHeight: '150%'
        }
    }
});

// Customizable Area End

import ReviewsController, {
    Props,
    configJSON,
} from "./ReviewsController";
import { primaryColor } from "../../../components/src/theme";


export default class ServiceProviderReviews extends ReviewsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const {
            providerData, selectedStars
        } = this.state;

        const startRange = (providerData.page_options.current_page - 1) * Number(providerData.page_options.per_page) + 1;
        const endRange = Math.min(providerData.page_options.current_page * Number(providerData.page_options.per_page), providerData.page_options.total_items);

        const rangeString = `${startRange} - ${endRange}`;
        // Customizable Area End
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <Typography
                    className={classes.mainTitle}
                >
                    {i18next.t('travelAgency.reviews.reviews')}
                </Typography>
                <div
                    className={classes.mainContainer}
                >
                    <div className={classes.marginStyles}>
                        <Box className={classes.breadCrumbStyle}>
                            <Breadcrumbs list={[
                                {
                                    title: i18next.t('travelAgency.reviews.dashboard'),
                                    link: '/Dashboard'
                                },
                                {
                                    title: i18next.t('travelAgency.reviews.reviews')
                                }
                            ]} />
                        </Box>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', marginBottom: '32px' }}>
                        <div>
                            <Typography className={classes.secondaryTitle}>{i18next.t('travelAgency.reviews.customerReviewAndRatings')}</Typography>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                <Rating
                                    // name={`rating-${index}`}
                                    value={parseFloat(providerData.average_rating.toFixed(2))}
                                    precision={0.1}
                                    readOnly
                                    size="small"
                                    className={classes.rating} // Use className prop to apply styles
                                />
                                <Typography style={{ fontFamily: 'Inter', fontWeight: 600, fontSize: '20px' }}>{providerData?.average_rating}</Typography>
                            </div>
                            <Typography style={{ fontFamily: 'Inter', fontWeight: 400, fontSize: '12px' }}>{i18next.t('travelAgency.reviews.reviewedBy')}{` ${providerData?.total_number_of_review}`}{i18next.t('travelAgency.reviews.customers')}</Typography>
                        </div>
                        <div>
                            <RatingSlider
                                count5={providerData.star_rating.find(item => "5_star_rating" in item)?.["5_star_rating"] ?? 0}
                                count4={providerData.star_rating.find(item => "4_star_rating" in item)?.["4_star_rating"] ?? 0}
                                count3={providerData.star_rating.find(item => "3_star_rating" in item)?.["3_star_rating"] ?? 0}
                                count2={providerData.star_rating.find(item => "2_star_rating" in item)?.["2_star_rating"] ?? 0}
                                count1={providerData.star_rating.find(item => "1_star_rating" in item)?.["1_star_rating"] ?? 0}
                                onRatingSelect={this.handleSelectedStar}
                                selectedStars={selectedStars}
                            />

                        </div>
                    </div>
                    <Divider />
                    <div style={{ marginTop: '32px' }}>
                                <ReviewList
                                    reviews={providerData?.data}
                                />
                    </div>
                    {providerData.page_options.total_pages > 0 &&
                    <>
                        <Divider style={{ margin: "32px 0", }} />
                        <Box position={"relative"}>
                            <Box display={"flex"} padding={"32px"}>
                                <Pagination
                                    count={providerData.page_options.total_pages}
                                    page={Number(providerData.page_options.current_page)}
                                    onChange={this.handlePaginationChange}
                                    // defaultPage={1}
                                    classes={{
                                        root: classes.paginationRoot,
                                        ul: classes.paginationItemR
                                    }}
                                />
                            </Box>
                            <Typography className={classes.paginationItemsR}>
                                {rangeString} {i18next.t('travelAgency.reviews.of')} {providerData.page_options.total_items} {i18next.t('travelAgency.reviews.items')}
                            </Typography>
                        </Box>
                    </>}

                </div>
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const stylesSPReviews = (theme: Theme) => ({
    mainContainer: {
        backgroundColor: '#FFF',
        marginBottom: '90px',
        borderRadius: '10px',
        padding: '32px 48px'
    },
    rating: {
        color: "#FE7F2D",
      },
      mainTitle: {
        fontFamily: 'Inter',
        fontSize: '28px',
        fontStyle: 'normal',
        lineHeight: 'normal',
        fontWeight: 600,
        color: '#212426',
        margin: "46px auto 27px", // Center the div horizontally
    },
    secondaryTitle: {
        fontFamily: 'Inter',
        fontSize: '24px',
        fontStyle: 'normal',
        lineHeight: 'normal',
        fontWeight: 600,
        color: '#212426',
        margin: "46px auto 27px", // Center the div horizontally
    },
    paginationItemR: {
        "& .Mui-selected, & .Mui-selected:hover": {
            backgroundColor: "transparent", // Change this to the desired background color
            color: primaryColor, // Change this to the desired text color
        }
    },
    paginationItemsR: {
        top: '39%',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '24px',
        fontFamily: 'Inter',
        fontStyle: 'normal',
        paddingLeft: '32px',
        letterSpacing: '0.1px',
        position: 'absolute' as const,
        color: 'rgba(33, 36, 38, 0.80)',
    },
    paginationRoot: {
        margin: 'auto',
        '& li:first-child': {
            borderRadius: '8px',
            border: '1px solid rgba(33, 36, 38, 0.20)',
            padding: '4px 4.9px'
        },
        '& li:last-child': {
            borderRadius: '8px',
            border: '1px solid rgba(33, 36, 38, 0.20)',
            padding: '4px 4.9px'
        },
        '& .MuiPaginationItem-root': {
            color: 'rgba(33, 36, 38, 0.50)',
            textAlign: 'center',
            fontFamily: 'Inter',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '24px',
            letterSpacing: '0.1px',
            display: 'flex',
            width: '39.206px',
            height: '32px',
            justifyContent: 'center',
            alignItems: 'center',
            '&.Mui-selected': {
                color: '#FE7F2D',
                backgroundColor: 'transparent',
                '&:hover': {
                    backgroundColor: 'transparent'
                }
            }
        }
    },
    [theme.breakpoints.down(1280)]: {
        breadToTrip: {
            marginBottom: '48px'
        },
        marginStyles: {
            margin: '0 48px'
        },
        mainTitle: {
            fontFamily: 'Inter',
            fontSize: '28px',
            fontStyle: 'normal',
            lineHeight: 'normal',
            fontWeight: 600,
            color: '#212426',
            margin: "46px auto 27px", // Center the div horizontally
        },
        mainContainer: {
            backgroundColor: '#F6FBFB',
            marginBottom: '72px'
        },
    },
    [theme.breakpoints.down(768)]: {
        breadToTrip: {
            marginBottom: '32px'
        },
        marginStyles: {
            margin: '0 16px'
        },
        mainTitle: {
            fontFamily: 'Inter',
            fontSize: '20px',
            fontStyle: 'normal',
            lineHeight: 'normal',
            fontWeight: 600,
            color: '#212426',
            margin: "29px auto 16px", // Center the div horizontally
        },
        mainContainer: {
            backgroundColor: '#F6FBFB',
            marginBottom: '48px'
        },
    },
    [theme.breakpoints.up(950)]: {
        breadCrumbStyle: {
            display: 'none'
        }
    }
});

const ServiceProviderReviewsWeb = withStyles(stylesSPReviews)(ServiceProviderReviews)
export { ServiceProviderReviewsWeb }
// Customizable Area End
