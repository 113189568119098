import React from "react";

import {
    // Customizable Area Start
    Box,
    Typography,
    Button,
    Grid,
    Divider
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider, withStyles, Theme } from "@material-ui/core/styles";
import { primaryColor } from "../../../components/src/theme";
import ResponseStatusModal from "../../../components/src/ResponseStatusModal";
import CustomTextField from "../../../components/src/CustomTextField";
import PhoneInput from "../../../components/src/PhoneInput";
import Breadcrumbs from "../../../components/src/Breadcrumbs";
import { CombinedItinerary } from "./Interface.we";
import { Formik, Form, FormikErrors, FormikTouched } from 'formik';
import CFItineraryInformation from "../../../components/src/CFItineraryInformation";
import { validationSchemaForPlanItinerary } from "../../../components/src/Step3CET";
import i18next from 'i18next';

interface CumtomPlanItineraryProps {
    values: CombinedItinerary;
    setFieldValue: (field: string, value: any) => void,
    errors: FormikErrors<CombinedItinerary>;
    touched: FormikTouched<CombinedItinerary>;
}


const theme = createTheme({
    overrides: {
        MuiTypography: {
            gutterBottom: {
                marginBottom: '8px'
            }
        }
    },
    typography: {
        body1: {
            color: '#212426',
            fontFamily: 'Inter',
            fontStyle: "normal",
            fontWeight: 400,
            letterSpacing: 0,
            fontSize: '16px',
            lineHeight: '150%'
        }
    }
});

// Customizable Area End

import CustomformController, {
    Props,
    configJSON,
} from "./CustomformController";

export default class PlanItinerary extends CustomformController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const {
            responseModalMsg,
            isSuccessIcon,
            responseStatsModal,
            customer_info,
            countryList,
            vibeList,
            cityList,

        } = this.state;


        const combinedInitialValues = {
            first_name: customer_info.first_name,
            last_name: customer_info.last_name,
            email: customer_info.email,
            country_code: customer_info.country_code || '93',
            onlyPhone: customer_info.mobile_number,
            type_of_help: '',
            have_date: '',
            from_date: undefined,
            to_date: undefined,
            persons: '',
            know_destination: '',
            country: '',
            city: '',
            have_buget: '',
            budget_amount: '',
            vibes: [],
        };


        const customerInformation = ({ values, setFieldValue, errors, touched }: CumtomPlanItineraryProps) => {
            return <Box>
                <Grid container spacing={3} justifyContent="space-between">
                    <Grid item md={6} xs={12}>
                        <CustomTextField
                            fieldTitle={i18next.t('itinerary.first_name')}
                            placeholder={i18next.t('itinerary.first_name')}
                            value={values.first_name}
                            fieldName="first_name"
                            onChange={setFieldValue}
                            error={errors.first_name as string}
                            touched={touched.first_name as boolean}
                            planItineraryTextColor={true}
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <CustomTextField
                            fieldTitle={i18next.t('itinerary.last_name')}
                            placeholder={i18next.t('itinerary.last_name')}
                            value={values.last_name}
                            fieldName="last_name"
                            onChange={setFieldValue}
                            error={errors.last_name as string}
                            touched={touched.last_name as boolean}
                            planItineraryTextColor={true}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3} justifyContent="space-between">
                    <Grid item md={6} xs={12}>
                        <CustomTextField
                            fieldTitle={i18next.t('itinerary.email')}
                            placeholder={i18next.t('itinerary.email')}
                            value={values.email}
                            fieldName="email"
                            onChange={setFieldValue}
                            error={errors.email as string}
                            touched={touched.email as boolean}
                            planItineraryTextColor={true}
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Typography
                            style={{
                                fontFamily: "Inter",
                                fontSize: "18px",
                                color: 'rgba(33, 36, 38, 0.80)',
                                marginBottom: "16px",
                                fontWeight: 500,
                                lineHeight: '150%'
                                
                            }}
                        >
                            {i18next.t('itinerary.mobile_number')}<span style={{ color: 'red' }}> *</span>
                        </Typography>
                        {
                            values.country_code &&
                            (<PhoneInput
                               phoneError={errors.onlyPhone as string}
                               isPhoneNumberValid={true}
                               phoneErrorMsg={''}
                               touchedPhone={touched.onlyPhone as boolean}
                               setFullPhoneNumber={setFieldValue}
                               phoneValue={values.onlyPhone}
                               sendEmailOrSmsOtpRequest={() => { } }
                               countryData={countryList}
                               isPhoneVarified={false}
                               handleCountryCodeChanges={this.handleCountryCodeChange}
                               country_code={values.country_code}
                               planItineraryPhn={true}
                                handleValueChange={()=>{}}
                                />
                            )
                        }
                    </Grid>
                </Grid>
                <Divider className={classes.dividerMarginPI} />
            </Box>
        }



        // Customizable Area End
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <Box className={classes.breadcrumbContainer}>
                    <Breadcrumbs list={[
                        {
                            title: i18next.t('itinerary.home'),
                            link: '/LandingPage'
                        },
                        {
                            title: i18next.t('itinerary.plan_itinerary')
                        },
                    ]} />
                    <Divider className={classes.dividerMarginPI} />
                </Box>
                <Box className={classes.mainContainerPI}>
                    <Box>
                        <Typography className={classes.textHeadingPI}>{i18next.t('itinerary.plan_itinerary')}
                            <br /> <span className={classes.selectionTextPI}>{i18next.t('itinerary.share_preferences')}</span>
                        </Typography>
                    </Box>
                    <Box className={classes.customerHeadingmarginPI}>
                        <Typography className={classes.customerheadingTextPI}>{i18next.t('itinerary.customer_information')}</Typography>
                    </Box>
                    <Formik
                        enableReinitialize={true}
                        initialValues={combinedInitialValues}
                        test-id="submitButtonId"
                        onSubmit={this.submitItineraryPlan}
                        validationSchema={validationSchemaForPlanItinerary}
                    >
                        {({ values, setFieldValue, errors, touched }) => {
                            return (
                                <Form style={{ marginBottom: '0px' }}>
                                    {customerInformation({ values, setFieldValue, errors, touched })}
                                    <Box className={classes.customerHeadingmarginPI}>
                                        <Typography className={classes.customerheadingTextPI}>{i18next.t('itinerary.itinerary_information')}</Typography>
                                    </Box>
                                    {
                                        <CFItineraryInformation
                                            values={values}
                                            setFieldValue={setFieldValue}
                                            errors={errors}
                                            touched={touched}
                                            countryList={countryList}
                                            cityList={cityList}
                                            vibeList={vibeList}
                                            getCityRequest={this.getCityListAPIRequest}
                                        />}
                                    <Grid container spacing={3} justifyContent="space-between">
                                        <Grid item md={4} xs={12}>
                                            {""}
                                        </Grid>
                                        <Grid item md={8} xs={12}>
                                            <Box className={classes.btnCntnrPI}>
                                                <Button className={classes.nextButtonPI} type="submit">{i18next.t('itinerary.submit')}</Button>
                                                <Button data-test-id="cancel" className={classes.calcelButtonPI} onClick={() => this.gotoLandingPage()}>{i18next.t('itinerary.cancel')}</Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )
                        }}
                    </Formik>
                </Box>
                <ResponseStatusModal
                    open={responseStatsModal}
                    isSuccess={isSuccessIcon}
                    message={responseModalMsg}
                    onClose={this.closeResponseModal}
                />
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const stylesPlanItineray = (theme: Theme) => ({
    breadcrumbContainer: {
        margin: '48px 72px 0px'
    },
    mainContainerPI: {
        margin: 'auto 183px auto'
    },
    textHeadingPI: {
        color: '#212426',
        fontSize: '48px',
        fontFamily: 'Inter',
        fontStyle: 'normal',
        lineHeight: '115%',
        fontWeight: 600,
    },
    headingToTopSecPI: {
        marginTop: '60px',
    },
    btwnSecPI: {
        marginTop: '96px',
    },
    headingPI: {
        color: '#212426',
        fontSize: '24px',
        fontFamily: 'Inter',
        fontStyle: 'normal',
        lineHeight: '135%',
        fontWeight: 700,
    },
    selectionTextPI: {
        color: '#212426',
        fontSize: '16px',
        fontFamily: 'Inter',
        fontStyle: 'normal',
        lineHeight: '150%',
        fontWeight: 400,
    },
    customerHeadingmarginPI: {
        margin: '48px auto 31px'
    },
    customerheadingTextPI: {
        color: '#212426',
        fontSize: '24px',
        fontFamily: 'Inter',
        fontStyle: 'normal',
        lineHeight: '121%',
        fontWeight: 600,
    },
   
    dividerMarginPI: {
        margin: '48px auto 48px'
    },
    
   
    imageListPI: {
        display: 'flex',
        flexWrap: 'wrap' as const,
        justifyContent: 'space-between',
        gap: '32px',
        marginTop: '31px',
    },
    imageListItemPI: { margin: 'auto' },
    imageContainerPI: {
        position: 'relative' as const,
        width: '100%',
        height: 'auto',
        justifyContent: 'center',
        display: 'flex',
    },
    responsiveImgPI: {
        height: '494px'
    },
    imagePI: {
        width: '100%',
        borderRadius: '16px',
        height: 'auto',
        objectFit: 'fill' as const,
    },
    textPI: {
        position: 'absolute' as const,
        bottom: 0,
        transform: 'translateX(-50%)',
        left: '50%',
        padding: '32px 0',
        color: '#FFF',
        textAlign: 'center' as const,
        width: '100%',
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '24px',
        lineHeight: '140%',
        borderRadius: '16px',
        background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 100%)',
    },
    btnCntnrPI: {
        width: '100%',
        display: 'flex',
        margin: '64px auto 120px auto',
        gap: '64px'
    },
    nextButtonPI: {
        width: '190px',
        borderRadius: '68px',
        height: '56px',
        border: '1px solid #FE7F2D',
        textTransform: 'initial' as const,
        color: '#fff',
        background: '#FE7F2D',
        textAlign: 'center' as const,
        fontFamily: 'Inter',
        fontStyle: 'normal' as const,
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '150%',
        '&:hover': {
            backgroundColor: primaryColor,
            color: '#ffffff',
        }
    },
    calcelButtonPI: {
        width: '190px',
        borderRadius: '68px',
        height: '56px',
        background: '#FFF',
        textTransform: 'initial' as const,
        textAlign: 'center' as const,
        fontFamily: 'Inter',
        color: primaryColor,
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '150%',
        fontStyle: 'normal' as const,
        '&:hover': {
            backgroundColor: '#ffffff',
            color: primaryColor,
        }
    },
    [theme.breakpoints.between(560.05, 1024.05)]: {
        headingPI: { fontSize: '36px' },
        imageListPI: {
            marginTop: '48px',
            gap: '32px',
            flexWrap: 'wrap'
        },
        responsiveImgPI: {
            objectFit: 'cover',
            borderRadius: '16px',
            width: '320px',
            height: '414px',
        },
        breadcrumbContainer: {
            margin: '24px 30px 0px'
        },
        dividerMarginPI: {
            margin: '24px auto 24px'
        },
        mainContainerPI: {
            margin: 'auto 113px auto'
        },
        textHeadingPI: {
            fontSize: '36px'
        },
        customerHeadingmarginPI: {
            margin: '36px auto 31px'
        }
    },
    [theme.breakpoints.down(560.05)]: {
        headingPI: {
            fontSize: '20px',
            fontWeight: 600,
            lineHeight: '100%',
            '&.sliderHeading': {
                fontSize: '18px'
            }
        },
        imageListPI: {
            marginTop: '24px',
            gap: '16px',
            flexWrap: 'wrap'
        },
        responsiveImgPI: {
            width: '156px',
            minWidth: 'auto',
            height: '208px',
            objectFit: 'cover'
        },
        imagePI: {
            borderRadius: '8px'
        },
        breadcrumbContainer: {
            margin: '24px 30px 0px'
        },
        dividerMarginPI: {
            margin: '24px auto 24px'
        },
         mainContainerPI: {
            margin: 'auto 24px auto'
        },
        textHeadingPI: {
            fontSize: '24px'
        },
        selectionTextPI: {
            fontSize: '14px'
        },
        customerHeadingmarginPI: {
            margin: '24px auto 24px'
        },
    }
});

const PlanItineraryWeb = withStyles(stylesPlanItineray)(PlanItinerary)
export { PlanItineraryWeb }
// Customizable Area End
