
import React from "react";

import {
   TableContainer,
   Table, TableBody, TableHead, TableCell, TableRow

} from "@material-ui/core";
import { capitalizeFirstLetter, ternaryCondition } from "./utils";
import i18next from 'i18next';


const columns = [
   { id: 'name', label: 'Name', minWidth: '200px' },
   { id: 'date', label: 'Date', maxWidth: '144px' },
   {
      id: 'tripTitle', label: 'Trip Title',
      maxWidth: '235px', style: {
         maxWidth: '235px',
         overflow: 'hidden',
         textOverflow: 'ellipsis',
      },
   },
   { id: 'country', label: 'Country', maxWidth: '144px' },
   { id: 'to', label: 'To', maxWidth: '144px' },
   { id: 'amount', label: 'Amount', maxWidth: '144px' },
   { id: 'bookingStatus', label: 'Booking Status', maxWidth: '172px', style: { width: '110px' } }
];



type BookingStatus = 'completed' | 'cancelled' | 'closed' | 'payment_captured' | 'refunded' | 'created';

const statusStyles: Record<BookingStatus, React.CSSProperties> = {
   completed: {
      background: 'rgb(194, 255, 211)',
      borderRadius: 5,
      textTransform: 'capitalize' as 'capitalize',
      padding: '0 30px'
   },
   cancelled: {
      background: 'rgb(251, 231, 233)',
      borderRadius: 5,
      textTransform: 'capitalize' as 'capitalize',
      padding: '0 30px'
   },
   closed: {
      background: 'rgb(255, 245, 186)',
      borderRadius: 5,
      textTransform: 'capitalize' as 'capitalize',
      padding: '0 30px'
   },
   payment_captured: {
      background: '#f4f4c1',
      borderRadius: 5,
      textTransform: 'capitalize' as 'capitalize',
      padding: '0 30px'
   },
   refunded: {
      background: 'rgb(237, 194, 251)',
      borderRadius: 5,
      textTransform: 'capitalize' as 'capitalize',
      padding: '0 30px'
   },
   created: {
      background: '#f4f4c1',
      borderRadius: 5,
      textTransform: 'capitalize' as 'capitalize',
      padding: '0 30px'
   }
};


const renderContent = (columnId: string, row: any) => {
   switch (columnId) {
      case 'name':
         return (
            <div style={webStyle.flexContainers}>
               <img src={row?.attributes?.trip_image} alt='users' style={{ width: '50px', height: '50px', borderRadius: '50%' }} />
               <div style={webStyle.userName}>{row.attributes.customer_name}</div>
            </div>
         );
      case 'date':
         return (
            <div style={webStyle.flexContainer}>
               <div style={webStyle.userName}>{row.attributes.trip_date}</div>
            </div>
         );
      case 'tripTitle':
         return (
            <div style={webStyle.flexContainer}>
               <div style={webStyle.userName}>{row.attributes.trip_title}</div>
            </div>
         );
      case 'country':
         return (
            <div style={webStyle.flexContainer}>
               <div style={webStyle.userName}>
                  {row?.attributes?.trip_country?.data?.attributes.name}
               </div>
            </div>
         );
      case 'to':
         return (
            <div style={webStyle.flexContainer}>
               <div style={webStyle.userName}>{capitalizeFirstLetter(row?.attributes?.to)}</div>
            </div>
         );
      case 'amount':
         return (
            <div style={webStyle.flexContainer}>
               <div style={webStyle.userName}>{row.attributes.price}</div>
            </div>
         );
      case 'bookingStatus':
         return renderBookingStatus(row.attributes.status);
      default:
         return null;
   }
};

const renderBookingStatus = (status: string) => {
   if (status in statusStyles) {
      return <div style={statusStyles[status as BookingStatus]}>{status}</div>;
   }
   return <div>{status}</div>
}

export const bookingListTable = (classes: any, orders: any, handleNavigation: (id: number) => void, style?: any, loader?: any) => {
   return (
      <TableContainer>
         <Table stickyHeader aria-label='sticky table'>
            <TableHead>
               <TableRow>
                  {columns.map((item, index, list) => {
                     let columnTitle = item.label;

                     switch (columnTitle) {
                        case 'Name':
                           columnTitle = i18next.t('travelAgency.home.name');
                           break;
                        case 'Date':
                           columnTitle = i18next.t('travelAgency.home.date');
                           break;
                        case 'Trip Title':
                           columnTitle = i18next.t('travelAgency.home.tripTitle');
                           break;
                        case 'Country':
                           columnTitle = i18next.t('travelAgency.home.country');
                           break;
                        case 'To':
                           columnTitle = i18next.t('travelAgency.home.to');
                           break;
                        case 'Amount':
                           columnTitle = i18next.t('travelAgency.home.amount');
                           break;
                        case 'Booking Status':
                           columnTitle = i18next.t('travelAgency.home.bookingStatus');
                           break;
                        default:
                           // Retain the original label if no case matches
                           columnTitle = item.label;
                     }


                     return (
                        <TableCell
                           style={{
                              ...webStyle.tableCell,
                              maxWidth: item.maxWidth,
                              ...ternaryCondition(
                                 index == 0,
                                 {
                                    borderRadius: '8px 0 0 8px',
                                    textAlign: 'left',
                                    paddingLeft: '32px',
                                 },
                                 ternaryCondition(
                                    index == list.length - 1,
                                    {
                                       borderRadius: '0 8px 8px 0',
                                       textAlign: 'end',
                                       paddingRight: '32px',
                                    },
                                    {}
                                 )
                              ),
                              ...style,
                           }}
                           key={item.id}
                           align={'center'}
                        >
                           {columnTitle}
                        </TableCell>
                     )
                  })}
               </TableRow>
            </TableHead>
            <TableBody className={classes.tableBody} data-testid="orderList">
               {
                  /* istanbul ignore next */
                  orders && orders?.length && orders?.length ? orders.map((row: any, index: number) => {
                     return (
                        <TableRow hover role='checkbox' tabIndex={-1} key={index}
                           onClick={() => handleNavigation(row.attributes.id)} style={{ cursor: 'pointer' }}>
                           {columns.map((column, index) => {
                              return (
                                 <TableCell
                                    key={index}
                                    align={'center'}
                                    style={{
                                       ...webStyle.tableRowCell,
                                       ...style
                                    }}
                                 >
                                    {renderContent(column.id, row)}

                                 </TableCell>
                              );
                           })}
                        </TableRow>
                     );
                  })
                     : <TableRow>
                        <TableCell style={{ borderBottom: 'none' }} align={'center'}
                           colSpan={columns.length}>
                           {i18next.t('travelAgency.home.noAvailableBookings')}
                        </TableCell>
                     </TableRow>}
            </TableBody>
         </Table>
      </TableContainer>
   )
}


const webStyle = {
   containerLeft: {
      display: 'flex',
      gap: '24px',
      flexDirection: "column" as "column"
   },
   tableTitle: {
      color: '#212426',
      fontFamily: 'Inter',
      fontSize: '23px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '150%',
      padding: '24px 0 0 24px'
   },
   tableCell: {
      borderBottom: 'none',
      backgroundColor: '#EAF7FF',
      color: 'rgba(33, 36, 38, 0.40)',
      textAlign: 'center' as 'center',
      fontFamily: 'Inter',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: 'normal',
      textTransform: 'uppercase' as 'uppercase',
      whiteSpace: 'nowrap' as 'nowrap',
      padding: '12px 16px 11px'
   },
   tableRowCell: {
      borderBottom: 'none',
      color: 'rgba(33, 36, 38, 0.80)',
      textAlign: 'center' as 'center',
      fontFamily: 'Inter',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '26px',
      whiteSpace: 'nowrap' as 'nowrap',
      padding: '15px 16px'
   },
   Cancelled: {
      background: 'rgb(251, 231, 233)',
      borderRadius: 5,
      textTransform: 'capitalize' as 'capitalize',
      padding: '0 30px'
   },
   Confirmed: {
      background: 'rgb(194, 255, 211)',
      borderRadius: 5,
      textTransform: 'capitalize' as 'capitalize',
      padding: '0 30px'
   },
   Removed: {
      background: 'rgb(255, 245, 186)',
      borderRadius: 5,
      textTransform: 'capitalize' as 'capitalize',
      padding: '0 30px'
   },
   Completed: {
      background: 'rgb(230, 230, 230)',
      borderRadius: 5,
      textTransform: 'capitalize' as 'capitalize',
      padding: '0 30px'
   },
   Refunded: {
      background: 'rgb(237, 194, 251)',
      borderRadius: 5,
      textTransform: 'capitalize' as 'capitalize',
      padding: '0 30px'
   },
   paymentCaptured: {
      background: '#f4f4c1',
      borderRadius: 5,
      textTransform: 'capitalize' as 'capitalize',
      padding: '0 30px'
   },
   userName: {
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '26px',
      fontStyle: 'normal',
      fontFamily: 'Inter',
      whiteSpace: 'nowrap' as 'nowrap',
      letterSpacing: '0.1px',
      maxWidth: '119px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      color: 'rgba(33, 36, 38, 0.80)',
   },
   flexContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '16px',
      width: '200px',
      justifyContent: 'center',

   },
   flexContainers: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'flex-start',
      gap: '16px',
      width: '200px'
   },
   statusValue: {
      color: '#222',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '14px',
      borderRadius: '6px',
      padding: '4px 12px',
      width: '96px',
      margin: 'auto'
   },
   cardWrapper: {
      borderRadius: '20px',
      boxShadow: 'none',
      width: '100%'
   },
   cardWrapperTrips: {
      borderRadius: '20px',
      boxShadow: 'none',
      width: '100%'
   },
   table: {
      padding: '0 24px',
      marginTop: '12px'
   },
   uploadImg: {
      height: '24px',
      width: '24px'
   },

   content: {
      justifyContent: 'center',
      display: 'flex',
      flexDirection: 'column' as 'column'
   },

   rating: {
      display: 'flex',
      alignItems: 'center',
      gap: '12px'
   },
   tripBoldText: {
      color: '#212426',
      fontFamily: 'Inter',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '150%',
      marginBottom: '4px',
   },
   tripTitle: {
      color: 'rgba(33, 36, 38, 0.90)',
      fontFamily: 'Inter',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '150%',
   },
   tripValue: {
      color: '#212426',
      fontFamily: 'Inter',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '150%',
   },
   tripRow: {
      display: 'flex',
      gap: '12px',
      marginBottom: '8px'
   },
   tripRowWrapper: {
      display: 'flex',
      gap: '0 12px',
      marginBottom: '8px',
      flexWrap: 'wrap' as 'wrap',
      justifyContent: 'space-between'
   },

};