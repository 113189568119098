import React from "react";

// Customizable Area Start
import {
    Typography, Box,
    Card, Button,
    CardContent,
} from "@material-ui/core";
import {
    withStyles, Theme, ThemeProvider
} from "@material-ui/core/styles";
import { primaryColor } from "../../../components/src/theme";
import {
    signUptheme
} from "../../email-account-registration/src/EmailAccountRegistration.web";

import {
    KeyboardArrowLeft, KeyboardArrowRight
} from '@material-ui/icons';
export const ButtonStyle = withStyles({
    root: {
        backgroundColor: primaryColor,
        color: '#ffffff',
        minWidth: '160px',
        width: '100%',
        height: '56px',
        maxWidth: '228px',
        flexShrink: 0,
        textAlign: 'center',
        borderRadius: '49px',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '150%',
        fontFamily: [
            'Inter',
            'sans-serif',
        ].join(','),
        '&:hover': {
            backgroundColor: '#fff',
        },
        '&.Mui-disabled': {
            color: '#FFF',
            opacity: 0.6
        }
    },
})(Button);
import Slider from "react-slick";
import { ribbonImage } from "./assets";

// Customizable Area End

import SubscriptionPlanController, {
    Props
} from "./SubscriptionPlanController";
import i18next from "i18next";


export default class SubscriptionPlan extends SubscriptionPlanController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
     

    
      

    // Customizable Area End

    render() {
        // Customizable Area Start

        const { classes } = this.props
        const { subScriptionPlan } = this.state;
        const settings = (slideNo: number, centerMode: boolean = false) => {
            return {
                arrows: true,
                dots: false,
                infinite: true,
                speed: 500,
                adaptiveHeight: false,
                centerMode: centerMode,
                slidesToShow: slideNo,
                slidesToScroll: slideNo,
                responsive: [
                    {
                        breakpoint: 1024, // screen width less than 1024px
                        settings: {
                            slidesToShow: Math.min(3, slideNo), // show 3 slides or less depending on list length
                            slidesToScroll: Math.min(3, slideNo),
                        },
                    },
                    {
                        breakpoint: 768, // screen width less than 768px
                        settings: {
                            slidesToShow: Math.min(2, slideNo), // show 2 slides or less depending on list length
                            slidesToScroll: Math.min(2, slideNo),
                        },
                    },
                    {
                        breakpoint: 480, // screen width less than 480px
                        settings: {
                            slidesToShow: Math.min(1, slideNo), // show 1 slide or less depending on list length
                            slidesToScroll: Math.min(1, slideNo),
                        },
                    },
                ],
            };
        };

        const profileSilder = (list: Record<string, any>[]) => {
            
            const slidesToShow = Math.min(4, list.length); // Show the minimum of 4 or the length of the list
            return <Box style={{ width: "100%", display: 'flex' }}>
                <Slider {...settings(slidesToShow)} className={`${classes.profileSlider}  ${list.length === 1? classes.slickSlider1 : classes.slickSlider} `}
                    nextArrow={<Box><KeyboardArrowRight /></Box>}
                    prevArrow={<Box><KeyboardArrowLeft /></Box>}
                >

                    {list.map((plan, index) => {

                        return (
                            <div data-test-id="checkid" key={plan.id} className={`${list.length > 4 ? classes.innerSlideBlock1 : classes.innerSlideBlock}`}>
                                <Box key={plan.id} className={`${list.length === 1 ? classes.cardItem1 : classes.cardItem}`}>
                                    <Card className={classes.cardWrapper} style={{
                                        position: 'relative'
                                    }} >
                                        {plan.attributes.discount_percent > 0 ?
                                        <Box className={classes.ribbonContainer}>
                                            <img style={{
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'fill',
                                                filter: 'brightness(0) invert(1)'
                                            }}  src={ribbonImage} alt="" />
                                            <Typography className={`${classes.ribbonTextTopBottom} ${classes.ribbonTextTop}`}>
                                                {plan.attributes.discount_percent}%
                                            </Typography>
                                            <Typography className={`${classes.ribbonTextTopBottom} ${classes.ribbonTextBottom}`}>
                                            {i18next.t('travelAgency.subscripitonPlan.status')}
                                            </Typography>
                                        </Box>
                                        : ""
                                         }
                                        <CardContent
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                backgroundColor: "rgb(254, 127, 45)",
                                                color: "white",
                                                position: "relative"
                                            }}
                                            data-testid="subscription-container"
                                        >
                                            <Typography
                                                variant="h6"
                                                title={plan.attributes.name}
                                                style={{
                                                    width: '70%',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    display: '-webkit-box',
                                                    WebkitLineClamp: 2,
                                                    WebkitBoxOrient: 'vertical',
                                                    maxHeight: '3em', // Adjust if needed for line height
                                                }}
                                            >
                                                {plan.attributes.name}
                                            </Typography>
                                            <Typography variant="h6">
                                                <span style={{ fontWeight: 600 }}>
                                                    {plan.attributes.price}
                                                </span>
                                                <span style={{ fontSize: '13px' }}>
                                                    {' '}
                                                    {plan.attributes.currency}/{plan.attributes.duration}
                                                </span>
                                            </Typography>
                                            {/* <div className={`${classes.ribbon} `}>
                                                <div className={`${classes.ribbonText} `}>10SAROFF</div>
                                            </div> */}
                                        </CardContent>
                                        <Box sx={{ padding: '15px' }}>
                                            <Typography
                                                variant="body2"
                                                style={{ marginTop: '15px', wordBreak: 'break-word', maxHeight: '200px', minHeight: "200px", overflow: 'scroll' }}
                                            >
                                                {`${plan.attributes.description}`}
                                            </Typography>
                                        </Box>
                                        <CardContent
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                backgroundColor: "rgb(255, 228, 246)",
                                                color: "white"
                                            }}
                                        >
                                            <Button
                                                onClick={() => {
                                                        this.paymentForm(plan);
                                                
                                                }}
                                                variant="contained"
                                                className={`${classes.buttonStyle} buttonStyleHover`}
                                                data-test-id={`btnGetStarted-${index}`}
                                            >
                                              {plan.attributes.description === "free" ? i18next.t('travelAgency.subscripitonPlan.getStarted') :
                                              i18next.t('travelAgency.subscripitonPlan.comingSoon')}  {}
                                            </Button>
                                        </CardContent>
                                    </Card>
                                </Box>
                            </div>
                        )
                    })}

                </Slider>
            </Box >
        }


        // Customizable Area End
        return (
            // Customizable Area Start
            <ThemeProvider theme={signUptheme} >
                <Box className={classes.container}  style={{ paddingLeft:"40px",paddingRight:"26px"}}>
                    <Box style={{
                        //   width: "600px",
                          maxWidth: "600px",
                          width: "100%",
                          margin: "0 auto"
                         
                    }}>
                        <Typography className={classes.dividerStyle}>
                        {i18next.t('travelAgency.subscripitonPlan.subscriptionTitle')}
                        </Typography>
                        <Typography style={{textAlign:"center"}}>
                            {i18next.t('travelAgency.subscripitonPlan.subscriptionDesc')}
                        </Typography>
                    </Box>
                    <Box className={`${classes.userProfileInfo}`}>

                        {profileSilder(subScriptionPlan)}

                    </Box>

                </Box >
            </ThemeProvider >
            // Customizable Area End
        );
    }
}

// Customizable Area Start

export const ProfileStyles = (theme: Theme) => ({
    profileSlider: {
        // maxHeight: '280px',
        '& img': {
            height: '234px'
        },
        '& .slick-list': {
            margin: '0 0 0 -16px',
            maxHeight: 'inherit'
        },
        width: '100%',
        marginBottom: "50px",
        paddingTop: "20px"
    },
    slickSlider1: {
        height: '100%',
        '& .slick-list': {
            height: '100%',
            '& .slick-slide': {
                // width: 'auto !important',
                width: '100% !important',
                // margin: '0 10px',
                margin: '0 auto',
                height: '324px',
                // position: 'absolute',
                // left: '50%',
                // transform: 'translate(-50%, 0%)'
                display: 'flex',
                justifyContent: 'center',
            }

        },
    },
    slickSlider: {

        height: '100%',
        '& .slick-list': {
            maxHeight: 'inherit',
            height: '100%',
            '& .slick-slide': {
                width: 'auto !important',
                margin: '0 10px',
                height: '324px'
            }
        },
        '& .slick-arrow': {
            width: '40px',
            height: '40px',
            borderRadius: '50%',
            filter: 'drop-shadow(0px 6.588235378265381px 31.05882453918457px rgba(0, 0, 0, 0.07)) drop-shadow(0px 1.8823529481887817px 18.823530197143555px rgba(0, 0, 0, 0.04)) drop-shadow(0px 1.8823529481887817px 3.7647058963775635px rgba(0, 0, 0, 0.08))',
            background: '#fff',
            display: 'flex !important',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: '1',
            top: '260px',
            '& svg': {
                color: '#1F55DE',
            },
            '&:before': {
                display: 'none',
            }
        },
        '& .slick-prev': {
            left: '-10px'
        },
        '& .slick-next': {
            right: '-20px'
        },
        '& .slick-prev.slick-disabled': {
            cursor: 'initial',
            backgroundColor: 'rgba(192, 190, 190, 0.75)',
            '& svg': {
                color: 'rgba(75, 75, 76, 0.75)'
            }
        },
        '& .slick-next.slick-disabled': {
            cursor: 'initial',
            backgroundColor: 'rgba(192, 190, 190, 0.75)',
            '& svg': {
                color: 'rgba(75, 75, 76, 0.75)'
            }
        },
    },
    innerTripSlideBlock: {
        maxHeight: '226px',
        maxWidth: '300px',
        position: 'relative' as 'relative',
        [theme.breakpoints.up(768)]: {
            maxHeight: '213px',
        },
        [theme.breakpoints.up(1024)]: {
            maxHeight: '300px',
        },
        [theme.breakpoints.up(1440)]: {
            maxHeight: '378px',
        },
    },
    innerSlideBlock: {
        maxHeight: '234px',
        maxWidth: '280px',
        position: 'relative' as 'relative',
        paddingTop: "20px"
    },
    innerSlideBlock1: {
        maxHeight: '434px',
        maxWidth: '480px',
        position: 'relative' as 'relative',
        paddingTop: "20px"
    },


    [theme.breakpoints.down(600.05)]: {
        slickSlider1: {
            height: '100%',
            '& .slick-list': {
                maxHeight: 'inherit',
                height: '100%',
                '& .slick-slide': {
                    width: 'auto !important',
                    margin: '0 10px',
                    height: '324px'
                }
            },
        },
        slickSlider: {
            marginTop: '0',
            '& .slick-list': {
                '& .slick-slide': {
                    margin: '0 16px 0 0',
                    marginLeft: '30px',
                }
            },
            '& .slick-arrow': {
                width: '32px',
                height: '32px',
                // top: '60px',
                top: '25%',
            },
            '& .slick-prev': {
                left: '-8px'
            },
            '& .slick-next': {
                right: '-8px'
            }
        },
    },
    container: {
        margin: '0px 0 20px',
    },
    divider: {
        marginTop: '20px'
    },

    buttonStyle: {
        background: '#FE7F2D',
        color: '#fff',
        borderRadius: '30px',
        width: '100%',
        fontWeight: 500,
        fontSize: "1rem",
        textTransform: "none"
        // '&:hover': {
        //     backgroundColor: '#fff',

        //     // transition: 'opactiy 0.3s linear',
        // },
    },

    // ribbon {
    //     position: relative;
    //     background-color: #f58220; /* Orange background */
    //     width: 100px;
    //     height: 100px;
    //     border-radius: 0 20px 20px 0;
    //   }

    ribbon: {
        width: "100px",
        height: "50px",
        position: "absolute",
        top: "25px",
        left: "0",
        borderRadius: "0 20px 20px 0"
    },

    ribbonText: {
        position: 'absolute',
        top: '10px',
        left: '169px',
        color: '#f58220',
        width: '100px',
        height: '30px',
        transform: 'rotate(90deg)',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
        textAlign: 'center',
        lineHeight: '30px',
        backgroundColor: '#fff',

    },

    cardItem: {
        maxWidth: '400px',
        minWidth: '250px',
        flex: '1 1 300px',
        margin: '10px',
        wordBreak: 'break-word',
    },
    cardItem1: {
        minWidth: '350px',
        flex: '1 1 300px',
        margin: '10px',
        wordBreak: 'break-word',
    },
    rootTab: {
        display: 'flex',
        padding: '4px 4px',
        flexDirection: 'column' as const,
        justifyContent: 'center',
        alignItems: 'flex-start',
        gap: '4px',
        minWidth: 'auto',
        minHeight: 'auto'
    },
    flexContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
        gap: '24px',
    },
    rootTabs: {
        minHeight: '32px',
        height: '100%'
    },

    agencyImg: {
        objectFit: 'cover',
        borderRadius: '50px'
    },
    selectedTab: {
        '& span': {
            color: '#212426',
            fontFeatureSettings: "'cv11' on, 'cv01' on, 'ss01' on",
            fontFamily: 'Inter',
            fontWeight: 600,
            lineHeight: '20px',
            fontSize: '16px',
            fontStyle: 'normal',
        }
    },
    tabItem: {
        color: 'rgba(0, 0, 0, 0.40)',
        fontFeatureSettings: "'cv11' on, 'cv01' on, 'ss01' on",
        fontFamily: 'Inter',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '20px',
        textTransform: 'none' as const
    },


    cardWrapper: {
        borderRadius: '16px',
        border: '0.5px solid rgba(33, 36, 38, 0.20)',
        background: '#FFF',
        boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.04)',
        transition: 'transform .2s',
        '&:hover': {
            // backgroundColor: '#F0A974',
            transform: 'scale(1.1)',
            boxShadow: '0 24px 36px rgba(0,0,0,0.11)',
            color: 'rgba(0, 0, 0, 0.87)',
            "& .buttonStyleHover": {
                background: 'rgb(254, 127, 45)',
                color: '#fff'
            },
            "& .dividerHover": {
                borderBottom: '0.5px solid #fff'
            }
        }
    },
    carddetails: {
        display: 'flex',
        flexDirection: 'column',
        padding: '20px 30px',
        borderRadius: '30px',
        background: '#FFF2D7',
        gap: '10px',
        justifyContent: 'flex-start',

    },

    userProfileCard: {
        borderRadius: '16px',
        background: '#FFDBAC',
        padding: '15px'

    },
    userProfileInfo: {
        flexDirection: 'row',
        gap: '20px',
        width: '100%',
        display: 'flex',
        marginTop: '10px',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        height: '100%'

    },
    userProfilesecondCard: {
        marginTop: '16px',
        padding: '30px',
        borderRadius: '16px'
    },


    editProfileInfo: {
        maxWidth: '900px',
    },
    userDetails: {
        display: 'flex',
        gap: '20px'
    },
    cardkeys: {
        width: '100px'
    },
    heading: {
        // color: '#212426',
        // fontSize: '20px',
        fontWeight: 500,
        // lineHeight: '100%',
    },

    cardbtns: {
        padding: '10px 60px',
        background: '#FE7F2D',
        color: '#fff',
        borderRadius: '20px'
    },
    editdetailstyle: {
        color: primaryColor,
        fontWeight: 500,
        gap: '8px',
        alignItems: 'center',
        display: 'flex',
        cursor: 'pointer'
    },
    textfields: {
        height: '44px'
    },

    logoContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '30px',
        padding: '0px 30px 40px 0px'
    },
    editdetailstyles: {
        color: '#FE7F2D',
        marginLeft: '30px',
        whiteSpace: 'nowrap',
        fontWeight: '500',

    },
    lightLabelTextstyle: {
        fontWeight: 500,
        whiteSpace: 'noWrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: '340px',
        minWidth: 'fit-content'


    },


    boldLabel: {
        fontWeight: 'bold'
    },

    valueTextstyle: {
        color: 'rgba(33, 36, 38, 0.90)',
        fontWeight: 500,
        display: 'flex',
        marginLeft: '40px',
        width: '320px',
        height: '24px',

    },
    cardBoxes: {
        display: 'flex',
        gap: '20px'
    },
    valueTextstylespan: {

        color: 'rgba(33, 36, 38, 0.90)',
        width: '270px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap' as const,
        display: 'block',
        fontWeight: 500,
        height: '24px',


    },


    required: {
        color: '#F00'
    },
    dividerStyle: {
        margin: '18px 0 10px',
        color: '#212426',
        fontSize: '28px',
        fontWeight: 700,
        lineHeight: '100%',
        textAlign:'center'
    },
    userImage: {
        height: '20px',
        width: '20px'
    },
    labelText: {
        fontWeight: 500,
        marginBottom: '10px'
    },
    ribbonContainer: {
        position: "absolute",
        top: "-10px",
        right: "4px",
        width: "98px",
        height: "90px",
        zIndex: "999",
    },
    
    ribbonImage: {
        width: "100%",
        height: "100%",
        objectFit: "fill",
        filter: "brightness(0) invert(1)",
    },

    ribbonTextBottom: {
        top: "55%",
        fontSize: "14px"
    },
    ribbonTextTop: {
        top: "35%",
        fontSize: "13px",
    },
    
    ribbonTextTopBottom: {
        width: "60%",
        textAlign: "center",
        position: "absolute",
        left: "50%",
        transform: "translate(-50%, -50%)",
        fontWeight: 600,
        lineHeight: 1.4,
        color: "#fe7f2d",
    }
    
  
    
   
    
})

const SubscriptionPlanWeb = withStyles(ProfileStyles as object)(SubscriptionPlan)
export { SubscriptionPlanWeb }
// Customizable Area End
