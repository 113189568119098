import React from "react";
// Customizable Area Start
import {
    Box, Divider, Button, CardMedia,
    Typography, Chip, Avatar, Dialog, DialogTitle, IconButton, DialogContent, DialogActions,
} from "@material-ui/core";
import {
    withStyles, Theme, ThemeProvider
} from "@material-ui/core/styles";
import { Clear } from '@material-ui/icons';
import { muiTheme } from "./MyBookings.web";
import {
    detailsList,
    headingImgBlock, reviewBtn, reviewList,
    tripContainer, tripDetailsBlock, tripStyles
} from "./TripDetails.web";
import moment from "moment";
import {
    bringBag, cancelationPolicy, facility,
    include, moreInfo, notAllowed, notInclude,
    editIcon, callIcon, downloadIcon, reviewCircle,
} from "./assets"
import { tour1 } from "../../landingpage/src/assets";
import Loader from "../../../components/src/Loader.web";
import Breadcrumbs from "../../../components/src/Breadcrumbs";
import AddReviewWeb from "../../../components/src/AddReview.web";
import ResponseStatusModal from "../../../components/src/ResponseStatusModal";
import { getStatusBgColor, getStatusTextColor } from "../../../components/src/utils";
import CloseIcon from '@material-ui/icons/Close';
import i18next from 'i18next';


export const styles = (theme: Theme) => ({
    outlineButton: {
        width: '300px',
        padding: '8px 48px',
        borderRadius: '55px',
        border: '1px solid rgba(33, 36, 38, 0.20)',
        background: '#FFF',
        height: '48px',
    },
    buttonLabel: {
        color: '#212426',
        fontFamily: 'Inter',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '150%',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: '12px',
        textTransform: 'none' as const,
        textAlign: 'center' as const,
        whiteSpace: 'nowrap' as const,
        '&.error': {
            color: '#F00',
        }
    },
    tripHeading: {
        fontSize: '32px',
        fontWeight: 700,
        lineHeight: '140%',
        marginBottom: '32px',
        maxWidth: '853px',
        [theme.breakpoints.down(768)]:{
            fontSize: '22px',
        }
    },
    imgMedia: {
        borderRadius: '16px',
        width: '300px',
        height: '300px',
        border: '1.24px solid rgba(33, 36, 38, 0.20)'
    },
    statusRoot: {
        position: 'absolute' as const,
        top: '-8px',
        left: '-8px',
        borderRadius: '6px',
        padding: '8px 16px',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '10px',
    },
    statusLabel: {
        fontFamily: 'Inter',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '14px',
        padding: 0
    },
    cardMedia: {
        display: 'flex',
        justifyContent: 'space-between',
        gap: '32px',
        flexGrow: 1,
        position: 'relative' as const,
        [theme.breakpoints.down(768)] : {
            flexDirection : 'column', 
            alignItems :'center' as const
        }
    },
    removeItemDelete: {
        color: '#F67E2D',
        marginTop: '10px',
        padding: '11px 30px',
        width: 'auto',
        height: 'auto',
        whiteSpace: 'nowrap' as const,
        borderRadius: '30px',
        marginRight: '10px',
        [theme.breakpoints.down(768)] : {
            fontSize : '12px'
        }
    },
    bookNowDelete: {
        borderRadius: '30px',
        background: '#F67E2D',
        color: 'white',
        marginTop: '10px',
        padding: '10px 30px',
        width: 'auto',
        height: 'auto',
        whiteSpace: 'nowrap' as const,
        [theme.breakpoints.down(768)] : {
            fontSize : '12px'
        }
    },
    dialogWidth: {
        width: '600px',
        borderRadius: '30px',
        padding: '20px 20px',
    },
    bookingDetailContainer : {
      display :'flex' as const,
      gap :'143px',
      [theme.breakpoints.between(768 , 1080)]:{
        flexDirection :'column',
        gap:'20px'
      },
      [theme.breakpoints.down(768 )]:{
        flexDirection :'column',
        gap:'20px',
        alignItems : 'center' as const
      }
    },
    actionBtnContainer : {
      display :'flex' as const ,
      flexDirection : 'column' as const,
      gap:'24px',

      [theme.breakpoints.between(768 ,1080)] : {
        flexDirection : 'row'  ,
        gap :'14px'
      },
      [theme.breakpoints.down(768)] : {
        alignItems : 'center' as const
      }
    }, 

    belowMediaBox : {
     width :'100%' ,
     display :'flex'as const,
     flexDirection:'column' as const,
     gap:'24px' ,
     [theme.breakpoints.down(768)] : {
        padding : '0px 40px'
     }

    },

    ...tripStyles(theme)
})

// Customizable Area End

import OrdermanagementController, {
    Props,
} from "./OrdermanagementController";
/* istanbul ignore next */
export default class BookingDetailsCustomer extends OrdermanagementController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes , navigation: { match : { params: { id: bookingID }}}} = this.props
        const { tripDetails, loading, itemDetail } = this.state;
        
        const {
            area = '',
            trip_type = '',
            payment_method = '',
            country: { data: {
                attributes: { name: country_name = '' } = {} } = {}
            } = {},
            city: {
                data: {
                    attributes: { name: cityName = '' } = {}
                } = {}
            } = {},
            group_preference: {
                data: {
                    attributes: {
                        preference_type = '', min_seat = null, max_seat = null
                    } = {} } = {}
            } = {},
            trip_detail: {
                data: {
                    attributes: {
                        period = '', duration = '', guided_tour = false, dress_code = '',
                        language = [],
                        facilities = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
                        check_list = "Pack your valid identification, comfortable clothing for warm weather, sunscreen, sunglasses, and a hat. Don't forget your comfortable walking shoes, swimwear, and beach essentials. Carry a small backpack for personal belongings, any necessary medications, and adaptors for electronic devices.",
                        inclusion = "Rest assured, your trip to Jeddah's coastal gems will include comfortable accommodations, convenient transportation, knowledgeable guides, selected meals, entrance fees to attractions, and dedicated support throughout your journey. Enjoy a worry-free experience with these essential inclusions, allowing you to fully immerse yourself in the beauty and culture of Jeddah.",
                        exclusion = "Please note that flights, travel insurance, visa fees, personal expenses, optional activities, and tips are generally not included in the package. It's important to review the specific terms and conditions to understand what is not covered and make the necessary arrangements.",
                        restricted_item = "Please be aware that certain activities or items may not be permitted during the trip. It is important to follow local laws and regulations and adhere to any restrictions or guidelines set by the authorities or the travel agency.",
                        other_detail = "For a smooth and enjoyable trip, here are a few key points to remember. Pack light and breathable clothing suitable for the hot desert climate of Jeddah. Respect the local customs and dress modestly when visiting attractions. English is widely spoken, but knowing a few basic Arabic phrases can be helpful.",
                        cancellation_policy = "A 50% refund will be provided for cancellations made between 3 to 6 days before the trip. However, if you cancel within 2 days or less of the trip start date, no refund will be available.",
                        transportation_provided = false, traveler_type = '',
                        kids_friendly = false, female_only = false, wheelchair_access = false
                    } = {}, attributes = {} } = {}
            } = {},
            vibes = [],
            
        } = tripDetails
        const detailsForAttributes = itemDetail?.attributes?.trip?.attributes?.trip_detail?.data?.attributes || {};
        const reviews = itemDetail?.attributes?.trip?.attributes?.reviews?.data.length > 0 ?
            itemDetail?.attributes?.trip?.attributes?.reviews?.data : [];
        const title = itemDetail?.attributes?.trip?.attributes?.title ?? ''
        const imgUrl = itemDetail?.attributes?.trip?.attributes?.primary_image_url ?? tour1
/* istanbul ignore next */
        const _details = detailsList(detailsForAttributes, {
            preference_type: itemDetail?.attributes?.trip?.attributes?.group_preference?.data?.attributes?.preference_type,
            min_seat: itemDetail?.attributes?.trip?.attributes?.group_preference?.data?.attributes?.min_seat,
            max_seat: itemDetail?.attributes?.trip?.attributes?.group_preference?.data?.attributes?.max_seat,
            payment_method: itemDetail?.attributes?.trip?.attributes?.payment_method,
            trip_type: itemDetail?.attributes?.trip?.attributes?.trip_type,
            country_name: itemDetail?.attributes?.trip?.attributes?.country?.data?.attributes?.name,
            cityName: itemDetail?.attributes?.trip?.attributes?.city?.data?.attributes?.name,
            area: itemDetail?.attributes?.trip?.attributes?.area,
            vibes: itemDetail?.attributes?.trip?.attributes?.vibes,
            duration: itemDetail?.attributes?.trip?.attributes?.trip_detail?.data?.attributes?.duration,
            traveler_type: itemDetail?.attributes?.trip?.attributes?.trip_detail?.data?.attributes?.traveler_type?.name,
            language: itemDetail?.attributes?.trip?.attributes?.trip_detail?.data?.attributes?.language || [],
            dress_code: itemDetail?.attributes?.trip?.attributes?.trip_detail?.data?.attributes?.dress_code,
            period: itemDetail?.attributes?.trip?.attributes?.trip_detail?.data?.attributes?.period?.name,
        })
        // time: itemDetail?.attributes?.trip?.attibutes?.trip_detail?.data?.attributes?.language,
        //duration: itemDetial?.attributes?.trip?.attibutes?.trip_detail?.data?.attributes?.duration,
        //traveler_type:itemDetail?.attributes?.trip?.attibutes?.trip_detail?.data?.attributes?.traveler_type,
        //language:itemDetail?.attributes?.trip?.attibutes?.trip_detail?.data?.attributes?.language,
        //dress_code:itemDetia
        /* istanbul ignore next */
        const itineraries = itemDetail?.attributes?.trip?.attributes?.itineraries?.data
        const serviceProviderId = itemDetail?.attributes?.trip?.attributes?.account?.data?.id
        /* istanbul ignore next */
        const tripDetailsList = [
            {
                title: i18next.t('customer.myBookings.facilities'),
                icon: facility,
                description: itemDetail?.attributes?.trip?.attributes?.trip_detail?.data?.attributes?.facilities
            },
            {
                title: i18next.t('customer.myBookings.whatToBring'),
                icon: bringBag,
                description: itemDetail?.attributes?.trip?.attributes?.trip_detail?.data?.attributes?.check_list
            },
            {
                title: i18next.t('customer.myBookings.whatsIncluded'),
                icon: include,
                description: itemDetail?.attributes?.trip?.attributes?.trip_detail?.data?.attributes?.inclusion
            },
            {
                title: i18next.t('customer.myBookings.doesntIncluded'),
                icon: notInclude,
                description: itemDetail?.attributes?.trip?.attributes?.trip_detail?.data?.attributes?.exclusion
            },
            {
                title: i18next.t('customer.myBookings.notAllowed'),
                icon: notAllowed,
                description: itemDetail?.attributes?.trip?.attributes?.trip_detail?.data?.attributes?.restricted_item
            },
            {
                icon: moreInfo,
                title: i18next.t('customer.myBookings.moreInformation'),
                description: itemDetail?.attributes?.trip?.attributes?.trip_detail?.data?.attributes?.other_detail
            },
            {
                title: i18next.t('customer.myBookings.cancellationPolicyNoShow'),
                icon: cancelationPolicy,
                description: itemDetail?.attributes?.trip?.attributes?.trip_detail?.data?.attributes?.cancellation_policy
            },
        ]
        const tripId = itemDetail?.attributes?.trip?.id;
        const booking_Id = itemDetail?.id
        const providerType = itemDetail?.attributes?.provider_details?.provider_type

/* istanbul ignore next */
        const bookingInfo = {
            'Status': itemDetail?.attributes?.status,
            'Confirmation no.': itemDetail?.attributes?.order_number,
            'Booking date': moment(itemDetail?.attributes?.created_at).format('dddd, Do MMMM'),
            'Trip Date': moment(itemDetail?.attributes?.trip_schedule?.attributes?.scheduled_date)?.format('dddd, Do MMMM'),
            'Number of people': itemDetail?.attributes?.order_item?.attributes.quantity,
        }
        const date = new Date(itemDetail?.attributes?.provider_details?.created_at);
        const activeYear = date.getFullYear().toString();
        // Customizable Area End
        return (
            <>
                {/* Customizable Area Start */}
                <ThemeProvider theme={muiTheme} >
                    {loading
                        ? <Loader loading={loading} />
                        : <div className={classes.container}>
                            <AddReviewWeb
                                reviewModal={this.state.openReviewModal}
                                addReviewRequest={this.addReviewRequest}
                                closeAddReviewModal={this.handleCloseReviewModal}
                            />
                            <ResponseStatusModal
                                isSuccess={this.state.isSuccessResponse}
                                message={this.state.responseMsg}
                                open={this.state.responseStatusModal}
                                onClose={this.closeResponseModal}
                            />
                            <Box style={{ marginBottom: '58px' }}>
                                <Breadcrumbs list={[
                                    {
                                        title: i18next.t('customer.myBookings.home'),
                                        link: '/LandingPage'
                                    },
                                    {
                                        title: i18next.t('customer.myBookings.myBookings'),
                                        link: '/MyBookings'
                                    },
                                    {
                                        title: i18next.t('customer.myBookings.bookingDetails')
                                    }
                                ]} />
                            </Box>

                            <Divider style={{ margin: '48 0' }} />
                            <Typography className={classes.tripHeading}>
                                {title}
                            </Typography>
                            <Box className={classes.bookingDetailContainer}>
                                <Box className={classes.cardMedia}>
                                    
                                    <CardMedia
                                        component="img"
                                        alt="Card Image"
                                        height="150px"
                                        width={'150px'}
                                        image={imgUrl}
                                        classes={{ media: classes.imgMedia }}
                                    />
                                    <Chip label={bookingInfo.Status} style={{
                                        color: getStatusTextColor(bookingInfo.Status),
                                        backgroundColor: getStatusBgColor(bookingInfo.Status)
                                    }} classes={{ label: classes.statusLabel, root: classes.statusRoot }} />
                                    <Box className={classes.belowMediaBox} >

                                        {Object.entries(bookingInfo).map(([label, value], index) => {
                                            return <Box key={index} style={{
                                                display: 'flex',
                                                gap: '32px',
                                                justifyContent: 'space-between',
                                                width: '100%',
                                            }}>
                                                <Typography style={{
                                                    width: '39%',
                                                    flexBasis: '39%',
                                                    color: 'rgba(33, 36, 38, 0.65)',
                                                    fontWeight: 500,
                                                }}>{label}</Typography>
                                                <Typography style={{
                                                    opacity: 0.9,
                                                    width: '61%',
                                                    flexBasis: '61%',
                                                    color: 'rgba(33, 36, 38, 0.90)',
                                                    fontWeight: 500,
                                                    textTransform: "capitalize"
                                                }}>{value}</Typography>
                                            </Box>
                                        })}
                                        <Box style={{
                                            display: 'flex',
                                            gap: '32px',
                                            width: '100%',
                                        }}>
                                            <Typography style={{
                                                width: '39%',
                                                flexBasis: '39%',
                                                color: 'rgba(33, 36, 38, 0.65)',
                                                fontWeight: 500,
                                            }}>{i18next.t('customer.myBookings.serviceProvider')}</Typography>
                                            <Box>
                                                <Box className={classes.flexStyle12}>
                                                    <Avatar className={classes.userImage} alt="User Avatar" src={itemDetail?.attributes?.provider_details?.image_url} />
                                                    <Box>
                                                        <Typography className={classes.travelAgencyName}>{`${itemDetail?.attributes?.provider_details?.name} (Agency)`}</Typography>
                                                        <Typography className={classes.yearsAGo}>{i18next.t('customer.myBookings.activeSince')} {activeYear || 2018}</Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box className={classes.actionBtnContainer}>
                                    <Button className={classes.outlineButton} classes={{ label: classes.buttonLabel }}
                                        startIcon={<img src={callIcon} alt='contact' />} data-test-id="contact_org" onClick={() => this.navigateToProviderDetailsPage(providerType, serviceProviderId)}>
                                        {i18next.t('customer.myBookings.contactOrganizer')}
                                    </Button>
                                    {["confirmed", "completed", "created"].includes(itemDetail?.attributes?.status) && (
                                        <Button onClick={this.downloadPDF} className={classes.outlineButton} classes={{ label: classes.buttonLabel }}
                                            startIcon={<img src={downloadIcon} alt='contact' />}>
                                           {i18next.t('customer.myBookings.downloadInvoice')}
                                        </Button>
                                    )}
                                    {!(itemDetail?.attributes?.status === "cancelled" || itemDetail?.attributes?.status === "removed" || itemDetail?.attributes?.status === "completed") && (
                                        <>
                                            <Button className={classes.outlineButton} classes={{ label: classes.buttonLabel }}
                                                startIcon={<img src={editIcon} alt='contact' />} onClick={()=>{this.gotoEditBooking(tripId , bookingID)}} data-testId = "editBtn">
                                                {i18next.t('customer.myBookings.editBooking')}
                                            </Button>
                                            {itemDetail?.attributes?.status !== "refunded" && (
                                                <Button className={classes.outlineButton} classes={{ label: `${classes.buttonLabel} error` }}
                                                    onClick={this.handleOpenDeleteModal}>
                                                    <Clear color="error" />
                                                    {i18next.t('customer.myBookings.cancelBooking')}
                                                </Button>
                                            )}
                                            {
                                                this?.state?.isDeleteModalOpen &&
                                                <Box>
                                                    <Dialog
                                                        open={this.state.isDeleteModalOpen}
                                                        onClose={this.handleClosemodal}
                                                        classes={{
                                                            paper: classes.dialogWidth
                                                        }}
                                                    >
                                                        <DialogTitle id="delete-item-dialog-title" >
                                                            <Typography style={{ fontWeight: 'bolder', textAlign: 'center', fontSize: '24px' }}>{i18next.t('customer.myBookings.confirmCancellation')}</Typography>
                                                            <IconButton
                                                                aria-label="close"
                                                                onClick={this.handleClosemodal}
                                                                style={{ position: 'absolute', right: '8px', top: '8px' }}
                                                                className={classes.closeIcon}
                                                            >
                                                                <CloseIcon />
                                                            </IconButton>
                                                        </DialogTitle>
                                                        <DialogContent>

                                                            <Typography > {i18next.t('customer.myBookings.cancellationPolicyMessage')} </Typography>

                                                        </DialogContent>
                                                        <DialogActions>
                                                            <Button onClick={this.handleClosemodal} variant="outlined" className={classes.removeItemDelete} >
                                                            {i18next.t('customer.myBookings.noDontCancel')}
                                                            </Button>
                                                            <Button onClick={() => this.handleCancelBooking(booking_Id)} className={classes.bookNowDelete} autoFocus data-test-id="cancelBtn" disabled= {this.state?.iscancelBtnClicked}>
                                                            {i18next.t('customer.myBookings.yesCancel')}
                                                            </Button>
                                                        </DialogActions>
                                                    </Dialog>
                                                    <ResponseStatusModal
                                                        isSuccess={this.state.isSuccessResponse}
                                                        message={this.state.responseMsg}
                                                        open={this.state.responseStatusModal}
                                                        onClose={this.closeResponseModal}
                                                     />
                                                </Box>
                                            }
                                        </>
                                    )}

                                </Box>
                            </Box>

                            <Divider style={{ margin: '48px 0' }} />

                            <Box margin='0 auto' width='82.7%'>
                                {tripContainer(classes, _details, itineraries)}
                                {tripDetailsBlock(classes, tripDetailsList)}
                            </Box>
                            <Divider style={{ margin: '48px 0 32px' }} />

                            <Box data-testid='reviews' margin='0 auto' width='82.7%' >
                                <Box display={'flex'} justifyContent={'space-between'} alignItems='center' marginBottom={'48px'}>
                                    {headingImgBlock(classes, reviewCircle, i18next.t('customer.myBookings.reviews'), { marginBottom: 0 })}
                                    {reviewBtn({ handleOpenReviewModal: this.handleOpenReviewModal })}
                                </Box>
                                {reviewList(classes, reviews)}
                            </Box >
                        </div>
                    }
                </ThemeProvider >
                {/* Customizable Area End */}
            </>
        )
    }
}

// Customizable Area Start

const BookingDetailsCustomerWeb = withStyles(styles)(BookingDetailsCustomer);
export { BookingDetailsCustomerWeb };
// Customizable Area End
