import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getAuthToken } from "../../../components/src/utils";
import ApiRequest from "../../../components/src/ApiRequest";

interface UserData {
  name: string;
  email: string;
  mobile: string;
}

interface PaymentDetails {
  tripAmountPerPerson: number;
  discountedPricePerPerson: number;
  numberOfPeople: number;
  total: number;
}

export interface TripDates {
  date: string;
  data: {
    [key: string]: {
      sub_status: string;
      count: string;
      userList: {
        name: string;
        profileImg: string;
      }[]
    }
  }
}

interface TripBookingList {
  [key: string]: {
    main_status: string;
    dates: {
      [key: string]: TripDates
    }
  }
}

 const demoTripBookingList: TripBookingList = {
  "trip1": {
    main_status: "Upcoming",
    dates: {
      "2024-06-01": {
        date: "2024-06-01",
        data: {
          "TripToGoa": {
            sub_status: "confirmed",
            count: "5",
            userList: [
              { name: "Alice Johnson", profileImg: "img1.jpg" },
              { name: "Bob Smith", profileImg: "img2.jpg" },
              { name: "Carol White", profileImg: "img3.jpg" },
              { name: "David Brown", profileImg: "img4.jpg" },
              { name: "Eve Davis", profileImg: "img5.jpg" }
            ]
          },
          "TripToIndia": {
            sub_status: "cancelled",
            count: "3",
            userList: [
              { name: "Frank Green", profileImg: "img6.jpg" },
              { name: "Grace Black", profileImg: "img7.jpg" },
              { name: "Hank Moore", profileImg: "img8.jpg" }
            ]
          }
        }
      },
      "2024-06-02": {
        date: "2024-06-02",
        data: {
          "TripToAgra": {
            
            count: "2",
            userList: [
              { name: "Ivy Reed", profileImg: "img9.jpg" },
              { name: "Jack Wright", profileImg: "img10.jpg" }
            ],
            sub_status: "confirmed",
          }
        }
      }
    }
  },
  "trip2": {
    
    dates: {
      "2024-07-10": {
        data: {
          "TripToNepal": {
            sub_status: "Removed",
            userList: [
              { name: "Kelly Adams", profileImg: "img11.jpg" },
              { name: "Liam King", profileImg: "img12.jpg" },
              { name: "Mia Scott", profileImg: "img13.jpg" },
              { name: "Noah Clark", profileImg: "img14.jpg" }
            ],
            count: "1",
          },
        },
        date: "2024-07-10",
      },
      "2024-07-18": {
        date: "2024-07-18",
        data: {
          "TripToBali": {
            sub_status: "Removed",
            count: "3",
            userList: [
              { name: "Olivia Lee", profileImg: "img15.jpg" },
              { name: "Sophia Lewis", profileImg: "img16.jpg" },
              { name: "Michael Smith", profileImg: "img17.jpg" }
            ]
          }
        }
      }
    },
    main_status: "Removed",
  },
  "trip3": {
    main_status: "Completed",
    dates: {
      "2024-08-15": {
        date: "2024-08-15",
        data: {
          "TripToBankok": {
            sub_status: "Completed",
            count: "3",
            userList: [
              { name: "Emily Turner", profileImg: "img21.jpg" },
              { name: "James Harris", profileImg: "img22.jpg" },
              { name: "Sophia Clark", profileImg: "img23.jpg" }
            ]
          },
        }
      },
    }
  }
};


// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  orders: any;
  page: number,
  orderPagination: any,
  isCancelVisible: boolean;
  isResponseVisible: boolean
  loading: boolean;
  token: string;
  itemDetail: any;
  cancelReason: string;
  cancelResponse: string;
  tab: string;
 
  paymentResponse: any;
  booking: any;
  searchValue: string;
  tripTitles: any[];
  filteredTripTitle: string;
  startDate: Date | null;
  endDate: Date | null;
  dateRange: string;
  isSmallScreen: boolean;
  tripBookingList: TripBookingList;
  tripBookingId: string;
  tripBookingTitle: string;
  searchByCustomer: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class BookingListController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getOrdersAPICallId: any;
  getBookingDataAPICallId: any;
  getItemDetailAPICallId: any;
  cancelOrderAPICallId: any;
  rateOrderAPICallId: any;
  apiCallIdSimilarTrips: string = '';
  apiCallIdExloreTrips: string = '';
  apiCallIdAvaibleSeats: string = '';
  apiCallIdBookingSummary: string = '';
  apiCallIdBookMyTrip: string = '';
  apiCallIdTripBookingDetails: string = '';
  debouncedSearch: (() => void) & { cancel: () => void };
  debouncedSearch2: (() => void) & { cancel: () => void };
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];

    this.state = {
      orders: [],
      page: 1,
      orderPagination: "",
      isCancelVisible: false,
      token: "",
      itemDetail: null,
      cancelReason: '',
      tab: '',
      loading: false,
      cancelResponse: '',
      paymentResponse: '',
      isResponseVisible: false,
      searchValue: '',
      booking: [],
      tripTitles: [{value: '', label: 'Any'}],
      filteredTripTitle: '',
      startDate: null,
      endDate: null,
      dateRange: '',
      isSmallScreen: false,
      tripBookingList: {},
      tripBookingTitle: '',
      tripBookingId: '',
      searchByCustomer: '',
    };
    this.debouncedSearch = this.debounce(this.performSearch, 300);
    this.debouncedSearch2 = this.debounce(this.getTripBookingList, 300);
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    // this.getBookingData(this.state.token)
    this.checkIfMobile()
    this.handleSessionResponseMessage()
    super.componentDidMount();
    this.getToken();

    const { pathname } = window.location;
    if (pathname.startsWith('/TripBookingList/')) {
     
      const tripId = pathname.split('/').pop() as string;
      this.setState({
        tripBookingId: tripId,
      }, () => this.getTripBookingList())
    } 
   
    this.clearDate()
    window.addEventListener('resize', this.checkIfMobile);
    this.clearDate()
     // Customizable Area End
  }
  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
      const responseJson = message.getData(getName(MessageEnum.SessionResponseData));
      this.setState({
        paymentResponse: responseJson.payload
      })
    }
    /* istanbul ignore next */
    const apiRequestCallIds = {
      [this.getOrdersAPICallId]: this.getOrderDataResponse,
      [this.getItemDetailAPICallId]: this.getItemDetailsResponse,
      [this.cancelOrderAPICallId]: this.cancelItemDetailsResponse,
      [this.apiCallIdTripBookingDetails]: this.getTripBookingDetilsResponse,

      // Add more API call IDs and handlers as needed
    };

    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

    if (apiRequestCallId != null && apiRequestCallIds[apiRequestCallId]) {
      apiRequestCallIds[apiRequestCallId](responseJson, errorResponse);
    }
    




  }
  // Customizable Area Start
  maxCharacters: number = 142;
  showTripByIDApiCallId: any;
/* istanbul ignore next */
  handleSessionResponseMessage = async () => {
    const token = await getAuthToken()
    this.setState({ token: token });
    const id = this.props.navigation.getParam('id')
    const result = await getAuthToken()
    const { navigation: { history: { location } } } = this.props
    if (location.pathname.includes('/BookingDetails')) {
      this.setState({ token: result, loading: true }, () => {
        this.getItemDetailDataRequest(id,token)
      });
    }
    if (location.pathname == '/OrderDetails' || location.pathname == '/Ordermanagement' || location.pathname == '/MyBookings' ) {
      this.getOrdersDataRequest();
      this.getItemDetailDataRequest(id,token)
    }
  }
  openOrderModal = () => {
    this.setState({
      isCancelVisible: true,
    });
  };

  openResponseModal = () => {
    this.setState({isResponseVisible: true})
  }

  hideResponseModal = () => {
    this.setState({isResponseVisible: false, cancelReason: ""})
  }

  hideCancelModal = () => {
    this.setState({ isCancelVisible: false });
  };


  // Request functions

  /* istanbul ignore next */
  getOrdersDataRequest = async (status: string = "") => {
    const { page, searchValue, filteredTripTitle, startDate, endDate } = this.state;
    const token = await getAuthToken()
    const header = {
      "Content-Type": configJSON.ordersApiContentType,
      "Authorization": `Bearer ${token}`,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getOrdersAPICallId = requestMessage.messageId;

    let queryParams: Record<string, string> = {}

    const addQueryParamIfNotEmptyValue = (paramName: string, paramValue: string | undefined) => {
      if (paramValue) {
        queryParams[paramName] = paramValue;
      }
    };

    // Add other query parameters
    addQueryParamIfNotEmptyValue('status', status);
    addQueryParamIfNotEmptyValue('page', page.toString());
    addQueryParamIfNotEmptyValue('from', startDate?.toDateString());
    addQueryParamIfNotEmptyValue('to', endDate?.toDateString());
    addQueryParamIfNotEmptyValue('search', searchValue);
    addQueryParamIfNotEmptyValue('any_trip', filteredTripTitle);
    addQueryParamIfNotEmptyValue('limit', '10');

    const queryString = new URLSearchParams(queryParams).toString();
    const endpoint = `${configJSON.ordersAPiEndPoint}?${queryString}`;


    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handlePaginationChange = (e: any, value: number) => {  
    const pageNumber = Number(value);
    this.setState({
      page: pageNumber,
    },() =>  this.getOrdersDataRequest(this.state.tab))
  }

  getItemDetailDataRequest = async (id: string,authToken:string) => {
    const header = {
      "Content-Type": configJSON.ordersApiContentType,
      "Authorization": `Bearer ${authToken}`
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.ordersAPiEndPoint + `/${id}`
    );

    this.getItemDetailAPICallId = requestMessage.messageId;

   
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

  

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  

/* istanbul ignore next */
  cancelOrderDataRequest = async (id: any) => {
    const authToken = await getAuthToken();
    const header = {
      "Content-Type": configJSON.ordersApiContentType,
      "Authorization": `Bearer ${authToken}`
    };
    
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.cancelOrderAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.cancelAPiEndPoint + `?cancellation_reason=${this.state.cancelReason}` + `&order_id=${id}` 
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getTripBookingList = async () => {
    const {tripBookingId, searchByCustomer} = this.state;
    const token = await getAuthToken();

    const header = {
      'Contect-Type': configJSON.ordersApiContentType,
      "Authorization": `Bearer ${token}`
    }

    let query: Record<string, string> = {}

    const addQueryParamIfNotEmpty = (paramName: string, paramValue: string | undefined) => {
      if (paramValue) {
        query[paramName] = paramValue;
      }
    };

    // Add other query parameters
    addQueryParamIfNotEmpty('search', searchByCustomer);
    
    const queryString = new URLSearchParams(query).toString();

    const endPoint = `bx_block_productdescription/trips/${tripBookingId}/trip_booking_details?${queryString}`

    const requestMessage = ApiRequest({
      header,
      endPoint,
      method: "GET",
    });
    this.apiCallIdTripBookingDetails = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }




  // Response fucntions
  getOrderDataResponse = (responseJson: any, errorResponse: any) => {
    if (responseJson?.bookings?.data) {
      const initialObject = {
        value: '',
        label: 'Any',
    };
      const tripTitlesArray = responseJson?.trip_titles?.map((title: string) => ({
        value: title,
        label: title,
      }));
      const combinedtitlesArray = [initialObject, ...tripTitlesArray]
      this.setState({
        orders: responseJson.bookings.data,
        orderPagination: responseJson.bookings.page_options,
        tripTitles: combinedtitlesArray
      });
    } else {
      this.setState({ orders: [], orderPagination: "", tripTitles: [] })
    }
  }

  handleChange = (value: string) => {
    if (value !== this.state.tab) {
      this.setState({ tab: value ,page:1},()=>{
        this.getOrdersDataRequest(this.state.tab)
      });
      
    }
  };


  getItemDetailsResponse = (responseJson: any, errorResponse: any) => {
    /* istanbul ignore next */
    if(responseJson) {
      this.setState({ itemDetail: responseJson.data?.attributes, loading: false })
      return;
    }
    this.setState({ itemDetail: null })
  }

  cancelItemDetailsResponse = (responseJson: any, errorResponse: any) => {
    if(responseJson.message)
      {
        this.setState({ cancelResponse: responseJson.message })
      }
      else{
        this.setState({ cancelResponse: responseJson.errors })
      }
      
     if(this.state.cancelResponse === 'Order cancelled successfully') {
        this.props.navigation.navigate('TripList')
     }
      this.openResponseModal()
      this.hideCancelModal()
  }

  getTripBookingDetilsResponse = (responseJson: any, errorResponse: any) => {

    const transformedData = this.transformData(responseJson);
    const tripTitle =responseJson?.trip_title;
    this.setState({ tripBookingList: transformedData, tripBookingTitle: tripTitle });
  };

  transformData = (data: any): TripBookingList => {
    const transformed: TripBookingList = {
      upcoming: {
        main_status: 'upcoming',
        dates: {
          ...this.mapDates(data.upcoming_bookings.bookings, 'confirmed'),
          ...this.mapDates(data.upcoming_cancelled_bookings.bookings, 'cancelled')
        }
      },
      
      removed_bookings: {
        main_status: 'removed',
        dates: this.mapDates(data.removed_bookings.bookings, 'removed')
      },
      completed_bookings: {
        main_status: 'completed',
        dates: this.mapDates(data.completed_bookings.bookings, 'completed')
      },
    };
    return transformed;
  };

  mapDates = (bookings: any[], subStatus: string): { [key: string]: TripDates } => {
    return bookings.reduce((acc, booking) => {
      const date = booking.trip_date;
      if (!acc[date]) {
        acc[date] = {
          date: date,
          data: {}
        };
      }
      if (!acc[date].data[subStatus]) {
        acc[date].data[subStatus] = {
          sub_status: subStatus,
          count: '0',
          userList: []
        };
      }
      acc[date].data[subStatus].userList.push({
        name: booking.account_name,
        profileImg: booking.account_photo
      });
      acc[date].data[subStatus].count = String(parseInt(acc[date].data[subStatus].count) + 1);
      return acc;
    }, {} as { [key: string]: TripDates });
  };


  

 
/* istanbul ignore next */
  navigateBookingDetails = (detailId: string | number) => {
    this.props.navigation.navigate("BookingDetails",
      {
        id: detailId,
      })
  }

  handleSearchTrip = (value: string) => {
    this.setState({
      searchValue: value
    })

    // Call the debounced function
    this.debouncedSearch();
  }

  handleSearchTripBookingCustomer = (value: string) => {
    this.setState({
      searchByCustomer: value
    })

    // Call the debounced function
    this.debouncedSearch2();
  }

  // Function to perform the actual search
  performSearch = () => {
    // Call the API with the updated search query
    
    this.getOrdersDataRequest(this.state.tab);
  };

  debounce<T extends (...args: any[]) => void>(func: T, delay: number): T & { cancel: () => void } {
    let timeout: any;

    const debounced = (...args: any[]) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), delay);
    };


    return debounced as T & { cancel: () => void };
  }
  
  handleSelectTitle=(name: string, value: string) => {
    this.setState({
      filteredTripTitle: value,
    }, () => this.getOrdersDataRequest(this.state.tab))
  }

  handleDateChange = (name: string, value: Date | null) => {
    if(name === "dateFrom"){
      this.setState({startDate: value})
    }else if(name === "dateTo") {
      this.setState({endDate: value})
    }
    
  }

  renderDateRange = () => {
    const { startDate, endDate, isSmallScreen } = this.state;

    // Determine date format based on screen size
    const dateFormatOptions: Intl.DateTimeFormatOptions = isSmallScreen 
        ? { month: 'short', day: 'numeric' }
        : { month: 'short', day: 'numeric', year: 'numeric' };

    // Format the start and end dates
    const startDateFormatted = startDate
        ? new Intl.DateTimeFormat('en-US', dateFormatOptions).format(startDate)
        : '';

    const endDateFormatted = endDate
        ? new Intl.DateTimeFormat('en-US', dateFormatOptions).format(endDate)
        : '';

    // Combine the formatted start and end dates
    const dateRange = `${startDateFormatted} - ${endDateFormatted}`;

    // Update the value prop of the Select component
    this.setState({dateRange})
};

clearDate = () => {
  this.setState({
    startDate: null,
    endDate: null,
    dateRange: ''
  },() => {
  
    this.getOrdersDataRequest();
  })
 
}

navigateToReport = () => {
  this.props.navigation.navigate("BookingReport")
}

navigateToSalesReport = (pathName: string) => {
  const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
  msg.addData(getName(MessageEnum.NavigationTargetMessage), pathName);
  msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
  msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
  this.send(msg);
};


checkIfMobile = () => {
  const isSmallScreen = window.innerWidth <= 960;
  this.setState({ isSmallScreen });
}
  
  // Customizable Area End
}
