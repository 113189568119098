import React from "react";

// Customizable Area Start
import {
    Typography, Box, Button, Modal,
    TextField
} from "@material-ui/core";
import { Clear } from "@material-ui/icons";
import {
    withStyles, Theme, ThemeProvider,  
} from "@material-ui/core/styles";
import { DynamicButtonGroup, buttonGroupStyles, muiTheme } from "./MyBookings.web";
import i18next from 'i18next';

// Customizable Area End

import BookingListController, {
    Props
} from "./BookingListController.web";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';



export default class BookingDetails extends BookingListController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const { itemDetail, cancelResponse, isResponseVisible } = this.state
        let numberOfPeople = itemDetail?.no_of_people

        // Customizable Area End
        return (
            // Customizable Area Start
            <ThemeProvider theme={muiTheme}>
                <Typography style={{
                    fontWeight: 600,
                    fontSize: '32px',
                    lineHeight: 'normal',
                    letterSpacing: '0.1px',
                    marginBottom: '32px'
                }}>
                {i18next.t('travelAgency.myBookings.bookingDetails.bookingDetails')}
                </Typography>
                <div className={`${classes.bookingDetail}`} >
                    {
                        itemDetail === null ?
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60vh' }}>
                                <Typography style={{fontFamily: 'Inter', fontWeight: 600, fontSize: '24px'}}>{i18next.t('travelAgency.myBookings.bookingDetails.bookingDetails')}</Typography>
                            </div>
                            :
                            <Box className={`${classes.bookingDetailData}`}>
                                <Box marginBottom={'48px'}>
                                    <DynamicButtonGroup classes={classes}
                                        data={[
                                            { label: i18next.t('travelAgency.myBookings.bookingDetails.confirmationNumber'), value: itemDetail?.order_number },
                                            { label: i18next.t('travelAgency.myBookings.bookingDetails.tripDate'), value: itemDetail?.trip_schedule?.attributes?.scheduled_date },
                                            { label: i18next.t('travelAgency.myBookings.bookingDetails.numberOfPeople'), value: numberOfPeople},
                                            { label: i18next.t('travelAgency.myBookings.bookingDetails.status'), value: itemDetail?.status },
                                        ]} style={{ maxWidth: '938px', width: '100%' }} />
                                </Box>

                                <Box display={'flex'} justifyContent={'space-between'} gridGap={'55px'} flexWrap={'wrap'}>
                                    <Box display={'flex'} gridRowGap={'48px'} flexDirection={'column'} width={'100%'}>
                                        <Box display={'flex'} width={'100%'}>
                                            <Typography className={`${classes.infoLabelColor} ${classes.textStyle}`}>
                                            {i18next.t('travelAgency.myBookings.bookingDetails.title')}</Typography>
                                            <Typography className={`${classes.infoValueColor} ${classes.textStyle}`}>
                                                {itemDetail?.trip?.attributes?.title}</Typography>
                                        </Box>
                                        <Box display={'flex'} width={'100%'}>
                                            <Typography className={`${classes.infoLabelColor} ${classes.textStyle}`}>
                                            {i18next.t('travelAgency.myBookings.bookingDetails.country')}</Typography>
                                            <Typography className={`${classes.infoValueColor} ${classes.textStyle}`}>
                                                {itemDetail?.trip?.attributes?.country?.data?.attributes?.name}</Typography>
                                        </Box>
                                        <Box display={'flex'} width={'100%'}>
                                            <Typography className={`${classes.infoLabelColor} ${classes.textStyle}`}>
                                            {i18next.t('travelAgency.myBookings.bookingDetails.city')}</Typography>
                                            <Typography className={`${classes.infoValueColor} ${classes.textStyle}`}>
                                                {itemDetail?.trip?.attributes?.city?.data?.attributes?.name}</Typography>
                                        </Box>
                                        <Box display={'flex'} width={'100%'}>
                                            <Typography className={`${classes.infoLabelColor} ${classes.textStyle}`}>
                                            {i18next.t('travelAgency.myBookings.bookingDetails.travelerName')}</Typography>
                                            <Typography className={`${classes.infoValueColor} ${classes.textStyle}`}>
                                                {itemDetail?.user_details?.name}</Typography>
                                        </Box>
                                        <Box display={'flex'} width={'100%'}>
                                            <Typography className={`${classes.infoLabelColor} ${classes.textStyle}`}>
                                            {i18next.t('travelAgency.myBookings.bookingDetails.mobileNumber')}</Typography>
                                            <Typography className={`${classes.infoValueColor} ${classes.textStyle}`}>
                                                {itemDetail?.user_details?.mobile}</Typography>
                                        </Box>
                                        <Box display={'flex'} width={'100%'}>
                                            <Typography className={`${classes.infoLabelColor} ${classes.textStyle}`}>
                                            {i18next.t('travelAgency.myBookings.bookingDetails.emailAddress')}</Typography>
                                            <Typography className={`${classes.infoValueColor} ${classes.textStyle}`}>
                                                {itemDetail?.user_details?.email}</Typography>
                                        </Box>
                                        <Box display={'flex'} width={'100%'}>
                                            <Typography className={`${classes.infoLabelColor} ${classes.textStyle}`}>
                                            {i18next.t('travelAgency.myBookings.bookingDetails.numberOfPeople')}</Typography>
                                            <Typography className={`${classes.infoValueColor} ${classes.textStyle}`}>
                                                {numberOfPeople}</Typography>
                                        </Box>
                                        <Box display={'flex'} width={'100%'}>
                                            <Typography className={`${classes.infoLabelColor} ${classes.textStyle}`}>
                                            {i18next.t('travelAgency.myBookings.bookingDetails.paymentMode')}</Typography>
                                            <Typography className={`${classes.infoValueColor} ${classes.textStyle}`}>
                                                {itemDetail?.trip?.attributes?.payment_method}</Typography>
                                        </Box>




                                    </Box>
                                    {itemDetail?.status === 'cancelled' ?  null : <Button
                                        onClick={() => this.openOrderModal()}
                                        data-testId={'btnOpenModal'}
                                        className={classes.outlineButton} classes={{ label: classes.buttonLabel }}>
                                        <Clear color="error" />
                                        {i18next.t('travelAgency.myBookings.bookingDetails.cancelBooking')}
                                    </Button>}
                                    {
                                        this.state.isCancelVisible &&

                                        <Modal
                                            open={this.state.isCancelVisible}
                                            onClose={this.hideCancelModal}
                                            aria-labelledby="modal-modal-title"
                                            aria-describedby="modal-modal-description"
                                        >
                                            <Box sx={modalStyle}>
                                                <Box sx={{ minHeight: 250 }}>
                                                    <Box style={{width: '100%', display: 'flex', justifyContent: 'end', cursor: 'pointer'}}>
                                                        <Clear data-testId={'iconCloseModal'} onClick={() => this.hideCancelModal()} />
                                                    </Box>
                                                    <Typography variant="subtitle1" gutterBottom component="div">
                                                        <Typography gutterBottom display="inline">
                                                        {i18next.t('travelAgency.myBookings.bookingDetails.cancelReason')}                                                        </Typography>{" "}
                                                    </Typography>



                                                    <Typography style={{ marginTop: 30 }} variant="subtitle1" gutterBottom component="div">
                                                        <Typography variant="h6" gutterBottom display="inline">
                                                        {i18next.t('travelAgency.myBookings.bookingDetails.cancelTitle')}    
                                                        </Typography>{" "}
                                                    </Typography>
                                                    <TextField
                                                        id="outlined-multiline-static"
                                                        label= {i18next.t('travelAgency.myBookings.bookingDetails.writeSomething')} 
                                                        fullWidth
                                                        multiline
                                                        minRows={10}
                                                        value={this.state.cancelReason}
                                                        data-test-id={'btnCancelBookingInput'}
                                                        onChange={(event) => this.setState({cancelReason: event.target.value})}
                                                        maxRows={10}
                                                        variant="outlined"
                                                    />

                                                </Box>


                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "flex-end",
                                                        padding: "10px 0px",
                                                        marginTop: 20
                                                    }}
                                                >
                                                    <Button
                                                        className={classes.cancelModal}
                                                        classes={{ label: classes.buttonLabel }}
                                                        data-testId={'btnCloseModal'}
                                                        onClick={() => this.hideCancelModal()}
                                                    >
                                                        {i18next.t('travelAgency.myBookings.bookingDetails.discard')} 
                                                    </Button>

                                                    {
                                                        this.state.cancelReason === '' ? <button
                                                            className={classes.proceedModalButton}
                                                            style={{cursor: 'not-allowed'}}
                                                            disabled
                                                        >
                                                            {i18next.t('travelAgency.myBookings.bookingDetails.proceed')} 
                                                        </button> :
                                                            <button
                                                                className={classes.proceedModalButton}
                                                                data-test-id={'btnProceeedButton'}
                                                                onClick={() => this.cancelOrderDataRequest(itemDetail?.id)}
                                                            >
                                                                {i18next.t('travelAgency.myBookings.bookingDetails.proceed')} 
                                                            </button>
                                                    }


                                                </Box>
                                            </Box>
                                        </Modal>
                                    }
                                    {
                                        isResponseVisible &&  <Modal
                                            open={this.state.isResponseVisible}
                                            onClose={this.hideResponseModal}
                                            data-test-id={'openResponseModal'}
                                            aria-labelledby="modal-modal-title"
                                            aria-describedby="modal-modal-description"
                                        >
                                        <div className={classes.paperResponseMsg}>
                                            <Box style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: 12
                                            }}>
                                                {cancelResponse === "Order cancelled successfully" ? (
                                                    <CheckCircleIcon className={classes.successIcon} />
                                                ) : (
                                                    <CancelIcon className={classes.errorIcon} />
                                                )}
                                                <Typography style={{
                                                    fontFamily: 'Inter',
                                                    fontSize: '16px',
                                                    fontWeight: 500,
                                                    color: '#212426'
                                                }}>{cancelResponse}</Typography>
                                            </Box>
                                        </div>
                                        </Modal>

                                    }

                                </Box>
                            </Box>
                    }
                </div>
            </ThemeProvider >
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const modalStyle = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 600,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: '25px',
    p: 4
}

const styles = (theme: Theme) => ({
    successIcon: {
        color: theme.palette.success.main,
        fontSize: 32,
    },
    errorIcon: {
        color: theme.palette.error.main,
        fontSize: 32,
    },
    paperResponseMsg: {
        position: 'absolute',
        width: 530,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: '16px 20px',
        borderRadius: '16px',
        top: '25%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center'
    },
    [theme.breakpoints.down('sm')]: {
        paperResponseMsg: {
            position: 'absolute',
            width: 370,
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            padding: '16px 20px',
            borderRadius: '16px',
            top: '25%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            textAlign: 'center'
        },
    },
    mdlYesTxt: {
        fontSize: 16,
    },
    cancelCntr: {
        height: 50,
        justifyContent: "center",
        backgroundColor: "#FFF",
        alignItems: "center",
        elevation: 2,
        shadowOffset: { height: 2, width: 0 },
        shadowColor: "grey",
        shadowOpacity: 0.5,
    },
    bookingDetail: {
        backgroundColor: '#FFF',
        borderRadius: '16px',
        width: '69.54%',
        boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.08)'
    },
    bookingDetailData: {
        padding: "48px 72px"
    },
    cancelCntrText: {
        fontSize: 15,
    },
    close: {
        fontWeight: "bold",
    },
    cancelContainer: {
        height: "75%",
        alignSelf: "center",
        alignItems: "center",
    },
    mdlCntr: {
        backgroundColor: "rgba(49,49,49,0.8)",
        justifyContent: "center",
        flex: 1,
    },
    mdlInnerCntr: {
        height: "25%",
        alignSelf: "center",
        width: "80%",
        backgroundColor: "#fff",
        borderRadius: 5,
    },
    mdlHeader: {
        fontWeight: "bold",
        fontSize: 18,
        marginVertical: 15,
    },
    
    mdlBtnCntr: {
        justifyContent: "space-evenly",
        borderTopWidth: 0.5,
        flexDirection: "row",
        alignItems: "center",
        borderBottomColor: "grey",
        height: 40,
    },
    mdlTxt: {
        fontSize: 14,
        maxWidth: 250,
        textAlign: "center",
        width: 240,
    },
    verticalSep: {
        height: 20,
        width: 0.5,
        backgroundColor: "#000",
    },
    mdlCancelTxt: {
        fontSize: 16,
    },
    infoValueColor: {
        color: 'rgba(33, 36, 38, 0.80)',
    },
    infoLabelColor: {
        color: 'rgba(33, 36, 38, 0.65)',
        flexBasis: '30%'
    },
    textStyle: {
        fontFamily: 'Inter',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '150%',
    },
    cancelModal: {
        padding: '8px 40px',
        borderRadius: '55px',
        border: '1px solid #f00',
        background: '#FFF',
        height: '48px',
        marginTop: 'auto',
        marginLeft: '30px'
    },
    proceedModalButton: {
        padding: '8px 40px',
        borderRadius: '55px',
        border: '1px solid rgba(33, 36, 38, 0.20)',
        background: '#f00',
        height: '48px',
        marginTop: 'auto',
        marginLeft: '30px',
        color: '#fff',
        cursor: 'pointer',
        '&:disabled': {
            backgroundColor: 'rgb(33 36 38 / 60%)'
        },
    },
    outlineButton: {
        width: '217px',
        padding: '8px 24px',
        borderRadius: '55px',
        border: '1px solid rgba(33, 36, 38, 0.20)',
        background: '#FFF',
        height: '48px',
        marginTop: 'auto',
        marginLeft: 'auto'
    },
    buttonLabel: {
        color: '#F00',
        fontFamily: 'Inter',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '150%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '12px',
        textTransform: 'none' as const,
        textAlign: 'center' as const,
        whiteSpace: 'nowrap' as const,
    },
    [theme.breakpoints.down(1340)]: {
        bookingDetail: {
            width: '90%'
        },  
        bookingDetailData: {
            padding: '48px 30px'
        }
       
    },
    [theme.breakpoints.down(1024)]: {
        bookingDetail: {
            width: '95%'
        },  
       
    },
    [theme.breakpoints.down(950)]: {
        bookingDetail: {
            width: '80%'
        },  
       
    },
    ...buttonGroupStyles(theme)
})

const BookingDetailsWeb = withStyles(styles as object)(BookingDetails)
export { BookingDetailsWeb }
// Customizable Area End
