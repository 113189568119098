Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.entityDetailsContentType = "application/pdf"
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Settings5";
exports.labelBodyText = "Settings5 Body";
exports.ChangePasswordText = "You can change your password by filling the bellow values. Password must be at least 5 characters, it may contain characters, numbers and special characters";
exports.changePasswordEndpoint = "bx_block_profile/passwords";
exports.countryListEndpoint = 'account_block/country_code_and_flags';
exports.sendVerifyEmailApiEndPoint = "account_block/otps";
exports.travelersRegistrationEndpoint = "account_block/accounts/service_providers";
exports.paymentTokenEndpoint = "https://api.sandbox.checkout.com/tokens";
exports.paymentAPiEndPoint = "bx_block_invoicebilling/subscription_billings";
exports.subscriptionPlanEndPoint = "bx_block_invoicebilling/subscription_billings/3"
exports.automaticRenewalEndpointFirst = "/bx_block_invoicebilling/subscription_billings"
exports.automaticRenewalEndpointSec = "/update_auto_renewal"
exports.btnExampleTitle = "CLICK ME";
exports.subScriptionPlan = "bx_block_invoicebilling/subscription_plans";
exports.getSubscriptionDetails = "bx_block_invoicebilling/subscription_plans/";
// Customizable Area End