import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { primaryColor } from './theme';
import { Close } from "@material-ui/icons"
import i18next from 'i18next';

interface RatingSliderProps {
    count5: number;
    count4: number;
    count3: number;
    count2: number;
    count1: number;
    onRatingSelect: (rating: number) => void;
    selectedStars: number[];
}

const useStyles = makeStyles({
    container: {
        display: 'flex',
        alignItems: 'center',
    },
    sliderContainer: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
    },
    sliderBackground: {
        width: '300px', // Width of the slider space
        height: '10px', // Height of the slider space
        backgroundColor: '#ccc',
        borderRadius: '5px',
        margin: '0 5px', // Adjust margin between sliders
    },
    slider: {
        height: '100%',
        borderRadius: '5px',
    },
    count: {
        marginLeft: '5px', // Adjust margin between slider and count
    },
});

const RatingSlider: React.FC<RatingSliderProps> = ({
    count5,
    count4,
    count3,
    count2,
    count1,
    onRatingSelect,
    selectedStars
}) => {
    const classes = useStyles();

    const handleRatingSelect = (rating: number) => {
        onRatingSelect(rating);
    };

    console.log({selectedStars})

    const renderSlider = (count: number, rating: number) => {
        const sliderColor = count > 0 ? primaryColor : 'transparent'; // Violet color if count > 0, otherwise transparent
        const sliderWidth = count * 0.5; // Calculate width based on count

        return (
            <div className={classes.container}>
                <div onClick={() => handleRatingSelect(rating)} className={classes.sliderContainer}>
                    <div style={{ display: 'flex', gap: '10px', alignItems: 'center', margin: '10px 0' }}>
                        {
                            selectedStars.includes(rating)
                            ? <div style={{ fontFamily: 'Inter', color: primaryColor, display: 'flex', alignItems: 'center' }}>
                            <Close style={{ fontSize: 'inherit' }} />
                            <span style={{ fontSize: 'inherit' }}>{rating} {i18next.t('travelAgency.reviews.star')}</span>
                        </div>
                        
                            : <div style={{fontFamily: 'Inter'}}>{rating} {i18next.t('travelAgency.reviews.star')}</div>
                        }
                        <div style={{ border: selectedStars.includes(rating) ? '1px dashed gray' : '1px dashed transparent', padding: '3px' }}>
                            <div className={classes.sliderBackground}>
                                <div className={classes.slider} style={{ width: `${sliderWidth}%`, backgroundColor: sliderColor }}></div>
                            </div>
                        </div>
                        
                        {
                            selectedStars.includes(rating)
                            ? <span className={classes.count} style={{fontFamily: "Inter", color: primaryColor}}>{count}</span>
                            : <span className={classes.count} style={{fontFamily: "Inter"}}>{count}</span>
                        }
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div>
            {renderSlider(count5, 5)}
            {renderSlider(count4, 4)}
            {renderSlider(count3, 3)}
            {renderSlider(count2, 2)}
            {renderSlider(count1, 1)}
        </div>
    );
};

export default RatingSlider;
