// Customizable Area Start
import React from "react";
import {
    Typography,
    Box,
    Modal,
    Divider,
    Button
} from "@material-ui/core";
import { Close, Add, FormatListBulleted } from "@material-ui/icons";

import { createTheme, ThemeProvider, Theme, withStyles } from '@material-ui/core/styles';
import BigCalendarComponent from "../../../components/src/BigCalendarComponent";


const themeCalendar = createTheme({
    palette: {
        primary: {
            main: '#FE7F2D',
            contrastText: '#fff',
        },
    },
    typography: {
        fontFamily: 'Inter, sans-serif',
        fontWeightLight: 400,
        fontWeightRegular: 500,
        fontWeightBold: 600,
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: '20px 0px',
            fontSize: 12, // Custom font size for subtitle1
            fontWeight: 400, // Custom font weight for subtitle1
        },
        // Add more custom typography styles here as needed
    },
});

import SchedulingController, {
    Props,
} from "./SchedulingController";
import Loader from "../../../components/src/Loader.web";
import { primaryColor } from "../../../components/src/theme";
import i18next from 'i18next';




export default class TripListCalendarView extends SchedulingController {

    constructor(props: Props) {
        super(props);
    }

    render() {

        const { classes } = this.props;

        const {
            openADateEvents,
            allEvents,
            singleDayEvents,
            selectedDay,
            loader,
            selectedMonth
        } = this.state;


        const showMoreModal = () => {
            return (
                <Modal open={openADateEvents}>
                    <div className={classes.paperResponseMsg}>
                        <Box className={classes.closeButton} onClick={this.handleClose}>
                            <Close style={{ fontSize: '20px' }} />
                        </Box>
                        <Box>
                            <Typography>
                                Trips on - {selectedDay}
                            </Typography>
                            <Divider style={{margin: '24px 0 32px 0'}} />
                            <Box style={{overflowY: 'scroll', height: '40vh'}}>
                                {
                                    singleDayEvents.map(event => {
                                        return (
                                            <Box
                                                key={event.title}
                                                test-id="navigateToTripDetails"
                                                className={classes.eventTitlestyle}
                                                onClick={() => this.navigateToTripDetails(event.id)}
                                            >
                                                <Typography>{event.title}</Typography>
                                            </Box>
                                        )
                                    })
                                }
                            </Box>
                        </Box>
                    </div>
                </Modal>
            )
        }


        return (
            <ThemeProvider theme={themeCalendar}>
                <Loader loading={loader} />
                <div
                    style={{
                        backgroundColor: '#F6FBFB',
                        marginBottom: '90px'
                    }}
                >
                    <div
                        className={classes.flexBoxContainer}
                    >
                        <Typography
                            className={classes.mainTitleCV}
                        >
                           {i18next.t('travelAgency.singleTripDetail.myTrips')}
                        </Typography>

                        <div
                            className={classes.btnFlexBox}
                        >
                            <Button
                                startIcon={<Add />}
                                test-id="navigateToCreateTrip"
                                onClick={() => this.navigateToCreateTripFC()}
                                className={classes.addTripBtn}
                            >
                               {i18next.t('travelAgency.singleTripDetail.addNewTrip')}
                            </Button>
                            <Button
                                startIcon={<FormatListBulleted style={{color: primaryColor}} />}
                                test-id="navigateToTripCalendar"
                                onClick={() => this.navigateToListView()}
                                className={classes.listViewBtn}
                            >
                                {i18next.t('travelAgency.singleTripDetail.listView')}
                            </Button>
                        </div>
                    </div>
                    <Box>
                        <BigCalendarComponent
                            allEvents={allEvents}
                            handleDrillDown={this.handleDrillDown}
                            navigateToTripDetails={this.navigateToTripDetails}
                            selectedData={selectedMonth}
                        />
                    </Box>
                </div>
                {showMoreModal()}
            </ThemeProvider>
        );
    }
}
export const tripListCalendarViewStyles = (theme: Theme) => ({
    flexBoxContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: 'center',
        marginBottom: "24px",
        flexDirection: 'row' as const,
    },
    btnFlexBox: {
        display: "flex",
        flexWrap: "wrap" as const,
        justifyContent: "flex-end",
        gap: "24px"
    },
    addTripBtn: {
        fontFamily: "Inter",
        fontWeight: 500,
        fontSize: "16px",
        height: "48px",
        width: "189px",
        whiteSpace: "nowrap" as const,
        padding: "10px 50px",
        textTransform: "initial" as const,
        borderRadius: "34px",
        backgroundColor: primaryColor,
        border: `1px solid ${primaryColor}`,
        color: "#ffffff",
        '&:hover': {
            backgroundColor: primaryColor,
            color: '#ffffff',
          }
    },
    listViewBtn: {
        fontFamily: "Inter",
        fontWeight: 500,
        fontSize: "16px",
        height: "48px",
        width: "189px",
        whiteSpace: "nowrap" as const,
        padding: "-1px 50px",
        textTransform: "initial" as const,
        borderRadius: "34px",
        backgroundColor: "transparent",
        color: primaryColor,
        border: `1px solid ${primaryColor}`
    },
    headingTextStyle: {
        fontFamily: 'Inter',
        fontSize: '32px',
        fontStyle: 'normal',
        lineHeight: 'normal',
        fontWeight: 600,
        color: '#212426',
        margin: "22px auto 32px", // Center the div horizontally
    },
    mainTitleCV: {
        fontFamily: 'Inter',
        fontSize: '32px',
        fontStyle: 'normal',
        lineHeight: 'normal',
        fontWeight: 600,
        color: '#212426',
        // margin: "22px auto 32px", // Center the div horizontally
    },
    breadCrumbRStyles: {
        display: 'inline'
    },
    marginRStyle: {
        margin: 'none'
    },
    paperResponseMsg: {
        position: 'absolute' as const,
        width: 608,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: '16px 20px',
        borderRadius: '16px',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        // textAlign: 'center' as const,
    },
    eventTitlestyle: {
        borderRadius: '9px',
        background: '#FDE189',
        boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.04)',
        padding: '12px 16px',
        marginBottom: '24px',
        cursor: 'pointer',
    },
    closeButton: {
        position: 'absolute' as const,
        top: '16px', // Adjust as needed
        right: '16px', // Adjust as needed
        cursor: 'pointer',
    },
    [theme.breakpoints.down(1280)]: {
        marginRStyle: {
            margin: '0 48px'
        },
        headingTextStyle: {
            fontFamily: 'Inter',
            fontSize: '28px',
            fontStyle: 'normal',
            lineHeight: 'normal',
            fontWeight: 600,
            color: '#212426',
            margin: "22px auto 32px", // Center the div horizontally
        },
        mainTitleCV: {
            fontFamily: 'Inter',
            fontSize: '28px',
            fontStyle: 'normal',
            lineHeight: 'normal',
            fontWeight: 600,
            color: '#212426',
            // margin: "22px auto 32px", // Center the div horizontally
        },
    },
    [theme.breakpoints.down(768)]: {
        flexBoxContainer: {
            flexDirection: 'column' as const,
            alignItems: 'flex-start',
            gap: '20px',
            paddingLeft: '10px'
        },
        btnFlexBox: {
            flexWrap: 'nowrap' as const,
            justifyContent: "flex-start",
            gap: "20px"
        },
        addTripBtn: {
            fontSize: '12px',
            width: '150px',
            height: '40px'
        },
        listViewBtn: {
            fontSize: '12px',
            width: '150px',
            height: '40px'
        },
        marginRStyle: {
            margin: '0 16px'
        },
        headingTextStyle: {
            fontFamily: 'Inter',
            fontSize: '20px',
            fontStyle: 'normal',
            lineHeight: 'normal',
            fontWeight: 600,
            color: '#212426',
            margin: "22px auto 32px", // Center the div horizontally
        },
        paperResponseMsg: {
            position: 'absolute' as const,
            width: 350,
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            padding: '16px 20px',
            borderRadius: '16px',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            // textAlign: 'center' as const,
        },
        mainTitleCV: {
            fontFamily: 'Inter',
            fontSize: '20px',
            fontStyle: 'normal',
            lineHeight: 'normal',
            fontWeight: 600,
            color: '#212426',
            // margin: "22px auto 32px", // Center the div horizontally
        },
    },
    [theme.breakpoints.up(1280)]: {
        breadCrumbRStyles: {
            display: 'none'
        }
    }
})
const TripListCalendarViewWeb = withStyles(tripListCalendarViewStyles)(TripListCalendarView)
export { TripListCalendarViewWeb }
// Customizable Area End
