import React, { useState, useEffect } from "react";
import { Typography, Box, TextField, FormControlLabel, Checkbox, Button, Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles"
import * as Yup from "yup";
import { Formik, Form, ErrorMessage } from 'formik'
import { primaryColor } from "./theme";
import CitySelect from "./CitySelect";
import { BusinessDetailsValues, CityData, CountryData } from "../../blocks/email-account-registration/src/Interface.web";
import { useWindowWidth } from "./utils";
import CountrySelect from "./CountrySelect";
import i18next from "i18next";
interface BusinessDetailsProps {
    countries: CountryData[];
    initialState: BusinessDetailsValues;
    submitBusinessDetails: (values: any) => void;
    traveleAgencyEditRequest
    : (value:any) => void
    handleBack: (values: any) => void;
    errorMsg: string;
    cities: CityData[];
    getCityListRequest: (countryName: string) => void;
    isEditClicked : boolean
}

const validationSchema = Yup.object().shape({
    bankName: Yup.string().required(()=>{i18next.t('travelAgency.myProfile.error_enter_bank_name')}),
    accountName: Yup.string().required(()=>{i18next.t('travelAgency.myProfile.error_enter_account_name')}),
    accountNumber: Yup.string().required(()=>{i18next.t('travelAgency.myProfile.error_enter_account_number')}),
    iban: Yup.string().required(()=>{i18next.t('travelAgency.myProfile.error_enter_iban')}),
    streetName: Yup.string().required(()=>{i18next.t('travelAgency.myProfile.error_enter_street_name')}),
    country: Yup.string().required(()=>{i18next.t('travelAgency.myProfile.error_select_country')}).nullable(),
    city: Yup.string().required(()=>{i18next.t('travelAgency.myProfile.error_select_city')}).nullable(),
    zipCode: Yup.string().required(()=>{i18next.t('travelAgency.myProfile.error_enter_zip_code')}),
    agreeTermsAndCondition: Yup.boolean().test(
        "is-true",
        i18next.t('travelAgency.myProfile.error_check_agree_button'),
        (value) => value === true
    ),
})

const initialValues = {
    bankName: '',
    accountName: '',
    accountNumber: '',
    iban: '',
    streetName: '',
    city: null,
    zipCode: ''
}

const citiesData = [
    "New York", "London", "Riyad"
    // Add more cities as needed
];

const useStyles = makeStyles((theme) => ({
    customFont: {
        fontFamily: 'Inter',
        fontWeight: 400
    },
    toastModal: {
        borderRadius: '8px',
        display: 'inline-flex',
        height: '45px',
        alignItems: 'center',
        gap: '12px',
        flexShrink: 0,
        backgroundColor: '#FFF2F2',
        borderLeft: '8px solid #FF0000',
        padding: '8px 16px 8px 12px',
        margin: '30px 0'
    },
}));


const BusinessDetails = ({ 
    countries, 
    initialState, 
    submitBusinessDetails, 
    handleBack, 
    errorMsg, 
    getCityListRequest,
    traveleAgencyEditRequest,
    cities,
    isEditClicked = false
}: BusinessDetailsProps) => {
    const classes = useStyles();
    const [showToast, setShowToast] = useState(false)
    const windowWidth: number = useWindowWidth();

    // Define your responsive logic based on windowWidth
    const isMobile: boolean = windowWidth < 768;

    return (
        <div
            style={{
                width: isMobile ? '100%' : "77%", // Adjust the width as needed
                margin: "0 auto", // Center the div horizontally
                padding: "20px",
            }}
        >
            {
                showToast &&
                <Box className={classes.toastModal}>
                    <Typography style={{ fontFamily: "Inter", fontSize: '14px', color: '#FF0000' }}>{i18next.t('travelAgency.myProfile.privacy_policy_terms_agreement')}</Typography>
                </Box>
            }
             {
                errorMsg &&
                <Box className={classes.toastModal}>
                    <Typography style={{ fontFamily: "Inter", fontSize: '14px', color: '#FF0000' }}>{errorMsg}</Typography>
                </Box>
            }
       {isEditClicked &&  <Typography style={{ fontSize: '20px', marginTop: '48px', fontFamily: 'Inter', fontWeight: 200, color: '#212426' }} data-testid = "Business-heading">{i18next.t('travelAgency.myProfile.bankDetails')}</Typography> }

          { !isEditClicked &&  <Box>
                <Typography style={{ fontSize: '32px', marginTop: '30px', fontFamily: 'Inter', fontWeight: 500, color: '#212426' }}>{i18next.t('travelAgency.myProfile.businessDetails')}</Typography>
                <Typography style={{ fontSize: '24px', marginTop: '48px', fontFamily: 'Inter', fontWeight: 500, color: '#212426' }}>{i18next.t('travelAgency.myProfile.bankDetails')}</Typography>
            </Box>}
            <Formik
                enableReinitialize={true}
                initialValues={initialState}
                test-id="submitButtonId"
                onSubmit={isEditClicked ? traveleAgencyEditRequest :submitBusinessDetails}
                validationSchema={validationSchema}
            >
                {({ values, setFieldValue, errors, touched }) => {

                    useEffect(() => {
                        const hasAgreeTermsValidationError = !!errors.agreeTermsAndCondition;

                        const otherErrorsPresent = Object.keys(errors).some(
                            (field) => field !== "agreeTermsAndCondition" &&
                                (errors as { [key: string]: any })[field] !== undefined
                        );


                        setShowToast(
                            hasAgreeTermsValidationError && !otherErrorsPresent
                        );
                    }, [errors]);

                    return (
                        <Form>
                            <Box style={{ marginTop: '32px' }}>
                                <Typography
                                    style={{
                                        fontFamily: "Inter",
                                        fontSize: "16px",
                                        color: "rgb(33 36 38 / 65%)",
                                        marginBottom: "8px",
                                        height: '24px',
                                        fontWeight: 500
                                    
                                    }}
                                    data-testid = 'Business-heading'
                                >
                                  {i18next.t('travelAgency.myProfile.bankName')}<span style={{ color: 'red' }}> *</span>
                                </Typography>
                                <Box style={{ display: 'flex', gap: '10px', width: '100%' }}>

                                    <TextField
                                        style={{ width: "100%" }}
                                        placeholder={i18next.t('travelAgency.myProfile.bank_name')}
                                        variant="standard"
                                        name="firstName"
                                        autoComplete="off"
                                        className="email_input"
                                        onChange={(e) => {
                                            setFieldValue("bankName", e.target.value);
                                            touched.bankName = true;
                                        }}
                                        error={
                                            Boolean(errors?.bankName) && touched.bankName
                                        }
                                        helperText={
                                            touched.bankName ? errors?.bankName : ""
                                        }
                                        FormHelperTextProps={{
                                            className: classes.customFont,
                                        }}
                                        value={values?.bankName}
                                        InputProps={{
                                            style: {
                                                backgroundColor: "#ffffff",
                                                color: "rgba(33, 36, 38, 0.90)",
                                                padding: "16px 20px",
                                                height: '56px',
                                                border: `1px solid`,
                                                borderColor: errors?.bankName && touched.bankName ? 'red' : '#C3CAD9',
                                                borderRadius: '8px',
                                                fontFamily: "Inter",
                                                fontSize: '16px',
                                                fontWeight: 500
                                            },
                                            disableUnderline: true,
                                        }}
                                    />
                                </Box>
                            </Box>
                            <Box style={{ marginTop: '32px' }}>
                                <Typography
                                    style={{
                                        fontFamily: "Inter",
                                        fontSize: "16px",
                                        color: "rgb(33 36 38 / 65%)",
                                        marginBottom: "8px",
                                        height: '24px',
                                        fontWeight: 500
                                    }}
                                >
                                    {i18next.t('travelAgency.myProfile.account_name_label')}<span style={{ color: 'red' }}> *</span>
                                </Typography>
                                <Box style={{ display: 'flex', gap: '10px', width: '100%' }}>

                                    <TextField
                                        style={{ width: "100%" }}
                                        placeholder={i18next.t('travelAgency.myProfile.account_name')}
                                        variant="standard"
                                        name="firstName"
                                        autoComplete="off"
                                        className="email_input"
                                        onChange={(e) => {
                                            setFieldValue("accountName", e.target.value);
                                            touched.accountName = true;
                                        }}
                                        error={
                                            Boolean(errors?.accountName) && touched.accountName
                                        }
                                        helperText={
                                            touched.accountName ? errors?.accountName : ""
                                        }
                                        FormHelperTextProps={{
                                            className: classes.customFont,
                                        }}
                                        value={values?.accountName}
                                        InputProps={{
                                            style: {
                                                backgroundColor: "#ffffff",
                                                color: "rgba(33, 36, 38, 0.90)",
                                                padding: "16px 20px",
                                                height: '56px',
                                                border: `1px solid`,
                                                borderColor: errors?.accountName && touched.accountName ? 'red' : '#C3CAD9',
                                                borderRadius: '8px',
                                                fontFamily: "Inter",
                                                fontSize: '16px',
                                                fontWeight: 500
                                            },
                                            disableUnderline: true,
                                        }}
                                    />
                                </Box>
                            </Box>
                            <Box style={{ marginTop: '32px' }}>
                                <Typography
                                    style={{
                                        fontFamily: "Inter",
                                        fontSize: "16px",
                                        color: "rgb(33 36 38 / 65%)",
                                        marginBottom: "8px",
                                        height: '24px',
                                        fontWeight: 500
                                    }}
                                >
                                    {i18next.t('travelAgency.myProfile.accoutnNumber')}<span style={{ color: 'red' }}> *</span>
                                </Typography>
                                <Box style={{ display: 'flex', gap: '10px', width: '100%' }}>

                                    <TextField
                                        style={{ width: "100%" }}
                                        placeholder={i18next.t('travelAgency.myProfile.accoutnNumber')}
                                        variant="standard"
                                        name="firstName"
                                        autoComplete="off"
                                        className="email_input"
                                        onChange={(e) => {
                                            setFieldValue("accountNumber", e.target.value);
                                            touched.accountNumber = true;
                                        }}
                                        error={
                                            Boolean(errors?.accountNumber) && touched.accountNumber
                                        }
                                        helperText={
                                            touched.accountNumber ? errors?.accountNumber : ""
                                        }
                                        FormHelperTextProps={{
                                            className: classes.customFont,
                                        }}
                                        value={values?.accountNumber}
                                        InputProps={{
                                            style: {
                                                backgroundColor: "#ffffff",
                                                color: "rgba(33, 36, 38, 0.90)",
                                                padding: "16px 20px",
                                                height: '56px',
                                                border: `1px solid`,
                                                borderColor: errors?.accountNumber && touched.accountNumber ? 'red' : '#C3CAD9',
                                                borderRadius: '8px',
                                                fontFamily: "Inter",
                                                fontSize: '16px',
                                                fontWeight: 500
                                            },
                                            disableUnderline: true,
                                        }}
                                    />
                                </Box>
                            </Box>
                            <Box style={{ marginTop: '32px' }}>
                                <Typography
                                    style={{
                                        fontFamily: "Inter",
                                        fontSize: "16px",
                                        color: "rgb(33 36 38 / 65%)",
                                        marginBottom: "8px",
                                        height: '24px',
                                        fontWeight: 500
                                    }}
                                >
                                    {i18next.t('travelAgency.myProfile.IBAN')}<span style={{ color: 'red' }}> *</span>
                                </Typography>
                                <Box style={{ display: 'flex', gap: '10px', width: '100%' }}>

                                    <TextField
                                        style={{ width: "100%" }}
                                        placeholder={i18next.t('travelAgency.myProfile.iban')}
                                        variant="standard"
                                        name="firstName"
                                        autoComplete="off"
                                        className="email_input"
                                        onChange={(e) => {
                                            setFieldValue("iban", e.target.value);
                                            touched.iban = true;
                                        }}
                                        error={
                                            Boolean(errors?.iban) && touched.iban
                                        }
                                        helperText={
                                            touched.iban ? errors?.iban : ""
                                        }
                                        FormHelperTextProps={{
                                            className: classes.customFont,
                                        }}
                                        value={values?.iban}
                                        InputProps={{
                                            style: {
                                                backgroundColor: "#ffffff",
                                                color: "rgba(33, 36, 38, 0.90)",
                                                padding: "16px 20px",
                                                height: '56px',
                                                border: `1px solid`,
                                                borderColor: errors?.iban && touched.iban ? 'red' : '#C3CAD9',
                                                borderRadius: '8px',
                                                fontFamily: "Inter",
                                                fontSize: '16px',
                                                fontWeight: 500
                                            },
                                            disableUnderline: true,
                                        }}
                                    />
                                </Box>
                            </Box>
                            <Box>
                                <Typography style={{ fontSize: '20px', marginTop: '80px', fontFamily: "Inter", fontWeight: 400, color: '#212426' }}> { isEditClicked ?  i18next.t('travelAgency.myProfile.nationaladdress'): i18next.t('travelAgency.myProfile.address_details')}</Typography>
                            </Box>
                           
                            <Box style={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                gap: isMobile ? '0px' : '50px',
                                flexDirection: isMobile ? 'column' : 'row',
                                marginTop: '2px'
                            }}>

                                <Box style={{ marginTop: '30px', width: isMobile ? '100%' : '50%' }}>
                                    {/* <Typography
                                        style={{
                                            fontFamily: "Inter",
                                            fontSize: "16px",
                                            color: "rgb(33 36 38 / 65%)",
                                            marginBottom: "8px",
                                            height: '24px',
                                            fontWeight: 500
                                        }}
                                    >
                                        Country<span style={{ color: 'red' }}> *</span>
                                    </Typography> */}
                                    <Box>
                                        <CountrySelect
                                            countries={countries}
                                            countryTouched={touched.country as boolean}
                                            selectedCountry={values.country}
                                            setFieldValue={setFieldValue}
                                            countryError={errors?.country as string}
                                            getCityListRequest={getCityListRequest}

                                        />
                                    </Box>
                                </Box>
                                <Box style={{ marginTop: '30px', width: isMobile ? '100%' : '50%' }}>
                                    {/* <Typography
                                        style={{
                                            fontFamily: "Inter",
                                            fontSize: "16px",
                                            color: "rgb(33 36 38 / 65%)",
                                            marginBottom: "8px",
                                            height: '24px',
                                            fontWeight: 500
                                        }}
                                    >
                                        City<span style={{ color: 'red' }}> *</span>
                                    </Typography> */}
                                    <Box>
                                        <CitySelect
                                            cities={cities}
                                            cityTouched={touched.city as boolean}
                                            selectedCity={values.city}
                                            setFieldValue={setFieldValue}
                                            cityError={errors?.city as string}
                                        />
                                    </Box>
                                </Box>


                            </Box>
                            <Box style={{ marginTop: '30px' }}>
                                <Typography
                                    style={{
                                        fontFamily: "Inter",
                                        fontSize: "16px",
                                        color: "rgb(33 36 38 / 65%)",
                                        marginBottom: "8px",
                                        height: '24px',
                                        fontWeight: 500
                                    }}
                                >
                                    {i18next.t('travelAgency.myProfile.street_name')}<span style={{ color: 'red' }}> *</span>
                                </Typography>
                                <Box style={{ display: 'flex', gap: '10px', width: '100%' }}>

                                    <TextField
                                        style={{ width: "100%" }}
                                        placeholder={i18next.t('travelAgency.myProfile.enter_street_name')}
                                        variant="standard"
                                        name="streetName"
                                        autoComplete="off"
                                        className="email_input"
                                        onChange={(e) => {
                                            setFieldValue("streetName", e.target.value);
                                            touched.streetName = true;
                                        }}
                                        error={
                                            Boolean(errors?.streetName) && touched.streetName
                                        }
                                        helperText={
                                            touched.streetName ? errors?.streetName : ""
                                        }
                                        FormHelperTextProps={{
                                            className: classes.customFont,
                                        }}
                                        value={values?.streetName}
                                        InputProps={{
                                            style: {
                                                backgroundColor: "#ffffff",
                                                color: "rgba(33, 36, 38, 0.90)",
                                                padding: "16px 20px",
                                                height: '56px',
                                                border: `1px solid`,
                                                borderColor: errors?.streetName && touched.streetName ? 'red' : '#C3CAD9',
                                                borderRadius: '8px',
                                                fontFamily: "Inter",
                                                fontSize: '16px',
                                                fontWeight: 500
                                            },
                                            disableUnderline: true,
                                        }}
                                    />
                                </Box>
                            </Box>
                            <Box style={{ marginTop: '32px', width: isMobile ? '100%' : "50%", marginRight: isMobile ? '0px' : '50px', }}>
                                <Typography
                                    style={{
                                        fontFamily: "Inter",
                                        fontSize: "16px",
                                        color: "rgb(33 36 38 / 65%)",
                                        marginBottom: "8px",
                                        height: '24px',
                                        fontWeight: 500
                                    }}
                                >
                                    {i18next.t('travelAgency.myProfile.zip_code')}<span style={{ color: 'red' }}> *</span>
                                </Typography>
                                <Box style={{ display: 'flex', gap: '10px', width: '100%' }}>

                                    <TextField
                                        style={{ width: "100%" }}
                                        placeholder={i18next.t('travelAgency.myProfile.enter_zip_code')}
                                        variant="standard"
                                        name="firstName"
                                        autoComplete="off"
                                        className="email_input"
                                        onChange={(e) => {
                                            setFieldValue("zipCode", e.target.value);
                                            touched.zipCode = true;
                                        }}
                                        error={
                                            Boolean(errors?.zipCode) && touched.zipCode
                                        }
                                        helperText={
                                            touched.zipCode ? errors?.zipCode : ""
                                        }
                                        FormHelperTextProps={{
                                            className: classes.customFont,
                                        }}
                                        value={values?.zipCode}
                                        InputProps={{
                                            style: {
                                                backgroundColor: "#ffffff",
                                                color: "rgba(33, 36, 38, 0.90)",
                                                padding: "16px 20px",
                                                height: '56px',
                                                border: `1px solid`,
                                                borderColor: errors?.zipCode && touched.zipCode ? 'red' : '#C3CAD9',
                                                borderRadius: '8px',
                                                fontFamily: "Inter",
                                                fontSize: '16px',
                                                fontWeight: 500,
                                                marginRight: isMobile ? '0px' : '24px',
                                            },
                                            disableUnderline: true,
                                        }}
                                    />
                                </Box>
                            </Box>
                          {
                            isEditClicked ? null
                            :
                            <Box style={{marginTop: '32px'}}>
                                <FormControlLabel
                                    control={<Checkbox
                                        style={{ color: primaryColor, fontFamily: "Inter" }}
                                        checked={values.agreeTermsAndCondition}
                                        onChange={(e) => setFieldValue("agreeTermsAndCondition", e.target.checked)}
                                        name="agreeTermsAndCondition" />}
                                    label={
                                        <span style={{ fontFamily: "Inter", fontSize: '14px', fontWeight: 500, color: 'rgba(33, 36, 38, 0.65)' }}>
                                           {i18next.t('travelAgency.myProfile.privacy_policy_consent_part1')}
                                            <Link target="_blank" href="/privacy-policy" style={{ color: primaryColor, fontFamily: "Inter" }}>{i18next.t('travelAgency.myProfile.privacy_policy_consent_privacy_policy')}</Link> {i18next.t('travelAgency.myProfile.and')}{" "}
                                            <Link target="_blank" href="/terms-of-use" style={{ color: primaryColor, fontFamily: "Inter" }}>{i18next.t('travelAgency.myProfile.privacy_policy_consent_terms_of_use')}</Link>
                                        </span>
                                    }

                                />
                            </Box>
                          }
                            <Box style={{ display: 'flex', gap: '24px', marginTop: '48px' }}>
                               { !isEditClicked ?
                               <>
                                <Button
                                    style={{
                                        backgroundColor: primaryColor,
                                        color: '#ffffff',
                                        textTransform: 'initial',
                                        borderRadius: '34px',
                                        padding: '10px 50px',
                                        fontSize: '16px',
                                        fontFamily: 'Inter',
                                        fontWeight: 500,
                                        width: '182px',
                                        height: '56px',
                                        whiteSpace: 'nowrap'
                                    }}
                                    type="submit"
                                >
                                    {i18next.t('travelAgency.myProfile.sign_up')}
                                </Button>
                                <Button
                                    style={{
                                        border: `1px solid ${primaryColor}`,
                                        color: primaryColor,
                                        textTransform: 'initial',
                                        borderRadius: '34px',
                                        padding: '10px 50px',
                                        fontSize: '16px',
                                        fontFamily: 'Inter',
                                        fontWeight: 500,
                                        width: '182px',
                                        height: '56px' 
                                    }}
                                    onClick={() => handleBack(values)}
                                >
                                    {i18next.t('travelAgency.myProfile.back')}
                                </Button>
                                </> 
                                :
                                <Box sx={{ display: 'flex', gridGap: '20px' }}>
                                <Button
                                    style={{
                                        backgroundColor: primaryColor,
                                        color: '#ffffff',
                                        textTransform: 'initial',
                                        borderRadius: '34px',
                                        padding: '10px 60px',
                                        fontSize: '16px',
                                        fontFamily: "Inter",
                                        fontWeight: 500,
                                        width: '182px',
                                        height: '56px',
                                        whiteSpace: 'nowrap'
                                    }}
                                    type="submit"
                                >
                                    {i18next.t('travelAgency.myProfile.save_changes')}
                                </Button>
                                <Button
                                    style={{
                                        color: primaryColor,
                                        textTransform: 'initial',
                                        borderRadius: '34px',
                                        padding: '10px 50px',
                                        fontSize: '16px',
                                        fontFamily: "Inter",
                                        fontWeight: 500,
                                        width: '182px',
                                        height: '56px',
                                    }}
                                    onClick={handleBack}
                                >
                                    {i18next.t('travelAgency.myProfile.cancel')}
                                </Button>
                            </Box>

                }
                            </Box>
                        </Form>)
                }}
            </Formik>
        </div>
    )
}

export default BusinessDetails;