import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import ApiRequest from "../../../components/src/ApiRequest";
import { getAuthToken } from "../../../components/src/utils";
import { SubscriptionPlan } from "./Interface.web";
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  cardNumber: string;
  cardHolderName: string;
  expiration: string;
  securityCode: string;
  paymentToken: string;
  trip_id: string;
  trip_schedule_id: string;
  quantity: string;
  paymentStatus: boolean;
  paymentResponse: any;
  responseModal: boolean;
  subcriptionPayment: any,
  ischecked:any;
  token: string;
  subScriptionPlan:SubscriptionPlan[];
  subScriptionData:SubscriptionPlan;
  responseStatusModal:boolean;
  account_type:string;
  subScriptionDetails:SubscriptionPlan
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SubscriptionPlanController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiCallIdGetPaymentToken: string = "";
  apiCallIdsubmitPayment: string = "";
  getSubscriptionPlanAPICallId: string = "";
  updateAutoRenewalapiCallId : any;
  apiCallIdSubscriptionPlan:string='';
  apiCallIdSubscriptionDetails:string='';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      cardNumber: '',
      cardHolderName: '',
      expiration: '',
      securityCode: '',
      paymentToken: '',
      trip_id: '',
      trip_schedule_id: '',
      quantity: '',
      paymentStatus: false,
      paymentResponse: {},
      responseModal: false,
      subcriptionPayment: [],
      ischecked : false,
      token: '',
      subScriptionPlan:[],
      subScriptionData:{
        id: "",
        type: "",
        attributes: {
          id: 0,
          name: "",
          description: "",
          duration: "",
          price: "",
          visible: false,
          currency: ""
        }
      },
      responseStatusModal:false,
      account_type:"",
      subScriptionDetails:{
        id: "",
        type: "",
        attributes: {
          id: 0,
          name: "",
          description: "",
          duration: "",
          price: "",
          visible: false,
          currency: ""
        }
      },
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
   /* istanbul ignore next */
    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));
      this.setState({ txtSavedValue: value });
      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );
    }

    // Customizable Area Start

    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const subScriptionData = message.getData(
        getName(MessageEnum.NavigationPayLoadMessage)
      )
      this.setState({
        subScriptionData:subScriptionData.data,
        paymentResponse: subScriptionData.paymentResponse


      })
    } 

    
    
    const apiRequestCallIds = {
      [this.apiCallIdGetPaymentToken]: this.getPaymentTokenResponse,
      [this.apiCallIdsubmitPayment]: this.getSubmitPaymentResponse,
      [this.getSubscriptionPlanAPICallId]: this.getSubscriptionPaymentResponse,
      [this.updateAutoRenewalapiCallId]: this.handleAutoRenewalResponse,
      [this.apiCallIdSubscriptionPlan]: this.handleSubscriptionPlanResponse,

      // Add more API call IDs and handlers as needed
    };

    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

    if (apiRequestCallId != null && apiRequestCallIds[apiRequestCallId]) {
      apiRequestCallIds[apiRequestCallId](responseJson, errorResponse);
    }



    // Customizable Area End
  }

  
 

  // Customizable Area Start
  // life-cycle method
  async componentDidMount() {
    const authToken = await getAuthToken();
    /* istanbul ignore next */
    this.setState({token : authToken}, () => {
      this.getSubscriptionPlan()
    })
    const accountType = await getStorageData("account_type");
    let type: string = '';

    switch (accountType.toLowerCase()) {
        case 'agency':
            type = 'Agency';
            break;
        case 'buddy':
            type = 'Buddy';
            break;
    }

    this.setState({
      account_type:type
    })

    this.getSubscriptionPlans(type)
  }

  handleAutoRenewalResponse(responseJson: any, errorReponse: any){
  console.log(responseJson ,errorReponse )
  }

  updateAutoRenewalApiRequest = async ()=>{
    const header = {
     Authorization: `Bearer ${this.state.token}`,
    }
    const requestMessage = ApiRequest({
     header: header,
     endPoint: configJSON.automaticRenewalEndpointFirst+configJSON.automaticRenewalEndpointSec,
     method: "PATCH",
   });
   this.updateAutoRenewalapiCallId = requestMessage.messageId;
   runEngine.sendMessage(requestMessage.id, requestMessage);
   this.setState({ischecked:!this.state?.ischecked})
 }

  getSubscriptionPlan = async () => {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "Authorization": `Bearer ${this.state.token}`
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.subscriptionPlanEndPoint
    );

    this.getSubscriptionPlanAPICallId = requestMessage.messageId;

   
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getSubscriptionPaymentResponse = (responseJson: any, errorResponse: any) => {
    this.setState({ subcriptionPayment: responseJson?.data?.attributes })
  }

// Request function
getPaymentTokenRequest = () => {
  const {cardNumber, expiration, securityCode} = this.state;
  const [expiryMonth, expiryYear] = expiration.split('/');

  // Convert the strings to numbers
  const numericMonth = parseInt(expiryMonth, 10);
  const numericYear = parseInt(expiryYear, 10);

  // Take the last two digits of the year and make it a full year
  const fullYear = new Date().getFullYear();
  const fourDigitYear = parseInt((fullYear + '').slice(0, 2) + numericYear, 10);

  // Remove spaces from the input value
  const unformattedCardNumber = cardNumber.replace(/\s/g, '');

  const payload = { 
    "type": "card", 
    "number": unformattedCardNumber, 
    "expiry_month": numericMonth, 
    "expiry_year": fourDigitYear, 
    "phone": {}, 
    "preferred_scheme": "", 
    "requestSource": "JS" ,
    "cvv": securityCode

  }
  const authToken = "pk_sbox_gw262xxhmwiv3hi2eglhwnxbjet";
    const header = {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = ApiRequest({
      header: header,
      endPoint: configJSON.paymentTokenEndpoint,
      payload: JSON.stringify(payload),
      method: "POST",
    });
    this.apiCallIdGetPaymentToken = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
}

submitPaymentRequest = async (item:any) => {
  const header = {
    Authorization: `Bearer ${this.state.token}`,
  };
  const endpoint = `${configJSON.paymentAPiEndPoint}`;
  
  const formdata = new FormData();

  const appendFieldIfValueExists = (field: string, value: any) => {
    if (value) {
      formdata.append(field, value);
    }
   
  };

  const type = item.attributes.plan_type === "free" ? "Free" : "Premium"
  appendFieldIfValueExists('subscription_billing[active]', true);
  appendFieldIfValueExists('subscription_billing[subscription_price]', item.attributes.price);
  appendFieldIfValueExists('subscription_billing[subscription_type]',type);
  appendFieldIfValueExists('subscription_billing[auto_renewal]','false');
  appendFieldIfValueExists('subscription_billing[currency]',item.attributes.currency);
  appendFieldIfValueExists('subscription_billing[subscription_plan_id]',item.id);



  const requestMessage = ApiRequest({
    header,
    endPoint: endpoint,
    payload: formdata,
    method: 'POST',
  });
  this.apiCallIdsubmitPayment = requestMessage.messageId;
  runEngine.sendMessage(requestMessage.id, requestMessage);
}

// Response function

getPaymentTokenResponse = (responseJson: any, errorReponse: any) => {
  if(responseJson.token) {
    this.setState({paymentToken: responseJson.token})
    // () => this.submitPaymentRequest()
  } else {
    this.setState({
      responseModal: true,
    })
  }
}

paymentForm = (data:any) => {
    if(data.attributes.plan_type === "free")
    {
      this.submitPaymentRequest(data)
    }
    
}

 /* istanbul ignore next */
formBackButton = () => {
  const message: Message = new Message(
    getName(MessageEnum.NavigationMessage)
  );
  message.addData(
    getName(MessageEnum.NavigationTargetMessage),
    "SubscriptionPlan"
  );
  message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  this.send(message);
}



getSubmitPaymentResponse = (responseJson: any, errorResponse: any) => {
  this.setState({ 
    paymentStatus: !!responseJson?.data, 
    paymentResponse: responseJson
  }, () => this.gotoPaymentStatusPage());
}

// Internal function
  /* istanbul ignore next */

gotoPaymentStatusPage = () => {
  const payload = {
    responseJson: this.state.paymentResponse,
    paymentStatus: this.state.paymentStatus
  }
  const navigationMessage: Message = new Message(
    getName(MessageEnum.NavigationMessage)
  );
  navigationMessage.addData(
    getName(MessageEnum.NavigationTargetMessage),
    "SubscriptionPaymentStatus"
  );
  const raiseMessage: Message = new Message(
    getName(MessageEnum.NavigationPayLoadMessage)
  );
  raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), {paymentResponse:payload });
  navigationMessage.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
  navigationMessage.addData(
    getName(MessageEnum.NavigationPropsMessage),
    this.props
  );
  this.send(navigationMessage);
}
handleCardNumberChange = (event: any) => {
const subscriptionCardNumber = event.target.value;
const subscriptionValue = subscriptionCardNumber.replace(/\D/g, '');
const formattedCardNumber = subscriptionValue.replace(/(\d{4})/g, '$1 ').trim();

  this.setState({
    cardNumber: formattedCardNumber
  });
};

handleCardHolderNameChange = (event: any) => {
  const inputCardHolderName = event.target.value;
  this.setState({
    cardHolderName: inputCardHolderName
  });
};


handleSecurityChange = (event: any) => {
  const inputSecurityCard = event.target.value;
  this.setState({
    securityCode: inputSecurityCard
  });
};
handleExpirationChange = (event: any) => {
  const inputExpiration = event.target.value;
  this.setState({
    expiration: this.formatExpiration(inputExpiration)
  });
};

formatExpiration = (inputExpiration: string) => {
  // Remove non-numeric characters
  const numericValue = inputExpiration.replace(/\D/g, '');

  // Take only the first 4 characters (MMYY)
  const formattedValue = numericValue.slice(0, 4);

  // Extract month and year
  const month = formattedValue.slice(0, 2);
  const year = formattedValue.slice(2, 4);

  // Concatenate and return in MM/YY format
  return formattedValue.length >= 3 ? `${month}/${year}` : formattedValue;
};

getSubscriptionPlans = async (type: string) => {
  if(type !== "")
  {
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.apiCallIdSubscriptionPlan = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.subScriptionPlan}?subscription_user_type=${type}`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  }
}

handleSubscriptionPlanResponse = (responseJson: any, errorReponse: any) => {
  const subScriptionData = responseJson.data;
    if (subScriptionData) {
      this.setState({
        subScriptionPlan: subScriptionData,
      });
    }
}

onClose = () => {
  this.setState({
    responseModal: false ,
})
}



  // Customizable Area End
}
