// Customizable Area Start
import React from "react";
import {
    Button,
    Container,
    Grid,
    TextField,
    Typography,
    FormControl,
    InputAdornment,
    Box
} from "@material-ui/core";
import { createTheme, ThemeProvider, withStyles, Theme } from "@material-ui/core/styles";
import i18next from 'i18next';

const theme = createTheme({
    palette: {
        primary: {
            main: "#FE7F2D",
            contrastText: "#fff",
        },
    },
    typography: {
        fontFamily: "Inter, sans-serif",
        fontWeightLight: 400,
        fontWeightRegular: 500,
        fontWeightBold: 600,
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
            fontSize: 12, // Custom font size for subtitle1
            fontWeight: 400, // Custom font weight for subtitle1
        },
        // Add more custom typography styles here as needed
    },
});

import AdminConsoleController, { Props } from "./AdminConsoleController";
import { primaryColor } from "../../../components/src/theme";
import { CreditCardIcon } from "./assets";
import ResponseStatusModal from "../../../components/src/ResponseStatusModal";

export default class MakePayment extends AdminConsoleController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const { classes } = this.props;
        const {
            cardNumber,
            cardHolderName,
            expiration,
            securityCode,
            responseModal
        } = this.state;

        const isFormValid = cardNumber !== '' && cardHolderName !== '' && expiration !== '' && securityCode !== '';

        return (
            <ThemeProvider theme={theme}>
                <div
                    style={{
                        backgroundColor: "#FFF",
                        marginBottom: "90px",
                    }}
                >
                    <Container
                       className={classes.mainWrapper}
                    >
                        <Typography
                           className={classes.makePaymentTextStyle}
                        >
                        {i18next.t('customer.makePayment.makePayment')}
                        </Typography>
                        <Typography
                            className={classes.subTextStyle}
                        >
                        {i18next.t('customer.makePayment.completePaymentMessage')}
                        </Typography>

                        {/* Card Number Field */}
                        <FormControl fullWidth>
                            <Typography
                                className={classes.fieldNameText}
                            >
                        {i18next.t('customer.makePayment.cardNumber')}
                            </Typography>
                            <TextField
                                name="cardNumber"
                                placeholder={i18next.t('customer.makePayment.cardNumber')}
                                fullWidth
                                value={cardNumber}
                                onChange={(e) => this.handleCardNumberChange(e)}
                                InputProps={{
                                    className: classes.textFieldStyle,
                                    disableUnderline: true,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <img alt="creditcard" src={CreditCardIcon} height='100%' width='100%' />
                                        </InputAdornment>
                                    ),
                                }}
                                variant="standard"
                            />
                        </FormControl>

                        {/* Card Holder Name Field */}
                        <FormControl fullWidth>
                            <Typography
                                className={classes.fieldNameText}
                            >
                               {i18next.t('customer.makePayment.cardHolderName')}
                            </Typography>
                            <TextField
                                name="cardHolderName"
                                placeholder={i18next.t('customer.makePayment.cardHolderName')}
                                fullWidth
                                value={cardHolderName}
                                onChange={(e) => this.handleCardHolderNameChange(e)}
                                InputProps={{
                                    className: classes.textFieldStyle,
                                    disableUnderline: true,
                                }}
                                variant="standard"
                            />
                        </FormControl>

                        {/* Expiration and Security Code Fields */}
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <Typography
                                        className={classes.fieldNameText}
                                    >
                                       {i18next.t('customer.makePayment.expiration')}
                                    </Typography>
                                    <TextField
                                        name="expiration"
                                        placeholder="MM / YY"
                                        fullWidth
                                        value={expiration}
                                        onChange={(e) => this.handleExpirationChange(e)}
                                        InputProps={{
                                            className: classes.textFieldStyle,
                                            disableUnderline: true,
                                        }}
                                        variant="standard"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <Typography
                                        className={classes.fieldNameText}
                                    >
                                         {i18next.t('customer.makePayment.securityCode')}
                                    </Typography>
                                    <TextField
                                        name="securityCode"
                                        placeholder= {i18next.t('customer.makePayment.securityCode')}
                                        fullWidth
                                        value={securityCode}
                                        onChange={(e) => this.handleSecurityChange(e)}
                                        InputProps={{
                                            className: classes.textFieldStyle,
                                            disableUnderline: true,
                                        }}
                                        variant="standard"
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>

                        {/* Make Payment and Back Buttons */}
                        <Box className={classes.btnWrapper}>
                            <Button className={classes.nextButtonMP}
                            disabled={!isFormValid}
                            onClick={this.getPaymentTokenRequest}
                            >
                                {i18next.t('customer.makePayment.makePayment')}
                            </Button>
                            <Button
                            onClick={() => this.props.navigation.goBack()}
                            className={classes.backButtonMP}>
                                {i18next.t('customer.makePayment.back')}
                            </Button>
                        </Box>
                        <ResponseStatusModal
                            isSuccess={false}
                            message="Please check your card details"
                            open={responseModal}
                            onClose={this.onClose}
                        />

                    </Container>
                </div>
            </ThemeProvider>
        );
    }
}

const stylesMakePayment = (theme: Theme) => ({
    mainWrapper: {
        width: '100%',
        maxWidth: '750px',
        height: "80vh",
        display: "flex",
        flexDirection: "column" as const,
        alignItems: "flex-start",
        justifyContent: "center",
    },
    makePaymentTextStyle: {
        color: '#212426',
        fontFamily: 'Inter',
        fontSize: '40px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '120%' /* 48px */
    },
    subTextStyle: {
        color: 'rgba(33, 36, 38, 0.65)',
        fontFamily: 'Inter',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '160%', /* 48px */
        marginBottom: '32px'
    },
    textFieldStyle: {
        backgroundColor: '#ffffff',
        color: '#212426',
        padding: '16px 20px',
        height: '48px',
        border: `1px solid rgba(33, 36, 38, 0.20)`,
        borderRadius: '8px',
        fontFamily: 'Inter',
        fontSize: '16px',
        marginBottom: '32px',
        fontWeight: 500,
        lineHeight: '150%',
        fontStyle: 'normal'
    },
    fieldNameText: {
        fontFamily: 'Inter',
        fontSize: '16px',
        fontStyle: 'normal',
        color: 'rgba(33, 36, 38, 0.65)',
        marginBottom: '8px',
        lineHeight: '150%',
        fontWeight: 500,
    },
    nextButtonMP: {
        width: '190px',
        borderRadius: '68px',
        height: '56px',
        border: '1px solid #FE7F2D',
        textTransform: 'initial' as const,
        color: '#fff',
        background: '#FE7F2D',
        textAlign: 'center' as const,
        fontFamily: 'Inter',
        fontStyle: 'normal' as const,
        fontWeight: 500,
        whiteSpace: 'nowrap' as const,
        fontSize: '16px',
        lineHeight: '150%',
        '&:hover': {
            backgroundColor: primaryColor,
            color: '#ffffff',
        }
    },
    backButtonMP: {
        width: '190px',
        borderRadius: '68px',
        height: '56px',
        background: '#FFF',
        textTransform: 'initial' as const,
        textAlign: 'center' as const,
        fontFamily: 'Inter',
        color: primaryColor,
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '150%',
        whiteSpace: 'nowrap' as const,
        fontStyle: 'normal' as const,
        '&:hover': {
            backgroundColor: '#ffffff',
            color: primaryColor,
        }
    },
    btnWrapper: {
        display: 'flex',
        flexDirection: 'row' as const,
        justifyContent: 'space-between'
    },

    [theme.breakpoints.between(768, 1024)]: {
        breadcrumbContainer: {
            margin: '48px 72px 0px'
        },
    },
    [theme.breakpoints.down(768)]: {
        breadcrumbContainer: {
            margin: '48px 72px 0px'
        },
        mainWrapper: {
            height: '100vh',
        },
        makePaymentTextStyle: {
            fontSize: '24px',
            fontWeight: 500,
        },
    }
});

const MakePaymentWeb = withStyles(stylesMakePayment)(MakePayment)
export { MakePaymentWeb }

// Customizable Area End
