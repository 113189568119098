import React, { useEffect, useState } from 'react';
import { makeStyles, Modal, Typography, Button, Box, Divider, TextField } from '@material-ui/core';
import { Close } from "@material-ui/icons";
import { primaryColor } from './theme';
import { date } from 'yup';
import { TripListCard } from '../../blocks/catalogue/src/CatalogueController';
import i18next from 'i18next';

interface ReasonI {
  id: string;
  date: string;
  reason: string;
}

interface CustomModalProps {
  open: boolean;
  onClose: () => void;
  message: string;
  mode: string;
  deleteTripApiRequest?: () => void;
  editTripRequest?: () => void;
  dateCancellationReason?: (value: string) => void;
  modifiedDate?: any[];
  sureDelete?: boolean;
  handleProceedwithDeleteTripReason?: () => void;
  addReason: (id: string, reason: string) => void;
  updateTrip?: () => void;
  triplist? :  TripListCard[];
  tripId? : string
  handleDeletereason?:(value: string) => void

}

const useStyles = makeStyles((theme) => ({
  paperDeleteTrip: {
    position: 'absolute',
    width: '40vw',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: '16px 20px',
    borderRadius: '16px',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'initial'
  },
  paperEditTrip: {
    position: 'absolute',
    width: '35vw',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: '16px 20px',
    borderRadius: '16px',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'initial'
  },
  successIcon: {
    color: theme.palette.success.main,
    fontSize: 32,
  },
  errorIcon: {
    color: theme.palette.error.main,
    fontSize: 32,
  },
  modalContent: {
    position: 'relative',
    padding: '2rem 2.2rem', // Your desired padding value
    maxWidth: '100%',
    maxHeight: '100%',
    overflow: 'auto',
  },
  modalEditContent: {
    position: 'relative',
    padding: '2rem 2.2rem', // Your desired padding value
    maxWidth: '100%',
    maxHeight: '50vh',
    overflowY: 'scroll',
    overflow: 'auto',
  },
  closeButton: {
    position: 'absolute',
    top: '3px', // Adjust as needed
    right: '3px', // Adjust as needed
    cursor: 'pointer',
  },
}));

const CustomModal: React.FC<CustomModalProps> = ({
  open,
  onClose,
  message,
  mode,
  editTripRequest,
  deleteTripApiRequest,
  dateCancellationReason,
  modifiedDate = [],
  sureDelete = false,
  handleProceedwithDeleteTripReason,
  addReason,
  updateTrip,
  triplist,
  tripId,
  handleDeletereason

}) => {
  const classes = useStyles();
  const [reason, setReason] = useState('');
  const [removedDates, setRemovedDates] = useState<ReasonI[]>([])

 const filteredTrip = triplist&& triplist.find(trip => trip.id === tripId); 
const bookingCount = filteredTrip ? filteredTrip.booking_count : 0;   

  const handleChange = (event: any) => {
    const value = event.target.value;
    setReason(value);
    dateCancellationReason?.(value);
    handleDeletereason?.(value)
  }

  const handleClose = () => {
    onClose();
  };

  const removedDatesVariable = modifiedDate.map((date) => {
    const dateM = new Date(date.attributes.scheduled_date);
    const day = dateM.getDate();
    const month = dateM.toLocaleString('en-US', { month: 'short' });
    const year = dateM.getFullYear();
    const formattedDate = `${day} ${month} ${year}`;
    return {
      id: date.id,
      date: formattedDate,
      reason: ''
    };
  });

  function getRandomNumber(): number {
    const min = 10;
    const max = 100;
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  useEffect(() => {
    if(modifiedDate.length > 0){
      setRemovedDates(removedDatesVariable)
    }
  }, [modifiedDate])



  const handleMultiReasonChange = (id: string, reason: string) => {
    setRemovedDates((prevState) =>
      prevState.map((item) => (item.id === id ? { ...item, reason } : item))
    );
    addReason(id, reason)
  };


  const allReasonsFilled = () => {
    return removedDates.every((date) => date.reason.trim() !== '');
  };

  const renderModalUI = (mode: string) => {
    if (mode === 'deleteTrip' && !sureDelete) {
      return (
        <div className={classes.paperEditTrip}>
          <Box className={classes.modalContent}>
            <Box className={classes.closeButton} onClick={handleClose}>
              <Close style={{ fontSize: '20px' }} />
            </Box>
            <Typography
              style={{
                fontFamily: 'Inter',
                fontSize: '16px',
                fontWeight: 400,
                color: '#212426',
                marginBottom: '16px'
              }}
            >
               {` ${bookingCount} `}{i18next.t('travelAgency.cancelTripPopup.customersAlreadyBookedTrip')}
            </Typography>
            <Divider />
            <Typography
              style={{
                fontFamily: 'Inter',
                fontSize: '16px',
                fontWeight: 400,
                color: '#212426',
                margin: '16px 0px'
              }}
            >
             {i18next.t('travelAgency.cancelTripPopup.reasonForCancellingTrip')}
            </Typography>
            <TextField
              style={{ width: '100%' }}
              placeholder={i18next.t('travelAgency.cancelTripPopup.writeSomething')}
              variant="standard"
              type='text'
              autoComplete="off"
              onChange={handleChange}
              value={reason}
              InputProps={{
                style: {
                  backgroundColor: '#ffffff',
                  color: 'rgba(33, 36, 38, 0.90)',
                  padding: '16px 20px',
                  height: '152px',
                  border: `1px solid`,
                  borderColor: 'rgba(33, 36, 38, 0.20)', // Conditionally set border color
                  borderRadius: '8px',
                  fontFamily: 'Inter',
                  fontSize: '16px',
                  fontWeight: 500,
                  marginBottom: '48px'
                },
                multiline: true,
                minRows: '7',
                maxRows: '20',
                disableUnderline: true,
              }}
            />
            <Box
              style={{
                display: 'flex',
                gap: '30px',
                justifyContent: 'flex-end'
              }}
            >
              <Button
                style={{
                  backgroundColor: 'transparent',
                  color: primaryColor,
                  border: `1px solid ${primaryColor}`,
                  textTransform: 'initial',
                  borderRadius: '34px',
                  padding: '10px 50px',
                  fontSize: '16px',
                  fontFamily: "Inter",
                  fontWeight: 500,
                  width: '182px',
                  height: '56px',
                }}
                onClick={handleClose}
              >
                {i18next.t('travelAgency.cancelTripPopup.discard')}
              </Button>
              <Button
                style={{
                  backgroundColor: primaryColor,
                  color: '#ffffff',
                  textTransform: 'initial',
                  borderRadius: '34px',
                  padding: '10px 50px',
                  fontSize: '16px',
                  fontFamily: "Inter",
                  fontWeight: 500,
                  width: '182px',
                  height: '56px',
                }}
                disabled={reason.length === 0}
                onClick={handleProceedwithDeleteTripReason}
              >
                {i18next.t('travelAgency.cancelTripPopup.proceed')}
              </Button>
            </Box>

          </Box>
        </div>
      )
    } else if (mode === 'deleteTrip' && sureDelete) {
      return (
        <div className={classes.paperDeleteTrip}>
          <Box className={classes.modalContent}>
            <Box className={classes.closeButton} onClick={handleClose}>
              <Close style={{ fontSize: '20px' }} />
            </Box>
            <Typography
              style={{
                fontFamily: 'Inter',
                fontSize: '24px',
                fontWeight: 600,
                color: '#212426',
                marginBottom: '16px'
              }}
            >
             {i18next.t('travelAgency.cancelTripPopup.areYouSureYouWantToDeleteTrip')}
            </Typography>
            <Typography
              style={{
                fontFamily: 'Inter',
                fontSize: '16px',
                fontWeight: 400,
                color: '#212426',
                marginBottom: '16px'
              }}
            >
              {i18next.t('travelAgency.cancelTripPopup.deletingTripWarning')}
            </Typography>
            <Box
              style={{
                display: 'flex',
                gap: '30px',
                justifyContent: 'flex-end'
              }}
            >
              <Button
                style={{
                  backgroundColor: 'transparent',
                  color: primaryColor,
                  border: `1px solid ${primaryColor}`,
                  textTransform: 'initial',
                  borderRadius: '34px',
                  padding: '10px 50px',
                  fontSize: '16px',
                  fontFamily: "Inter",
                  fontWeight: 500,
                  width: '182px',
                  height: '56px',
                }}
                onClick={handleClose}
              >
                {i18next.t('travelAgency.cancelTripPopup.discard')}
              </Button>
              <Button
                style={{
                  backgroundColor: primaryColor,
                  color: '#ffffff',
                  textTransform: 'initial',
                  borderRadius: '34px',
                  padding: '10px 50px',
                  fontSize: '16px',
                  fontFamily: "Inter",
                  fontWeight: 500,
                  width: '182px',
                  height: '56px',
                }}
                onClick={deleteTripApiRequest}
                disabled={reason.length === 0}
              >
                {i18next.t('travelAgency.cancelTripPopup.proceed')}
              </Button>
            </Box>

          </Box>
        </div>
      )
    } else if (mode === 'edit') {
      return (
        <div className={classes.paperEditTrip}>
          <Box 
          // className={classes.modalEditContent}
          style={{
            position: 'relative',
            padding: '2rem 2.2rem', // Your desired padding value
            maxWidth: '100%',
            maxHeight: removedDates.length === 1 ? '100%' : '50vh',
            overflowY: removedDates.length === 1 ? 'hidden' : 'scroll',
            // overflow: 'auto',
          }}
          >
            <Box className={classes.closeButton} onClick={handleClose}>
              <Close style={{ fontSize: '20px' }} />
            </Box>
            
            {
              removedDates.map((date, index) => {
                return (
                  <Box key={date.id}>
                    <Typography
                      style={{
                        fontFamily: 'Inter',
                        fontSize: '16px',
                        fontWeight: 400,
                        color: '#212426',
                        marginBottom: '16px'
                      }}
                    >
                      { 30 + index } {i18next.t('travelAgency.cancelTripPopup.customersAlreadyBookedDate')} {date.date}, {i18next.t('travelAgency.cancelTripPopup.removingDateWarning')}
                    </Typography>
                    <Divider />
                    <Typography
                      style={{
                        fontFamily: 'Inter',
                        fontSize: '16px',
                        fontWeight: 400,
                        color: '#212426',
                        margin: '16px 0px'
                      }}
                    >
                      {i18next.t('travelAgency.cancelTripPopup.reasonForCancellingTripOn')} {date.date}.
                    </Typography>
                    <TextField
                      style={{ width: '100%' }}
                      placeholder={i18next.t('travelAgency.cancelTripPopup.writeSomething')}
                      variant="standard"
                      type='text'
                      autoComplete="off"
                      onChange={(event) => handleMultiReasonChange(date.id, event.target.value)}
                      value={date.reason}
                      InputProps={{
                        style: {
                          backgroundColor: '#ffffff',
                          color: 'rgba(33, 36, 38, 0.90)',
                          padding: '16px 20px',
                          height: '152px',
                          border: `1px solid`,
                          borderColor: 'rgba(33, 36, 38, 0.20)', // Conditionally set border color
                          borderRadius: '8px',
                          fontFamily: 'Inter',
                          fontSize: '16px',
                          fontWeight: 500,
                          marginBottom: '48px'
                        },
                        multiline: true,
                        minRows: '7',
                        maxRows: '20',
                        disableUnderline: true,
                      }}
                    />
                  </Box>
                )
              })
            }
            <Box
              style={{
                display: 'flex',
                gap: '30px',
                justifyContent: 'flex-end'
              }}
            >
              <Button
                style={{
                  backgroundColor: 'transparent',
                  color: primaryColor,
                  border: `1px solid ${primaryColor}`,
                  textTransform: 'initial',
                  borderRadius: '34px',
                  padding: '10px 50px',
                  fontSize: '16px',
                  fontFamily: "Inter",
                  fontWeight: 500,
                  width: '182px',
                  height: '56px',
                }}
                onClick={handleClose}
              >
                {i18next.t('travelAgency.cancelTripPopup.discard')}
              </Button>
              <Button
                style={{
                  backgroundColor: primaryColor,
                  color: '#ffffff',
                  textTransform: 'initial',
                  borderRadius: '34px',
                  padding: '10px 50px',
                  fontSize: '16px',
                  fontFamily: "Inter",
                  fontWeight: 500,
                  width: '182px',
                  height: '56px',
                }}
                onClick={() => updateTrip?.()}
                disabled={!allReasonsFilled()}
              >
                {i18next.t('travelAgency.cancelTripPopup.proceed')}
              </Button>
            </Box>

          </Box>
        </div>
      )
    }
  }

  return (
    <Modal open={open}>
      <div >
        {renderModalUI(mode)}
      </div>
    </Modal>
  );
}

export default CustomModal;
