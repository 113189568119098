import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { Categories, ICurrency, IStep1CET, IStep2CET, IStep3CET, IStep4CET } from "./Interface.web";
import { CityData, CountryData } from "../../email-account-registration/src/Interface.web";
import ApiRequest from "../../../components/src/ApiRequest";
import { capitalizeFirstLetter, getAuthToken, handleScrollToTop } from "../../../components/src/utils";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  loader: boolean;
  activeStep: number;
  step1CETValues: IStep1CET;
  step2CETValues: IStep2CET;
  step3CETValues: IStep3CET;
  step4CETValues: IStep4CET;
  openModal: boolean;
  modalMsg: string;
  isSuccess: boolean;
  countryList: CountryData[];
  cityList: CityData[];
  currencyList: ICurrency[],
  mode: 'create' | 'edit';
  tripId: string;
  errors: any;
  dateCancellationReason: string;
  sureDelete: boolean;
  responseStatusModal: boolean;
  cancelTripCreationModal: boolean;
  tripType: Categories[];
  travelerType: Categories[];
  vibeList: Categories[];
  time: Categories[]
  datesOfTrip: string[]
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ProductDescriptionController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiCallIdCountryList: string = '';
  apiCallIdCityList: string = '';
  apiCallIdCurrencyList: string = '';
  apiCallIdCreateTrip: string = '';
  apiCallIdShowSingleTrip: string = '';
  apiCallIdDeleteTrip: string = '';
  apiCallIdTripType: string = '';
  apiCallIdTravelerType: string = '';
  apiCallIdVibeList: string = '';
  apiCallIdTime: string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      loader: false,
      activeStep: 0,
      step1CETValues: {
        title: '',
        description: '',
        trip_type: '',
        country: null,
        city: null,
        area: '',
        vibes: [],
        main_photo: null,
        photos: [],
        time: '',
        duration: '',
        trip_calendar: [],
        original_trip_calendar: [],
        modified_trip_calendar: []
      },
      step2CETValues: {
        guided_tour: '',
        itinerary: [],
        facilities: '',
        what_to_bring: '',
        transportation: '',
        include: '',
        does_not_include: '',
        not_allowed: '',
        more_information: ''
      },
      step3CETValues: {
        traveler_type: '',
        group_preference: '',
        minimum_seats: '',
        maximum_seats: '',
        dress_code: '',
        language: [],
        kids_friendly: '',
        female_only: '',
        wheelchair_access: ''
      },
      step4CETValues: {
        payment_method: '',
        price_per_person: '',
        currency: '',
        cancellation_policy_or_no_show: '',
        apply_discount: '',
        select_discount: '',
        start_date: '',
        end_date: '',
        title: '',
        description: '',
        quantity: '',
        discounted_price_per_person_purchase_quantity: '',
        discounted_price_per_person_early_payment: '',
        how_many_days_before_the_trip: ''
      },
      openModal: false,
      modalMsg: '',
      isSuccess: false,
      countryList: [],
      cityList: [],
      currencyList: [],
      mode: 'create',
      tripId: '',
      errors: {},
      dateCancellationReason: '',
      sureDelete: false,
      responseStatusModal: false,
      cancelTripCreationModal: false,
      tripType:[],
      travelerType: [],
      vibeList: [],
      time: [],
      datesOfTrip: []
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    const apiRequestCallIds = {
      [this.apiCallIdCountryList]: this.countryListApiResponse,
      [this.apiCallIdCityList]: this.cityListApiResponse,
      [this.apiCallIdCreateTrip]: this.getCreateTripResponse,
      [this.apiCallIdCurrencyList]: this.currencyListApiResponse,
      [this.apiCallIdShowSingleTrip]: this.tripDetailsApiResponse,
      [this.apiCallIdDeleteTrip]: this.deleteTripResponse,
      [this.apiCallIdTripType]: this.tripTypeApiResponse,
      [this.apiCallIdTravelerType]: this.travelerTypeApiResponse,
      [this.apiCallIdVibeList]: this.vibeListApiResponse,
      [this.apiCallIdTime]: this.timeApiResponse,

      // Add more API call IDs and handlers as needed
    };

    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

    if (apiRequestCallId != null && apiRequestCallIds[apiRequestCallId]) {
      apiRequestCallIds[apiRequestCallId](responseJson, errorResponse);
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  // LifeCycle methods
  async componentDidMount() {
    const { pathname } = window.location;
    if (pathname === '/CreateTrip') {
      // Execute logic specific to the '/CreateTrip' path
      this.getCountryListAPIRequest();
      this.getCurrencyListAPIRequest();
      this.getTripTypeAPIRequest();
      this.getTravelerTypeAPIRequest();
      this.getVibeListAPIRequest();
      this.getTimeAPIRequest();
      this.setState({
        mode: 'create'
      })
    } else if (pathname.startsWith('/EditTrip/')) {
      // Execute logic specific to edit trip paths (e.g., '/EditTrip/2', '/EditTrip/3')
      this.getTripTypeAPIRequest();
      this.getCountryListAPIRequest();
      this.getCurrencyListAPIRequest();
      this.getVibeListAPIRequest();
      this.getTravelerTypeAPIRequest();
      this.getTimeAPIRequest();
      const tripId = pathname.split('/').pop(); // Extract the trip ID from the path
      this.getTripDetailsAPIRequest(tripId as string)
      
      this.setState({
        mode: 'edit',
        tripId: tripId as string
      })
    } else if (pathname.startsWith('/SingleTripDetails/')) {
      this.getTripTypeAPIRequest();
      this.getTravelerTypeAPIRequest();
      this.getTimeAPIRequest();
      const tripId = pathname.split('/').pop(); // Extract the trip ID from the path
      this.getTripDetailsAPIRequest(tripId as string)
      this.setState({
        tripId: tripId as string
      })
    }

  }

  // Request functions

  getCountryListAPIRequest = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = ApiRequest({
      header: header,
      endPoint: configJSON.getCountryListEndpoint,
      method: "GET",
    });
    this.apiCallIdCountryList = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getCityListAPIRequest = (country: string) => {

    const countryId = this.getCountryIdFunc(country)

    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = ApiRequest({
      header: header,
      endPoint: `account_block/country/${countryId}/cities`,
      method: "GET",
    });
    this.apiCallIdCityList = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getCurrencyListAPIRequest = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = ApiRequest({
      header: header,
      endPoint: configJSON.getCurrencyListEndpoint,
      method: "GET",
    });
    this.apiCallIdCurrencyList = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  createTripRequest = async () => {

    this.setState({
      loader: true,
      openModal: false,
      responseStatusModal: false,
      dateCancellationReason: ''
    })

    const formdata = this.createFormData()

    const authToken = await getAuthToken();
    const header = {
      Authorization: `Bearer ${authToken}`,
    };

    const requestMessage = ApiRequest({
      header: header,
      endPoint: this.state.mode === 'create' ? configJSON.createTripEndpoint : `${configJSON.createTripEndpoint}/${this.state.tripId}`,
      payload: formdata,
      method: this.state.mode === 'create' ? "POST" : "PUT",
    });
    this.apiCallIdCreateTrip = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  createFormData = () => {
    const {
      area, city, country, description, duration, main_photo, photos,
      time, title, trip_calendar, trip_type, vibes
    } = this.state.step1CETValues;

    const {
      does_not_include, facilities, guided_tour, include, itinerary,
      more_information, not_allowed, transportation, what_to_bring,
    } = this.state.step2CETValues;

    const {
      dress_code, female_only, group_preference, kids_friendly, language,
      maximum_seats, minimum_seats, traveler_type, wheelchair_access,
    } = this.state.step3CETValues;

    const {
      apply_discount, cancellation_policy_or_no_show, description: apply_discount_description,
      discounted_price_per_person_early_payment, discounted_price_per_person_purchase_quantity,
      payment_method, price_per_person, currency, title: apply_discount_title, select_discount,
      start_date, end_date, quantity, how_many_days_before_the_trip,
    } = this.state.step4CETValues;

    const formdata = new FormData();
    const photosArray = photos;
    const tripCalendarArray = [...trip_calendar] ;
    const itineraryArray = itinerary;

    // Helper function to append a field and value to formdata if the value exists
    const appendFieldIfValueExists = (field: string, value: any) => {
      if (value) {
        formdata.append(field, value);
      }
    };

    // Helper function to append multiple fields with the same prefix
    const appendMultipleFields = (prefix: string, values: any[]) => {
      values.forEach((value) => {
        appendFieldIfValueExists(`${prefix}`, value);
      });
    };
    // Append fields with values if they exist
    appendFieldIfValueExists('title', title);
    appendFieldIfValueExists('description', description);
    appendFieldIfValueExists('trip_type_id', trip_type);
    appendFieldIfValueExists('country_id', this.getCountryIdFunc(country));
    appendFieldIfValueExists('city_id', this.getCityIdFunc(city));
    appendFieldIfValueExists('area', area);
    appendMultipleFields('vibes[][title]', vibes);
    if (main_photo && main_photo[0].name) {
      appendFieldIfValueExists('primary_image', main_photo[0]);
    }
    photosArray.forEach((element: any) => {
      if (element.name) {
        appendFieldIfValueExists('other_images[]', element);
      }
    });
    appendFieldIfValueExists('trip_detail[period_id]', time);
    appendFieldIfValueExists('trip_detail[duration]', duration);
    tripCalendarArray.forEach((element) => {
      if (element.locallyCreated) {
        appendFieldIfValueExists('trip_schedules[][scheduled_date]', element.attributes.scheduled_date);
        appendFieldIfValueExists('trip_schedules[][reason]', element.attributes.reason);
      } else if (!element.locallyCreated && element.deleted) {
        appendFieldIfValueExists('trip_schedules[][id]', element.id);
        appendFieldIfValueExists('trip_schedules[][reason]', element.attributes.reason);
        appendFieldIfValueExists('trip_schedules[][scheduled_date]', element.attributes.scheduled_date);
      }
    });
    appendFieldIfValueExists('trip_detail[guided_tour]', guided_tour);
    itineraryArray.forEach((element) => {
      appendFieldIfValueExists('itineraries[][id]', element.id);
      appendFieldIfValueExists('itineraries[][description]', element.value);
      appendFieldIfValueExists('itineraries[][_destroy]', element._destroy);
    });
    appendFieldIfValueExists('trip_detail[facilities]', facilities);
    appendFieldIfValueExists('trip_detail[check_list]', what_to_bring);
    appendFieldIfValueExists('trip_detail[transportation_provided]', transportation);
    appendFieldIfValueExists('trip_detail[inclusion]', include);
    appendFieldIfValueExists('trip_detail[exclusion]', does_not_include);
    appendFieldIfValueExists('trip_detail[restricted_item]', not_allowed);
    appendFieldIfValueExists('trip_detail[other_detail]', more_information);
    appendFieldIfValueExists('trip_detail[traveler_type_id]', traveler_type);
    appendFieldIfValueExists('group_preference[preference_type]', group_preference);
    appendFieldIfValueExists('group_preference[min_seat]', minimum_seats);
    appendFieldIfValueExists('group_preference[max_seat]', maximum_seats);
    appendFieldIfValueExists('trip_detail[dress_code]', dress_code);
    appendFieldIfValueExists('trip_detail[traveler_type]', traveler_type);
    appendMultipleFields('trip_detail[language][]', language);
    appendFieldIfValueExists('trip_detail[kids_friendly]', kids_friendly);
    appendFieldIfValueExists('trip_detail[female_only]', female_only);
    appendFieldIfValueExists('trip_detail[wheelchair_access]', wheelchair_access);
    appendFieldIfValueExists('payment_method', payment_method);
    appendFieldIfValueExists('price_per_person', price_per_person);
    appendFieldIfValueExists('currency_id', this.getCurrencyIdFunc(currency));
    appendFieldIfValueExists('trip_detail[cancellation_policy]', cancellation_policy_or_no_show);
    if (apply_discount === 'define_discount_percent') {
      appendFieldIfValueExists('defined_discount[discount]', select_discount);
      appendFieldIfValueExists('defined_discount[start_date]', start_date);
      appendFieldIfValueExists('defined_discount[end_date]', end_date);
      appendFieldIfValueExists('quantity_discount[_destroy]', true);
      appendFieldIfValueExists('early_payment_discount[_destroy]', true);
    } else if (apply_discount === 'based_on_purchase_quantity') {
      appendFieldIfValueExists('quantity_discount[title]', apply_discount_title);
      appendFieldIfValueExists('quantity_discount[quantity]', quantity);
      appendFieldIfValueExists('quantity_discount[discounted_price_per_person]', discounted_price_per_person_purchase_quantity);
      appendFieldIfValueExists('quantity_discount[description]', apply_discount_description);
      appendFieldIfValueExists('defined_discount[_destroy]', true);
      appendFieldIfValueExists('early_payment_discount[_destroy]', true);
    } else if (apply_discount === 'early_payment_price') {
      appendFieldIfValueExists('early_payment_discount[discounted_price_per_person]', discounted_price_per_person_early_payment);
      appendFieldIfValueExists('early_payment_discount[days_before_trip]', how_many_days_before_the_trip);
      appendFieldIfValueExists('defined_discount[_destroy]', true);
      appendFieldIfValueExists('quantity_discount[_destroy]', true);
    } else {
      appendFieldIfValueExists('defined_discount[_destroy]', true);
      appendFieldIfValueExists('quantity_discount[_destroy]', true);
      appendFieldIfValueExists('early_payment_discount[_destroy]', true);
    }
    
    return formdata;
  }


  getCountryIdFunc = (country: string | null) => {
    return country
      ? (this.state.countryList.find(
        (countryData: CountryData) => countryData.name === country
      )?.id ?? null)
      : null;
  };

  getCityIdFunc = (city: string | null) => {
    return city
      ? (this.state.cityList.find(
        (cityData: CityData) => cityData.name === city
      )?.id ?? null)
      : null;
  };

  getCurrencyIdFunc = (currency: string | null) => {
    return currency
      ? (this.state.currencyList.find(
        (currencyData: ICurrency) => currencyData.currency_code === currency
      )?.currency_id ?? null)
      : null;
  };

  getTripDetailsAPIRequest = async (id: string) => {
    const authToken = await getAuthToken();
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      Authorization: `Bearer ${authToken}`,
    };
    const requestMessage = ApiRequest({
      header: header,
      endPoint: `${configJSON.showTripEndpoint}/${id}`,
      method: "GET",
    });
    this.apiCallIdShowSingleTrip = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  deleteTripAPIRequest = async () => {
    const authToken = await getAuthToken();
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      Authorization: `Bearer ${authToken}`,
    };
    const requestMessage = ApiRequest({
      header: header,
      endPoint: `${configJSON.showTripEndpoint}/${this.state.tripId}`,
      method: "DELETE",
    });
    this.apiCallIdDeleteTrip = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getTripTypeAPIRequest = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = ApiRequest({
      header: header,
      endPoint: configJSON.tripTypeEndpoint,
      method: "GET",
    });
    this.apiCallIdTripType = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getTravelerTypeAPIRequest = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = ApiRequest({
      header: header,
      endPoint: configJSON.travelerTypeEndpoint,
      method: "GET",
    });
    this.apiCallIdTravelerType = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getVibeListAPIRequest = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = ApiRequest({
      header: header,
      endPoint: configJSON.vibeListEndpoint,
      method: "GET",
    });
    this.apiCallIdVibeList = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getTimeAPIRequest = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = ApiRequest({
      header: header,
      endPoint: configJSON.timeEndpoint,
      method: "GET",
    });
    this.apiCallIdTime = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }



  // Response Functions

  countryListApiResponse = (responseJson: any, errorReponse: any) => {
    const countryDataArray = responseJson.country_data.data;
    if (countryDataArray) {
      const countryList: CountryData[] = countryDataArray.map(
        (country: any) => {
          const attributes = country.attributes;
          attributes.country_code = '+' + attributes.country_code; // Add '+' sign
          attributes.id = country.id;
          return attributes;
        }
      );

      this.setState({
        countryList: countryList,
      });
    }
  }

  cityListApiResponse = (responseJson: any, errorReponse: any) => {
    const citiesDataArray = responseJson?.cities_data?.data;
    if (citiesDataArray) {
      const cityList = citiesDataArray.map(
        (city: any) => {
          const attributes = city.attributes;
          attributes.id = city.id;
          return attributes;
        }
      );

      this.setState({
        cityList: cityList,
      });
    }
  }

  currencyListApiResponse = (responseJson: any, errorReponse: any) => {
    const currencyDataArray = responseJson.data;
    if (currencyDataArray) {
      const currencyList: ICurrency[] = currencyDataArray.map(
        (currency: any) => {
          const attributes = currency.attributes;
          attributes.currency_id = currency.id;
          return attributes;
        }
      );

      this.setState(prev => {
        return {
          ...prev,
          currencyList: currencyList,
          step4CETValues: {
            ...prev.step4CETValues,
            currency: currencyList[0].currency_code,
          }
        }
      });
    }
  }

  getCreateTripResponse = (responseJson: any, errorReponse: any) => {
    let errorMessage = "Sorry, something went wrong!";

  if (responseJson?.errors && responseJson.errors[0]?.trip) {
    if (responseJson.errors[0].trip["quantity_discount.discount_price"]) {
      errorMessage = responseJson.errors[0].trip["quantity_discount.discount_price"][0];
    }
  }

  if (responseJson?.status === 500) {
    this.setState({
      loader: false,
      responseStatusModal: true,
      isSuccess: false,
      modalMsg: errorMessage
    });
    return;
  }

  if (responseJson?.messages && !responseJson?.errors) {
    const msg = responseJson.messages[0]?.trip;
    this.setState({
      loader: false,
      responseStatusModal: true,
      isSuccess: true,
      modalMsg: msg,
    });
    return;
  }

  this.setState({
    loader: false,
    responseStatusModal: true,
    isSuccess: false,
    modalMsg: errorMessage,
    errors: responseJson.errors
  });
  }

  deleteTripResponse = (responseJson: any, errorReponse: any) => {

    if (responseJson.status === 500) {
      this.setState({
        responseStatusModal: true,
        isSuccess: false,
        modalMsg: 'Sorry, something went wrong!'
      })
      return;
    }

    if (responseJson.messages && !responseJson.errors) {
      const msg = responseJson.messages[0].trip;
      this.setState({
        openModal: false,
        responseStatusModal: true,
        isSuccess: true,
        modalMsg: msg,
      })
    } else {
      this.setState({
        openModal: false,
        responseStatusModal: true,
        isSuccess: false,
        modalMsg: 'Sorry, something went wrong! ',
      })
    }
  }

  tripDetailsApiResponse = (responseJson: any, errorResponse: any) => {
    const attributes = responseJson?.trip?.data?.attributes || {};


    if (attributes) {
      const step1CETValues = this.extractStep1CETValues(attributes);
      const step2CETValues = this.extractStep2CETValues(attributes);
      const step3CETValues = this.extractStep3CETValues(attributes);
      const step4CETValues = this.extractStep4CETValues(attributes);
      const datesOfTrip = this.getScheduleDates(attributes?.trip_schedules);


      this.setState(
        {
          step1CETValues,
          step2CETValues,
          step3CETValues,
          step4CETValues,
          datesOfTrip
        },
        () => this.getCityListAPIRequest(step1CETValues.country as string)
      );
    }
  };

  extractStep1CETValues = (attributes: any) => {
    const { tripType } = this.state;
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    return {
      title: attributes.title || '',
      description: attributes.description || '',
      trip_type: tripType?.filter(type => type.label.toLowerCase() == attributes?.trip_type?.toLowerCase())[0]?.value || '',
      country: attributes.country?.data?.attributes.name || '',
      city: attributes.city?.data?.attributes.name || '',
      area: attributes.area || '',
      vibes: this.extractVibes(attributes),
      main_photo: attributes.primary_image_url || '',
      photos: attributes.other_images || [],
      time: attributes.trip_detail?.data?.attributes.period?.id || '',
      duration: attributes.trip_detail?.data.attributes.duration || '',
      trip_calendar: this.extractTripCalendar(attributes),
      original_trip_calendar: this.extractTripCalendar(attributes),
      realApiResponse_trip_calender: attributes.trip_schedules?.data,
      previous_calendar_dates: attributes.trip_schedules?.data?.filter((date: any) => {
        const selectedDate = new Date(date.attributes.scheduled_date);
        selectedDate.setHours(0, 0, 0, 0);
        return selectedDate < currentDate;
      })
    };
  };

  extractVibes = (attributes: any) => {
    return attributes.vibes?.map((vibe: any) => vibe) || [];
  };

  extractTripCalendar = (attributes: any) => {
    return attributes.trip_schedules?.data || [];
  };

  extractStep2CETValues = (attributes: any) => {
    return {
      guided_tour: attributes.trip_detail?.data.attributes.guided_tour?.toString() || '',
      itinerary: this.extractItinerary(attributes),
      facilities: attributes.trip_detail?.data.attributes.facilities || '',
      what_to_bring: attributes.trip_detail?.data.attributes.check_list || '',
      transportation: attributes.trip_detail?.data.attributes.transportation_provided?.toString() || '',
      include: attributes.trip_detail?.data.attributes.inclusion || '',
      does_not_include: attributes.trip_detail?.data.attributes.exclusion || '',
      not_allowed: attributes.trip_detail?.data.attributes.restricted_item || '',
      more_information: attributes.trip_detail?.data.attributes.other_detail || '',
    };
  };

  extractItinerary = (attributes: any) => {
    return attributes.itineraries?.data?.map((des: any, index: number) => ({
      id: des.id,
      serial: index + 1,
      value: des.attributes.description,
    })) || [];
  };

  extractStep3CETValues = (attributes: any) => {
    return {
      traveler_type: attributes.trip_detail?.data.attributes.traveler_type?.id || '',
      group_preference: attributes.group_preference?.data.attributes.preference_type || '',
      minimum_seats: attributes.group_preference?.data.attributes.min_seat || null,
      maximum_seats: attributes.group_preference?.data.attributes.max_seat || null,
      dress_code: attributes.trip_detail?.data.attributes.dress_code || '',
      language: attributes.trip_detail?.data.attributes.language || [],
      kids_friendly: attributes.trip_detail?.data.attributes.kids_friendly?.toString() || '',
      female_only: attributes.trip_detail?.data.attributes.female_only?.toString() || '',
      wheelchair_access: attributes.trip_detail?.data.attributes.wheelchair_access?.toString() || '',
    };
  };

  extractStep4CETValues = (attributes: any) => {
    let apply_discount: string = 'default_value';
    if (attributes.defined_discount?.data) {
      apply_discount = 'define_discount_percent';
    } else if (attributes.quantity_discount?.data) {
      apply_discount = 'based_on_purchase_quantity';
    } else if (attributes.early_payment_discount?.data) {
      apply_discount = 'early_payment_price';
    }

    return {
      payment_method: attributes.payment_method || '',
      price_per_person: attributes.price_per_person || '',
      currency: attributes.currency,
      cancellation_policy_or_no_show: attributes.trip_detail?.data.attributes.cancellation_policy || '',
      apply_discount: apply_discount,
      select_discount: attributes.defined_discount?.data?.attributes?.discount || '',
      start_date: attributes.defined_discount?.data?.attributes?.start_date || '',
      end_date: attributes.defined_discount?.data?.attributes?.end_date || '',
      title: attributes.quantity_discount?.data?.attributes?.title || '',
      description: attributes.quantity_discount?.data?.attributes?.description || '',
      quantity: attributes.quantity_discount?.data?.attributes?.quantity || '',
      discounted_price_per_person_purchase_quantity: attributes.quantity_discount?.data?.attributes.discounted_price_per_person || '',
      discounted_price_per_person_early_payment: attributes.early_payment_discount?.data?.attributes.discounted_price_per_person || '',
      how_many_days_before_the_trip: attributes.early_payment_discount?.data?.attributes.days_before_trip || '',
    };
  };

  tripTypeApiResponse = (responseJson: any, errorReponse: any) => {
    const tripTypeData = responseJson.data;
    if (tripTypeData) {
      const tripTypeList: Categories[] = tripTypeData.map(
        (tripType: any) => {
          const value = tripType.id;
          const label = capitalizeFirstLetter(tripType.attributes.name);
          return {label, value};
        }
      );

      this.setState({
        tripType: tripTypeList,
      });
    }
  }

  travelerTypeApiResponse = (responseJson: any, errorReponse: any) => {
    const travelerTypeData = responseJson.data;
    if (travelerTypeData) {
      const travelerTypeList: Categories[] = travelerTypeData.map(
        (travelerType: any) => {
          const value = travelerType.id;
          const label = capitalizeFirstLetter(travelerType.attributes.name);
          return {label, value};
        }
      );

      this.setState({
        travelerType: travelerTypeList,
      });
    }
  }

  vibeListApiResponse = (responseJson: any, errorReponse: any) => {
    const vibeListData = responseJson.data;
    if (vibeListData) {
      const vibeList: Categories[] = vibeListData.map(
        (vibe: any) => {
          const value = vibe.attributes.title;
          const label = capitalizeFirstLetter(vibe.attributes.title);
          return {label, value};
        }
      );

      this.setState({
        vibeList: vibeList,
      });
    }
  }

  timeApiResponse = (responseJson: any, errorReponse: any) => {
    const timeData = responseJson.data;
    if (timeData) {
      const timeList: Categories[] = timeData.map(
        (time: any) => {
          const value = time.id;
          const label = capitalizeFirstLetter(time.attributes.name);
          return {label, value};
        }
      );

      this.setState({
        time: timeList,
      });
    }
  }




  // Internal Functions

  submitStep1CET = (values: IStep1CET) => {
    // Scroll to the top of the page
    handleScrollToTop()
    this.setState(prev => ({
      ...prev,
      activeStep: prev.activeStep + 1,
      step1CETValues: values,
    }))
  }

  submitStep2CET = (values: IStep2CET) => {
    // Scroll to the top of the page
    handleScrollToTop()
    this.setState(prev => ({
      ...prev,
      activeStep: prev.activeStep + 1,
      step2CETValues: values,
    }))
  }

  backStep2CET = (values: IStep2CET) => {
    // Scroll to the top of the page
    handleScrollToTop()
    this.setState(prev => ({
      ...prev,
      activeStep: prev.activeStep - 1,
      step2CETValues: values,
    }))
  }

  submitStep3CET = (values: IStep3CET) => {
    // Scroll to the top of the page
    handleScrollToTop()
    this.setState(prev => ({
      ...prev,
      activeStep: prev.activeStep + 1,
      step3CETValues: values,
    }))
  }

  backStep3CET = (values: IStep3CET) => {
    // Scroll to the top of the page
    handleScrollToTop()
    this.setState(prev => ({
      ...prev,
      activeStep: prev.activeStep - 1,
      step3CETValues: values,
    }))
  }

  submitStep4CET = (values: IStep4CET) => {
    // Scroll to the top of the page
    handleScrollToTop()
    this.setState(prev => ({
      ...prev,
      // activeStep: prev.activeStep + 1,
      step4CETValues: values,
    }), () => this.handleSubmit())
  }

  handleSubmit = () => {
    const modifiedDates = this.state.step1CETValues.trip_calendar.filter(
      (item) => !item?.locallyCreated && item?.deleted
    );
    if (this.state.mode === 'edit') {
      if (modifiedDates.length > 0) {
        this.openModal()
      } else {
        this.createTripRequest()
      }
    } else {
      this.createTripRequest()
    }
  }

  backStep4CET = (values: IStep4CET) => {
    // Scroll to the top of the page
    handleScrollToTop()
    this.setState(prev => ({
      ...prev,
      activeStep: prev.activeStep - 1,
      step4CETValues: values,
    }))
  }

  onCloseModal = () => {
    this.setState({
      openModal: false,
    })
  }

  onCloseAndNavigate = () => {
    this.setState({
      responseStatusModal: false,
    }, () => {
      if(this.state.isSuccess) {
        this.props.navigation.navigate("TripList")
      }
    })
  }

  openModal = () => {
    this.setState({
      openModal: true,
      dateCancellationReason: ''
    })
  }

  handleDateCancellationReason = (value: string) => {
    this.setState({
      dateCancellationReason: value,
    })
  }

  handleProceedwithDeleteTripReason = () => {
    this.setState({
      sureDelete: true,
    })
  }

  addReason = (id: string, reason: string) => {
    const newDates = this.state.step1CETValues.trip_calendar.map((item) => {
      if (!item?.locallyCreated && item?.deleted && item.id === id) {
        return {
          ...item,
          attributes: {
            ...item.attributes,
            reason: reason,
          },
        };
      } else {
        return item;
      }
    });
    this.setState((prev) => {
      return {
        ...prev,
        step1CETValues: {
          ...prev.step1CETValues,
          trip_calendar: newDates
        }
      }
    })
  };


  handleCancelTripCreation = () => {
    this.setState({
      cancelTripCreationModal: false,
    }, () => this.props.navigation.goBack())
  }

  openCancelTripCreationModal = () => {
    this.setState({cancelTripCreationModal: true})
  }

  closeCancelTripCreationModal = () => {
    this.setState({cancelTripCreationModal: false})
  }

  getScheduleDates(tripSchedules: any) {
    return tripSchedules.data
      .filter((schedule: any) => schedule.attributes.reason === null && schedule.attributes.cancelled_at === null)
      .map((schedule: any) => schedule.attributes.scheduled_date);
  }

  gotoTripCalendarPage = async () => {
    const queryParams = {
     'datesOfTrip': this.state.datesOfTrip
    }
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "TripListCalendarView"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
      queryParams
    });
    message.addData(
      getName(MessageEnum.NavigationRaiseMessage),
      raiseMessage
    );
    this.send(message);
  }



  // Customizable Area End
}
