import React from "react";
// Customizable Area Start
import {
  Box,
  Typography,
  createStyles,
  Card,
  Divider,
  Button,
  CardActions,
  Dialog,
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid

} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { createTheme, withStyles } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
import i18next from 'i18next';


// Customizable Area End

import ShoppingCartOrdersController, {
  Props,
} from "./ShoppingCartOrdersController";
import Breadcrumbs from "../../../components/src/Breadcrumbs";
import { noTripCreated } from "../../catalogue/src/assets";
import { tripSlider } from "../../landingpage/src/LandingPage.web";
import ResponseStatusModal from "../../../components/src/ResponseStatusModal";

export class ShoppingCart extends ShoppingCartOrdersController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {

    const { similarTripExperience } = this.state
    const CartItems = this.context?.cartItems


    // Customizable Area Start
    const { classes } = this.props
    return (
      <Box>
        <Box className={classes.breadCrumbs}>
          <Breadcrumbs list={[
            {
              title: i18next.t('customer.cart.home'),
              link: '/Dashboard'
            },
            {
              title: i18next.t('customer.cart.cart')
            }
          ]} />
        </Box>
        <Divider />


        {       
        /* istanbul ignore next */
        CartItems && CartItems?.length ?
          <Box>
            <Box className = {classes.outerBox}>
              <Box>
                <Typography  className = {classes.cartHeading}>
                  {i18next.t('customer.cart.cart')}
                </Typography>
                <Typography variant="body2">
                  {i18next.t('customer.cart.useCartMessage')}
                </Typography>
              </Box>
                <Box className={classes.cartItemsContainer}>
                  {CartItems?.map((elem: any) => {
                    return (
                      <Card className={classes.card} key={elem?.attributes?.trip?.id}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={4}>
                            <img src={elem?.attributes?.trip_image} className={classes.cardImg} alt="Trip" />
                          </Grid>
                          <Grid item xs={12} sm={8}>
                            <Box className={classes.innerBox}>
                              <Typography>{elem?.attributes?.trip_title}</Typography>
                              <Typography>{elem?.attributes?.trip_date}</Typography>
                              <Typography> {i18next.t('customer.cart.numberOfPeople')} {elem?.attributes?.no_of_people}</Typography>
                              <Typography> {i18next.t('customer.cart.total')}  SAR {elem?.attributes?.total_price}</Typography>
                            </Box>
                            <CardActions>
                              <Button className={classes.removeItem} onClick={() => this.handleDeletePopup(elem)}>{i18next.t('customer.cart.remove')} </Button>
                              <Button className={classes.bookNow} data-test-id="submitBtn" onClick={() => this.handleBookingSummaryActionBtn(elem)}>{i18next.t('customer.cart.bookNow')}</Button>
                            </CardActions>
                          </Grid>
                        </Grid>
                      </Card>
                    )
                  })}
                  <ResponseStatusModal
                    isSuccess={this.state.isSucess}
                    message={i18next.t('customer.cart.itemRemovedFromCart')} 
                    onClose={this.handleCloseModal}
                    open={this.state.responseStatusModal} />
                </Box>
            </Box>
            <Divider style={{ marginTop: '30px' }} />
            <Box style={{ margin: '30px' }}>
              {tripSlider(classes, similarTripExperience, i18next.t('customer.cart.similarExperiences'), this.props.navigation, this.goToServiceProviderPageList, {}, classes.marginTop96)(false)}
            </Box>
            {
                        this.state.isRemovePopupOpen &&
                        <Box>
                          <Dialog
                            open={this.state.isRemovePopupOpen}
                            onClose={this.handleCloseModal}
                            classes={{
                              paper: classes.dialogWidth
                            }}
                          >
                            <DialogTitle id="delete-item-dialog-title">
                            {i18next.t('customer.cart.total')} 
                              <IconButton
                                aria-label="close"
                                onClick={this.handleCloseModal}
                                style={{ position: 'absolute', right: '8px', top: '8px' }}
                              >
                                <CloseIcon />
                              </IconButton>
                            </DialogTitle>
                            <DialogContent>
                              <Card className = {classes.modalCard} >
                                <Box>
                                  <img src={this.state?.elem?.attributes?.trip_image} className={classes.cardImg} />
                                </Box>
                                <Box style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                  <Typography>{this.state?.elem?.attributes?.trip_title}</Typography>
                                  <Typography>{this.state?.elem?.attributes?.trip_date}</Typography>
                                  <Typography> {i18next.t('customer.cart.numberOfPeople')}  {this.state?.elem?.attributes?.no_of_people}</Typography>
                                  <Typography> {i18next.t('customer.cart.total')} SAR {this.state?.elem?.attributes?.total_price}</Typography>
                                </Box>
                              </Card>

                            </DialogContent>
                            <DialogActions>
                              <Button onClick={this.handleCloseModal} className={classes.removeItemDelete} >
                               {i18next.t('customer.cart.cancel')}
                              </Button>
                              <Button onClick={() => this.handleDeleteCartItem(this.state?.elem?.id)} className={classes.bookNowDelete} autoFocus>
                              {i18next.t('customer.cart.remove')}
                              </Button>
                            </DialogActions>
                          </Dialog>
                        </Box>
                      }
          </Box>        
          :
          <Box className={classes.mainContainer}>
            <img src={noTripCreated} alt='No Bookings' />
            <Typography className={classes.innertext}>
            {i18next.t('customer.cart.noTripsInCart')}
            </Typography>
            <Typography className={classes.internaltext}>
            {i18next.t('customer.cart.adventureStarts')}
            </Typography>
            <Typography> {i18next.t('customer.cart.addToCart')} </Typography>
            <Button className={classes.exploreNow} onClick={this.navigateToTripPage}>
            {i18next.t('customer.cart.exploreNow')}
            </Button>
          </Box>
        }
      </Box>


      //Merge Engine End DefaultContainer
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const CartStyles = () => createStyles({

  breadCrumbs: {
    marginLeft: '80px'
  },
  mainContainer: {
    display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' , margin:'30px'
  },
  innertext: {
    fontSize: '26px', fontWeight: 'bold', marginTop: '20px'
  },
  internaltext: {
    marginTop: '10px'
  },
  exploreNow: {
    borderRadius: '30px',
    background: '#F67E2D',
    color: 'white',
    marginTop: '30px',
    padding: '10px 30px',
    textTransform: 'initial',
    '&:hover': {
      backgroundColor: '#F67E2D',
      color: '#ffffff',
  }
  },
  bookNow: {
    borderRadius: '30px',
    background: '#F67E2D',
    color: 'white',
    marginTop: '40px',
    padding: '10px 30px',
    width: 'auto',
    height: 'auto',
    whiteSpace: 'nowrap',
    textTransform: 'initial',
    '&:hover': {
      backgroundColor: '#F67E2D',
      color: '#ffffff',
  }
  },
  bookNowDelete:{
    borderRadius: '30px',
    background: '#F67E2D',
    color: 'white',
    marginTop: '10px',
    padding: '10px 30px',
    width: 'auto',
    height: 'auto',
    whiteSpace: 'nowrap',
    textTransform: 'initial',
    '&:hover': {
      backgroundColor: '#F67E2D',
      color: '#ffffff',
  }
  },
  removeItem: {
    color: '#F67E2D',
    marginTop: '40px',
    padding: '10px 30px',
    width: 'auto',
    height: 'auto',
    whiteSpace: 'nowrap',
    textTransform: 'initial',
  },
  removeItemDelete: {
    color: '#F67E2D',
    marginTop: '10px',
    padding: '10px 30px',
    width: 'auto',
    height: 'auto',
    whiteSpace: 'nowrap',
    textTransform: 'initial'
  },


  ratingIcon: {
    '& svg': {
      height: '16px',
      width: '16px',
    }
  },
  sliderHeadingBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignContent: 'center',
  },
  heading: {
    color: '#212426',
    fontFamily: 'Inter',
    fontSize: '40px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '36px',
  },

  viewMoreLink: {
    color: '#FE7F2D',
    textAlign: 'right' as 'right',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    cursor: 'pointer'
  },
  tripTypeheading: {
    color: '#212426',
    fontFamily: 'Inter',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal'
  },
 
  slickSlider: {
    height: '100%',
    marginTop: '48px',
    // maxHeight: '50vh !important',
    '& .slick-list': {
      maxHeight: 'inherit',
      height:'100%',
      '& .slick-slide': {
        width: 'auto !important',
        margin: '0 16px',
        height: '324px'
      }
    },

    '& .slick-prev': {
      left: '-20px'
    },
    '& .slick-arrow': {
      width: '40px',
      height: '40px',
      borderRadius: '50%',
      filter: 'drop-shadow(0px 6.588235378265381px 31.05882453918457px rgba(0, 0, 0, 0.07)) drop-shadow(0px 1.8823529481887817px 18.823530197143555px rgba(0, 0, 0, 0.04)) drop-shadow(0px 1.8823529481887817px 3.7647058963775635px rgba(0, 0, 0, 0.08))',
      justifyContent: 'center',
      background: '#fff',
      display: 'flex !important',
      alignItems: 'center',
      zIndex: '1',
      top: '113px',
      '& svg': {
        color: '#1F55DE',
      },
      '&:before': {
        display: 'none',
      }
    },
    '& .slick-next': {
      right: '-20px'
    },

    '& .slick-next.slick-disabled': {
      cursor: 'initial',
      backgroundColor: 'rgba(192, 190, 190, 0.75)',
      '& svg': {
        color: 'rgba(75, 75, 76, 0.75)'
      }
    },
    '& .slick-prev.slick-disabled': {
      backgroundColor: 'rgba(192, 190, 190, 0.75)',
      cursor: 'initial',
      '& svg': {
        color: 'rgba(75, 75, 76, 0.75)'
      }
    },
  },
  profileSlider: {
    maxHeight: '280px',
    '& img': {
      height: '234px'
    },
    '& .slick-list': {
      margin: '0 0 0 -16px',
      maxHeight: 'inherit'
    },
  },

  fixedImg: {
    width: '300px !important',
    height: '226px !important'
  },
  innerSlideBlock: {
    maxHeight: '234px',
    maxWidth: '280px',
    position: 'relative' as 'relative'
  },
  badge: {
    '&.MuiAvatar-root': { width: '24px', height: '24px', }
  },

  starIcon: {
    color: '#F5950B',
  },
  chip: {
    background: 'white',
    maxWidth: '58px',
    maxHeight: '27px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#F5950B',
    borderRadius: '17px'
  },

  serviceName: {
    color: '#FFF',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '150%',
    display: 'block',
    whiteSpace: 'nowrap' as const,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: '106.5px',
    textTransform: 'none' as const,
    fontSize: '14px',
    justifyContent: 'flex-start'
  },
  chipLabel: {
    color: '#F5950B',
    textAlign: 'right' as 'right',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    letterSpacing: '0.301px',
    display: 'flex',
    alignItems: 'center',
    padding: '4px 16px'
  },
  tripName: {
    color: '#212426',
    fontFamily: 'Inter',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    letterSpacing: 0,
    marginTop: '16px',
    whiteSpace: 'nowrap' as const,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    cursor: 'pointer'
  },

  price: {
    display: 'flex',
    gap: '12px',
    color: '#212426',
    fontFamily: 'Inter',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    alignItems: 'center',
    letterSpacing: 0,
    marginTop: '16px',
  },
  tripLocation: {
    color: 'rgba(33, 36, 38, 0.85)',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    letterSpacing: 0,
    marginTop: '8px',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical' as const,
    WebkitLineClamp: 1,
    overflow: 'hidden'
  },
  strikeThrough: {
    color: '#212426',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 300,
    lineHeight: 'normal',
    textDecorationLine: 'line-through',
  },

  smallDescription: {
    color: 'rgba(33, 36, 38, 0.75)',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    letterSpacing: '0.301px',
    marginTop: '8px'
  },
  imageVignette: {
    position: 'relative' as 'relative',
    cursor: 'pointer',
    '&:before': {
      content: "''",
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: 0,
      top: 'auto',
      background: 'linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.8))',
      pointerEvents: 'none',
      zIndex: 1,
      height: '40%',
      borderRadius: '0 0 16px 16px',
    }
  },
  innerTripSlideBlock: {
    maxWidth: '300px',
    maxHeight: '226px',
    position: 'relative' as 'relative',

    [theme.breakpoints.up(1024)]: {
      maxHeight: '300px',
    },
    [theme.breakpoints.up(768)]: {
      maxHeight: '213px',
    },
    [theme.breakpoints.up(1440)]: {
      maxHeight: '378px',
    },
  },
  tripSlider: {
    [theme.breakpoints.between(768, 1254)]: {
      maxHeight: '324px',
    },
    '& .slick-list': {
      margin: '0 -16px',
    },
    maxHeight: '349px',

  },

 
  agencyDetails: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 12px',
    gap: '8px',
    position: 'absolute' as const,
    bottom: 0,
    height: '49px',
    width: '100%',
    zIndex: 1
  },
  image: {
    width: '100%',
    height: 'auto',
    borderRadius: '16px',
    // objectFit: 'fill' as 'fill',
  },
  dialogWidth:{
    width:'600px',
    borderRadius:'30px',
    padding:'10px 20px',
  },
  outerBox:{
    display: 'flex', 
    flexDirection: 'column', 
    margin: 'auto', 
    width: '50%', 
    gridGap: '20px',
    [theme.breakpoints.down(560)]: {
      width: '100%', 
      margin: '10px'
    }
  },
  cartHeading:{
    fontWeight: 'bold', fontSize: '24px'
  },
  cartItemsContainer: {
    maxHeight: 'calc(100vh - 250px)', 
    overflowY: 'scroll',
    marginTop: '20px',
    padding: '0 20px',
  },
  card: {
    display: 'flex', padding: '20px 30px', marginTop: '15px', justifyContent: 'space-between' 

  },
  cardImg:{
    height: '150px', width: '150px', borderRadius:'10px'
  },
  innerBox:{
    display: 'flex', flexDirection: 'column', gap: '10px'
  },
  modalCard:{
    display: 'flex', padding: '20px 30px', marginTop: '15px', justifyContent: 'space-around' ,border:'.5px solid black'
  }



})

export default withStyles(CartStyles as object)(ShoppingCart);

// Customizable Area End
