import React, { useState, useEffect } from "react"
import { Calendar } from "react-multi-date-picker"
import {
    Typography,
    useMediaQuery,
    makeStyles,
} from '@material-ui/core';
import './MultiSelectCalendar.css';
import { leftArrowIcon, rightArrowIcon } from "../../blocks/ProductDescription/src/assets";
import i18next from 'i18next';


const isPreviousDate = (date: string) => {
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);
  const selectedDate = new Date(date);
  selectedDate.setHours(0, 0, 0, 0); // Set time to midnight to ignore time component
  return selectedDate < currentDate;
}

interface MultiSelectCalenderProps {
    dateValues: any[];
    originDateValues?: any[];
    onChange: (fieldName: string, value: any[]) => void;
    error: string;
    touch: boolean;
    mode: 'create' | 'edit';
    // handleDateChange: (selectedDate: any[]) => void;
}

const useStyles = makeStyles((theme) => ({
    selected: {
        backgroundColor: '#FE7F2D',
        color: '#ffffff'
    },
    main: {
        backgroundColor: '#FFF6ED',
        border: '1px solid #FE7F2D',
        borderRadius: '13px',
        // width: '297px',
        // height: '266px',
        boxShadow: 'none',
        textAlign: 'center'
    },

}));

export function CustomButton({ direction, handleClick, disabled } : any) {
    return (
      <i
        onClick={handleClick}
        style={{
          padding: "0 10px",
          fontWeight: "bold",
          color: disabled ? "gray" : "blue"
        }}
        className={disabled ? "cursor-default" : "cursor-pointer"}
      >
        {direction === "right" ? <img src={rightArrowIcon} alt=">" style={{height: '100%', width: '100%'}} /> : <img src={leftArrowIcon} alt="<" style={{height: '100%', width: '100%'}} />}
      </i>
    )
  }

  const weekDays = ["SUN", "MON", "TUE", "WED", "THRU", "FRI", "SAT"]

const MultiSelectCalender = ({ dateValues, onChange, touch, error, mode, originDateValues = [] }: MultiSelectCalenderProps) => {

    const classes = useStyles();
    const isTab = useMediaQuery('(max-width:1024px)');
    const [isLoaded, setIsLoaded] = useState(false);

    const [values, setValues] = useState<any>([])

    const handlePropsDateChange = (selectedDates: string[]) => {
        const updatedDates = dateValues.map((item) => {
          const inluded = selectedDates?.includes(
            item?.attributes?.scheduled_date
          );
          const isNotPrevious =  !isPreviousDate(item?.attributes?.scheduled_date);
          if (!inluded && isNotPrevious) {
            return {
              ...item,
              deleted: true,
            };
          } else
            return {
              ...item,
              deleted: false,
              attributes: {
                ...item.attributes,
                reason: "",
              },
            };
        });
        const newDates = selectedDates
          .filter((date) => {
            const isExist = dateValues.find(
              (prevDate) => prevDate?.attributes?.scheduled_date === date
            );
            return !isExist;
          })
          .map((item) => ({
            id: new Date().getTime().toString(),
            locallyCreated: true,
            deleted: false,
            type: "trip_schedules",
            attributes: {
              reason: null,
              scheduled_date: item,
              cancelled_at: null,
            },
          }));
    
        const newDatesAfterModified = [...updatedDates, ...newDates].filter(
          (item) => !(item?.locallyCreated && item?.deleted)
        );

        const deletedDates =  newDatesAfterModified.filter((date) => {
          const currentDate = new Date();
          currentDate.setHours(0, 0, 0, 0);
          const selectedDate = new Date(date.attributes.scheduled_date);
          selectedDate.setHours(0, 0, 0, 0);
          return date.deleted === true && selectedDate >= currentDate;
        })

      const scheduledDates = newDatesAfterModified
        .filter((item) => !item.deleted)
        .map((item) => item.attributes.scheduled_date);
    
        onChange('trip_calendar', newDatesAfterModified);
        onChange('modified_trip_calendar', deletedDates);
        setValues(scheduledDates);
      };

    const handleDateChange = (newDates: any) => {
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);

        // Filter out any dates that are in the past
        const futureDates = newDates.filter((date: any) => {
            const selectedDate = new Date(date);
            selectedDate.setHours(0, 0, 0, 0); // Set time to midnight to ignore time component
            return selectedDate >= currentDate;
        });

        const formattedFutureDates = futureDates.map((date: any) => {
            const parts = date.toString().split('/');
            if (parts.length === 3) {
              // Assuming the format is "YYYY/MM/DD"
              const [year, month, day] = parts;
              return `${year}-${month}-${day}`;
            }
            return date; // If already in "YYYY-MM-DD" format
          });

          handlePropsDateChange(formattedFutureDates);

        


        // setValues(formattedFutureDates);
        

        // if(mode === 'edit' && originDateValues?.length > 0) {
        //     const unmatchedDates = originDateValues.filter((date: any) => {
        //         return !formattedFutureDates.includes(date)
        //     });
        //     onChange('modified_trip_calendar', unmatchedDates)
        // }
    };

    useEffect(() => {
        if(!isLoaded && dateValues.length > 0){
            setValues(dateValues.filter((item) => !item.deleted).map((item) => item?.attributes?.scheduled_date))
            setIsLoaded(true)
        }
        
    }, [dateValues, isLoaded])

    return (
        <div
        style={{
            marginLeft: '2.6px',
            display: 'flex',
            flexDirection: isTab ? "column" : "row",
            gap: isTab ? '0px' : '34px'
        }}
        >
            < Calendar
                showOtherDays
                monthYearSeparator=" "
                highlightToday={false}
                multiple
                value={values}
                weekDays={weekDays}
                className={classes.main}
                onChange={handleDateChange}
                renderButton={<CustomButton />}
                // minDate={new Date()}
            />
            <Typography variant="caption" style={{ fontFamily: "Inter", fontWeight: 400, marginTop: '8px', color: '#FF0000' }}>
                {touch && values.length === 0 ?  i18next.t('travelAgency.editTrip.pleaseSelectDatesForTheTrip') : ""}
            </Typography>
        </div>
    )
}

export default MultiSelectCalender;