import React from "react";

// Customizable Area Start

import {
    createTheme,
     ThemeProvider
} from "@material-ui/core/styles";
import SubscriptionPaymentSuccess from "../../../components/src/SubscriptionPaymentSuccess";

// Customizable Area End

import SubscriptionPlanController, {
    Props
} from "./SubscriptionPlanController";

const muiTheme = createTheme({
    overrides: {
        MuiTypography: {
            gutterBottom: {
                marginBottom: '8px'
            }
        }
    },
    typography: {
        body1: {
            color: '#212426',
            fontFamily: 'Inter',
            fontStyle: "normal",
            fontWeight: 400,
            letterSpacing: 0,
            fontSize: '16px',
            lineHeight: '150%'
        }
    }
}); 

export default class SubscriptionPaymentStatus extends SubscriptionPlanController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    

    
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { paymentResponse ,  } = this.state;
        
        // Customizable Area End
        return (
            // Customizable Area Start
            <ThemeProvider theme={muiTheme}>
                <SubscriptionPaymentSuccess
                    navigation={this.props.navigation}
                    responseJson={paymentResponse}
                />
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

// Customizable Area End
