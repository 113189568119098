Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";

exports.btnExampleTitle = "CLICK ME";
exports.topTravelBuddies = 'account_block/accounts/top_buddies'
exports.topTravelAgencies = 'account_block/accounts/top_agencies'
exports.tripByType = 'bx_block_productdescription/trips_by_type'
exports.customerProfileApi = 'account_block/accounts/profile'
exports.upcomingtrips = 'account_block/get_upcoming_trips'
exports.tripTypeEndPoint = "bx_block_productdescription/trip_types";
// Customizable Area End