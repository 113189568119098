import React from 'react';
import {
  Typography,
  TextField,
  makeStyles,
  Theme,
  FormControl,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import { useWindowWidth } from './utils';
import Visibility from "@material-ui/icons/Visibility"
import VisibilityOff from "@material-ui/icons/VisibilityOff"
import i18next from 'i18next';

interface CustomTextFieldProps {
  fieldTitle: string;
  placeholder: string;
  value: string;
  fieldName: string;
  onChange: (fieldName: string, newValue: string) => void;
  error: string;
  touched: boolean;
  multiline?: boolean;
  minRows?: number;
  maxRows?: number;
  optional?: boolean;
  endAdorment?: string;
  type?: string;
  isApplyDiscount?: boolean;
  isDisable?: boolean;
  isChangePassword?: boolean;
  handleShowPassword?: () => void;
  showPassword?: boolean;
  readonly?: boolean;
  neitherOptionalOrRequired?: boolean;
  planItineraryTextColor?: boolean;
  onlyText?: boolean;
}



const CustomTextField: React.FC<CustomTextFieldProps> = ({
  fieldTitle,
  placeholder,
  value,
  fieldName,
  onChange,
  error,
  touched,
  multiline = false,
  minRows = 1,
  maxRows = 4,
  optional = false,
  endAdorment = '',
  type = 'text',
  isApplyDiscount = false,
  isDisable = false,
  isChangePassword = false,
  handleShowPassword,
  showPassword = false,
  readonly = false,
  neitherOptionalOrRequired = false,
  planItineraryTextColor = false,
  onlyText = false,

}) => {
  const useStyles = makeStyles((theme: Theme) => ({
    customFont: {
      fontFamily: 'Inter',
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '121%',
      '&.MuiFormHelperText-root.Mui-error': {
        color: '#FF0000',
      },
    },
    fieldNameText: {
      fontFamily: 'Inter',
      fontSize: isApplyDiscount ? '16px' : '18px',
      fontStyle: 'normal',
      color: isChangePassword ? '#212426' : planItineraryTextColor ? 'rgba(33, 36, 38, 0.80)' : 'rgba(33, 36, 38, 0.90)',
      marginBottom: onlyText ? '8px' : isChangePassword ? '12px' : '16px',
      lineHeight: '150%',
      fontWeight: 500,

    },
    optionalText: {
      fontWeight: 400,
      color: 'rgba(33, 36, 38, 0.65)',
      fontSize: '16px'
    },
    [theme.breakpoints.down(1024)]: {
      fieldNameText: {
        fontFamily: 'Inter',
        fontSize: isApplyDiscount ? '14px' : '16px',
        fontStyle: 'normal',
        color: 'rgba(33, 36, 38, 0.90)',
        marginBottom: onlyText ? '8px' : '12px',
        lineHeight: '150%',
        fontWeight: 500,

      },
      optionalText: {
        fontWeight: 400,
        color: 'rgba(33, 36, 38, 0.65)',
        fontSize: '14px'
      },
    },
    [theme.breakpoints.down(768)]: {
      fieldNameText: {
        fontFamily: 'Inter',
        fontSize: '14px',
        fontStyle: 'normal',
        color: 'rgba(33, 36, 38, 0.90)',
        marginBottom: '8px',
        lineHeight: '150%',
        fontWeight: 500,

      },
      optionalText: {
        fontWeight: 400,
        color: 'rgba(33, 36, 38, 0.65)',
        fontSize: '12px'
      },
    }
  }));
  const classes = useStyles();
  const windowWidth = useWindowWidth();

  const isMobile = windowWidth < 768;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(fieldName, e.target.value);
  };

  return (
    <FormControl fullWidth>
      {
        neitherOptionalOrRequired ? null :
          <Typography
            className={classes.fieldNameText}
          >
            {fieldTitle} {onlyText ? null : optional ? <span className={classes.optionalText}>{i18next.t('travelAgency.editTrip.optional')}</span> : <span style={{ color: '#F00' }}> *</span>}
          </Typography>
      }
      <TextField
        style={{ width: '100%' }}
        placeholder={placeholder}
        variant="standard"
        type={type}
        disabled={isDisable}
        autoComplete="off"
        className="email_input"
        onChange={handleChange}
        error={Boolean(error) && touched} // Show error if touched is true
        helperText={touched ? error : ''} // Show error message if touched is true
        FormHelperTextProps={{
          className: classes.customFont,
        }}
        value={value}
        InputProps={{
          style: {
            backgroundColor: '#ffffff',
            color: '#212426',
            padding: '16px 20px',
            height: multiline ? 'none' : isMobile ? '48px' : '56px',
            border: `1px solid`,
            borderColor: error && touched ? 'red' : 'rgba(33, 36, 38, 0.20)', // Conditionally set border color
            borderRadius: '8px',
            fontFamily: 'Inter',
            fontSize: isMobile ? '14px' : '16px',
            fontWeight: isChangePassword ? 400 : 500,
            lineHeight: '150%',
            fontStyle: 'normal'
          },
          multiline: multiline,
          minRows: minRows,
          maxRows: maxRows,
          readOnly: readonly,
          disableUnderline: true,
          endAdornment: endAdorment ? (
            <div style={{
              color: 'rgba(33, 36, 38, 0.50)',
              fontSize: isMobile ? '14px' : '16px',
              fontWeight: 500,
              fontFamily: 'Inter'
            }}>{endAdorment}</div>)
            : isChangePassword ?
              <InputAdornment position='end'>
                <IconButton
                  aria-label='toggle password visibility'
                  onClick={() => handleShowPassword?.()}
                  // onMouseDown={handleMouseDownPassword}
                  style={{ fontSize: "15px", textDecoration: 'none' }}
                  tabIndex={-1}
                >
                  {showPassword ? <Visibility style={{ height: '24px', width: '24px' }} />
                    : <VisibilityOff style={{ height: '24px', width: '24px' }} />}
                </IconButton>
              </InputAdornment>
              : null,
        }}
      />
    </FormControl>
  );
};

export default CustomTextField;
