import React from "react";
// Customizable Area Start
import {
  Box, Divider, Button, Tabs,
  Typography, Avatar, Grid, Tab
} from "@material-ui/core";
import { Rating } from "@material-ui/lab"
import {
  withStyles, Theme,
  createTheme, ThemeProvider
} from "@material-ui/core/styles";
import { Star } from '@material-ui/icons';
import { carouselStyle, tripSlider } from "../../landingpage/src/LandingPage.web";
import {
  bringBag, cancelationPolicy, details as detailsImg,
  reviewCircle, verticalBorder, facility, notInclude,
  include, itinerary as itineraryImg, moreInfo, notAllowed
} from "./assets"
import AvailabilityModal from "./availabilityModal";
import Loader from "../../../components/src/Loader.web";
import { primaryColor } from "../../../components/src/theme";
const { showMoreText, ratingText,
  checkAvailability, writeReviewBtn } = require('./config.js')
import Breadcrumbs from "../../../components/src/Breadcrumbs";
import {
  checkCondition, convertToTitleCase, convertStringToTitleCase,
  capitalizeWords, ternaryCondition, formatMonthDateYear, capitalizeFirstLetter
} from "../../../components/src/utils";
import { agencyLogo, tour1, tour2, tour3 } from "../../landingpage/src/assets";
import { BootstrapButton } from "../../user-profile-basic/src/UserProfileCustomer.web";
import SliderNavFor from "../../../components/src/SliderNavFor";
import ShareTripModal from "../../../components/src/ShareTripModal";
import AddReviewWeb from "../../../components/src/AddReview.web";
import ResponseStatusModal from "../../../components/src/ResponseStatusModal";
import i18next from 'i18next';

export const commomStyles = (theme: Theme) => ({
  container: {
    margin: '49px auto 120px',
    maxWidth: '90%',
    width: '100%',
    [theme.breakpoints.down(768)]:{
    overflowX : 'hidden' 
    }
  },
  travelAgencyName: {
    fontWeight: 500,
    marginBottom: '4px'
  },
  userImage: {
    height: 48,
    width: 48,
  },
  marginBottom16: {
    marginBottom: '16px'
  },
  blockTitle: {
    fontSize: "20px",
    fontWeight: 600,
    letterSpacing: "0.2px",
    opacity: 0.9
  },
  flexStyle12: {
    display: 'flex',
    gap: '12px',
    alignItems: 'center',
    marginBottom: '16px'
  },
})

export const tripStyles = (theme: Theme) => ({

  progressSteps: {
    counterReset: "number",
    display: "flex",
    flexDirection: "column" as "column",
    gap: "63px",
    justifyContent: "space-between",
    listStyle: "none",
    margin: 0,
    maxWidth: "740px",
    padding: 0,
    position: "relative" as "relative",
    width: "-moz-fit-content",
  },
  progressStep: {
    display: "flex",
    gap: "16px",
    position: "relative" as "relative",
    alignItems: 'flex-start',
    '&:not(:last-child):before': {
      alignItems: "center",
      border: "1px solid #39AB2F",
      borderRadius: "50%",
      content: "counter(number)",
      counterIncrement: "number",
      display: "flex",
      height: "32px",
      justifyContent: "center",
      position: "relative" as "relative",
      minWidth: "32px",
      zIndex: 1,
      backgroundColor: "#F4F9F7",
      color: "#39AB2F",
      fontWeight: 500,
      lineHeight: '150%'
    },
    '&:not(:last-child):after': {
      top: '0%',
      left: '15px',
      height: 'calc(100% + 63px)',
      content: '""',
      position: 'absolute',
      borderLeft: '2px dashed #39AB2F',
      opacity: '0.5',
    },
    '&:last-child:before': {
      alignItems: "center",
      border: "1px solid #FB5959",
      borderRadius: "50%",
      content: "counter(number)",
      counterIncrement: "number",
      display: "flex",
      height: "32px",
      justifyContent: "center",
      position: "relative" as "relative",
      minWidth: "32px",
      zIndex: 1,
      backgroundColor: "#FFEAEA",
      color: "#FB5959",
      fontWeight: 500,
      lineHeight: '150%'
    },
  },
  progressTextBox: {
    display: "flex",
    flexDirection: "column" as "column",
    gap: "2px",
  },
  progressStepText: {
    opacity: 0.9
  },
  progressStepContent: {
    fontSize: '12px',
    lineHeight: '19px'
  },
  progressRoot: {
    [theme.breakpoints.up(1025)]: {
      backgroundColor: '#fff',
      padding: '40px 0',
      width: '100%',
      marginLeft: '113px'
    },
    [theme.breakpoints.down(1025)]: {
      marginLeft: '0px'
    }
  },
  tripTitle: {
    fontSize: "28px",
  },
  tripDescription: {
    color: "rgba(33, 36, 38, 0.80)",
    marginBottom: '24px'
  },
  textstyle: {
    lineHeight: "170%",
    opacity: 0.75,
  },
  readMore: {
    color: 'rgba(33, 36, 38, 0.90)',
    fontWeight: 600,
    cursor: 'pointer'
  },
  ratingBlock: {
    color: "rgba(33, 36, 38, 0.80)",
    fontWeight: 500,
  },
  tripPrice: {
    fontSize: "28px",
    fontWeight: 600,
    lineHeight: "135%",
    opacity: 0.9
  },
  discountPrice: {
    lineHeight: "140%",
    textDecorationLine: "line-through",
    opacity: 0.75
  },
  discountPercent: {
    color: "#FE7F2D",
    fontWeight: 500,
    lineHeight: "140%",
  },
  discountBlock: {
    display: 'flex',
    borderRadius: '16px',
    border: '1px solid rgba(33, 36, 38, 0.20)',
    background: '#FFF',
    boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.04)',
    padding: '23px',
    alignItems: 'center',
    margin: '28px 0 32px',
    justifyContent: 'space-between',
    gap: '18px',
    [theme.breakpoints.down(768)]:{
      flexDirection: 'column',
      alignItems: 'start',
    }
  },
  pricePerPerson: {
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: '135%',
    opacity: 0.9
  },
  perPersonText: {
    opacity: 0.9,
    fontSize: '14px',
    lineHeight: '135%',
  },
  flexStyle8: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center'
  },
  flexStyle32: {
    display: 'flex',
    gap: '12px',
    marginBottom: '16px',
    justifyContent: 'flex-start'
  },
  priceIncl: {
    color: "rgba(33, 36, 38, 0.65)",
    fontFamily: "Poppins",
    fontSize: "14px",
    lineHeight: "normal",
  },
  yearsAGo: {
    color: "rgba(33, 36, 38, 0.80)",
    fontSize: "14px",
  },
  textTitle: {
    fontWeight: 600,
    opacity: 0.9
  },
  progressTextUnderline: {
    fontSize: "14px",
    fontWeight: 500,
    letterSpacing: "0.14px",
    textDecorationLine: "underline",
  },
  quantityDiscounts: {
    fontWeight: 600,
    lineHeight: '135%',
    opacity: 0.9
  },
  quantityDiscountDescription: {
    color: 'rgba(33, 36, 38, 0.65)',
    fontSize: '14px',
    lineHeight: 'normal',
  },
  blockDescription: {
    color: "rgba(33, 36, 38, 0.80)",
    lineHeight: "180%",
    "&::first-letter": {
      textTransform: "uppercase"
    }
  },
  flexStyleSB: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '70px'
  },
  blockTitle1: {
    fontSize: "20px",
    fontWeight: 600,
  },
  selectedTab: {
    '& span': {
      color: primaryColor,
      fontWeight: 700,
    }
  },
  textColor: {
    opacity: 1
  },
  tabItem: {
    color: '#000',
    textAlign: 'center' as 'center',
    fontFamily: 'Inter',
    fontSize: '23px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '150%',
    textTransform: 'none' as 'none',
    letterSpacing: 0
  },
  flexContainer: {
    gap: '80px',
    '& .MuiTab-root': {
      padding: '8px',
      minWidth: 'unset',
      minHeight: 'unset',
    }
  },
  fontWeight600: {
    fontWeight: 600,
  },
  textOverflow: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical' as 'vertical',
  },
  slider: {
    overflow: 'hidden',
    width: '100%',
    maxHeight: '613px',
    margin: 0,
    '& .slick-slide img': {
      width: 'calc(100% - 293px) !important',
      height: '613px'
    }
  },
  tripContainer: {
    position: 'relative' as 'relative',
    background: `url(${verticalBorder})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: 'center center',
    marginBottom: '20px',
    '&:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: '50%',
      transform: 'translate(-50%) rotate(-45deg)',
      width: 5,
      height: 5,
      background: 'rgba(33, 38, 35, 0.15)',
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: '50%',
      transform: 'translate(-50%) rotate(-45deg)',
      width: 5,
      height: 5,
      background: 'rgba(33, 38, 35, 0.15)',
    },
    [theme.breakpoints.down(768)]: {
      background: 'none',     
    }
  },
  detailsPC: {
    display: "inline"
  },
  detailsTM: {
    display: "none"
  },
  reviewBtnPC: {
    display: "inline"
  },
  reviewBtnTM: {
    display: "none"
  },
  itineraryPC: {
    display: "inline"
  },
  itineraryTM: {
    display: "none"
  },
  deailsAndReviewContainer: { margin: '0 auto', width: '82.7%' },
  [theme.breakpoints.between(768, 1024)]: {
    detailsPC: {
      display: "none"
    },
    detailsTM: {
      display: "inline"
    },
    reviewBtnPC: {
      display: "none"
    },
    reviewBtnTM: {
      margin: '10px',
      display: "inline"
    },
    itineraryPC: {
      display: "none"
    },
    itineraryTM: {
      display: "inline",
    },
    textTitle: {
      fontWeight: 500,
      opacity: 0.9
    },
    tripTitle: {
      fontSize: "24px",
    },
    tripPrice: {
      fontSize: "24px",
      fontWeight: 500,
      lineHeight: "135%",
      opacity: 0.9
    },
    pricePerPerson: {
      fontSize: '20px',
      fontWeight: 500,
      lineHeight: '135%',
      opacity: 0.9
    },
    
  },
  [theme.breakpoints.down(768)]: {
    detailsPC: {
      display: "none"
    },
    detailsTM: {
      display: "inline"
    },
    reviewBtnPC: {
      display: "none"
    },
    reviewBtnTM: {
      margin: '20px 0',
      display: "inline"
    },
    deailsAndReviewContainer: { 
      margin: '0 auto', 
      width: '100%' 
    },
    itineraryPC: {
      display: "none"
    },
    itineraryTM: {
      display: "inline",
    },
    textTitle: {
      fontWeight: 500,
      opacity: 0.9
    },
    tripTitle: {
      fontSize: "20px",
    },
    tripPrice: {
      fontSize: "20px",
      fontWeight: 500,
      lineHeight: "135%",
      opacity: 0.9
    },
    pricePerPerson: {
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '135%',
      opacity: 0.9
    },
  },
  ...commomStyles(theme),
  ...carouselStyle(theme)
})

const theme = createTheme({
  overrides: {
    MuiTypography: {
      gutterBottom: {
        marginBottom: '8px'
      }
    }
  },
  typography: {
    body1: {
      color: '#212426',
      fontFamily: 'Inter',
      fontStyle: "normal",
      fontWeight: 400,
      letterSpacing: 0,
      fontSize: '16px',
      lineHeight: '150%'
    }
  }
});

export const reviewBtn = ({handleOpenReviewModal}: any) => {
  return <Button 
  onClick={() => handleOpenReviewModal()}
  style={{
    border: `1px solid ${primaryColor}`,
    color: primaryColor,
    textTransform: 'initial',
    padding: '1% 3%',
    fontSize: '16px',
    fontFamily: 'Inter',
    fontWeight: 500,
    textAlign: 'center',
    lineHeight: '150%',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    borderRadius: '51px',
    whiteSpace: 'nowrap',
    // width: '100%'
  }}
  data-test-id="reviewBtn"
  >
    {i18next.t('customer.tripDetails.writeAReview')
}
  </Button>
}

export const cultureTrip = [
  {
    attributes: {
      agency_logo: agencyLogo,
      agency_name: 'The Travel Bug (Agency)',
      rating: 5,
      title: 'Riyadh nofa wildlife park tour',
      city: 'Riyadh',
      country: 'Saudi Arabia',
      price: '1120',
      discounted_price: '299',
      currency: 'SAR',
      trip_main_picture: tour1
    }
  },
  {
    attributes: {
      agency_logo: agencyLogo,
      agency_name: 'The Travel Bug (Agency)',
      rating: 5,
      title: 'Riyadh nofa wildlife park tour',
      city: 'Riyadh',
      country: 'Saudi Arabia',
      price: '1120',
      discounted_price: '299',
      currency: 'SAR',
      trip_main_picture: tour3
    }
  },
  {
    attributes: {
      agency_logo: agencyLogo,
      agency_name: 'The Travel Bug (Agency)',
      rating: 5,
      title: 'Riyadh nofa wildlife park tour',
      city: 'Riyadh',
      country: 'Saudi Arabia',
      price: '1120',
      trip_main_picture: tour2
    }
  },
  {
    attributes: {
      agency_logo: agencyLogo,
      agency_name: 'The Travel Bug (Agency)',
      rating: 5,
      title: 'Riyadh nofa wildlife park tour',
      city: 'Riyadh',
      country: 'Saudi Arabia',
      discounted_price: '299',
      trip_main_picture: tour3
    }
  },
  {
    attributes: {
      agency_logo: agencyLogo,
      agency_name: 'The Travel Bug (Agency)',
      rating: 5,
      title: 'Riyadh nofa wildlife park tour',
      city: 'Riyadh',
      country: 'Saudi Arabia',
      currency: 'SAR',
      trip_main_picture: tour1
    }
  },
]

export const headingImgBlock = (classes: any, icon: string, title: string, style: {} = {}) => {
  return <Box className={classes.flexStyle12} style={style}>
    <img src={icon} alt={title} style={{ height: '24px', width: '24px' }} />
    <Typography className={classes.blockTitle}>{title}</Typography>
  </Box>
}

export const tripContainer = (classes: any, details: Record<string, any>, itineraries: []) => {
  return <Grid container className={classes.tripContainer}>
    <Grid item md={6}>
      {headingImgBlock(classes, detailsImg, i18next.t('customer.tripDetails.details'), { marginBottom: '24px' })}
      {Object.entries(details).map(([label, value], index) => {
        if(typeof value === 'object'){
          value = value?.name
        }
        return <Box key={index} className={classes.flexStyle32}>
          <Typography className={classes.textTitle} style={{ width: '35%' }}>{label}</Typography>
          <Typography style={{ opacity: 0.9, width: '65%' }}>{capitalizeFirstLetter(value)}</Typography>
        </Box>
      })}
    </Grid>
    <Grid item md={6}>
      <div className={classes.itineraryPC}>
        {headingImgBlock(classes, itineraryImg, i18next.t('customer.tripDetails.itinerary'), { maxWidth: '321px', margin: 'auto' })}
      </div>
      <div className={classes.itineraryTM}>
        {headingImgBlock(classes, itineraryImg, i18next.t('customer.tripDetails.itinerary'), 
        { maxWidth: '321px', margin: '10px 0 10px' })}
      </div>
      <div className={classes.progressRoot}>
        <ol className={classes.progressSteps}>
          {itineraries?.map((item: Record<string, any>, key: number) => (
            <li key={key} className={classes.progressStep}>
              <Box style={{ width: '361px' }}>
                <Typography color="initial" className={classes.progressStepText}>
                  {item.attributes.description}
                </Typography>
              </Box>
            </li>
          ))}
        </ol>
      </div>
    </Grid>
  </Grid>
}

export const tripDetailsBlock = (classes: any, tripDetailsList: Record<string, any>[]) => {
  return tripDetailsList.map((item, index) => {
    return <Box key={index}>
      <Divider style={{ margin: '48px 0 32px' }} />
      {headingImgBlock(classes, item.icon, item.title)}
      <Typography color="initial" className={classes.blockDescription}>
        {item.description}
      </Typography>
    </Box>
  })

}

export const reviewList = (classes: any, reviews: Record<string, any>[]) => {
  return <>
    {reviews.length
      ? reviews.slice(0, 3).map((value: Record<string, any>, index: number, array: Record<string, any>[]) => {
        const { rating, commenter, reviewer, title, comment, created_at } = value.attributes;
        const reviewerName = commenter?.name || reviewer?.name;
        return <React.Fragment key={index}>
          <Grid container >
            <Grid item md={3}>
              <Rating
                name={`rating`}
                value={rating}
                precision={0.1}
                readOnly
                size="small"
                style={{
                  color: "#FE7F2D",
                  marginBottom: '20px'
                }}
              />
              <Typography className={classes.fontWeight600} gutterBottom>
                {reviewerName}
              </Typography>
            </Grid>
            <Grid item md={9} >
              <Typography className={`${classes.blockTitle1} ${classes.marginBottom16}`}>
                {title}
              </Typography>
              <Typography className={`${classes.marginBottom16} ${classes.textOverflow}`}
                style={{ color: 'rgba(33, 36, 38, 0.80)' }}>
                {comment}
              </Typography>
            </Grid>
          </Grid>
          <Typography style={{
            color: 'rgba(33, 36, 38, 0.80)',
            textAlign: 'end'
          }}>{formatMonthDateYear(created_at)}</Typography>
          {(index !== (array.length - 1)) && <Divider style={{ margin: '16px 0 50px' }} />}
        </React.Fragment>
      })
      : <Typography className={`${classes.fontWeight600}`} style={{
        textAlign: 'center',
      }}>{i18next.t('customer.tripDetails.noReviewsYet')}
      </Typography>}
    {reviews.length > 3 &&
      <Typography className={`${classes.fontWeight600}`} style={{
        textAlign: 'center',
        color: primaryColor
      }}>{showMoreText}</Typography>
    }
  </>
}

const reviewBlock = (classes: any, reviews: Record<string, any>[]) => {
  return <Box data-testid='reviews' style={{marginBottom: '20px'}}>
    <Box style={{ marginBottom: '48px' }}>
      {headingImgBlock(classes, reviewCircle, i18next.t('customer.tripDetails.reviews'))}
    </Box>
    {reviewList(classes, reviews)}
  </Box>
}

export const detailsList = (attributes: Record<string, any>,
  { trip_type, country_name, cityName, area, vibes,
    preference_type, min_seat, max_seat, payment_method
  }: Record<string, any>) => {

  const {
    period: {name:period_name = ''} = {}, duration = '', guided_tour = false, dress_code = '',
    language = [], transportation_provided = false, traveler_type: { name: traveler_name = ''} = {},
    kids_friendly = false, female_only = false, wheelchair_access = false
  } = attributes

  const minSeat = `(Min. seats = ${min_seat} to ${max_seat})`;
  const maxSeat = `(Max. seats = ${max_seat})`;

  return {
    [i18next.t('customer.tripDetails.country')]: country_name,
    [i18next.t('customer.tripDetails.city')]: cityName,
    [i18next.t('customer.tripDetails.area')]: area,
    // 'Vibes': ternaryCondition(Array.isArray(vibes) && vibes.length > 0, vibes.join(', '), ''),
    [i18next.t('customer.tripDetails.time')]: capitalizeFirstLetter(period_name),
    [i18next.t('customer.tripDetails.duration')]: duration,
    [i18next.t('customer.tripDetails.travelerType')]: convertStringToTitleCase(traveler_name),
    [i18next.t('customer.tripDetails.groupPreference')]: `${capitalizeWords(preference_type)} ${ternaryCondition(
      min_seat, minSeat, maxSeat)}`,
      [i18next.t('customer.tripDetails.language')]: convertToTitleCase(language),
      [i18next.t('customer.tripDetails.dressCode')]: dress_code,
      [i18next.t('customer.tripDetails.transportation')]: ternaryCondition(transportation_provided, 'Provided', 'Not Provided'),
      [i18next.t('customer.tripDetails.kidsFriendly')]: checkCondition(kids_friendly),
      [i18next.t('customer.tripDetails.femaleOnly')]: checkCondition(female_only),
      [i18next.t('customer.tripDetails.guidedTour')]: checkCondition(guided_tour),
      [i18next.t('customer.tripDetails.wheelchairAccess')]: checkCondition(wheelchair_access),
      [i18next.t('customer.tripDetails.paymentMethod')]: capitalizeFirstLetter(payment_method),
  }
}

// Customizable Area End

import OrdermanagementController, {
  Props,
} from "./OrdermanagementController";

export default class TripDetails extends OrdermanagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    /* istanbul ignore next */
    const booking_id = props.navigation?.match?.params?.bookingId ;
   /* istanbul ignore next */
    if(booking_id){
      this.getOrdersDataRequest()
    }
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props
    const { isExpanded, tripDetails, loading, 
      exploreMoreTrips, similarTrips, openShareModal, 
      responseStatusModal, responseMsg,
      isSuccessResponse
    } = this.state;
    const {
      area = '',
      title = '',
      trip_type = '',
      description = '',
      payment_method = '',
      price_per_person = 0,
      average_rating = 0,
      total_rating = 0,
      country: {
        data: {
          attributes: {
            name: country_name = '', phone_number_length = [10]
          } = {} } = {}
      } = {},
      city: {
        data: {
          attributes: { name: cityName = '' } = {}
        } = {}
      } = {},
      early_payment_discount: { data: {
        attributes: {
          discounted_price_per_person: earlyPaymentDiscount = '',
          days_before_trip = '',
        } = {}
      } = {} } = {},
      quantity_discount: {
        data: {
          attributes: {
            title: quantity_discount_title = '',
            description: quantity_discount_desc = '',
            quantity = '',
            discounted_price_per_person: quantityDiscount = '',
          } = {} } = {}
      } = {},
      defined_discount: { data: { attributes: { discount: defineDiscountPercent = null, end_date = '' } = {} } = {} } = {},
      group_preference: {
        data: {
          attributes: {
            preference_type = '', min_seat = null, max_seat = null
          } = {} } = {}
      } = {},
      trip_detail: {
        data: {
          attributes: {
            facilities = '', check_list = '', inclusion = '',
            exclusion = '', restricted_item = '', other_detail = '',
            cancellation_policy = '',
          } = {}, attributes = {} } = {}
      } = {},
      vibes = [],
      reviews: { data: reviews = [] } = {},
      primary_image_url = '', other_images = [], currency = '',
      itineraries: { data: itineraries = [] } = {},
      trip_schedules: { data: trip_schedules = [] } = {},
      servise_provier_details: {
        about_me = '',
        active_since = '',
        name: full_name = '',
        user_type: account_type = ''
      } = {},
      account: {
        data: {
          attributes: {
            entity_detail: {
              data: {
                attributes: {
                  agency_logo = ''
                } = {}
              } = {}
            } = {}
          } = {}
        } = {}
      } = {}
    } = tripDetails

    const shouldDisplayButton = description.length > this.maxCharacters + 7;
    const [day, month, year] = active_since.split('-');
    const isoDateStr = `20${year}-${month}-${day}`;
    const date = new Date(isoDateStr);
    const activeYear = date.getFullYear().toString();
    const validDefinedDiscount = new Date() < new Date(end_date)

    const texts = isExpanded
      ? {
        readMoreText: " read less",
        displayedText: description
      }
      : {
        readMoreText: " read more...",
        displayedText: description.slice(0, this.maxCharacters)
      }

    const details = detailsList(attributes, {
      trip_type, country_name, cityName, area, vibes,
      preference_type, min_seat, max_seat, payment_method
    })

    const tripDetailsList = [
      {
        icon: facility,
        title: i18next.t('customer.tripDetails.facilities'),
        description: facilities
      },
      {
        icon: bringBag,
        title: i18next.t('customer.tripDetails.whatToBring'),
        description: check_list
      },
      {
        icon: include,
        title: i18next.t('customer.tripDetails.whatsIncluded'),
        description: inclusion
      },
      {
        icon: notInclude,
        title: i18next.t('customer.tripDetails.doesntInclude'),
        description: exclusion
      },
      {
        icon: notAllowed,
        title: i18next.t('customer.tripDetails.notAllowed'),
        description: restricted_item
      },
      {
        icon: moreInfo,
        title: i18next.t('customer.tripDetails.moreInformation'),
        description: other_detail
      },
      {
        icon: cancelationPolicy,
        title: i18next.t('customer.tripDetails.cancellationPolicyNoShow'),
        description: cancellation_policy
      },
    ]

    const agencyDetailsBlock = () => {
      return (
        <Grid container spacing={2} style={{ marginBottom: '72px' }}   >
          {/* Details for tablet and mobile */}
          <Box style={{width: '100%'}} className={classes.detailsTM}>
              <Typography className={` ${classes.textTitle} ${classes.tripTitle}`} gutterBottom>
                {title}
              </Typography>
              <Typography className={`${classes.textstyle} ${classes.tripDescription}`}>
                {texts.displayedText}
                {shouldDisplayButton && (
                  <Typography
                  data-test-id='textExpand'
                    onClick={this.toggleExpand}
                    className={`${classes.textstyle} ${classes.readMore}`}
                    component="span">
                    {texts.readMoreText}
                  </Typography>
                )}
              </Typography>
              <Box className={`${classes.flexStyle8} ${classes.ratingBlock}`}>
                <Star style={{ color: primaryColor }} /> {average_rating}
                <Divider orientation="vertical" style={{ margin: '0 4px', height: '24px' }} />
                {total_rating} {ratingText}
              </Box>
              <Divider style={{ margin: '32px 0 40px' }} />
            </Box>
        {/* Slider */}
        <Grid item sm={12} md={6}>
          <SliderNavFor data-test-id="sidernav"  handleShare={this.openShareModal} slides={primary_image_url ? [primary_image_url, ...other_images] : other_images} />
        </Grid>
        {/* Details */}
        <Grid item sm={12} md={6}>
            <Box className={classes.detailsPC}>
              <Typography className={`${classes.tripTitle} ${classes.textTitle} `} gutterBottom>
                {title}
              </Typography>
              <Typography className={`${classes.tripDescription} ${classes.textstyle} `}>
                {texts.displayedText}
                {shouldDisplayButton && (
                  <Typography component="span" onClick={this.toggleExpand}
                    className={`${classes.readMore} ${classes.textstyle} `}>
                    {texts.readMoreText}
                  </Typography>
                )}
              </Typography>
              <Box className={`${classes.ratingBlock} ${classes.flexStyle8} `}>
                <Star style={{ color: primaryColor }} /> {average_rating}
                <Divider orientation="vertical" style={{ height: '24px', margin: '0 4px' }} />
                {total_rating} {i18next.t('customer.tripDetails.rating')}
              </Box>
              <Divider style={{ margin: '32px 0 40px' }} />
            </Box>
          <Grid container spacing={2}>
            <Grid item sm={6}>
              <Typography className={classes.flexStyle8} gutterBottom component={'div'}>
                <Typography className={classes.tripPrice}>
                  {this.calculatePrice(price_per_person, validDefinedDiscount, defineDiscountPercent)} {currency}
                </Typography>
                {validDefinedDiscount && 
                <>
                <s className={classes.discountPrice}>{price_per_person} {currency}</s>
                <Typography className={classes.discountPercent}>({defineDiscountPercent}% off)</Typography>
                </>}
              </Typography>
              <Typography className={classes.priceIncl}>{i18next.t('customer.tripDetails.inclRegionalTaxes')}</Typography>
            </Grid>
            <Grid item sm={12} md={6} style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
              <BootstrapButton type="button" onClick={this.handleClose}>
              {i18next.t('customer.tripDetails.checkAvailability')}
              </BootstrapButton>
            </Grid>
          </Grid>
          {/* Discounts */}
          {earlyPaymentDiscount || quantityDiscount
              ? <Box className={classes.discountBlock}>
                <Box>
                  {
                    quantityDiscount && <Typography className={classes.quantityDiscounts} gutterBottom>
                    {i18next.t('customer.tripDetails.bookFor')} {quantity} And Get</Typography>
                  }
                  {
                    earlyPaymentDiscount && <Typography className={classes.quantityDiscounts} gutterBottom>
                    {i18next.t('customer.tripDetails.bookFor')} {days_before_trip} {i18next.t('customer.tripDetails.daysBeforeTheTrip')} </Typography>
                  }
                  <Typography className={classes.quantityDiscountDescription}>
                    {quantity_discount_desc}
                  </Typography>
                </Box>
                <Box style={{
                  display: 'flex',
                  alignItems: 'baseline'
                }}>
                  <Typography className={classes.pricePerPerson}>
                    {earlyPaymentDiscount || defineDiscountPercent || quantityDiscount} {currency} &nbsp;
                  </Typography>
                  <Typography className={classes.perPersonText}>
                    / {i18next.t('customer.tripDetails.person')}
                  </Typography>
                </Box>
              </Box>
              : <Divider style={{ margin: '40px 0 32px' }} />
          }
          {/* Service Provider */}
          <Typography className={`${classes.blockTitle} ${classes.marginBottom16} `} >{i18next.t('customer.tripDetails.serviceProvider')}
</Typography>
          <Box className={classes.flexStyle12}>
            <Avatar className={classes.userImage} alt="User Avatar" src={agency_logo} />
            <Box>
              <Typography className={classes.travelAgencyName}>{`${full_name} (${capitalizeFirstLetter(account_type)})`}</Typography>
              <Typography className={classes.yearsAGo}>{i18next.t('customer.tripDetails.activeSince')} {activeYear || 2018}</Typography>
            </Box>
          </Box>

          <Typography className={classes.blockDescription}>
            {about_me}
          </Typography>

          <Divider style={{ marginTop: '24px' }} />
        </Grid>
      </Grid>
      )
    }

    const DetailandReviewTabs = () => {
      return <Box className={classes.deailsAndReviewContainer} >
        <Box className={classes.flexStyleSB}>
          <Tabs
            id="tabs"
            data-test-id="tabs"
            value={this.state.tab}
            TabIndicatorProps={{
              style: { backgroundColor: '#FE7F2D', }
            }}
            classes={{ flexContainer: classes.flexContainer, }}
            onChange={(event: any, value: string) =>
              this.handleChange(value)
            }
          >
            <Tab label={i18next.t('customer.tripDetails.details')} value={'0'} classes={{
              selected: classes.selectedTab,
              wrapper: classes.tabItem,
              textColorInherit: classes.textColor
            }} />
            <Tab label={i18next.t('customer.tripDetails.reviews')} value={'1'} classes={{
              selected: classes.selectedTab,
              wrapper: classes.tabItem,
              textColorInherit: classes.textColor
            }} />
          </Tabs>
          <div className={classes.reviewBtnPC}>
          {reviewBtn({ handleOpenReviewModal: this.handleOpenReviewModal })}
          </div>
        </Box>
        <div hidden={this.state.tab === '1'}>
          {tripContainer(classes, details, itineraries)}
        </div>
        <div hidden={this.state.tab === '0'}>
          {reviewBlock(classes, reviews)}
        </div>
        <div className={classes.reviewBtnTM}>
          {reviewBtn({ handleOpenReviewModal: this.handleOpenReviewModal })}
          </div>
        {tripDetailsBlock(classes, tripDetailsList)}

      </Box>
    }

    // Customizable Area End
    return (
      <>
        {/* Customizable Area Start */}
        < ThemeProvider theme={theme} >
        <ShareTripModal
        data-test-id="ShareTripModal"
                  isOpen={openShareModal}
                  onClose={this.closeShareModal}
                  />
          <AddReviewWeb 
          data-test-id="reviewAdd"
          reviewModal={this.state.openReviewModal} 
          addReviewRequest={this.addReviewRequest}
          closeAddReviewModal={this.handleCloseReviewModal} 
          />
          <ResponseStatusModal
           data-test-id="close-btn"
          isSuccess={isSuccessResponse}
          message={responseMsg}
          open={responseStatusModal}
          onClose={this.closeResponseModal}
          />
          {
            loading
              ? 
              <Box style={{height:'65vh'}}>
              <Loader loading={loading}  />
              </Box>
              : <Box>
                <div className={classes.container}>
                  <Box style={{ marginBottom: '58px' }}>
                    <Breadcrumbs list={[
                      {
                        title: i18next.t('customer.tripDetails.home'),
                        link: '/LandingPage'
                      },
                      {
                        title: i18next.t('customer.tripDetails.tripDetails')
                      }
                    ]} />
                  </Box>

                  {agencyDetailsBlock()}
                  {DetailandReviewTabs()}

                  <Divider style={{ margin: '48px 0 72px' }} />

                  {tripSlider(classes, similarTrips, i18next.t('customer.tripDetails.similarExperiences'), this.props.navigation, this.goToAdvancedSearch, { marginBottom: '88px' })()}
                  {tripSlider(classes, exploreMoreTrips, i18next.t('customer.tripDetails.exploreMore'), this.props.navigation, this.goToAdvancedSearch, { marginBottom: '32px' })()}

                </div>
                <AvailabilityModal
                  instance={this}
                  id='AvailabilityModal'
                  navigation={this.props.navigation}
                />
              </Box>
          }
        </ThemeProvider >
        {/* Customizable Area End */}
      </>
    );
  }
}

// Customizable Area Start

const TripDetailsWeb = withStyles(tripStyles)(TripDetails);
export { TripDetailsWeb };
// Customizable Area End
