import React from "react";

// Customizable Area Start
import {
    Typography, Box, TextField,
    Card, Button,
    CardContent, Checkbox, Tooltip, IconButton,
    InputAdornment
} from "@material-ui/core";
import {
    withStyles, Theme, ThemeProvider
} from "@material-ui/core/styles";

import { primaryColor } from "../../../components/src/theme";
import InfoIcon from '@material-ui/icons/Info';
import {
    
    signUptheme,
} from "../../email-account-registration/src/EmailAccountRegistration.web";

import { Rating } from "@material-ui/lab"
import { successIcon } from "../../Settings5/src/assets";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { CreditCardIcon } from "./assets";


export const ButtonStyle = withStyles({
    root: {
        color: '#ffffff',
        minWidth: '160px',
        textTransform: 'none',
        backgroundColor: primaryColor,
        width: '100%',
        height: '56px',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '150%',
        maxWidth: '228px',
        flexShrink: 0,
        borderRadius: '49px',
        textAlign: 'center',


        fontFamily: [
            'Inter',
            'sans-serif',
        ].join(','),
        '&:hover': {
            backgroundColor: primaryColor,
        },
        '&.Mui-disabled': {
            opacity: 0.6,
            color: '#FFF'
        }
    },
})(Button);



// Customizable Area End

import SubscriptionPlanController, {
    Props
} from "./SubscriptionPlanController";
import i18next from "i18next";
import ResponseStatusModal from "../../../components/src/ResponseStatusModal";


export default class SubscriptionDetailForm extends SubscriptionPlanController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
     

    
      

    // Customizable Area End

    render() {
        // Customizable Area Start
     
             const { classes } = this.props
             const {
                cardNumber,
                cardHolderName,
                expiration,
                securityCode,
                subScriptionData,
                responseModal
            } = this.state;
            
            const isFormValid = cardNumber !== '' && cardHolderName !== '' && expiration !== '' && securityCode !== '';

       
        
        
        // Customizable Area End
        return (
            // Customizable Area Start
            <ThemeProvider theme={signUptheme}>
                {
                <Box className={classes.container}>
                   
                   <Typography className={classes.dividerStyle}>
                   {i18next.t('travelAgency.subscripitonPlan.make_payment')}
                   </Typography>
                   <Typography>{i18next.t('travelAgency.subscripitonPlan.complete_payment')}</Typography>
                           <Box>
                               <Card className={classes.userProfileCard}>
                               <Box>
                                        <CardContent style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }} data-testid="subscription-container">

                                            <Box style={{ display: 'flex', alignItems: 'end' }}>
                                                <Box style={{ backgroundColor: '#fff', width: '30px', height: '30px', borderRadius: '5px', display: 'flex' }}>
                                                    <Rating value={0.5} precision={0.1} readOnly size="medium" max={1} style={{ color: "#FE7F2D", margin: 'auto 0' }} />
                                                </Box>&nbsp;&nbsp;


                                                <Typography variant="body1">{subScriptionData.attributes.name} Plan</Typography>&nbsp;&nbsp;


                                                <Typography variant="body1" style={{ color: "rgb(254 127 45)", fontWeight: 'bold', marginTop: '20px' }}>Change plan</Typography>
                                            </Box>


                                            <Typography variant="h6" style={{ color: "rgb(254 127 45)", fontWeight: 'bold', marginTop: '20px' }}>
                                                {subScriptionData.attributes.price} {subScriptionData.attributes.currency}
                                            </Typography>


                                            <Typography variant="body1">Price per {subScriptionData.attributes.duration}</Typography>
                                        </CardContent>


                               
                                   
                                
                                   </Box>

                               </Card>
                           </Box>
                                    <Box className={`${classes.userProfileInfo}`}>
                                    <Box style={{ borderRadius: '30px' }}>
                                    
                                    <Box>

                                       <Box style={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
                                           <Box>
                                               <Typography>{i18next.t('travelAgency.subscripitonPlan.cardNumber')}</Typography>
                                            <TextField
                                                name="cardNumber"
                                                placeholder="Card Number"
                                                fullWidth
                                                value={cardNumber}
                                                data-testId="cardNumberInput"
                                                onChange={(e) => this.handleCardNumberChange(e)}
                                                InputProps={{
                                                    className: classes.textFieldStyle,
                                                    disableUnderline: true,
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <img alt="creditcard" src={CreditCardIcon} height='100%' width='100%' />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                variant="standard"
                                            />
                                           </Box>
                                           <Box>
                                               <Typography>{i18next.t('travelAgency.subscripitonPlan.cardHolderName')}</Typography>
                                               <TextField
                                                   autoFocus
                                                   id="cardHolderName"
                                                   name="cardholder_name"
                                                   placeholder={i18next.t('travelAgency.subscripitonPlan.card_holder_name')}
                                                   type="text"
                                                   fullWidth
                                                   variant="outlined"
                                                   required
                                                   value={cardHolderName}
                                                   data-testId={'inputCardName'}
                                                   onChange={(e) => this.handleCardHolderNameChange(e)}
                                               />
                                           </Box>
                                           <Box sx={{ display: 'flex', gridGap: '20px' }}>
                                               <Box>
                                                   <Typography>{i18next.t('travelAgency.subscripitonPlan.expiration')}</Typography>
                                                   <TextField
                                                       autoFocus
                                                       id="expiration"
                                                       name="expiration_date"
                                                       placeholder="mm/yy"
                                                       type="text"
                                                       fullWidth
                                                       variant="outlined"
                                                       required
                                                       value={expiration}
                                                       data-testId={'inputExpiration'}
                                                       onChange={(e) => this.handleExpirationChange(e)}
                                                   />
                                               </Box>
                                               <Box>
                                                   <Typography>{i18next.t('travelAgency.subscripitonPlan.securityCode')}</Typography>
                                                   <TextField
                                                       autoFocus
                                                       id="cvv"
                                                       name="cvv"
                                                       placeholder={i18next.t('travelAgency.subscripitonPlan.security_code')}
                                                       type="text"
                                                       fullWidth
                                                       variant="outlined"
                                                       required
                                                       value={securityCode}
                                                       data-testId={'inputSecurityCode'}
                                                       onChange={(e) => this.handleSecurityChange(e)}
                                                   />
                                               </Box>
                                           </Box>
                                           <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', fontSize: '13px' }}>
                                               <Checkbox style={{color:'FE7F2D'}}  checked={this.state?.ischecked}
                                               data-testid = "autorenewalcheckbox" onChange={this.updateAutoRenewalApiRequest}/>
                                               {i18next.t('travelAgency.subscripitonPlan.turn_on_auto_renewal')}
                                               <Tooltip title={i18next.t('travelAgency.subscripitonPlan.auto_renewal_message') || 'By turning on auto renewal, you agree to renew your subscription automatically on the next renewable date. You may turn off this option at any time from your profile page.'}>
                                               <IconButton>
                                               <InfoIcon />
                                               </IconButton>
                                               </Tooltip>
                                           </Box>
                                       </Box>
                                           <Box style={{ display: 'flex', justifyContent: 'space-between', marginTop: '30px' }}>
                                               <Button data-testId={'btnPaymentTokenApi'} variant="outlined" disabled={!isFormValid} onClick={this.getPaymentTokenRequest} style={{ padding: '10px 60px', borderRadius: '20px', color: '#fff', background: '#FE7F2D',textTransform:'capitalize' }} type="submit" >{i18next.t('travelAgency.subscripitonPlan.make_payment_btn')}</Button>
                                               <Button variant="outlined" style={{ padding: '10px 55px', borderRadius: '20px', color: '#FE7F2D', textTransform: 'capitalize' ,border:"none"}} data-testID = {"formBackBtn"} onClick={this.formBackButton}>{i18next.t('travelAgency.subscripitonPlan.back')}</Button>
                                           </Box>
                                           <ResponseStatusModal
                            isSuccess={false}
                            message="Please check your card details"
                            open={responseModal}
                            onClose={this.onClose}
                        />
                             
                   </Box>
               </Box>
                       
                        
                   </Box>
                   
               </Box >  
                 }
               
            </ThemeProvider >
            // Customizable Area End
        );
    }
}

// Customizable Area Start

export const ProfileStyles = (theme: Theme) => ({
    container: {
        width: "600px",
        margin: "0 auto",
        paddingBottom: "30px"
    },

    rootTab: {
        display: 'flex',
        padding: '4px 4px',
        flexDirection: 'column' as const,
        gap: '4px',
        justifyContent: 'center',
        alignItems: 'flex-start',
        minWidth: 'auto',
        minHeight: 'auto'
    },
    rootTabs: {
        minHeight: '32px',
        height: '100%'
    },

    selectedTab: {
        '& span': {
            color: '#212426',
            fontFeatureSettings: "'cv11' on, 'cv01' on, 'ss01' on",
            fontFamily: 'Inter',
            fontWeight: 600,
            lineHeight: '20px',
            fontSize: '16px',
            fontStyle: 'normal',

        }
    },
    flexContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
        gap: '24px',
    },
    agencyImg: {
        objectFit: 'cover',
        borderRadius: '50px'
    },

    carddetails: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        justifyContent: 'flex-start',
        padding: '20px 30px',
        borderRadius: '30px',
        background: '#FFF2D7'
    },
    tabItem: {
        color: 'rgba(0, 0, 0, 0.40)',
        fontFeatureSettings: "'cv11' on, 'cv01' on, 'ss01' on",
        fontFamily: 'Inter',
        fontSize: '16px',
        lineHeight: '20px',
        textTransform: 'none' as const,
        fontStyle: 'normal',
        fontWeight: 400,

    },
    cardWrapper: {
        padding: '48px',
        border: '0.5px solid rgba(33, 36, 38, 0.20)',
        background: '#FFF',
        borderRadius: '16px',

        // '&:hover': {
        //     backgroundColor: primaryColor,
        //     transform: 'scale(105%)',
        //     boxShadow: '0 24px 36px rgba(0,0,0,0.11)',
        //     transition: 'opactiy 0.3s linear',
        // },
    },

    userProfileCard: {
        borderRadius: '16px',
        background: '#FDF6ED',
        boxShadow: 'none',
        margin: '40px 0',
        padding: '0 0px 0px 15px',


    },
    userProfileInfo: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        gap: '20px'
    },

   
    // cardkeys : {
    //   width:'100px'
    // },
    editProfileInfo: {
        maxWidth: '900px',
    },


    heading: {
        // color: '#212426',
        // fontSize: '20px',
        fontWeight: 500,
        // lineHeight: '100%',
    },
    userProfilesecondCard:{
        marginTop : '16px' ,
        borderRadius : '16px',
     padding : '30px',

    },
    userDetails: {
        gap: '20px',
        display: 'flex',
   
    },

    cardbtns: {
        color: '#fff',
        borderRadius: '20px',
        padding: '10px 60px',
        background: '#FE7F2D',
    },

    textfields: {
        height: '44px'
    },
    editdetailstyles: {
        color: '#FE7F2D',
        fontWeight: '500',
        marginLeft: '30px',
        whiteSpace: 'nowrap'

    },
    editdetailstyle: {
        color: primaryColor,
        gap: '8px',
        alignItems: 'center',
        fontWeight: 500,
        display: 'flex',
        cursor: 'pointer'
    },
    logoContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '30px',
        padding: '0px 30px 40px 0px'
    },

    cardBoxes:{
      display:'flex',
      gap:'20px',
      justifyContent: 'space-between'
    },

    lightLabelTextstyle: {
        fontWeight: 500,
        whiteSpace: 'noWrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: '340px',
        minWidth: 'fit-content'


    },

    boldLabel: {
        fontWeight: 'bold'
    },


    valueTextstylespan: {

        color: 'rgba(33, 36, 38, 0.90)',
        width: '270px',
        fontWeight: 500,
        height: '24px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap' as const,
        display: 'block'

    },
    valueTextstyle: {
        color: 'rgba(33, 36, 38, 0.90)',
        fontWeight: 500,
        width: '320px',
        height: '24px',
        display: 'flex',
        marginLeft: '40px'
    },

    userImage: {
        height: '20px',
        width: '20px'
    },

    dividerStyle: {
        margin: '18px 0 35px',
        fontWeight: 700,
        lineHeight: '100%',
        color: '#212426',
        fontSize: '28px',

    },
    required: {
        color: '#F00'
    },
    labelText: {
        fontWeight: 500,
        marginBottom: '10px'
    },
})

const SubscriptionDetailFormWeb = withStyles(ProfileStyles as object)(SubscriptionDetailForm)
export { SubscriptionDetailFormWeb }
// Customizable Area End
