import React from "react";

// Customizable Area Start
import * as Yup from "yup";
import {
  Box, Button, Grid, FormControlLabel,
  Checkbox,
  Typography, TextField, InputAdornment, IconButton,
} from "@material-ui/core";
import { Formik } from "formik";
import { Visibility, VisibilityOff } from "@material-ui/icons"
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import { travelloginImage } from "./assets";
import { primaryColor } from "../../../components/src/theme";
import { borderColor, helperTextValue, isErrorInField } from "../../../components/src/utils";
import './CustomerLoginScreen.css';
import i18next from 'i18next';

const styles = {
  description: {
    fontWeight: 400,
    fontSize: '16px',
    letterSpacing: 0,
    lineHeight: '150%',
    fontStyle: 'normal',
    marginBottom: '32px',
    fontFamily: 'Inter, sans-serif',
    color: 'rgba(33, 36, 38, 0.65)',
  },
  outlineBtn: {
    width: '168px',
    height: '56px',
    flexShrink: 0,
    borderRadius: '34px',
    border: ` 1px solid ${primaryColor}`,
    background: '#FFF',
    color: primaryColor,
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '150%',
  },
  errorBlock: {
    color: '#FF0000',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '150%',
    padding: '8px 16px',
    borderRadius: '8px',
    fontStyle: 'normal',
    background: '#FFF2F2',
    display: 'inline-block',
  },
  errorBefore: {
    content: "",
    background: 'red',
    width: '5px',
    height: '100%',
    top: 0,
    left: 0,
    borderRadius: '8px 0 0 8px',
  },
  formHelperText: {
    color: '#F00',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    marginTop: '4px'
  }
};

const BootstrapButton = withStyles({
  root: {
    textTransform: 'none',
    backgroundColor: primaryColor,
    color: '#ffffff',
    minWidth: '160px',
    width: '100%',
    height: '56px',
    flexShrink: 0,
    textAlign: 'center',
    borderRadius: '44px',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '150%',
    fontFamily: [
      'Inter',
      'sans-serif',
    ].join(','),
    border: `1px solid ${primaryColor}`,
    '&:hover': {
      backgroundColor: primaryColor,
    },
    '@media (max-width: 768px)': {
      height: '48px',
      fontWeight: 600,
    }
  },
})(Button);
// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
}
  // Customizable Area Start
  validationSchema = Yup.object().shape({
    email: Yup.string().email("* Please enter a valid email")
      .required("* Please enter a valid email"),
    password: Yup.string().required("* Please enter a valid password"),
  });

  passwordShowHideBtn = (enablePasswordField: boolean) => {
    return <InputAdornment position='end'>
      <IconButton
        aria-label='toggle password visibility'
        onClick={this.handleClickShowPassword}
        style={{ fontSize: "15px", textDecoration: 'none', padding: 0 }}
      >
        {enablePasswordField
          ? <Visibility style={{ height: '24px', width: '24px' }} />
          : <VisibilityOff style={{ height: '24px', width: '24px' }} />
        }
      </IconButton>
    </InputAdornment>
  }

  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <ThemeProvider theme={theme}>
        <div className="guest-form login-wrapper">
          <Grid container justifyContent="space-between"
            style={{ margin: '0 auto', maxWidth: '1200px', }}
          >
            <Grid item xs={10} sm={9} md={6} lg={4} style={{ margin: '0 auto' }} className="login-form">
              <Typography className="title mb-root56" style={{ textAlign: 'center' }}>
                {i18next.t('loginBlock.welcomeBack')}
              </Typography>

              {this.state.signinErrorMsg && <Typography component={'div'}>
                <p style={{ ...styles.errorBlock, position: `relative`, wordBreak: 'break-word' }}>
                  <span style={{ ...styles.errorBefore, position: 'absolute' }}></span>
                  {this.state.signinErrorMsg}
                </p>
              </Typography>}

              <Formik
                initialValues={{
                  email: this.state.email,
                  password: this.state.password
                }}
                validationSchema={this.validationSchema}
                onSubmit={() => { this.doEmailLogIn() }}
              >
                {({ handleSubmit, handleChange, errors, values, touched }) => {
                  return (
                    <form onSubmit={handleSubmit}>
                      <Box className="mb-root32">
                        <Typography className="label">
                        {i18next.t('loginBlock.emailAddress')}
                        </Typography>
                        <Box style={{ display: 'flex', gap: '10px', width: '100%' }}>
                          <TextField
                            name="email"
                            variant="standard"
                            style={{ width: "100%" }}
                            placeholder={i18next.t('loginBlock.enterEmailAddress')}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              this.setEmail(e.target.value);
                              handleChange(e);
                            }}
                            error={isErrorInField(errors, touched, 'email')}
                            FormHelperTextProps={{ style: styles.formHelperText }}
                            helperText={helperTextValue(errors, touched, 'email')}
                            value={values.email}
                            InputProps={{
                              className: 'inputField',
                              style: {
                                borderColor: borderColor(errors, touched, 'email'),
                              },
                              disableUnderline: true,
                            }}
                          />
                        </Box>
                      </Box>

                      <Box className="mb-root24">
                        <Typography className="label">
                        {i18next.t('loginBlock.password')}
                        </Typography>
                        <Box style={{ display: 'flex', gap: '10px', width: '100%' }}>
                          <TextField
                            name="password"
                            variant="standard"
                            value={values.password}
                            style={{ width: "100%" }}
                            placeholder={i18next.t('loginBlock.enterPassword')}
                            type={this.state.enablePasswordField ? "text" : "password"}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              this.setPassword(e.target.value)
                              handleChange(e);
                            }}
                            error={isErrorInField(errors, touched, 'password')}
                            helperText={helperTextValue(errors, touched, 'password')}
                            FormHelperTextProps={{ style: styles.formHelperText }}
                            InputProps={{
                              className: 'inputField',
                              endAdornment: (this.passwordShowHideBtn(this.state.enablePasswordField)),
                              style: {
                                borderColor: borderColor(errors, touched, 'password'),
                              },
                              disableUnderline: true,
                            }}
                          />
                        </Box>
                      </Box>
                      <Box className='mb-root40' style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}>
                        <FormControlLabel
                          control={<Checkbox
                            id='rememberMe'
                            classes={{
                              checked: 'checked',
                              root: 'checkbox-root'
                            }}
                            checked={this.state.checkedRememberMe}
                            onClick={() => this.setRememberMe(!this.state.checkedRememberMe)}
                          />}
                          data-test-id="rememberMe"
                          label={i18next.t('loginBlock.rememberMe')}
                          classes={{ label: 'rememberMelabel' }}
                          style={{ gap: '10px', alignItems: 'center', marginLeft: '1px' }}
                        />
                        <span className="link" onClick={() => this.goToForgotPassword()}
                          data-test-id="forgotPassword" >{i18next.t('loginBlock.forgotPassword')}</span>
                      </Box>
                      <Box style={{ display: 'flex', justifyContent: 'center' }} className="mb-root32">
                        <BootstrapButton type="submit">
                        {i18next.t('loginBlock.login')}
                        </BootstrapButton>
                      </Box>
                    </form>
                  )
                }}
              </Formik>
              <Box className="account-block mb-root40">
                <Typography style={{
                  color: 'rgba(33, 36, 38, 0.65)',
                  fontFamily: 'Inter',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '150%',
                }}>
                  {i18next.t('loginBlock.dontHaveAnAccount')}
                  <span data-test-id='signUpBtn' onClick={() => this.goToAccountRegistration()} className="link">
                  {i18next.t('loginBlock.signUpNow')}
                  </span>
                </Typography>
              </Box>
              <div className="service-block">
                <h4>{i18next.t('loginBlock.wantToProvideService')}</h4>
                <p>{i18next.t('loginBlock.reachWiderAudience')}</p>
                <span data-test-id="navigateTravelAgency" onClick={() => this.props.navigation.navigate("TravelAgencyRegistration")} className="link">{i18next.t('loginBlock.registerNow')}</span>
              </div>
            </Grid>
            <Grid item lg={7} className="login-image-block hidden-sm">
              <img src={travelloginImage} alt="Images" />
            </Grid>
          </Grid>
        </div>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}