import React from "react";

// Customizable Area Start
import * as Yup from "yup";
import {
    Typography, Box, Divider, TextField,
    Card, Grid, Button, MenuItem
} from "@material-ui/core";
import {
    withStyles, Theme, ThemeProvider
} from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';
import { Formik } from "formik";
import DatePicker from 'react-datepicker';
import { cloudUpload, editPen, userIcon, verticalLine } from "./assets";
import { primaryColor } from "../../../components/src/theme";
import Breadcrumbs from "../../../components/src/Breadcrumbs";
import { calendarImg } from "../../email-account-registration/src/assets";
import {
    SelectInput, isErrorInEmail,
    signUptheme, validationSchema, verifyEmailBtn, verifyEmailModal
} from "../../email-account-registration/src/EmailAccountRegistration.web";
import {
    borderColor, helperTextValue,
    isErrorInField, formatDate, ternaryCondition
} from "../../../components/src/utils";
import ResponseStatusModal from "../../../components/src/ResponseStatusModal";

const getCountryId = (countryOption: { label: string, value: string }[], country_of_residence: null | string) => {
    const option = countryOption.find((item) => item.label === country_of_residence)
    return option && option.value ? option.value : null
}

const getModifiedValues = (
    values: Record<string, any>,
    initialValues: Record<string, any>
  ) => {
    let modifiedValues: any = {};
    
    if (values) {
      Object.keys(values).forEach((key) => {
        const newValue = values[key];
        const oldValue = initialValues[key];
  
        if (key === 'mobile_number' || key === 'selectedCountry') {
          // Check if 'mobile_number' or 'selectedCountry' is changed
          modifiedValues[key] = (newValue !== undefined) ? newValue : oldValue;
        } else if (newValue !== initialValues[key]) {
          // For other fields, include the new value
          modifiedValues[key] = newValue;
        }
      });
    }
  
    return modifiedValues;
  };
  

const DateOfBirthInput = React.forwardRef(({ value, onClick }: any, ref: any): React.ReactElement => {
    return <div className={ternaryCondition(!value, "date-placeholder", 'date-value')}
        data-testid="singledate" onClick={onClick} ref={ref}>
        {ternaryCondition(!value, "Select date of birth", <span>{formatDate(value)}</span>)}
        <img src={calendarImg} alt="" />
    </div>
});


export const BootstrapButton = withStyles((theme) =>({
    root: {
        textTransform: 'none',
        backgroundColor: primaryColor,
        color: '#ffffff',
        minWidth: '160px',
        width: '100%',
        height: '56px',
        maxWidth: '228px',
        flexShrink: 0,
        textAlign: 'center',
        borderRadius: '49px',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '150%',
        fontFamily: [
            'Inter',
            'sans-serif',
        ].join(','),
        '&:hover': {
            backgroundColor: primaryColor,
        },
        '&.Mui-disabled': {
            color: '#FFF',
            opacity: 0.6
        },
        [theme.breakpoints.down('sm')]: { // Media query for screens smaller than or equal to tablet size
            width: '100%', // Set width to 100% for mobile
            maxWidth: '100%', // Ensure it takes up full width on mobile
          },
    },
}))(Button);

const getBreadcrumbLink = (interestType: any) => {
    if (interestType === null) {
      return '/MyInterest';
    } else {
      return '/LandingPage';
    }
  };

// Customizable Area End

import UserProfileBasicController, {
    Props
} from "./UserProfileBasicController";
import i18next from "i18next";

export default class UserProfileCustomer extends UserProfileBasicController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderImage = (values: any, classes: any) => {
        return !values.profile_image
            ? <img src={userIcon} alt='user Image' className={classes.uploadImage} />
            : <img src={typeof values.profile_image === 'string'
                ? values.profile_image
                : URL.createObjectURL(values.profile_image)}
                alt='user Image' className={classes.uploadImage} />
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props
        const { countryOption, isEditProfile, isEmailVerified,
            userDetailsLeft, countdown,
            userDetailsRight, data: {
                first_name = '',
                last_name = '',
                email = '',
                date_of_birth = null,
                gender = null,
                interest_type = null,
                phone_number: {
                    country_code = '',
                    number = ''
                } = {},
                nationality = null,
                country_of_residence = null,
                profile_image = null,
            } = {}
        } = this.state

        const initialValues = {
            first_name: first_name,
            last_name: last_name,
            email: email,
            mobile_number: number,
            birth_day: new Date(date_of_birth) || null,
            gender: gender,
            country_id: getCountryId(countryOption, country_of_residence),
            nationality_id: getCountryId(countryOption, nationality),
            profile_image: profile_image,
            selectedCountry: `+${country_code}`
        }

        const countDownTime = `${Math.floor(countdown / 60)}:${(countdown % 60).toString().padStart(2, '0')}`;
        const getTranslationKey = (label: string) => {
            switch (label) {
              case 'First Name':
                return i18next.t('customer.myProfile.firstName');
              case 'Last Name':
                return i18next.t('customer.myProfile.lastName');
              case 'Email Address':
                return i18next.t('customer.myProfile.emailAddress');
              case 'Birthdate':
                return i18next.t('customer.myProfile.birthdate');
              case 'Nationality':
                return i18next.t('customer.myProfile.nationality');
              case 'Mobile Number':
                return i18next.t('customer.myProfile.mobileNumber');
              case 'Gender':
                return i18next.t('customer.myProfile.gender');
              case 'Country of Residence':
                return i18next.t('customer.myProfile.countryOfResidence');
              default:
                return label; 
            }
          };
        const detailsInColumnBlock = (list: Record<string, any>, className: string) => {
            return <Box display={'flex'} gridRowGap={'32px'} flexDirection={'column'} className={className}>
                {Object.entries(list).map(([label, value], index) => (
                    <Box display={'flex'} flexDirection={'column'} gridRowGap={'16px'} key={index}>
                        <Typography className={classes.lightLabelText}>
                         {getTranslationKey(label)} 
                        </Typography>
                        <Typography className={classes.valueText}>
                            {value}
                        </Typography>
                    </Box>
                ))}
            </Box>
        }

        const userDetailBlock = () => {
            return <>
                <Box className={classes.userProfile} sx={{
                    height: '352px', width: '100%', maxWidth: '172.5px'
                }} data-test-id="userDetailsBlock">
                    <Box sx={{
                        gridGap: '16px',
                        height: '164px',
                        display: 'inline-flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                    }}>
                        <Typography className={classes.lightLabelText}>
                        {i18next.t('customer.myProfile.myProfile')}
                        </Typography>
                        <img src={this.renderProfileImage()} alt='user Image' className={classes.userImageBorder} data-test-id="rederImg"/>
                    </Box>
                </Box>
                <Box display={'flex'} justifyContent={'space-between'} margin={'0 71px 0 88.5px'} width={'100%'}
                    className={classes.userDetails}>
                    {detailsInColumnBlock(userDetailsLeft, classes.detailsLeft)}
                    {detailsInColumnBlock(userDetailsRight, classes.detailsRight)}
                </Box>
            </>
        }
        // Customizable Area End
        return (
            // Customizable Area Start
            <ThemeProvider theme={signUptheme}>
                <Box className={classes.container}>

                    <Breadcrumbs list={[
                        {
                            title: i18next.t('customer.myProfile.home'),
                            link: getBreadcrumbLink(interest_type)
                        },
                        {
                            title: i18next.t('customer.myProfile.myProfile')
                        }
                    ]} />
                    <Divider className={classes.dividerStyle} />
                    <Box className={this.getClassName(isEditProfile, classes)}>
                    <Card className={classes.cardWrapper}> 
                    <Box display={'flex'} justifyContent={'space-between'} alignItems={'flex-start'} 
                    marginBottom={this.isEditProfileStyle()} >
                            <Typography className={classes.heading}> 
                            {i18next.t('customer.myProfile.myProfile')}
                                </Typography>
                                {this.renderEditButton(classes)}
                            </Box>
                            <Box display={'flex'} width={'100%'}>
                                {isEditProfile
                                    ? <Formik
                                            validateOnChange={true}
                                            initialValues={initialValues}
                                            enableReinitialize={true}
                                            validationSchema={Yup.object().shape(validationSchema)}
                                            onSubmit={(values, { setFieldError }) => {
                                                const modifiedValues = getModifiedValues(values, initialValues)
                                                if (Object.keys(modifiedValues).length) {
                                                    this.handleUpdateProfile(modifiedValues, setFieldError)
                                                } else {
                                                    this.handleToggleEditBtn()
                                                }
                                            }}
                                            data-test-id="formik"
                                        >
                                            {({ handleSubmit, values, setFieldValue, errors, touched, setFieldError }) => {
                                                return <form onSubmit={handleSubmit} style={{ display: 'flex', width: '100%' }}>
                                                    <Box className={classes.userProfile}
                                                        sx={{ flexBasis: '19.67%', height: '658px' }}>
                                                        <Box sx={{
                                                            gridGap: '16px',
                                                            display: 'inline-flex',
                                                            flexDirection: 'column',
                                                            justifyContent: 'flex-start',
                                                            alignItems: 'center',
                                                            width: '143px',
                                                            marginRight: '49px'
                                                        }}>
                                                            <Box position={"relative"}>
                                                            {  profile_image  && (
                                                                    <CloseIcon
                                                                        data-test-id="close-btn"
                                                                        className={classes.closeIcon}
                                                                        onClick={() => this.deleteProfile(setFieldValue)}
                                                                    />
                                                               
                                                                ) }
                                                                {this.renderImage(values, classes)}
                                                            </Box>
                                                           
                                                            <input
                                                                hidden
                                                                type='file'
                                                                accept='image/*'
                                                                id='profileImg'
                                                                onChange={(e) => this.handleImageUpload(e, setFieldValue, setFieldError)}
                                                            />
                                                            <Typography component={'label'} className={classes.editdetail} htmlFor="profileImg">
                                                                <img src={cloudUpload} alt='Edit' className={classes.userImage} />
                                                                    {this.getProfileImageAction(profile_image)}
                                                            </Typography>
                                                        </Box>
                                                        <Typography style={{
                                                            color: '#F00',
                                                            fontSize: '12px',
                                                            lineHeight: 'normal',
                                                            marginTop: '8px',
                                                            textAlign: 'center'
                                                        }}>{errors.profile_image}</Typography>
                                                    </Box>

                                                    <Box margin={'0 83px 0 59px'} width={'100%'}>
                                                        <Grid container spacing={3} justifyContent="space-between">
                                                            <Grid item xs={12} md={6}>
                                                                <Typography
                                                                    className={classes.labelText}
                                                                >
                                                                    {i18next.t('customer.myProfile.firstName')}<Typography component={'span'} className={classes.required}> *</Typography>
                                                                </Typography>
                                                                <TextField
                                                                    fullWidth
                                                                    id="first_name"
                                                                    name="first_name"
                                                                    variant="standard"
                                                                    placeholder={i18next.t('customer.myProfile.myProfile')}
                                                                    value={values.first_name}
                                                                    onChange={(e) => {
                                                                        setFieldValue('first_name', e.target.value)
                                                                        touched.first_name = true
                                                                    }}
                                                                    helperText={helperTextValue(errors, touched, 'first_name')}
                                                                    error={isErrorInField(errors, touched, 'first_name')}
                                                                    InputProps={{
                                                                        disableUnderline: true,
                                                                        style: {
                                                                            borderColor: borderColor(errors, touched, 'first_name'),
                                                                        },
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} md={6}>
                                                                <Typography className={classes.labelText}
                                                                >
                                                                   {i18next.t('customer.myProfile.lastName')}<Typography component={'span'} className={classes.required}> *</Typography>
                                                                </Typography>
                                                                <TextField
                                                                    fullWidth
                                                                    id="last_name"
                                                                    name="last_name"
                                                                    variant="standard"
                                                                    value={values.last_name}
                                                                    placeholder={i18next.t('customer.myProfile.last_name')}
                                                                    onChange={(e) => {
                                                                        touched.last_name = true
                                                                        setFieldValue('last_name', e.target.value)
                                                                    }}
                                                                    helperText={helperTextValue(errors, touched, 'last_name')}
                                                                    error={isErrorInField(errors, touched, 'last_name')}
                                                                    InputProps={{
                                                                        disableUnderline: true,
                                                                        style: {
                                                                            borderColor: borderColor(errors, touched, 'last_name'),
                                                                        },
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </Grid>

                                                        <Grid container spacing={3} justifyContent="space-between" style={{ marginTop: '8px' }}>
                                                            <Grid item xs={12} md={6}>
                                                                <Box display={'flex'} gridGap={'4px'} marginBottom={"10px"}>
                                                                    <Typography style={{ fontWeight: 500 }}>
                                                                    {i18next.t('customer.myProfile.birthdate')}
                                                                    </Typography>
                                                                    <Typography>
                                                                    ({i18next.t('customer.myProfile.optional')})
                                                                    </Typography>
                                                                </Box>
                                                                <DatePicker
                                                                    selected={values.birth_day}
                                                                    name="birth_day"
                                                                    onChange={(date) => setFieldValue('birth_day', date)}
                                                                    placeholderText={i18next.t('customer.myProfile.date_of_birth')}
                                                                    dateFormat={'dd MMM yyyy'}
                                                                    customInput={<DateOfBirthInput />}
                                                                    maxDate={new Date()}
                                                                    showYearDropdown
                                                                    showMonthDropdown
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} md={6}>
                                                                <SelectInput
                                                                    label={i18next.t('customer.myProfile.gender')}
                                                                    name="gender"
                                                                    value={values.gender}
                                                                    onChange={setFieldValue}
                                                                    error={errors.gender}
                                                                    touched={touched}
                                                                    options={[{ label: 'Male', value: 'Male' }, { label: 'Female', value: 'Female' }]}
                                                                    placeholder={i18next.t('customer.myProfile.selectGender')}
                                                                />
                                                            </Grid>
                                                        </Grid>

                                                        <Grid container spacing={4} justifyContent="space-between"
                                                            style={{ marginTop: '8px' }}>
                                                            <Grid item xs={12}>
                                                                <Typography className={classes.labelText} >
                                                                {i18next.t('customer.myProfile.emailAddress')}<Typography component={'span'} className={classes.required}> *</Typography>
                                                                </Typography>
                                                                <TextField
                                                                    fullWidth
                                                                    placeholder={i18next.t('customer.myProfile.email')}
                                                                    variant="standard"
                                                                    name="email"
                                                                    id="email"
                                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                        this.handleChangeEmail(e, setFieldValue, touched)
                                                                    }}
                                                                    error={isErrorInEmail(isEmailVerified, touched.email, errors.email)}
                                                                    helperText={helperTextValue(errors, { email: !isEmailVerified && touched.email }, 'email')}
                                                                    defaultValue={values.email}
                                                                    InputProps={{
                                                                        endAdornment: verifyEmailBtn(isEmailVerified, values.email, errors, setFieldError, this.handleVerfiyEmail),
                                                                        style: {
                                                                            borderColor: this.getBorderColor(isErrorInEmail,isEmailVerified, touched, errors)
                                                                        },
                                                                        disableUnderline: true,
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </Grid>


                                                        <Grid container spacing={4} justifyContent="space-between"
                                                            style={{ marginTop: '8px' }}>
                                                            <Grid item xs={12}>
                                                                <Typography className={classes.labelText} >
                                                                {i18next.t('customer.myProfile.myProfile')}<Typography component={'span'} className={classes.required}> *</Typography>
                                                                </Typography>
                                                                <TextField
                                                                    fullWidth
                                                                    id="mobileNumber"
                                                                    variant="standard"
                                                                    name="mobile_number"
                                                                    placeholder={i18next.t('customer.myProfile.mobile_number')}
                                                                    onChange={(e) => {
                                                                        setFieldValue('mobile_number', e.target.value)
                                                                        touched.mobile_number = true
                                                                    }}
                                                                    inputProps={{
                                                                        style: { paddingLeft: '16px' }
                                                                    }}
                                                                    value={values.mobile_number}
                                                                    error={isErrorInField(errors, touched, 'mobile_number')}
                                                                    helperText={helperTextValue(errors, touched, 'mobile_number')}
                                                                    InputProps={{
                                                                        startAdornment: (
                                                                            <TextField
                                                                                select
                                                                                variant="standard"
                                                                                name="selectedCountry"
                                                                                InputProps={{
                                                                                    style: {
                                                                                        borderColor: '#C3CAD9',
                                                                                        borderStyle: 'none solid none none',
                                                                                        borderWidth: 'medium 1px medium medium',
                                                                                        padding: '0px',
                                                                                        fontWeight: 500,
                                                                                        width: '125.76px',
                                                                                        borderRadius: '0px',
                                                                                    },
                                                                                    disableUnderline: true,
                                                                                }}
                                                                                SelectProps={{
                                                                                    MenuProps: {
                                                                                        transformOrigin: {
                                                                                            vertical: "top",
                                                                                            horizontal: "left",
                                                                                        },
                                                                                        anchorOrigin: {
                                                                                            vertical: "bottom",
                                                                                            horizontal: "left",
                                                                                        },
                                                                                        PaperProps: {
                                                                                            style: {
                                                                                                maxHeight: "250px",
                                                                                                maxWidth: "130px"
                                                                                            },
                                                                                        },
                                                                                        getContentAnchorEl: null,
                                                                                        MenuListProps: {
                                                                                            className: "countryCode-list-item",
                                                                                        },
                                                                                    },
                                                                                    classes: {
                                                                                        select: 'country-flag',
                                                                                    },
                                                                                }}
                                                                                onChange={(e) => setFieldValue('selectedCountry', e.target.value)}
                                                                                value={values.selectedCountry}
                                                                            >
                                                                                {this.state.countriesData.map((option) => (
                                                                                    <MenuItem
                                                                                        value={option.country_code}
                                                                                        key={option.country_code + option.name}
                                                                                        style={{ display: 'flex', justifyContent: 'space-between' }}
                                                                                        className="dropdown-menu-list">
                                                                                        {option.country_code}&nbsp;{<img
                                                                                            src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${option.id}.svg`}
                                                                                            style={{ borderRadius: '4px' }} className="flag-image"
                                                                                        />}
                                                                                    </MenuItem>
                                                                                ))}
                                                                            </TextField>
                                                                        ),
                                                                        style: {
                                                                            borderColor: borderColor(errors, touched, 'mobile_number')
                                                                        },
                                                                        className: 'inputField',
                                                                        disableUnderline: true,
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </Grid>

                                                        <Grid container spacing={4} justifyContent="space-between"
                                                            style={{ marginTop: '8px' }}>
                                                            <Grid item xs={12}>
                                                                <SelectInput
                                                                    label={i18next.t('customer.myProfile.nationality')}
                                                                    name="nationality_id"
                                                                    value={values.nationality_id}
                                                                    onChange={setFieldValue}
                                                                    error={errors.nationality_id}
                                                                    touched={touched}
                                                                    options={countryOption}
                                                                    placeholder={i18next.t('customer.myProfile.selectNationality')}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container spacing={4} justifyContent="space-between"
                                                            style={{ marginTop: '8px' }}>
                                                            <Grid item xs={12}>
                                                                <SelectInput
                                                                    label={i18next.t('customer.myProfile.countryOfResidence')}
                                                                    name="country_id"
                                                                    value={values.country_id}
                                                                    onChange={setFieldValue}
                                                                    error={errors.country_id}
                                                                    touched={touched}
                                                                    options={countryOption}
                                                                    placeholder={i18next.t('customer.myProfile.country')}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container style={{ marginTop: '48px', display: 'flex', gap: '64px', alignItems: 'center' }}>
                                                            <BootstrapButton type="submit">
                                                            {i18next.t('customer.myProfile.saveChanges')}
                                                            </BootstrapButton>
                                                            <Typography component={'span'} style={{ color: primaryColor, cursor: 'pointer' }} onClick={this.handleToggleEditBtn}>{i18next.t('customer.myProfile.cancel')}</Typography>
                                                        </Grid>
                                                    </Box>

                                                </form>
                                            }}
                                        </Formik>
                                    : userDetailBlock()
                                }
                            </Box>
                        </Card>
                    </Box>
                    {verifyEmailModal({ formattedTime: countDownTime, instance: this })}
                    <ResponseStatusModal
                        open={this.state.openStatusModal}
                        isSuccess={this.state.successIcon}
                        message={this.state.modalMsg}
                        onClose={this.closeStatusModal}
                    />
                </Box >
            </ThemeProvider >
            // Customizable Area End
        );
    }
}

// Customizable Area Start

export const customerProfileStyles = (theme: Theme) => ({
    container: {
        margin: '49px auto 120px',
        maxWidth: '90%',
        width: '100%',
        flexGrow: 1
    },
    cardWrapper: {
        padding: '48px',
        borderRadius: '16px',
        border: '0.5px solid rgba(33, 36, 38, 0.20)',
        background: '#FFF',
        boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.04)',
    },
    userProfileInfo: {
        maxWidth: '854px',
        height: '508px',
        margin: 'auto'
    },
    editProfileInfo: {
        height: '958px',
        maxWidth: '1074px',
        margin: 'auto'
    },
    heading: {
        color: '#212426',
        fontSize: '28px',
        fontWeight: 700,
        lineHeight: '100%',
    },
    editdetail: {
        color: primaryColor,
        fontWeight: 500,
        display: 'flex',
        gap: '8px',
        alignItems: 'center',
        cursor: 'pointer'
    },
    lightLabelText: {
        fontWeight: 500,
    },
    closeIcon: {
        position: 'absolute' as const,
        top: 0,
        right: 0,
        cursor: 'pointer',
        backgroundColor: 'white',
        borderRadius: '50%',
        padding: '2px',
        boxShadow: '0 0 3px rgba(0, 0, 0, 0.5)',
        fontSize: '21px'
    },
    valueText: {
        color: 'rgba(33, 36, 38, 0.90)',
        fontWeight: 500,
        height: '24px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap' as const
    },
    userImageBorder: {
        width: '124px',
        height: '124px',
        borderRadius: '59.704px',
        border: '1.851px solid #D9D9D9',
    },
    uploadImage: {
        height: '143px',
        width: '143px',
        borderRadius: '50%',
        border: '2.134px solid #D9D9D9',
    },
    required: {
        color: '#F00'
    },
    userImage: {
        height: '20px',
        width: '20px'
    },
    dividerStyle: {
        margin: '48px 0 72px'
    },
    labelText: {
        fontWeight: 500,
        marginBottom: '10px'
    },
    userProfile: {
        position: 'relative' as const,
        '&:before': {
            top: 0,
            right: 0,
            content: "''",
            width: '1px',
            height: '100%',
            position: 'absolute',
            background: `url(${verticalLine}) #FFF 50% / cover repeat-y`,
        }
    },
    detailsLeft: {
        width: '181px'
    },
    detailsRight: {
        width: '166px'
    },
    [theme.breakpoints.between(360.05, 768.05)]: {
        userProfileInfo: {
            height: 'auto'
        },
        userProfile: {
            height: 'auto'
        },
        userDetails: {
            flexWrap: 'wrap'
        },
        detailsLeft: {
            width: '262px'
        },
        detailsRight: {
            width: '262px'
        },
    },
})

const UserProfileCustomerWeb = withStyles(customerProfileStyles)(UserProfileCustomer)
export { UserProfileCustomerWeb }
// Customizable Area End
