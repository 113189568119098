import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import firebase from "firebase";

// Customizable Area Start
import { capitalizeFirstLetter, getAuthToken } from "../../../components/src/utils";
import ApiRequest from "../../../components/src/ApiRequest";
import { Categories } from "../../ProductDescription/src/Interface.web";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  bookingsData: any[];
  bookingReportPagination: any;
  bookingsTitle: any[];
  page: number;
  limit: string;
  selectedTripName: string;
  selectedTripType: string;
  selectedCountry: string;
  dateFrom: Date | null;
  dateTo: Date | null;
  seletedDateRange: string;
  countriesList: Categories[];
  tripTypeList: Categories[]; 
  isSmallScreen: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AnalyticsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiCallIdGetBookings: string = ""
  apiCallIdCountriesList: string = ""
  apiCallIdTripTypeList: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
    bookingsData: [],
    bookingReportPagination: {},
    bookingsTitle: [],
    page: 1,
    limit: '10',
    selectedTripName: '',
    selectedTripType: '',
    selectedCountry: '',
    seletedDateRange: '',
    dateFrom: null,
    dateTo: null,
    countriesList: [],
    tripTypeList: [],
    isSmallScreen: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
    if (firebase.apps.length !== 0) {
      const defaultAnalytics = firebase.app().analytics();
      defaultAnalytics.logEvent("Analytics::Web::Load");
    }
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    const apiRequestCallIds = {
      [this.apiCallIdCountriesList]: this.countryListApiResponse,
      [this.apiCallIdTripTypeList]: this.tripTypeApiResponse,
     [this.apiCallIdGetBookings]: this.getBookingsDataResponse,

      // Add more API call IDs and handlers as needed
    };

    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

    if (apiRequestCallId != null && apiRequestCallIds[apiRequestCallId]) {
      apiRequestCallIds[apiRequestCallId](responseJson, errorResponse);
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      // this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  async doButtonPressed() {
    const defaultAnalytics = firebase.app().analytics();
    defaultAnalytics.logEvent("Analytics::Web::button_clicked");
  }

  // Customizable Area Start

  // Lifecycle method
  async componentDidMount() {
    this.checkIfMobile()
    this.getBookingsDataRequest()
    this.getCountryListAPIRequest();
    this.getTripTypeAPIRequest();
    window.addEventListener('resize', this.checkIfMobile);
    this.clearDate()
  }
  
  // Request functions
  getCountryListAPIRequest = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = ApiRequest({
      header: header,
      endPoint: configJSON.getCountryListEndpoint,
      method: "GET",
    });
    this.apiCallIdCountriesList = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getTripTypeAPIRequest = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = ApiRequest({
      header: header,
      endPoint: configJSON.tripTypeEndpoint,
      method: "GET",
    });
    this.apiCallIdTripTypeList = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getBookingsDataRequest = async () => {
    const { page, selectedTripName, dateFrom, dateTo ,selectedTripType,selectedCountry} = this.state;
    const token = await getAuthToken()
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "Authorization": `Bearer ${token}`,
    };

    let queryParams: Record<string, string> = {}

    const addQueryParamIfNotEmptyValue = (paramName: string, paramValue: string | undefined) => {
      if (paramValue) {
        queryParams[paramName] = paramValue;
      }
    };

    // Add other query parameters
    addQueryParamIfNotEmptyValue('page', page.toString());
    addQueryParamIfNotEmptyValue('from', dateFrom?.toDateString());
    addQueryParamIfNotEmptyValue('to', dateTo?.toDateString());
    addQueryParamIfNotEmptyValue('any_trip', selectedTripName);
    addQueryParamIfNotEmptyValue('limit', '10');
    addQueryParamIfNotEmptyValue('trip_type_id', selectedTripType); 
    addQueryParamIfNotEmptyValue('country_id', selectedCountry); 


    const queryString = new URLSearchParams(queryParams).toString();
    const endpoint = `${configJSON.bookingsEndpoint}?${queryString}`;

    const requestMessage = ApiRequest({
      header: header,
      endPoint: endpoint,
      method: "GET",
    });
    this.apiCallIdGetBookings = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // Response functions
  countryListApiResponse = (responseJson: any, errorReponse: any) => {
    const countryDataArray = responseJson.country_data.data;
    if (countryDataArray) {
      const countryList = countryDataArray.map(
        (country: any) => {
          const attributes = country.attributes;
          const value = country.id;
          const label = attributes.name;
          return {value, label}
        }
      );

      this.setState({
        countriesList: countryList,
      });
    }
  }

  tripTypeApiResponse = (responseJson: any, errorReponse: any) => {
    const tripTypeData = responseJson.data;
    if (tripTypeData) {
      const tripTypeList = tripTypeData.map(
        (tripType: any) => {
          const value = tripType.id;
          const label = capitalizeFirstLetter(tripType.attributes.name);
          return {value, label};
        }
      );

      this.setState({
        tripTypeList: tripTypeList,
      });
    }
  }

  getBookingsDataResponse = (responseJson: any, errorResponse: any) => {
    if (responseJson?.bookings?.data) {
      const bookingsData = responseJson.bookings.data.map((booking: any) => ({
        trip_type: booking.attributes.trip_type,
        trip_name: booking.attributes.trip_title,
        country: booking.attributes.trip_country?.data?.attributes?.name || "",
        customer_name: booking.attributes.customer_name,
        trip_date: booking.attributes.trip_date,
        no_of_people: booking.attributes.no_of_people,
        amount: booking.attributes.total_price,
        navigation_id: booking.attributes.trip.id,
      }));

      const bookingTitles = responseJson?.trip_titles.map((title: string) => ({
        value: title,
        label: title,
      }));
  
      this.setState({
        bookingsData: bookingsData,
        bookingReportPagination: responseJson.bookings.page_options,
        bookingsTitle: bookingTitles
      });
    } else {
      this.setState({ bookingsData: [], bookingReportPagination: "" });
    }
  }
  

  
  // Internal functions
  handlePaginationChangeFBR = (e: any, value: number) => {  
    const pageNumber = Number(value);
    this.setState({
      page: pageNumber,
    }, () => this.getBookingsDataRequest())
  }

  handleFilterChange = (filterName: string, value: string) => {
    const newState: Partial<S> = {}; 
    
    if ((filterName === 'selectedCountry' || filterName === 'selectedTripType' || filterName === 'selectedTripName') && value !== 'Any') {
      newState[filterName] = value;
    } else if ((filterName === 'selectedCountry' || filterName === 'selectedTripType'  || filterName === 'selectedTripName') && value === 'Any') {
      newState[filterName] = '';
    }
        this.setState(newState as Pick<S, keyof S>, () => {
      this.getBookingsDataRequest();
    });
  };
  handleDateChange = (name: string, value: Date | null) => {
    if(name === "dateFrom"){
      this.setState({dateFrom: value})
    }else if(name === "dateTo") {
      this.setState({dateTo: value})
    }
    
  }

  renderDateRange = () => {
    const { dateFrom, dateTo } = this.state;
    // Format the start and end dates
    const startDateFormatted = dateFrom
        ? new Intl.DateTimeFormat('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
        }).format(dateFrom)
        : '';

    const endDateFormatted = dateTo
        ? new Intl.DateTimeFormat('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
        }).format(dateTo)
        : '';

    // Combine the formatted start and end dates
    const dateRange = `${startDateFormatted} - ${endDateFormatted}`;

    // Update the value prop of the Select component
    this.setState({seletedDateRange: dateRange})
};

clearDate = () => {
  this.setState({
    dateFrom: null,
    dateTo: null,
    seletedDateRange: ''
  },() => {
    this.getBookingsDataRequest();
  })
}

navigateToTripBookingList = (id: number) => {
  this.props.navigation.navigate("TripBookingList", {id: id})
}

checkIfMobile = () => {
  const isSmallScreen = window.innerWidth <= 960;
  this.setState({ isSmallScreen });
}
  // Customizable Area End
}
