import React from "react";
import {
    Select,
    MenuItem,
    Typography,
    FormControl,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { makeStyles } from '@material-ui/core/styles'
import { Categories } from "../../blocks/ProductDescription/src/Interface.web";
import i18next from 'i18next';


interface CustomSelectProps {
    fieldValues: Categories[];
    fieldName: string;
    selectedValue: string | null;
    fieldTouched: boolean;
    fieldError: string;
    setFieldValue: (field: string, value: any) => void; // Added setFieldValue prop
    planItineraryPlaceholderText?: boolean;
}





const CustomSelectField: React.FC<CustomSelectProps> = ({
    fieldValues,
    fieldName,
    selectedValue,
    fieldTouched,
    fieldError,
    setFieldValue,
    planItineraryPlaceholderText = false,
}) => {
    const useStyles = makeStyles((theme) => ({
        inputBlack: {
            color: "#212426E5",
            fontFamily: "Inter",
            fontSize: '16px',
            fontWeight: 500
        },
        inputGray: {
            color: planItineraryPlaceholderText ? "#A2A3A4" : "rgb(33 36 38 / 65%)",
            fontFamily: "Inter",
            fontSize: '16px',
            fontWeight: 500
        },
    }));
    const classes = useStyles();

    const handleFieldValueChange = (event: any) => {
        const selectedValue = event.target.value as string;
        if (!selectedValue) {
            setFieldValue(fieldName, null)
        }
        const value = fieldValues.find((val) => val.value === selectedValue);
        setFieldValue(fieldName, value?.value); // Use setFieldValue to update Formik's field value

    };


    return (
        <FormControl variant="outlined" fullWidth>
            <div style={{
                border: `1px solid`,
                borderColor: fieldError && fieldTouched ? 'red' : '#C3CAD9',
                borderRadius: '8px',
                padding: '11px 20px',
                height: '56px',
            }}>
                <Select
                    fullWidth
                    variant="standard"
                    IconComponent={KeyboardArrowDownIcon}
                    disableUnderline={true}
                    displayEmpty
                    inputProps={{
                        className:
                            selectedValue === ""
                                ? classes.inputGray
                                : classes.inputBlack,
                        style: {
                            backgroundColor: "#ffffff",
                            border: `none`,
                            fontFamily: "Inter",
                        },

                    }}
                    MenuProps={{
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                        },
                        transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                        },
                        getContentAnchorEl: null,
                        PaperProps: {
                            style: {
                                maxHeight: "250px",
                            },
                        },
                    }}
                    value={selectedValue ? selectedValue : ""}
                    onChange={handleFieldValueChange}
                >
                    <MenuItem value="" disabled style={{ fontFamily: "Inter", fontSize: '16px', fontWeight: 500 }}>
                    {i18next.t('itinerary.select_value')}
                    </MenuItem>
                    {fieldValues.map((val) => (
                        <MenuItem key={val.label}
                            value={val.value}
                            style={{ fontFamily: "Inter", fontSize: '16px', fontWeight: 500 }}
                        >
                            {val.label}
                        </MenuItem>
                    ))}
                </Select>
            </div>
            <Typography variant="caption" style={{ fontFamily: "Inter", fontWeight: 400, marginTop: '2.5px', color: '#FF0000', }}>
                {fieldTouched ? fieldError : ""}
            </Typography>
        </FormControl>
    );
};

export default CustomSelectField;
