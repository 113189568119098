Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Analytics";
exports.labelBodyText = "Analytics Body";

exports.btnExampleTitle = "CLICK ME";
exports.bookingsEndpoint = "bx_block_order_management/orders";
exports.getCountryListEndpoint = 'account_block/country_code_and_flags';
exports.tripTypeEndpoint = "bx_block_productdescription/trip_types";
exports.salesReportEndPoint = "bx_block_order_management/orders/sales_report";
exports.salesTitle = "Seles Report";
// Customizable Area End