import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEN from '../public/locals/en/translation.json'; 
import translationAR from '../public/locals/ar/translation.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next) 
  .init({
    fallbackLng: 'ar', 
    debug: true,
    interpolation: {
      escapeValue: false,
    },
    resources: {
      ar: {
        translation: translationAR
      },
      en: {
        translation: translationEN
      }
    },
  });
export default i18n;
