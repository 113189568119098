import React, { useState } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import { Box, TextField, List, ListItem, ListItemText } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { KeyboardArrowDown, KeyboardArrowRight, Search } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { CityData } from '../../blocks/email-account-registration/src/Interface.web';
import { capitalizeFirstLetter } from './utils';
import i18next from 'i18next';

interface CityFilterProps {
    city: string;
    cities: CityData[]; // Assuming list of city names is passed
    handleCityChange:(stateName: string, value: string) => void;
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    accordionPadding: {
        boxShadow: 'none',
    },
    accordionContentMargin: {
        '& .MuiAccordionSummary-content.Mui-expanded': {
            margin: '0'
        }
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
    },
    suggestionList: {
        border: '1px solid #ccc',
        borderRadius: '4px',
        boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
    },
}));

const CityFilter: React.FC<CityFilterProps> = ({ city: searchText, cities, handleCityChange }) => {
    const classes = useStyles();
    const [isAccordionExpanded, setIsAccordionExpanded] = useState(false);
    // const [searchText, setSearchText] = useState('');
    const [filteredCities, setFilteredCities] = useState<CityData[]>([]);

    const toggleAccordion = () => {
        setIsAccordionExpanded(!isAccordionExpanded);
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const text = event.target.value.trim();
        // setSearchText(text.charAt(0).toUpperCase() + text.slice(1));
        handleCityChange("city", capitalizeFirstLetter(text))
        const filtered = cities.filter(city => city.name.toLowerCase().includes(text.toLowerCase()));
        setFilteredCities(filtered);
    };

    const handleCitySelect = (city: CityData) => {
        // setSearchText(city.name);
        handleCityChange("city", city.name)
        handleCityChange("cityId", city.id)
        setFilteredCities([]);
    };

    const handleBlur = () => {
        setTimeout(() => {
            setFilteredCities([])
        }, 1000)
    }

    const isNoMatch = searchText !== '' && filteredCities.length === 0 && cities.every(city => !city.name.toLowerCase().includes(searchText.toLowerCase()));

    return (
        <div>
            <Box maxWidth={"300px"}>
                <Accordion className={classes.accordionPadding} expanded={isAccordionExpanded} onChange={toggleAccordion}>
                    <AccordionSummary className={classes.accordionContentMargin}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center', fontFamily: 'Inter' }}>
                            <Typography>{i18next.t('customer.advanceSearch.city')}</Typography>
                            <IconButton>
                                {isAccordionExpanded ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
                            </IconButton>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className={classes.container}>
                            <TextField
                                fullWidth
                                // variant="outlined"
                                placeholder={i18next.t('customer.advanceSearch.searchCity')}
                                value={searchText}
                                onChange={handleSearchChange}
                                onBlur={() => handleBlur()}
                                InputProps={{
                                    startAdornment: (
                                        <IconButton disabled>
                                            <Search />
                                        </IconButton>
                                    ),
                                    style: { border: '1px solid #ccc', borderRadius: '4px' },
                                    disableUnderline: true,
                                }}
                            />
                            <List className={classes.suggestionList} style={{ display: (searchText !== '' && filteredCities.length > 0) ? 'block' : 'none' }}>
                                {filteredCities.map(city => (
                                    <ListItem button key={city.id}>
                                        <ListItemText primary={city.name} onClick={() => handleCitySelect(city)} />
                                    </ListItem>
                                ))}
                                {isNoMatch && (
                                    <ListItem>
                                        <ListItemText primary="No city matches your search." />
                                    </ListItem>
                                )}
                            </List>
                        </div>
                    </AccordionDetails>
                </Accordion>
            </Box>
        </div>
    );
};

export default CityFilter;
