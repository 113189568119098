import React from "react";

// Customizable Area Start
import {
   Grid, Typography, Card, TableContainer,
   Table, TableBody, TableHead, TableCell, TableRow,
   CardMedia, Divider, CardActions
} from "@material-ui/core";
import { Rating } from "@material-ui/lab"
import { ChevronRight } from "./assets"
import { withStyles, Theme } from "@material-ui/core/styles";
import { capitalizeFirstLetter, ternaryCondition } from "../../../components/src/utils";
import { dataCard } from "../../visualanalytics/src/VisualAnalytics.web"
import i18next from 'i18next';

const columns = [
   { id: 'name', label: 'Name', minWidth: '200px' },
   { id: 'date', label: 'Date', maxWidth: '144px' },
   {
      id: 'tripTitle', label: 'Trip Title',
      maxWidth: '235px', style: {
         maxWidth: '235px',
         overflow: 'hidden',
         textOverflow: 'ellipsis',
      },
   },
   { id: 'country', label: 'Country', maxWidth: '144px' },
   { id: 'to', label: 'To', maxWidth: '144px' },
   { id: 'amount', label: 'Amount', maxWidth: '144px' },
   { id: 'bookingStatus', label: 'Booking Status', maxWidth: '172px', style: { width: '110px' } }
];



 type BookingStatus = 'completed' | 'cancelled' | 'closed' | 'payment_captured' | 'refunded' | 'created';

 const statusStyles: Record<BookingStatus, React.CSSProperties> = {
    completed: {
       background: 'rgb(194, 255, 211)',
       borderRadius: 5,
       textTransform: 'capitalize' as 'capitalize',
       padding: '0 30px'
    },
    cancelled: {
       background: 'rgb(251, 231, 233)',
       borderRadius: 5,
       textTransform: 'capitalize' as 'capitalize',
       padding: '0 30px'
    },
    closed: {
       background: 'rgb(255, 245, 186)',
       borderRadius: 5,
       textTransform: 'capitalize' as 'capitalize',
       padding: '0 30px'
    },
    payment_captured: {
       background: '#f4f4c1',
       borderRadius: 5,
       textTransform: 'capitalize' as 'capitalize',
       padding: '0 30px'
    },
    refunded: {
       background: 'rgb(237, 194, 251)',
       borderRadius: 5,
       textTransform: 'capitalize' as 'capitalize',
       padding: '0 30px'
    },
    created: {
      background: '#f4f4c1',
       borderRadius: 5,
       textTransform: 'capitalize' as 'capitalize',
       padding: '0 30px'
    }
 };
 



// Customizable Area End

import DashboardController, {
   Props
} from "./DashboardController";

export default class Dashboard extends DashboardController {
   constructor(props: Props) {
      super(props);
      // Customizable Area Start
      // Customizable Area End
   }

   // Customizable Area Start

   renderBookingStatus = (status: string) => {
      if (status in statusStyles) {
        return <div style={statusStyles[status as BookingStatus]}>{status}</div>;
      }
      return <div>{status}</div>
    }
   

   renderColumnContent = (columnId: string, row: any) => {
      switch (columnId) {
        case 'name':
          return (
            <div style={webStyle.flexContainers}>
              <img src={row?.attributes?.trip_image} alt='users' style={{ width: '50px', height: '50px', borderRadius: '50%' }} />
              <div style={webStyle.userName}>{row.attributes.customer_name}</div>
            </div>
          );
        case 'date':
          return (
            <div style={webStyle.flexContainer}>
              <div style={webStyle.userName}>{row.attributes.trip_date}</div>
            </div>
          );
        case 'tripTitle':
          return (
            <div style={webStyle.flexContainer}>
              <div style={webStyle.userName}>{row.attributes.trip_title}</div>
            </div>
          );
        case 'country':
          return (
            <div style={webStyle.flexContainer}>
              <div style={webStyle.userName}>
                {row?.attributes?.trip_country?.data?.attributes.name}
              </div>
            </div>
          );
        case 'to':
          return (
            <div style={webStyle.flexContainer}>
              <div style={webStyle.userName}>{capitalizeFirstLetter(row?.attributes?.to)}</div>
            </div>
          );
        case 'amount':
          return (
            <div style={webStyle.flexContainer}>
              <div style={webStyle.userName}>{row.attributes.price}</div>
            </div>
          );
        case 'bookingStatus':
          return this.renderBookingStatus(row.attributes.status);
        default:
          return null;
      }
    };

   bookingListTable = (classes: any, orders: any, handleNavigation: (id: number) => void, style?: any, loader?: any) => {
      return (
         <TableContainer>
            <Table stickyHeader aria-label='sticky table'>
               <TableHead>
                  <TableRow>
                     {columns.map((item, index, list) => {
                        let columnTitle = item.label;
   
                        switch (columnTitle) {
                          case 'Name':
                            columnTitle = i18next.t('travelAgency.home.name');
                            break;
                          case 'Date':
                            columnTitle = i18next.t('travelAgency.home.date');
                            break;
                          case 'Trip Title':
                            columnTitle = i18next.t('travelAgency.home.tripTitle');
                            break;
                          case 'Country':
                            columnTitle = i18next.t('travelAgency.home.country');
                            break;
                          case 'To':
                            columnTitle = i18next.t('travelAgency.home.to');
                            break;
                          case 'Amount':
                            columnTitle = i18next.t('travelAgency.home.amount');
                            break;
                          case 'Booking Status':
                            columnTitle = i18next.t('travelAgency.home.bookingStatus');
                            break;
                          default:
                            // Retain the original label if no case matches
                            columnTitle = item.label;
                        }
                        
   
                        return (
                           <TableCell
                              style={{
                                 ...webStyle.tableCell,
                                 maxWidth: item.maxWidth,
                                 ...ternaryCondition(
                                    index == 0,
                                    {
                                       borderRadius: '8px 0 0 8px',
                                       textAlign: 'left',
                                       paddingLeft: '32px',
                                    },
                                    ternaryCondition(
                                       index == list.length - 1,
                                       {
                                          borderRadius: '0 8px 8px 0',
                                          textAlign: 'end',
                                          paddingRight: '32px',
                                       },
                                       {}
                                    )
                                 ),
                                 ...style,
                              }}
                              key={item.id}
                              align={'center'}
                           >
                              {columnTitle}
                           </TableCell>
                        )
                     })}
                  </TableRow>
               </TableHead>
               <TableBody className={classes.tableBody} data-test-id="orderList">
                  {
                     /* istanbul ignore next */
                     orders && orders?.length && orders?.length ? orders.map((row: any, index: number) => {
                       
                        return (
                           <TableRow hover role='checkbox' tabIndex={-1} key={index} data-test-id={`tablerow-${index}`}
                              onClick={() => handleNavigation(row.attributes.id)} style={{ cursor: 'pointer' }}>
                              {columns.map((column, index) => {
                                 return (
                                    <TableCell
                                       key={index}
                                       align={'center'}
                                       style={{
                                          ...webStyle.tableRowCell,
                                          ...style
                                       }}
                                    >
                                {this.renderColumnContent(column.id, row)}
   
                                    </TableCell>
                                 );
                              })}
   
                           </TableRow>
                        );
                     })
                        : <TableRow>
                           <TableCell style={{ borderBottom: 'none' }} align={'center'}
                              colSpan={columns.length}>
                              {i18next.t('travelAgency.home.noAvailableBookings')}
                           </TableCell>
                        </TableRow>}
               </TableBody>
            </Table>
         </TableContainer>
      )
   }

   tripsList = (classes: any) => {
      return (
         <Card style={webStyle.cardWrapper} data-testid="TripList">
            <Typography variant="h3" style={{ ...webStyle.tableTitle, paddingBottom: '24px' }}>{i18next.t('travelAgency.home.Toptrips')}</Typography>
            <Divider />
            {this.state.tripList.length > 0
               ? <>
                  {this.state.tripList.map((trip) => {
                     const { attributes: {
                        title, trip_type, primary_image_url, city,
                        country, total_rating, average_rating, trip_details: { data: { id: trip_id } }
                     } } = trip
                     return <React.Fragment key={trip.id} >
                        <div className={classes.root} onClick={() => this.hanldeNavigateToTrips(trip_id)} data-testid='tripContainer'>
                           <CardMedia
                              className={classes.media}
                              image={primary_image_url}
                              title={title}
                           />
                           <div style={webStyle.content}>
                              <Typography component="span" style={webStyle.tripBoldText}>
                                 {title}
                              </Typography>
                              <div style={webStyle.tripRow}>
                                 <Typography variant="subtitle1" color="textSecondary" style={webStyle.tripTitle}>
                                    {i18next.t('travelAgency.home.types')}
                                 </Typography>
                                 <Typography variant="subtitle1" color="textSecondary" style={webStyle.tripValue}>
                                    {trip_type}
                                 </Typography>
                              </div>
                              <div style={webStyle.tripRowWrapper}>
                                 <div style={webStyle.tripRow}>
                                    <Typography variant="subtitle1" color="textSecondary" style={webStyle.tripTitle}>
                                       {i18next.t('travelAgency.home.country')}
                                    </Typography>
                                    <Typography variant="subtitle1" color="textSecondary" style={webStyle.tripValue}>
                                       {country}
                                    </Typography>
                                 </div>
                                 <div style={webStyle.tripRow}>
                                    <Typography variant="subtitle1" color="textSecondary" style={webStyle.tripTitle}>
                                       {i18next.t('travelAgency.home.city')}
                                    </Typography>
                                    <Typography variant="subtitle1" color="textSecondary" style={webStyle.tripValue}>
                                       {city}
                                    </Typography>
                                 </div>
                              </div>
                              <div style={webStyle.rating}>
                                 <Typography variant="subtitle1" color="textSecondary" style={webStyle.tripTitle}>
                                    {i18next.t('travelAgency.home.rating')}
                                 </Typography>
                                 <Rating
                                    name={`rating-${trip.id}`}
                                    value={average_rating}
                                    precision={0.1}
                                    readOnly
                                    size="small"
                                    style={{
                                       color: "#FE7F2D"
                                    }}
                                 />
                                 <Typography variant="body2" color="textSecondary" style={{
                                    color: 'rgba(33, 36, 38, 0.90)',
                                    fontFamily: 'Inter',
                                    fontSize: '14px',
                                    fontStyle: 'normal',
                                    fontWeight: 500,
                                    lineHeight: '150%',
                                    marginLeft: '-4px'
                                 }}>
                                    {average_rating % 1 === 0 ? average_rating : Math.floor(average_rating * 10) / 10} ({total_rating})
                                 </Typography>
                              </div>
                           </div>
                        </div>
                        <Divider style={{ margin: '0 24px' }} />
                     </React.Fragment>
                  })}
                  <CardActions style={{ padding: 1.25, paddingTop: 0, justifyContent: 'center' }}>
                     <Typography variant='subtitle2' data-testId={'seeAllTrip'} style={{
                        color: '#FE7F2D',
                        fontFamily: 'Inter',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: 700,
                        lineHeight: '150%',
                        display: 'flex',
                        justifyContent: 'center',
                        gap: '7px',
                        alignItems: 'center',
                        padding: '24px',
                        cursor: 'pointer'
                     }} onClick={this.seeAllTrips} data-testid={'seeAllBooking'}>
                        {i18next.t('travelAgency.home.seeAllTrips')} <img src={ChevronRight} />
                     </Typography>
                  </CardActions>
               </>
               : <Typography style={{ ...webStyle.tripBoldText, textAlign: 'center', padding: '20px' }}>
                  {i18next.t('travelAgency.home.noAvailableTrips')}
               </Typography>
            }
         </Card>
      )
   }

   bookingTable = (classes: any, orders: any) => (
      <Card style={webStyle.cardWrapper} data-testid="bookingList">
         <Grid container spacing={3}>
            <Grid item xs={12}>
               <Typography variant='subtitle2' style={webStyle.tableTitle}>
                  {i18next.t('travelAgency.home.recentBookings')}
               </Typography>
            </Grid>
            <Grid item xs={12} style={webStyle.table}>
               {this.bookingListTable(classes, orders, this.goToBookingDetails, {
                  textAlign: 'center', padding: '12px 16px 11px'
               }, this.state?.loader)}
            </Grid>
            {
               /* istanbul ignore next */
               orders && orders?.length > 0 && <><Divider style={{ width: '100%', marginTop: '12px', marginBottom: '12px' }} />
                  <Grid item xs={12}>
                     <Typography style={{
                        color: '#FE7F2D',
                        fontFamily: 'Inter',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: 700,
                        lineHeight: '150%',
                        display: 'flex',
                        justifyContent: 'center',
                        gap: '7px',
                        alignItems: 'center',
                        paddingBottom: '24px',
                        cursor: 'pointer'
                     }} data-testId={'seeAllBooking'} onClick={this.seeAllBooking}>
                        {i18next.t('travelAgency.home.seeAllBookings')}<img src={ChevronRight} />
                     </Typography>
                  </Grid>
               </>
            }
         </Grid>
      </Card>
   )


   // Customizable Area End

   render() {
      // Customizable Area Start
      const { dashboardData, orders } = this.state
      const { classes } = this.props;
      // Customizable Area End
      return (
         // Customizable Area Start
         <Grid container spacing={3} data-testid="dashboardWrapper" className={classes.dashboardWrapper}>
            <Grid item xs={12} lg={8} style={webStyle.containerLeft}
               className={classes.containerLeft}>
               <Grid item>
                  <Grid container className={classes.allDesign} spacing={3} >
                     {dataCard({ title: i18next.t('travelAgency.home.activeTrips'), count: dashboardData.active_trips })}
                     {dataCard({ title: i18next.t('travelAgency.home.bookings'), count: dashboardData.total_booking })}
                     {dataCard({ title: i18next.t('travelAgency.home.rating'), total: dashboardData.total_rating, rating: dashboardData.average_rating })}
                  </Grid>
               </Grid>
               <Grid item xs={12} >
                  {this.bookingTable(classes, orders)}
               </Grid>
            </Grid>
            <Grid item xs={12} lg={4} style={{ display: 'flex', justifyContent: 'center' }} className={classes.containerRight} >
               {this.tripsList(classes)}
            </Grid>
         </Grid>
         // Customizable Area End
      );
   }
}

// Customizable Area Start
const webStyle = {
   containerLeft: {
      display: 'flex',
      gap: '24px',
      flexDirection: "column" as "column"
   },
   tableTitle: {
      color: '#212426',
      fontFamily: 'Inter',
      fontSize: '23px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '150%',
      padding: '24px 0 0 24px'
   },
   tableCell: {
      borderBottom: 'none',
      backgroundColor: '#EAF7FF',
      color: 'rgba(33, 36, 38, 0.40)',
      textAlign: 'center' as 'center',
      fontFamily: 'Inter',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: 'normal',
      textTransform: 'uppercase' as 'uppercase',
      whiteSpace: 'nowrap' as 'nowrap',
      padding: '12px 16px 11px'
   },
   tableRowCell: {
      borderBottom: 'none',
      color: 'rgba(33, 36, 38, 0.80)',
      textAlign: 'center' as 'center',
      fontFamily: 'Inter',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '26px',
      whiteSpace: 'nowrap' as 'nowrap',
      padding: '15px 16px'
   },
   Cancelled: {
      background: 'rgb(251, 231, 233)',
      borderRadius: 5,
      textTransform: 'capitalize' as 'capitalize',
      padding: '0 30px'
   },
   Confirmed: {
      background: 'rgb(194, 255, 211)',
      borderRadius: 5,
      textTransform: 'capitalize' as 'capitalize',
      padding: '0 30px'
   },
   Removed: {
      background: 'rgb(255, 245, 186)',
      borderRadius: 5,
      textTransform: 'capitalize' as 'capitalize',
      padding: '0 30px'
   },
   Completed: {
      background: 'rgb(230, 230, 230)',
      borderRadius: 5,
      textTransform: 'capitalize' as 'capitalize',
      padding: '0 30px'
   },
   Refunded: {
      background: 'rgb(237, 194, 251)',
      borderRadius: 5,
      textTransform: 'capitalize' as 'capitalize',
      padding: '0 30px'
   },
   paymentCaptured: {
      background: '#f4f4c1',
      borderRadius: 5,
      textTransform: 'capitalize' as 'capitalize',
      padding: '0 30px'
   },
   userName: {
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '26px',
      fontStyle: 'normal',
      fontFamily: 'Inter',
      whiteSpace: 'nowrap' as 'nowrap',
      letterSpacing: '0.1px',
      maxWidth: '119px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      color: 'rgba(33, 36, 38, 0.80)',
   },
   flexContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '16px',
      width: '200px',
      justifyContent: 'center',

   },
   flexContainers: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'flex-start',
      gap: '16px',
      width: '200px'
   },
   statusValue: {
      color: '#222',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '14px',
      borderRadius: '6px',
      padding: '4px 12px',
      width: '96px',
      margin: 'auto'
   },
   cardWrapper: {
      borderRadius: '20px',
      boxShadow: 'none',
      width: '100%'
   },
   cardWrapperTrips: {
      borderRadius: '20px',
      boxShadow: 'none',
      width: '100%'
   },
   table: {
      padding: '0 24px',
      marginTop: '12px'
   },
   uploadImg: {
      height: '24px',
      width: '24px'
   },

   content: {
      justifyContent: 'center',
      display: 'flex',
      flexDirection: 'column' as 'column'
   },

   rating: {
      display: 'flex',
      alignItems: 'center',
      gap: '12px'
   },
   tripBoldText: {
      color: '#212426',
      fontFamily: 'Inter',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '150%',
      marginBottom: '4px',
   },
   tripTitle: {
      color: 'rgba(33, 36, 38, 0.90)',
      fontFamily: 'Inter',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '150%',
   },
   tripValue: {
      color: '#212426',
      fontFamily: 'Inter',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '150%',
   },
   tripRow: {
      display: 'flex',
      gap: '12px',
      marginBottom: '8px'
   },
   tripRowWrapper: {
      display: 'flex',
      gap: '0 12px',
      marginBottom: '8px',
      flexWrap: 'wrap' as 'wrap',
      justifyContent: 'space-between'
   },

};

export const styles = (theme: Theme) => ({
   root: {
      display: 'flex',
      gap: '12px',
      padding: '24px',
      cursor: 'pointer'
   },
   media: {
      width: 143,
      height: 143,
      borderRadius: '16px',
      objectFit: 'cover' as 'cover',
      minWidth: '143px'
   },
   containerRight: {
      '@media (min-width: 1920px)': {
         flexGrow: 0,
         maxWidth: '30%',
         flexBasis: '30%',
      }
   },
   containerLeft: {
      '@media (min-width: 1920px)': {
         flexGrow: 0,
         maxWidth: '70%',
         flexBasis: '70%',
      }
   },

   tableBody: {
      '& tr:first-child td': {
         paddingTop: '19px !important'
      }
   },
   [theme.breakpoints.down(768.05)]: {
      dashboardWrapper: {
         padding: '1.5px 24px 96px 48px'
      }
   },
   [theme.breakpoints.down(1280)]: {
      allDesign: {
         padding: '0 20px 0 0'
      },

   },
   [theme.breakpoints.between(1280, 1540)]: {
      root: {
         display: 'block'
      },
      media: {
         width: '100%',
         marginBottom: '10px'
      }
   },
})

const DashboardWeb = withStyles(styles)(Dashboard);
export { DashboardWeb };
// Customizable Area End
