import React from "react";
import {
    Select,
    MenuItem,
    Typography,
    FormControl,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { makeStyles } from '@material-ui/core/styles'
import { CityData } from "../../blocks/email-account-registration/src/Interface.web";
import { useWindowWidth } from "./utils";
import i18next from 'i18next';


interface CitySelectProps {
    cities: CityData[];
    selectedCity: string | null;
    cityTouched: boolean;
    cityError: string;
    setFieldValue: (field: string, value: any) => void; // Added setFieldValue prop
    isDashboard?: boolean;
    isDisable?: boolean;
}





const CitySelect: React.FC<CitySelectProps> = ({
    cities,
    selectedCity,
    cityTouched,
    cityError,
    setFieldValue, // Received setFieldValue prop
    isDashboard = false,
    isDisable = false,
}) => {
    const useStyles = makeStyles((theme) => ({
        inputBlack: {
          color: "#212426",
          fontFamily: "Inter",
          fontSize: '16px',
          fontWeight: 500
        },
        inputGray: {
          color: "#A2A3A4",
          fontFamily: "Inter",
          fontSize: '16px',
          fontWeight: 500
        },
        fieldText: {
            fontFamily: "Inter",
            fontSize: isDashboard ? '18px' : "16px",
            color: isDashboard ? 'rgba(33, 36, 38, 0.90)' : "rgb(33 36 38 / 65%)",
            marginBottom: isDashboard ? '16px' : "8px",
            height: '24px',
            fontWeight: 500
        },
        itemText: {
            fontFamily: "Inter", 
            fontSize: '16px', 
            fontWeight: 500
        },
        [theme.breakpoints.down(1024)]: {
            fieldText: {
                fontFamily: "Inter",
                fontSize: "16px",
                color: isDashboard ? 'rgba(33, 36, 38, 0.90)' : "rgb(33 36 38 / 65%)",
                marginBottom: isDashboard ? '12px' : "8px",
                fontWeight: 500
            },
            itemText: {
                fontFamily: "Inter", 
                fontSize: '14px', 
                fontWeight: 500
            },
        },
        [theme.breakpoints.down(768)]: {
            fieldText: {
                fontFamily: "Inter",
                fontSize: "14px",
                color: isDashboard ? 'rgba(33, 36, 38, 0.90)' : "rgb(33 36 38 / 65%)",
                marginBottom: "8px",
                fontWeight: 500
            },
            itemText: {
                fontFamily: "Inter", 
                fontSize: '14px', 
                fontWeight: 500
            },
            inputBlack: {
                color: "#212426E5",
                fontFamily: "Inter",
                fontSize: '14px',
                fontWeight: 500
              },
              inputGray: {
                color: "#A2A3A4",
                fontFamily: "Inter",
                fontSize: '14px',
                fontWeight: 500
              },
        }
      }));
    const classes = useStyles();
    const windowWidth = useWindowWidth();

  const isMobile = windowWidth < 768;

    const handleCityChange = (event: any) => {
        const cityName = event.target.value as string;
        if (!cityName) {
            setFieldValue("city", null)
        }
        const city = cities.find((city) => city.name === cityName)?.name;
        setFieldValue("city", city); // Use setFieldValue to update Formik's field value
    };


    return (
        <FormControl variant="outlined" fullWidth>
            <Typography
                className={classes.fieldText}
            >
                {i18next.t('travelAgency.editTrip.city')}<span style={{ color: 'red' }}> *</span>
            </Typography>
            <div style={{
                border: `1px solid`,
                borderColor: cityError && cityTouched ? 'red' : '#C3CAD9',
                borderRadius: '8px',
                padding: '11px 20px',
                height: isMobile ? '48px' : '56px',
                display: 'flex'
            }}>
                <Select
                    fullWidth
                    variant="standard"
                    IconComponent={KeyboardArrowDownIcon}
                    disableUnderline={true}
                    displayEmpty
                    disabled={isDisable}
                    style={{border: 'none', paddingLeft: '0px'}}
                    inputProps={{
                        className:
                            selectedCity === ""
                                ? classes.inputGray
                                : classes.inputBlack,
                        style: {
                            backgroundColor: "#ffffff",
                            // color: selectedCity === null ? "rgb(33 36 38 / 65%)" : "black",
                            border: `none`,
                            fontFamily: "Inter",
                        },

                    }}
                    MenuProps={{
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                        },
                        transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                        },
                        getContentAnchorEl: null,
                        PaperProps: {
                            style: {
                                maxHeight: "250px",
                            },
                        },
                    }}
                    value={selectedCity ? selectedCity : ""}
                    onChange={handleCityChange}
                >
                    <MenuItem value="" disabled className={classes.itemText}>
                    {i18next.t('travelAgency.editTrip.selectCity')}
                    </MenuItem>
                    {cities.map((city) => (
                        <MenuItem key={city.id} 
                        value={city.name}
                        className={classes.itemText}
                        >
                            {city.name}
                        </MenuItem>
                    ))}
                </Select>
            </div>
            <Typography variant="caption" style={{fontFamily: "Inter", fontWeight: 400, marginTop: '2.5px', color: '#FF0000',}}>
                {cityTouched ? cityError : ""}
            </Typography>
        </FormControl>
    );
};

export default CitySelect;
