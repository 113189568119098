// Customizable Area Start
import React from "react";
import {
    Stepper,
    Step,
    StepLabel,
    StepConnector,
    Divider,
    Container,
  } from "@material-ui/core";
  import { PersonOutline, Business, AccountBalance, Check, Done } from "@material-ui/icons"; // Import Material-UI icons
  import "./stepper.css";
  import { withStyles } from '@material-ui/core/styles';
  import PersonalDetails from "../../../components/src/PersonalDetails";
  import VerifyModal from "../../../components/src/VerifyModal";
  import EntityDetails from "../../../components/src/EntityDetails";
  import BusinessDetails from "../../../components/src/BusinessDetails";

import TravelAgencyRegistrationController, {
  Props,
} from "./TravelAgencyRegistrationController";
import SuccessFulSignUp from "../../../components/src/SuccessFulSignUp";
import ColorlibStepIcon from "../../../components/src/ColorlibStepIcon";
import TravelBuddyEntityDetails from "../../../components/src/TravelBuddySteps/TravelBuddyEntityDetails.web";
import Loader from "../../../components/src/Loader.web";
import i18next from "i18next";

const steps = [
    { label: "Personal Details", icon: <PersonOutline />, completedIcon: <Check /> },
    { label: "Entity Details", icon: <Business />, completedIcon: <Check /> },
    { label: "Business Details", icon: <AccountBalance />, completedIcon: <Done /> },
  ];

  const StyledStepLabel = withStyles({
    label: {
      fontWeight: 500,
      fontSize: '14px',
      fontFamily: "Inter", // Default font weight
      "&.MuiStepLabel-active, &.MuiStepLabel-completed": {
        fontWeight: 700, // Apply bold font weight for active and completed steps
        fontFamily: "Inter",
        fontSize: '14px',
        color: 'rgba(33, 36, 38, 0.90)'
      },
    },
  })(StepLabel);
  
  const ColorlibConnector = withStyles({
    alternativeLabel: {
      top: 22,
    },
    active: {
      '& $line': {
      width: '100%',
      height: '2px',
      /* dashed border */
      border: 'none',
      backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23F5950B' stroke-width='4' stroke-dasharray='6%2c 13' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`
      },
    },
    completed: {
      '& $line': {
      width: '100%',
      height: '2px',
      /* dashed border */
      border: 'none',
      backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23F5950B' stroke-width='4' stroke-dasharray='6%2c 13' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`
    
      },
    },
    line: {
      width: '100%',
      height: '2px',
      /* dashed border */
      border: 'none',
      marginTop: '2px',
      backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23212426A6' stroke-width='4' stroke-dasharray='6%2c 13' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`
    },
    
  })(StepConnector);
  
  
export default class TravelAgencyRegistration extends TravelAgencyRegistrationController {

  constructor(props: Props) {
    super(props);
  }

  render() {

    const {activeStep, 
      openVerifyModal, isResendClick,
      enableResendCode, otp, resendCount, 
      timer, email, successScreen, 
      mobile, modalForEmail,
      countryList, resetTimer,
      personalDetailsValues,
      entityDetailsValues,
      businessDetailsValues,
      buddyEntityDetailsValues,
      errorMsg, otpErrorMsg,
      phoneErrorMsg, isEmailVarified,
      isPhoneVarified, emailErrorMsg,
      loading, cityList
    } = this.state;



    return (
      <Container maxWidth="lg" className="container-wrapper" style={{ flexGrow: 1,marginTop: '22px',
      ...successScreen ? {justifyContent: 'center', alignItems: 'center', display: 'flex'} : {} }}>
        <div>
          <Loader loading={loading} />
        <VerifyModal
        open={openVerifyModal}
        closeOpenVerifyModal={this.closeOpenVerifyModal}
        otp={otp}
        isResendClick={isResendClick}
        handleOtpChange={this.handleValueChange}
        enableResendCode={enableResendCode}
        handleResendCode={this.handleResendCode}
        sendOtpRequest={this.sendEmailOrSmsOtpRequest}
        verifyEmailOrSmsOtpRequest={this.verifyEmailOrSmsOtpRequest}
        resendCount={resendCount}
        timer={timer}
        resetTimer={resetTimer}
        email={email}
        mobile={mobile}
        modalForEmail={modalForEmail}
        otpErrorMsg={otpErrorMsg}
        />
          {
            !successScreen ?
              <>
                <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
                  {steps.map((step) => {
                    let tabTitle = step.label;
                    switch (tabTitle) {
                      case 'Personal Details':
                        tabTitle = i18next.t('travelAgency.myProfile.personalDetails');
                        break;
                      case 'Entity Details':
                        tabTitle = i18next.t('travelAgency.myProfile.entityDetails');
                        break;
                      case 'Business Details':
                        tabTitle = i18next.t('travelAgency.myProfile.businessDetails');
                        break;
                      default:
                        tabTitle = step.label;
                    }

                    return (
                      <Step key={step.label}>
                        <StyledStepLabel StepIconComponent={ColorlibStepIcon}>
                          {tabTitle}
                        </StyledStepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
                <Divider variant="middle" />

                {
                  activeStep === 0 &&
                  <PersonalDetails
                  sendEmailOrSmsOtpRequest={this.sendEmailOrSmsOtpRequest}
                    handleValueChange={this.handleValueChange}
                    email={email}
                    mobile={mobile}
                    countryData={countryList}
                    initialState={personalDetailsValues}
                    submitPersonalDetails={this.submitPersonalDetails}
                    errorMsg = {errorMsg}
                    isEmailVarified={isEmailVarified}
                    isPhoneVarified={isPhoneVarified}
                    phoneErrorMsg = {phoneErrorMsg}
                    emailErrorMsg = {emailErrorMsg}
                    isEditClicked= {false}
                    traveleAgencyEditRequest={()=>{}}
                    handleBack={()=>{}}
                  />
                }
                {
                  activeStep === 1 &&(
                    personalDetailsValues.service_provider_type == 'agency'
                    ? <EntityDetails
                      agency_logo="something"
                      initialState={entityDetailsValues}
                      submitEntityDetails={this.submitEntityDetails}
                      handleBack={this.handleEntityBack}
                      isEditClicked= {false}
                      traveleAgencyEditRequest={()=>{}}

                    />
                    : <TravelBuddyEntityDetails
                      initialState={buddyEntityDetailsValues}
                      submitEntityDetails={this.submitBuddyEntityDetails}
                      handleBack={this.handleBuddyEntityBack}
                      isEditClicked= {false}
                      traveleAgencyEditRequest={()=>{}}

                    />
                  )
                 
                }
                {
                  activeStep === 2 &&
                  <BusinessDetails
                  countries={countryList}
                  cities={cityList}
                  getCityListRequest={this.getCityListRequest}
                  initialState={businessDetailsValues}
                  submitBusinessDetails={this.submitBusinessDetails}
                  handleBack={this.handleBusinessBack}
                  errorMsg={errorMsg}
                  isEditClicked= {false}
                  traveleAgencyEditRequest={()=>{}}

                  />
                }
              </>
              :
              <SuccessFulSignUp navigation={this.props.navigation}/>
          }
        </div>
      </Container>
    );
  }
}
// Customizable Area End
