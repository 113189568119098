import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { Calendar, momentLocalizer, ToolbarProps } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./BigCalendar.css";
import CustomToolbar from "./BCustomToolbar";
import { EventI } from "../../blocks/scheduling/src/Interface.web";
import { primaryColor } from "./theme";

moment.locale("en-GB");
const localizer = momentLocalizer(moment);


interface BigCalendarProps {
  allEvents: EventI[];
  handleDrillDown: (date: Date, view: string) => void;
  navigateToTripDetails: (id: string) => void;
  selectedData: string[];
}


const useStyles = makeStyles((theme) => ({
  inputBlack: {
    color: "#212426E5",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 500,
  },
  inputGray: {
    color: "rgb(33 36 38 / 65%)",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 500,
  },
  calendarHeight: {
    height: "100%",
    maxWidth: "100vw",
  },
  whiteContainer: {
    padding: "15px 15px 23px 15px",
    borderRadius: "12px",
  },
  seeMoreButton: {
    cursor: "pointer",
    color: primaryColor,
  },
  dateHeaderText: {
    margin: '12px',
    width: '30px',
    height: '30px',
    fontFamily: 'Inter',
    fontSize: '21px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    textAlign: 'center', // Center the text
    position: 'relative', // Ensure text is above the circle
    zIndex: 2, // Ensure the text is above the circle
  },
  dateHeaderCircle: {
    position: 'absolute',
    top: -2,
    left: 12,
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    zIndex: 1, // Ensure the circle is above the text
  },
  // Responsive font sizes and styles
  '@media (max-width: 767px)': {
    inputBlack: {
      fontSize: "14px",
    },
    inputGray: {
      fontSize: "14px",
    },
    calendarHeight: {
      height: "calc(100vh - 100px)", // Adjust height for smaller screens
    },
    whiteContainer: {
      padding: "10px", // Adjust padding for smaller screens
    },
    dateHeaderText: {
      fontSize: '14px',
      margin: '0px',
      height: '20px',
      width: '20px'
    },
    dateHeaderCircle: {
      top: 0,
      left: 0,
      width: '21px',
      height: '21px',
    },
  },
  '@media (min-width: 768px) and (max-width: 1023px)': {
    inputBlack: {
      fontSize: "15px",
    },
    inputGray: {
      fontSize: "15px",
    },
    dateHeaderText: {
      fontSize: '18px'
    }
  },
}));

const BigCalendarComponent: React.FC<BigCalendarProps> = ({
  allEvents,
  handleDrillDown,
  navigateToTripDetails,
  selectedData,
}) => {
  const classes = useStyles();
  const [eventsData, setEventsData] = useState([{
    'id': '',
    'title': 'All Day Event very long title',
    // 'allDay': true,
    'start': new Date(2015, 3, 0),
    'end': new Date(2015, 3, 1)
  }]);
  const [defaultDate, setDefaultDate] = useState<Date[]>([new Date()]);

  useEffect(() => {
    setEventsData(allEvents);
    if (selectedData && selectedData.length > 0) {
      const dateObjects = selectedData.map(dateString => new Date(dateString));
      setDefaultDate(dateObjects);
    } else {
      setDefaultDate([new Date()]);
    }
  }, [allEvents, selectedData]);

  const handleNavigation = (event: any) => {
    if (event.id) {
      navigateToTripDetails(event.id);
    }
  };



  return (
    <div
      className={classes.whiteContainer}
      style={{
        width: '100%',
        minWidth: "328px",
        height: '105vh',
        backgroundColor: '#fff',
        border: '0.5px solid rgba(33, 36, 38, 0.20)',
        boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.04)'
      }}>
      <div className={classes.calendarHeight}>
        <Calendar
          views={["day", "agenda", "work_week", "month"]}
          selectable
          localizer={localizer}
          defaultDate={defaultDate[0]}
          date={defaultDate[0]}
          onNavigate={date => setDefaultDate([date])}
          defaultView="month"
          events={eventsData}
          style={{ height: "100vh" }}
          // onShowMore={handleDrillDown}
          onSelectEvent={(event) => handleNavigation(event)}
          // onSelectSlot={handleSelect}
          onDrillDown={handleDrillDown}
          popup={false}
          // onView={handleViewChange}
          tooltipAccessor={null}
          components={{
            toolbar: CustomToolbar as React.ComponentType<ToolbarProps>, // Use the custom toolbar component
            month: {
              dateHeader: (props) => {
                return (
                  <div style={{ position: 'relative' }}>
                    <div
                      style={{
                        backgroundColor: defaultDate.some(date => date.toDateString() === props.date.toDateString()) ? primaryColor : 'transparent',
                      }}
                      className={classes.dateHeaderCircle}
                    />
                    <p
                      style={{
                        color: defaultDate.some(date => date.toDateString() === props.date.toDateString()) ? '#FFF' : 'rgba(33, 36, 38, 0.75)',
                      }}
                      className={classes.dateHeaderText}
                    >
                      {parseInt(props.label)}
                    </p>
                  </div>
                );
              },
            },
          }}
        />
      </div>
    </div>
  );
};

export default BigCalendarComponent;
