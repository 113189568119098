export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const verticalLine = require("../assets/verticalLine.svg");
export const editPen = require("../assets/editPen.svg");
export const cloudUpload = require("../assets/cloudUpload.svg");
export const userIcon = require("../assets/USER.png");
export const successIcon = require("../assets/successMsg.svg");
export const CreditCardIcon = require("../assets/creditCard.png");
export const ribbonImage = require("../assets/ribben.png");


