import React from 'react'
import { successImg } from "../../blocks/email-account-registration/src/assets"
import { Typography, Button, Box, Card } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles"
import { primaryColor } from "./theme"
import LazyImage from './LazyImage';
import { alertIcon } from '../../blocks/AdminConsole/src/assets';
import i18next from 'i18next';

function formatDateWithOrdinal(inputDate: string): string {
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    const date = new Date(inputDate);
    const dayOfWeek = daysOfWeek[date.getUTCDay()];
    const dayOfMonth = date.getUTCDate();
    const monthNames = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    const month = monthNames[date.getUTCMonth()];

    const ordinalSuffix = (day: number): string => {
        if (day >= 11 && day <= 13) {
            return 'th';
        }
        const lastDigit = day % 10;
        switch (lastDigit) {
            case 1:
                return 'st';
            case 2:
                return 'nd';
            case 3:
                return 'rd';
            default:
                return 'th';
        }
    };

    const formattedDate = `${dayOfWeek}, ${dayOfMonth}${ordinalSuffix(dayOfMonth)} ${month}`;
    return formattedDate;
}
  

const useStyle = makeStyles((theme) => ({
    label: {
        color: 'rgba(33, 36, 38, 0.80)',
        lineHeight: '130%',
        width: '195px'
    },
    value: {
        fontWeight: 500,
        lineHeight: '130%',
    },
    tripImage: {
        width: '190px',
        height: '190px'
    },
    title: {
        fontSize: '28px',
        fontWeight: 600,
        lineHeight: '130%',
        opacity: 0.9
    },
    description: {
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '160%',
        opacity: 0.8,
    },
    centerContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        maxWidth: '800px',
        margin: '48px auto'
    },
    cardWrapper: {
        display: 'flex',
        padding: '32px',
        flexDirection: 'column' as const,
        justifyContent: 'center',
        alignItems: 'flex-start',
        gap: '12px',
        borderRadius: '16px',
        border: '0.5px solid rgba(33, 36, 38, 0.20)',
        background: '#FFF',
        width: '100%',
        boxShadow: 'none',
        height: 'min-content'
    },
    fontSize19: {
        fontSize: '19px',
    },
}));

const cardDetails = (list: Record<string, any>, classes: any) => {
    return (
        <div>
            {Object.entries(list).map(([label, value], index) => (
                <Box key={index} display="grid" gridTemplateColumns="auto 1fr" marginBottom={'12px'} gridColumnGap={32}>
                    <Typography className={classes.label}>{label}</Typography>
                    <Typography className={classes.value}>{value}</Typography>
                </Box>
            ))}
        </div>
    );
};

const TripPaymentSuccess = ({ navigation, responseJson }: any) => {
    const classes = useStyle();
    console.log(responseJson, "object")
    const {
        trip: {
            attributes: {
                primary_image_url = '',
                title: tripTitle = '',
                description: tripDescription = '',
                trip_detail: {
                    data: {
                        attributes: {
                            cancellation_policy = '',
                        } = {},
                    } = {},
                } = {},
                currency = ''

            } = {},
        } = {},
        trip_schedule: {
            attributes: {
                scheduled_date: serviceDate = '',
            } = {},
        } = {},
        order_item: {
            attributes: {
                total_price: servicePrice = '',
            } = {},
        } = {},
        provider_details: {
            name: serviceProviderName = '',
        } = {},
    } = responseJson?.responseJson?.data?.data?.attributes ?? {};

    const { paymentStatus = false } = responseJson ?? {};

    console.log("paymentStatus", paymentStatus)



    const ServiceDetails = {
        [i18next.t('customer.makePayment.serviceData')]: formatDateWithOrdinal(serviceDate),
        [i18next.t('customer.makePayment.servicePrice')]: servicePrice + ' ' + currency,
        [i18next.t('customer.makePayment.serviceProviderName')]: serviceProviderName
    }

    const headingContent = paymentStatus ? i18next.t('customer.makePayment.orderConfirmed') : i18next.t('customer.makePayment.somethingWentWrong');
    const paraContent = paymentStatus ? i18next.t('customer.makePayment.thankYouMessage')
    : i18next.t('customer.makePayment.paymentIssueMessage')


    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', minHeight: '59.6vh', width: '554px', margin: '48px auto' }}>
                <LazyImage src={paymentStatus ? successImg : alertIcon} width={'164px'} height={'137px'} alt="success" />
                <Typography style={{ fontFamily: 'Inter', fontSize: '32px', color: 'rgba(33, 38, 35, 0.90)', fontWeight: 500 }}>{headingContent}</Typography>
                <Typography style={{ fontFamily: 'Inter', fontSize: '16px', color: 'rgba(33, 38, 35, 0.65)', fontWeight: 400, marginTop: '12px', textAlign: 'center' }}>{paraContent} </Typography>
                {
                    paymentStatus ? <Button
                    onClick={() => navigation?.navigate("MyBookings")}
                    style={{
                        marginTop: '32px',
                        height: '56px',
                        border: `1px solid ${primaryColor}`,
                        backgroundColor: primaryColor,
                        color: '#ffffff',
                        textTransform: 'initial',
                        borderRadius: '51px',
                        padding: '16px 48px',
                        fontSize: '16px',
                        fontFamily: "Inter",
                        fontWeight: 500
                    }}
                >
                    {i18next.t('customer.makePayment.viewYourOrders')}</Button>
                    : 
                    <Button
                    onClick={() => navigation?.goBack()}
                    style={{
                        marginTop: '32px',
                        height: '56px',
                        border: `1px solid ${primaryColor}`,
                        backgroundColor: primaryColor,
                        color: '#ffffff',
                        textTransform: 'initial',
                        borderRadius: '51px',
                        padding: '16px 48px',
                        fontSize: '16px',
                        fontFamily: "Inter",
                        fontWeight: 500
                    }}
                >
                    {i18next.t('customer.makePayment.tryAgain')}</Button>
                }
            </div>
            {
                paymentStatus &&
                <div className={classes.centerContainer}>
                <Card className={classes.cardWrapper} >
                    <Box display={'flex'} gridColumnGap={'32px'} marginBottom={'32px'}>
                        <img src={primary_image_url} alt='tripImage' className={classes.tripImage} />
                        <Box>
                            <Typography className={classes.title} gutterBottom>
                                {tripTitle}
                            </Typography>
                            <Typography className={`${classes.description}`}>
                                {tripDescription}
                            </Typography>
                        </Box>
                    </Box>
                    <Typography className={`${classes.fontSize19} ${classes.value}`}>
                    {i18next.t('customer.makePayment.bookingDetails')}
                    </Typography>
                    {cardDetails(ServiceDetails, classes)}
                    <Typography className={`${classes.fontSize19} ${classes.value}`}>
                    {i18next.t('customer.makePayment.cancellationPolicyNoShow')}
                    </Typography>
                    <Typography className={classes.value}>{cancellation_policy}</Typography>
                </Card>
            </div>
            }
        </>
    )
}

export default TripPaymentSuccess;