
import { 
    TextField, Grid, Typography, InputAdornment, IconButton, 
    Box, FormControl, Button, RadioGroup, FormControlLabel, Radio, 
    FormLabel 
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility"
import VisibilityOff from "@material-ui/icons/VisibilityOff"
import Check from "@material-ui/icons/Check"
import React, { useState, useEffect } from "react";
import { parsePhoneNumber } from 'libphonenumber-js'
import PhoneInput from "./PhoneInput";
import * as Yup from "yup";
import { Formik, Form, ErrorMessage } from 'formik'
import { primaryColor } from "./theme";
import { CountryData, PersonalDetailsValues } from "../../blocks/email-account-registration/src/Interface.web";
import { withStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import { useWindowWidth } from "./utils";
import { tooltipIcon } from "../../blocks/email-account-registration/src/assets";
import PhnInputNV from "./PhnInputNV";
import i18next from "i18next";

interface PersonalDetailsFormProps {
    sendEmailOrSmsOtpRequest: (field: string, button: string) => void;
    handleValueChange: (field: string, value: string) => void;
    email: string;
    mobile: string;
    errorMsg: string;
    isEmailVarified: boolean;
    isPhoneVarified: boolean;
    phoneErrorMsg: string;
    emailErrorMsg: any;
    countryData: CountryData[],
    initialState: PersonalDetailsValues;
    isEditClicked:boolean;
    submitPersonalDetails: (values: any) => void;
    traveleAgencyEditRequest
    : (value:any) => void;
    handleBack: () => void;
}


export const isValidPhoneNumber = (mobile_number: string): boolean => {
    try{
      const parsedPhoneNumber = parsePhoneNumber(mobile_number);
    return !!parsedPhoneNumber && parsedPhoneNumber.isPossible();
    }catch(error){
      return false;
    }
  };
  
const validationSchema = Yup.object().shape({
    fname: Yup.string().required(()=>{i18next.t('travelAgency.myProfile.enterFirstName')}),
    lname: Yup.string().required(()=>{i18next.t('travelAgency.myProfile.enterLastName')}),
    email: Yup.string().email(()=>{i18next.t('travelAgency.myProfile.enterValidEmail')})
        .required(()=>{i18next.t('travelAgency.myProfile.enterEmail')}),
    phoneNumber: Yup.string().required(()=>{i18next.t('travelAgency.myProfile.enterMobileNumber')}),
    password: Yup.string().min(5, ()=>{i18next.t('travelAgency.myProfile.passwordMinLength')}).required(()=>{i18next.t('travelAgency.myProfile.enterValidPassword')}),
    facebook_link: Yup.string().url(()=>{i18next.t('travelAgency.myProfile.linkInvalid')}).notRequired()
        .matches(
            /^https:\/\/www\.facebook\.com\/.*/,
            ()=>{i18next.t('travelAgency.myProfile.facebookLinkFormat')}
        ).nullable(),
    instagram_link: Yup.string().url(()=>{i18next.t('travelAgency.myProfile.instagramLinkFormat')}).notRequired()
        .matches(
            /^https:\/\/www\.instagram\.com\/.*/,
            ()=>{i18next.t('travelAgency.myProfile.instagramLinkFormat')}
        ).nullable(),
    website_link: Yup.string().url(()=>{i18next.t('travelAgency.myProfile.linkInvalid')}).notRequired().nullable(),
    service_provider_type: Yup.string().required(()=>{i18next.t('travelAgency.myProfile.selectServiceProvider')})
})

const initialValues = {
    fname: '',
    lname: '',
    email: '',
    phoneNumber: '',
    password: '',
    facebook_link: '',
    instagram_link: '',
    website_link: '',
    service_provider_type: '',
}

const useStylesBootstrap = makeStyles((theme: Theme) => ({
    arrow: {
      color: '#ffffff',
      margin: '0 auto -10',
      "&::before": {
        border: "1px solid #C3CAD9",
      },
    },
    tooltip: {
      backgroundColor: '#ffffff',
      border: '1px solid #C3CAD9',
      borderRadius: '8px',
      padding: '11px 16px',
      color: 'rgba(33, 36, 38, 0.90)',
      fontFamily: 'Inter',
      fontSize: '12px',
      width: 'auto',
      minWidth: '359px',
      fontWeight: 400
    },
  }));
  


const getErrorMsg = (touched: any, errors: any, emailErrorMsg: string, isEmailVarified: boolean) => {

    if (emailErrorMsg) {
        return `* ${emailErrorMsg}`;
    }else if(touched){
        if(errors?.email){
            return errors.email;
        }else if(!isEmailVarified){
            return i18next.t('travelAgency.myProfile.enterValidEmail')
        }
    }
    return '';
}

const PersonalDetailsForm = ({ 
    sendEmailOrSmsOtpRequest,
    handleValueChange,
    countryData,
    initialState,
    errorMsg,
    isEmailVarified,
    isPhoneVarified,
    phoneErrorMsg,
    emailErrorMsg,
    submitPersonalDetails,
    isEditClicked = false,
    traveleAgencyEditRequest,
    handleBack
}: PersonalDetailsFormProps) => {
    const windowWidth: number = useWindowWidth();
    const [openTooltip, setOpenTooltip] = useState(false);

    const handleClick = () => {
        setOpenTooltip(!openTooltip);
    };

    const BootstrapTooltip = (props: TooltipProps) => {
        const classes = useStylesBootstrap();

        return <Tooltip
            open={openTooltip}
            onClose={() => setOpenTooltip(false)}
            arrow classes={classes} {...props} />
    }


    // Define your responsive logic based on windowWidth
    const isMobile: boolean = windowWidth < 768;

    const useStyles = makeStyles((theme) => ({
        customFont: {
            fontFamily: 'Inter', // Replace with your desired font family
            fontWeight: 400,
        },
        buttonSelectStyle: {
            fontFamily: 'Inter', // Replace with your desired font family
            fontWeight: 500,
            color: 'rgba(33, 36, 38, 0.90)',
            fontSize: '16px',
            marginTop: '-22px'
        },
        toastModal: {
            borderRadius: '8px',
            display: 'inline-flex',
            height: '45px',
            alignItems: 'center',
            gap: '12px',
            flexShrink: 0,
            backgroundColor: '#FFF2F2',
            borderLeft: '8px solid #FF0000',
            padding: '8px 16px 8px 12px',
            margin: '30px 0'
        },
        formHelperText:{
            color: 'rgb(244, 67, 54)',
            fontSize: '12px',
            marginLeft: isMobile ? '0px' : '20px',
            marginTop: isMobile ? '8px' : '24px'
        }
    }));
    const classes = useStyles();
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showToast, setShowToast] = useState(false);
    const [emailTouched, setEmailTouched] = useState(false);

    useEffect(() => {
        setShowToast(
            !!errorMsg
        );
    }, [errorMsg]);

    const handlePasswordVisibility = () => {
        setShowPassword(!showPassword)
    }

    const CustomFormLabel = withStyles({
        root: {
          color: 'rgb(33 36 38 / 65%)',
          marginBottom: '8px',
          fontFamily: 'Inter',
          '&$focused': {
            color: 'rgb(33 36 38 / 65%)',
          },
        },
        focused: {}, // Empty object to prevent the default focus styles
      })(FormLabel);
    


    return (
        <Box 
        style={{
            width: isMobile ? '100%' : "77%", // Adjust the width as needed
            margin: "0 auto", // Center the div horizontally
            padding: "20px",
          }}
          data-testid = "personaldetailcontainer"
        >
            {
                showToast &&
                <Box className={classes.toastModal}>
                    <Typography style={{fontFamily: "Inter", fontSize: '14px', color: '#FF0000'}}>{errorMsg}</Typography>
                </Box>
            }
            { !isEditClicked &&
            <Box>
                <Typography
                    style={{ fontSize: '32px', marginTop: '30px', fontFamily: 'Inter', fontWeight: 500, color: '#212426' }}
                    data-testid = "personaldetail-heading"
                >
                    {i18next.t('travelAgency.myProfile.personalDetails')}
                </Typography>
            </Box>
}
            <Formik
                enableReinitialize={true}
                initialValues={initialState}
                test-id="submitButtonId"
                onSubmit={isEditClicked ? traveleAgencyEditRequest : submitPersonalDetails}
                validationSchema={validationSchema}
                data-testid = "personaldetailcontainer"
            >
                {({ values, setFieldValue, errors, touched, setTouched }) => {
                    const isPhoneNumberValid = true;
                    return (
                        <Form>
                            <Grid container spacing={3} justifyContent="space-between" style={{ marginTop: '32px' }}>
                                <Grid item md={6} xs={12}>
                                    <Typography
                                        style={{
                                            fontFamily: "Inter",
                                            fontSize: "16px",
                                            color: "rgb(33 36 38 / 65%)",
                                            marginBottom: "8px",
                                            height: '24px',
                                            fontWeight: 500
                                        }}
                                    >
                                         {i18next.t('travelAgency.myProfile.firstName')}<span style={{ color: 'red' }}> *</span>
                                    </Typography>
                                    <TextField
                                        style={{ width: "100%" }}
                                        placeholder= {i18next.t('travelAgency.myProfile.enterFirstNameLabel')}
                                        variant="standard"
                                        name="fname"
                                        autoComplete="off"
                                        className="email_input"
                                        onChange={(e) => {
                                            setFieldValue("fname", e.target.value);
                                            touched.fname = true;
                                        }}
                                        error={
                                            Boolean(errors?.fname) && touched?.fname
                                        }
                                        helperText={
                                            touched?.fname ? errors?.fname : ""
                                        }
                                        FormHelperTextProps={{
                                            className: classes.customFont,
                                        }}
                                        value={values.fname}
                                        InputProps={{
                                            style: {
                                                backgroundColor: "#ffffff",
                                                color: "rgba(33, 36, 38, 0.90)",
                                                padding: "16px 20px",
                                                height: '56px',
                                                border: `1px solid`,
                                                borderColor: errors?.fname && touched?.fname ? 'red' : '#C3CAD9',
                                                borderRadius: '8px',
                                                fontFamily: "Inter",
                                                fontSize: '16px',
                                                fontWeight: 500
                                            },
                                            disableUnderline: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item md={6} xs={12} style={{ marginTop: isMobile ? '8px' : '0px' }}>
                                    <Typography
                                        style={{
                                            fontFamily: "Inter",
                                            fontSize: "16px",
                                            color: "rgb(33 36 38 / 65%)",
                                            marginBottom: "8px",
                                            height: '24px',
                                            fontWeight: 500
                                        }}
                                    >
                                         {i18next.t('travelAgency.myProfile.lastName')}<span style={{ color: 'red' }}> *</span>
                                    </Typography>
                                    <TextField
                                        style={{ width: "100%" }}
                                        placeholder= {i18next.t('travelAgency.myProfile.enterLastNameLabel')}
                                        variant="standard"
                                        name="lname"
                                        autoComplete="off"
                                        className="email_input"
                                        onChange={(e) => {
                                            setFieldValue("lname", e.target.value);
                                            touched.lname = true;
                                        }}
                                        error={
                                            Boolean(errors?.lname) && touched.lname
                                        }
                                        helperText={
                                            touched.lname ? errors?.lname : ""
                                        }
                                        FormHelperTextProps={{
                                            className: classes.customFont,
                                        }}
                                        value={values.lname}
                                        InputProps={{
                                            style: {
                                                backgroundColor: "#ffffff",
                                                color: "rgba(33, 36, 38, 0.90)",
                                                padding: "16px 20px",
                                                height: '56px',
                                                border: `1px solid`,
                                                borderColor: errors?.lname && touched.lname ? 'red' : '#C3CAD9',
                                                borderRadius: '8px',
                                                fontFamily: "Inter",
                                                fontSize: '16px',
                                                fontWeight: 500
                                            },
                                            disableUnderline: true,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={3} justifyContent="space-between" style={{ marginTop: '20px' }}>
                                <Grid item md={6} xs={12}>
                                    <Typography
                                        style={{
                                            fontFamily: "Inter",
                                            fontSize: "16px",
                                            color: "rgb(33 36 38 / 65%)",
                                            marginBottom: "8px",
                                            height: '24px',
                                            fontWeight: 500
                                        }}
                                    >
                                         {i18next.t('travelAgency.myProfile.emailAddress')}<span style={{ color: 'red' }}> *</span>
                                    </Typography>
                                    <TextField
                                        style={{ width: "100%" }}
                                        placeholder= {i18next.t('travelAgency.myProfile.enterEmailLabel')}
                                        variant="standard"
                                        name="email"
                                        autoComplete="off"
                                        className="email_input"
                                        data-testid="handleValueChange"
                                        onChange={(e) => { handleValueChange("email", e.target.value); setFieldValue("email", e.target.value); setEmailTouched(true) }}
                                        error={!!errors.email && touched.email||emailErrorMsg}
                                        helperText={touched.email && errors.email||emailErrorMsg}
                                        FormHelperTextProps={{
                                            className: classes.customFont,
                                        }}
                                        value={values.email}
                                        InputProps={{
                                            endAdornment: isEditClicked ? null : isEmailVarified ? (
                                                <InputAdornment position='end'>
                                                    <div style={{ display: 'flex', gap: '5px' }}>
                                                        <Check style={{ color: '#6F964C', fontSize: '20px', fontWeight: 600 }} />
                                                        <Typography style={{ color: '#6F964C', fontSize: '14px', fontFamily: "Inter", fontWeight: 600 }}>Verified</Typography>
                                                    </div>
                                                </InputAdornment>
                                            ) : (
                                                <InputAdornment position='end'>
                                                    <IconButton
                                                        aria-label='toggle password visibility'
                                                        onClick={() => sendEmailOrSmsOtpRequest('email', 'verify')}
                                                        style={{ fontSize: "14px", fontFamily: "Inter", fontWeight: 500, color: primaryColor }}
                                                        disabled={!values?.email || !!(values?.email && errors?.email)}
                                                    >
                                                        {i18next.t('travelAgency.myProfile.verify')}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                            style: {
                                                backgroundColor: "#ffffff",
                                                color: "rgba(33, 36, 38, 0.90)",
                                                padding: "16px 20px",
                                                height: '56px',
                                                border: `1px solid`,
                                                borderColor: errors?.email && touched.email || emailErrorMsg || touched.email && !isEmailVarified ? 'red' : '#C3CAD9',
                                                borderRadius: '8px',
                                                fontFamily: "Inter",
                                                fontSize: '16px',
                                                fontWeight: 500
                                            },
                                            disableUnderline: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item md={6} xs={12} style={{ marginTop: isMobile ? '8px' : '0px' }}>
                                    <Typography
                                        style={{
                                            fontFamily: "Inter",
                                            fontSize: "16px",
                                            color: "rgb(33 36 38 / 65%)",
                                            marginBottom: "8px",
                                            // height: '20px',
                                            fontWeight: 500
                                        }}
                                    >
                                         {i18next.t('travelAgency.myProfile.mobileNumber')}<span style={{ color: 'red' }}> *</span>
                                    </Typography>
                                    {
                                        isEditClicked ?
                                        <PhnInputNV
                                        phoneError={errors?.phoneNumber as string}
                                        isPhoneNumberValid={isPhoneNumberValid}
                                        phoneErrorMsg={phoneErrorMsg}
                                        touchedPhone={touched.phoneNumber as boolean}
                                        setFullPhoneNumber={setFieldValue}
                                        phoneValue={values.onlyPhone}
                                        sendEmailOrSmsOtpRequest={sendEmailOrSmsOtpRequest}
                                        countryData={countryData}
                                        isPhoneVarified={isPhoneVarified}
                                        handleValueChange={handleValueChange}
                                        country_code={values.country_code}
                                        planItineraryPhn={isEditClicked}
                                    />:
                                    <PhoneInput
                                        phoneError={errors?.phoneNumber as string}
                                        isPhoneNumberValid={isPhoneNumberValid}
                                        phoneErrorMsg={phoneErrorMsg}
                                        touchedPhone={touched.phoneNumber as boolean}
                                        setFullPhoneNumber={setFieldValue}
                                        phoneValue={values.onlyPhone}
                                        sendEmailOrSmsOtpRequest={sendEmailOrSmsOtpRequest}
                                        countryData={countryData}
                                        isPhoneVarified={isPhoneVarified}
                                        handleValueChange={handleValueChange}
                                        country_code={values.country_code}
                                        planItineraryPhn={isEditClicked}
                                    />
                                    }
                                    {/* <ErrorMessage name='phoneNumber' component='div' style={{color: '#f44336', fontSize: '12px'}} /> */}
                                </Grid>
                            </Grid>
                            <Grid container spacing={3} justifyContent="space-between" style={{ marginTop: '16px' }}>
                                <Grid item md={isEditClicked ? 12 : 6} xs={12}>
                                    <Typography
                                        style={{
                                            fontFamily: "Inter",
                                            fontSize: "16px",
                                            color: "rgb(33 36 38 / 65%)",
                                            marginBottom: "8px",
                                            height: '24px',
                                            fontWeight: 500
                                        }}
                                    >
                                        {i18next.t('travelAgency.myProfile.facebook_link')}
                                    </Typography>
                                    <TextField
                                        style={{ width: "100%" }}
                                        placeholder="https://www.facebook.com/examplepage"
                                        variant="standard"
                                        name="facebook_link"
                                        autoComplete="off"
                                        className="email_input"
                                        onChange={(e) => {
                                            setFieldValue("facebook_link", e.target.value);
                                            touched.facebook_link = true;
                                        }}
                                        error={
                                            Boolean(errors?.facebook_link) && touched.facebook_link
                                        }
                                        helperText={
                                            touched.facebook_link ? errors?.facebook_link : ""
                                        }
                                        FormHelperTextProps={{
                                            className: classes.customFont,
                                        }}
                                        value={values?.facebook_link}
                                        InputProps={{
                                            style: {
                                                backgroundColor: "#ffffff",
                                                color: "rgba(33, 36, 38, 0.90)",
                                                padding: "16px 20px",
                                                height: '56px',
                                                border: `1px solid`,
                                                borderColor: errors?.facebook_link && touched.facebook_link ? 'red' : '#C3CAD9',
                                                borderRadius: '8px',
                                                fontFamily: "Inter",
                                                fontSize: '16px',
                                                fontWeight: 500
                                            },
                                            disableUnderline: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item md={isEditClicked ? 12 : 6} xs={12} style={{ marginTop: isMobile ? '8px' : '0px' }}>
                                    <Typography
                                        style={{
                                            fontFamily: "Inter",
                                            fontSize: "16px",
                                            color: "rgb(33 36 38 / 65%)",
                                            marginBottom: "8px",
                                            height: '24px',
                                            fontWeight: 500
                                        }}
                                    >
                                         {i18next.t('travelAgency.myProfile.instagram_link')}
                                    </Typography>
                                    <TextField
                                        style={{ width: "100%" }}
                                        placeholder="https://www.instagram.com/..."
                                        variant="standard"
                                        name="instagram_link"
                                        autoComplete="off"
                                        className="email_input"
                                        onChange={(e) => {
                                            setFieldValue("instagram_link", e.target.value);
                                            touched.instagram_link = true;
                                        }}
                                        error={
                                            Boolean(errors?.instagram_link) && touched.instagram_link
                                        }
                                        helperText={
                                            touched.instagram_link ? errors?.instagram_link : ""
                                        }
                                        FormHelperTextProps={{
                                            className: classes.customFont,
                                        }}
                                        value={values.instagram_link}
                                        InputProps={{
                                            style: {
                                                backgroundColor: "#ffffff",
                                                color: "rgba(33, 36, 38, 0.90)",
                                                padding: "16px 20px",
                                                height: '56px',
                                                border: `1px solid`,
                                                borderColor: errors?.instagram_link && touched.instagram_link ? 'red' : '#C3CAD9',
                                                borderRadius: '8px',
                                                fontFamily: "Inter",
                                                fontSize: '16px',
                                                fontWeight: 500
                                            },
                                            disableUnderline: true,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={3} justifyContent="space-between" style={{ marginTop: '20px' }}>
                                <Grid item md={isEditClicked ? 12 : 6} xs={12}>
                                    <Typography
                                        style={{
                                            fontFamily: "Inter",
                                            fontSize: "16px",
                                            color: "rgb(33 36 38 / 65%)",
                                            marginBottom: "8px",
                                            height: '24px',
                                            fontWeight: 500
                                        }}
                                    >
                                         {i18next.t('travelAgency.myProfile.website_link')}
                                    </Typography>
                                    <TextField
                                        style={{ width: "100%" }}
                                        placeholder="https://www.example.com/"
                                        variant="standard"
                                        name="website_link"
                                        autoComplete="off"
                                        className="email_input"
                                        onChange={(e) => {
                                            setFieldValue("website_link", e.target.value);
                                            touched.website_link = true;
                                        }}
                                        error={
                                            Boolean(errors?.website_link) && touched.website_link
                                        }
                                        helperText={
                                            touched.website_link ? errors?.website_link : ""
                                        }
                                        FormHelperTextProps={{
                                            className: classes.customFont,
                                        }}
                                        value={values.website_link}
                                        InputProps={{
                                            style: {
                                                backgroundColor: "#ffffff",
                                                color: "rgba(33, 36, 38, 0.90)",
                                                padding: "16px 20px",
                                                height: '56px',
                                                border: `1px solid`,
                                                borderColor: errors?.website_link && touched.website_link ? 'red' : '#C3CAD9',
                                                borderRadius: '8px',
                                                fontFamily: "Inter",
                                                fontSize: '16px',
                                                fontWeight: 500
                                            },
                                            disableUnderline: true,
                                        }}
                                    />
                                </Grid>
                                {!isEditClicked &&
                                    <Grid item md={6} xs={12} style={{ marginTop: isMobile ? '8px' : '0px' }}>
                                        <Box style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                            <Typography
                                                style={{
                                                    fontFamily: "Inter",
                                                    fontSize: "16px",
                                                    color: "rgb(33 36 38 / 65%)",
                                                    marginBottom: "8px",
                                                    height: '24px',
                                                    fontWeight: 500,
                                                    width: isMobile ? '49%' : '48%'
                                                }}
                                            >
                                                 {i18next.t('travelAgency.myProfile.password')}<span style={{ color: 'red' }}> *</span>
                                            </Typography>
                                            <Typography
                                                style={{
                                                    fontFamily: "Inter",
                                                    fontSize: "14px",
                                                    color: "rgb(33 36 38 / 65%)",
                                                    marginBottom: "8px",
                                                    // height: '24px',
                                                    fontWeight: 400,
                                                    width: isMobile ? '51%' : '52%'
                                                }}
                                            >
                                                 {i18next.t('travelAgency.myProfile.passwordRequirement')}
                                            </Typography>
                                        </Box>
                                        <TextField
                                            style={{ width: "100%" }}
                                            placeholder={i18next.t('travelAgency.myProfile.enterPassword')}
                                            variant="standard"
                                            name="firstName"
                                            autoComplete="off"
                                            className="email_input"
                                            type={showPassword ? 'text' : 'password'}
                                            onChange={(e) => {
                                                setFieldValue("password", e.target.value);
                                                touched.password = true;
                                            }}
                                            error={
                                                Boolean(errors?.password) && touched.password
                                            }
                                            helperText={
                                                touched.password ? errors?.password : ""
                                            }
                                            FormHelperTextProps={{
                                                className: classes.customFont,
                                            }}
                                            value={values.password}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position='end'>
                                                        <IconButton
                                                            aria-label='toggle password visibility'
                                                            onClick={handlePasswordVisibility}
                                                            // onMouseDown={handleMouseDownPassword}
                                                            style={{ fontSize: "15px", textDecoration: 'none' }}
                                                        >
                                                            {showPassword ? <VisibilityOff style={{ height: '24px', width: '24px' }} />
                                                                : <Visibility style={{ height: '24px', width: '24px' }} />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                                style: {
                                                    backgroundColor: "#ffffff",
                                                    color: "rgba(33, 36, 38, 0.90)",
                                                    padding: "16px 20px",
                                                    height: '56px',
                                                    border: `1px solid`,
                                                    borderColor: errors?.password && touched.password ? 'red' : '#C3CAD9',
                                                    borderRadius: '8px',
                                                    fontFamily: "Inter",
                                                    fontSize: '16px',
                                                    fontWeight: 500
                                                },
                                                disableUnderline: true,
                                            }}
                                        />
                                    </Grid>
                                }
                            </Grid>
                            {!isEditClicked &&
                                <Box style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', marginTop: '35px' }}>


                                    <FormControl component="fieldset">
                                        <RadioGroup row aria-label="gender" name="gender1" style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row' }} value={values.service_provider_type} onChange={(e) => { setFieldValue('service_provider_type', e.target.value); touched.service_provider_type = true; }}>
                                            <CustomFormLabel style={{ marginRight: '32px', fontFamily: 'Inter', fontWeight: 500 }}>
                                            {i18next.t('travelAgency.myProfile.serviceProviderType')}<span style={{ color: 'red', marginBottom: '8px' }}> *</span>
                                            </CustomFormLabel>
                                            <FormControlLabel value="agency" style={{ marginTop: '-13px', marginRight: '32px' }} control={<Radio style={{ color: 'rgba(33, 36, 38, 0.90)' }} />} label={
                                                <span className={classes.buttonSelectStyle}>
                                                     {i18next.t('travelAgency.myProfile.travelAgency')}
                                                </span>
                                            } />
                                            <FormControlLabel value="buddy" style={{ marginTop: '-13px', }} control={<Radio style={{ color: 'rgba(33, 36, 38, 0.90)' }} />} label={
                                                <span className={classes.buttonSelectStyle}>
                                                    {i18next.t('travelAgency.myProfile.travelBuddy')}
                                                </span>
                                            } />
                                        </RadioGroup>
                                    </FormControl>

                                    <BootstrapTooltip title={
                                        <>
                                            <p><span style={{ fontWeight: 500 }}> {i18next.t('travelAgency.myProfile.travelAgency')}</span>  {i18next.t('travelAgency.myProfile.travelAgencyDescription')}</p>
                                            <p><span style={{ fontWeight: 500 }}> {i18next.t('travelAgency.myProfile.travelBuddy')}</span>  {i18next.t('travelAgency.myProfile.travelBuddyDescription')}</p>
                                        </>
                                    }
                                        onClick={handleClick}
                                    >
                                        <img src={tooltipIcon} alt="SVG as an image" style={{ height: '21px', width: '21px', marginTop: isMobile ? '0px' : '-2px', marginLeft: isMobile ? '0px' : '32px' }} />
                                    </BootstrapTooltip>
                                    <Typography variant="caption" color="error" style={{ fontFamily: "Inter", fontWeight: 400, marginTop: isMobile ? '2.5px' : '0px', marginLeft: isMobile ? '0px' : '20px' }}>
                                        {touched.service_provider_type ? errors.service_provider_type : ""}
                                    </Typography>

                                </Box>
                            }
                            <Box style={{ marginTop: '48px', display: 'flex', justifyContent: isMobile ? 'center' : 'flex-start' }}>
                                {
                                    !isEditClicked ? <Button
                                        style={{
                                            backgroundColor: primaryColor,
                                            color: '#ffffff',
                                            textTransform: 'initial',
                                            borderRadius: '34px',
                                            padding: '10px 50px',
                                            fontSize: '16px',
                                            fontFamily: "Inter",
                                            fontWeight: 500,
                                            width: '182px',
                                            height: '56px',
                                        }}
                                        type="submit"
                                    >
                                         {i18next.t('travelAgency.myProfile.next')}
                                    </Button> :
                                        <Box sx={{ display: 'flex', gridGap: '20px' }}>
                                            <Button
                                                style={{
                                                    backgroundColor: primaryColor,
                                                    color: '#ffffff',
                                                    textTransform: 'initial',
                                                    borderRadius: '34px',
                                                    padding: '10px 60px',
                                                    fontSize: '16px',
                                                    fontFamily: "Inter",
                                                    fontWeight: 500,
                                                    width: '182px',
                                                    height: '56px',
                                                    whiteSpace: 'nowrap'
                                                }}
                                                type="submit"
                                            >
                                                 {i18next.t('travelAgency.myProfile.save_changes')}
                                            </Button>
                                            <Button
                                                style={{
                                                    color: primaryColor,
                                                    textTransform: 'initial',
                                                    borderRadius: '34px',
                                                    padding: '10px 50px',
                                                    fontSize: '16px',
                                                    fontFamily: "Inter",
                                                    fontWeight: 500,
                                                    width: '182px',
                                                    height: '56px',
                                                }}
                                                onClick={handleBack}
                                            >
                                                 {i18next.t('travelAgency.myProfile.cancel')}
                                            </Button>
                                        </Box>
                                }
                            </Box>
                        </Form>)
                }}
            </Formik>
        </Box>
    )
}

export default PersonalDetailsForm;