import React from "react";

// Customizable Area Start
import {
    Typography, Box, Divider, Chip, Card, Button,
    CardMedia, ButtonGroup, CardContent, Grid
} from "@material-ui/core";
import {
    withStyles, Theme, ThemeProvider, createTheme
} from "@material-ui/core/styles";
import { commomStyles } from "./TripDetails.web";
import Breadcrumbs from "../../../components/src/Breadcrumbs";
import { primaryColor } from "../../../components/src/theme";
import { imgImageLoader } from "../../customform/src/assets";
import { formatMonthDateYear } from "../../../components/src/utils";
import i18next from 'i18next';

export const muiTheme = createTheme({
    overrides: {
        MuiTypography: {
            gutterBottom: {
                marginBottom: '8px'
            }
        }
    },
    typography: {
        body1: {
            color: '#212426',
            fontFamily: 'Inter',
            fontStyle: "normal",
            fontWeight: 400,
            letterSpacing: 0,
            fontSize: '16px',
            lineHeight: '150%'
        }
    }
});
interface TripCardProps {
    id: string | number;
    type: string;
    attributes: {
        id: string | number;
        status: string;
        trip_image: string;
        trip_title: string;
        trip_description: string;
        trip_date: string;
        no_of_people: string | number;
    }
}

export const DynamicButtonGroup = ({ data, classes, style = {} }:
    { data: Record<string, any>[], classes: any, style?: Record<string, any> }) => (
    <ButtonGroup className={classes.infoGroup} classes={{ groupedOutlinedHorizontal: classes.outlineRight }} style={style}>
        {data.map((item, index) => (
            <Button classes={{ label: classes.bookInfo, outlined: classes.outlined }}
                key={index} disableTouchRipple disableFocusRipple>
                <Typography className={classes.label}>
                    {item.label}
                </Typography>
                <Typography className={`${classes.value} ${item.value}`}>
                    {item.value}
                </Typography>
            </Button>
        ))}
    </ButtonGroup>
);
// Customizable Area End

import OrdermanagementController, {
    Props
} from "./OrdermanagementController";
export default class MyBookings extends OrdermanagementController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props
        const { activeFilter, orders, bookingsLoader, orderCount } = this.state
        const interest_type = this.context?.currentUser?.interest_type
        const filterOptions =  
                [
                { label: i18next.t('customer.myBookings.all'), value: null, count: null },
                { label: i18next.t('customer.myBookings.confirmed'), value: 'created', count: orderCount?.created  },
                { label: i18next.t('customer.myBookings.completed'), value: 'closed', count: orderCount?.closed },
                { label: i18next.t('customer.myBookings.cancelled'), value: 'cancelled', count: orderCount?.cancelled },
                { label: i18next.t('customer.myBookings.removed'), value: 'refunded', count: orderCount?.refunded },
            ]
        const   filterButtons = () => {
            return filterOptions.map((option: any, index) => (
                <Chip
                    key={index}
                    data-testid="filterBtn"
                    label={`${option.label} ${option.count != null ? '(' + option.count + ')' : ''}`}
                    classes={{
                        root: `${classes.chipRoot} ${activeFilter === option.value ? 'active' : ''}`,
                        label: `${classes.chipLabel} ${activeFilter === option.value ? 'active' : ''}`
                    }}
                    onClick={() => this.handleChipClick(option.value)}
                />
            ))
        }
        
        const tripCard = (list: TripCardProps[]) => {
            return list?.map((item: TripCardProps, index) => {
                return <Card className={classes.cardWrapper} key={index}>
                    <CardContent className={classes.cardContent}>
                        <Box className={classes.cardMedia}>
                            <CardMedia
                                component="img"
                                alt="Card Image"
                                height="150px"
                                width={'150px'}
                                image={item.attributes.trip_image}
                                classes={{ media: classes.imgMedia }}
                            />
                            <Chip label={item.attributes.status} classes={{
                                label: `${classes.statusLabel} ${item.attributes.status}`,
                                root: `${classes.statusRoot} ${item.attributes.status}`
                            }} />
                            <Box>
                                <Typography className={classes.title} gutterBottom>
                                    {item.attributes.trip_title}
                                </Typography>
                                <Typography className={classes.description}>
                                    {item.attributes.trip_description}
                                </Typography>
                            </Box>
                        </Box>
                        <div className= {classes.btnContainer}>
                            <DynamicButtonGroup classes={classes}
                                data={[
                                    { label: i18next.t('customer.myBookings.tripDate'), value: formatMonthDateYear(item.attributes.trip_date) },
                                    { label: i18next.t('customer.myBookings.numberOfPeople'), value: item.attributes.no_of_people == "1" ? "1 Person" :  `${item.attributes.no_of_people} Persons`},
                                    { label: i18next.t('customer.myBookings.status'), value: this.getStatusLabel(item.attributes.status) },
                                ]} />
                            <Button variant="contained" className={classes.filledButton} classes={{ label: classes.btnLabel }} onClick={() => this.navigateBookingDetails(item.id)}>
                               {i18next.t('customer.myBookings.viewBooking')}
                            </Button>
                        </div>
                    </CardContent>
                </Card>
            })
        }
        // Customizable Area End
        return (
            // Customizable Area Start
            <ThemeProvider theme={muiTheme}>
                <Box className={classes.container} >
                    <Breadcrumbs list={[
                        {
                            title: i18next.t('customer.myBookings.home'),
                            link: interest_type === null ? '/MyInterest' : '/LandingPage'
                        },
                        {
                            title: i18next.t('customer.myBookings.myBookings'),
                        }
                    ]} />
                    <Divider className = {classes.navAndbookingDivider} />

                    <Box className={classes.innerContainer}>
                        <Typography className={classes.heading} gutterBottom>
                           {i18next.t('customer.myBookings.myBookings')}
                        </Typography>

                        <Typography className={classes.subHeading}>
                        {i18next.t('customer.myBookings.manageTravelExperiences')}
                        </Typography>
                        <Box  className={classes.filterBtnBox}>
                            {filterButtons()}
                        </Box>
                        <Grid container justifyContent="center" alignItems="center" style={{}}>
                            {bookingsLoader && <img src={imgImageLoader} width={'180px'} />}
                            {orders?.length === 0 && !bookingsLoader && <Typography style={{fontFamily: "Inter", fontWeight: 600}}> {i18next.t('customer.myBookings.noBookingsFound')}</Typography>}
                        </Grid>
                        {tripCard(orders)}
                    </Box>
                </Box>
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

export const buttonGroupStyles = (theme :Theme) => ({
    infoGroup: {
        borderRadius: '8px',
        background: '#FFF',
        border: '0.5px solid rgba(33, 36, 38, 0.20)',
        maxWidth: '510px', width: '100%' , whiteSpace :'nowrap'as const,
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        },
    
    },
    outlineRight: {
        width: '100%',
        '&:not(:last-child)': {
            borderRight: '0.5px solid rgba(33, 36, 38, 0.20)',
        }
    },
    outlined: {
        border: 'none',
        padding: 0
    },
    bookInfo: {
        gap: '8px',
        width: '100%',
        display: 'flex',
        padding: '12px 24px',
        alignItems: 'flex-start',
        flexDirection: 'column' as const,
        [theme.breakpoints.down('sm')]: {
            whiteSpace: 'nowrap', 
            overflow: 'hidden', 
            textOverflow: 'ellipsis', 
            alignItems: 'center',

        },
    },
    label: {
        color: 'rgba(33, 36, 38, 0.65)',
        fontSize: '12px',
        lineHeight: '130%',
        textTransform: 'none' as const,
        whiteSpace: 'nowrap' as const,
        [theme.breakpoints.down('sm')]: {
            whiteSpace: 'nowrap', 
            overflow: 'hidden', 
            textOverflow: 'ellipsis', 
        },

    },
    value: {
        color: 'rgba(33, 36, 38, 0.90)',
        fontWeight: 500,
        lineHeight: '130%',
        textTransform: 'none' as const,
        '&.Completed': {
            color: 'rgba(33, 36, 38, 0.90)',
        },
        '&.Confirmed': {
            color: '#11B42E',
        },
        '&.Cancelled': {
            color: '#E84D4D',
        },
        [theme.breakpoints.down('sm')]: {
            whiteSpace: 'break-spaces', 

        },
    
    },
})

const styles = (theme: Theme) => ({
    innerContainer: {
        width: '65.9%',
        margin: '0 auto',
        [theme.breakpoints.down('sm')]: {
            width: 'auto',
        },
    },

    heading: {
        fontSize: '40px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '120%',
        [theme.breakpoints.down(1024)] : {
            fontWeight: 700,
            fontSize: '30px',

        }
    },
    subHeading: {
        color: 'rgba(33, 36, 38, 0.65)',
        fontSize: '14px',
        marginBottom: '32px'
    },
    chipRoot: {
        height: 'auto',
        backgroundColor: '#FFF',
        border: '0.5px solid rgba(33, 36, 38, 0.90)',
        borderRadius: '8px',
        '&.active': {
            backgroundColor: primaryColor,
            border: 'none'
        }
    },
    chipLabel: {
        fontFamily: 'Inter',
        color: 'rgba(33, 36, 38, 0.90)',
        fontWeight: 400,
        fontSize: '16px',
        fontStyle: 'normal',
        lineHeight: 'normal',
        padding: '12px 24px',
        '&.active': {
            color: '#FFF',
            fontWeight: 500,
        },
        [theme.breakpoints.down(768)]:{
            padding : '8px'
        }
    },
    cardWrapper: {
        borderRadius: '16px',
        border: '0.5px solid rgba(33, 36, 38, 0.20)',
        background: '#FFF',
        boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.04)',
        '&:not(:last-child)': {
            marginBottom: '32px'
        }
    },
    cardContent: {
        padding: '24px'
    },
    title: {
        color: 'rgba(33, 36, 38, 0.90)',
        fontSize: "18px",
        fontWeight: 700,
        lineHeight: "140%",
        [theme.breakpoints.down(768)]:{
            position : 'absolute',
            left : 0,
            top : '-14px'
        }

    },
    description: {
        color: 'rgba(33, 36, 38, 0.90)',
        fontSize: '14px',
        [theme.breakpoints.down(768)]:{
            marginTop : '45px'
        }
    },
    imgMedia: {
        borderRadius: '6.453px',
        width: '121px !important',
        height: '121px',
        border: '0.5px solid rgba(33, 36, 38, 0.20)',
        [theme.breakpoints.down(768)]:{
            marginTop : '50px'
        }

    },
    cardMedia: {
        display: 'flex',
        // justifyContent: 'space-between',
        gap: '40px',
        marginBottom: '24px',
        position: 'relative' as const
    },
    filledButton: {
        width: '189px',
        padding: '16px 56px',
        borderRadius: '68px',
        background: '#FE7F2D',
        height: 'min-content',
    },
    btnLabel: {
        color: '#FFF',
        textAlign: 'center' as const,
        fontFamily: 'Inter',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '150%',
        whiteSpace: 'nowrap' as const,
        textTransform: 'none' as const
    },
    statusRoot: {
        position: 'absolute' as const,
        top: '-8px',
        left: '-8px',
        borderRadius: '6px',
        padding: '0',
        height: '22px',
        '&.Completed': {
            background: '#E6E6E6',
        },
        '&.Confirmed': {
            background: '#D4F8D3',
        },
        '&.Cancelled': {
            background: '#F8D3D3',
        },
        [theme.breakpoints.down(768)]:{
            top : '35px'
        }
    },
    statusLabel: {
        color: '#11B42E',
        fontFamily: 'Inter',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '14px',
        '&.Completed': {
            color: 'rgba(33, 36, 38, 0.90)',
        },
        '&.Confirmed': {
            color: '#11B42E',
        },
        '&.Cancelled': {
            color: '#E84D4D',
        }
    },
    btnContainer :{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center', 
        rowGap: '24px', 
        flexWrap: 'wrap'as const,
        [theme.breakpoints.between(768 , 1080)]: {
            display: 'flex',
            gap: '10px', 
            flexWrap: 'nowrap',
        },

    },
    navAndbookingDivider : {
        margin: '15px 0',

        [theme.breakpoints.up('md')] : {
        margin: '48px 0'
        }
    } ,
    filterBtnBox : {
        display :'flex',
        marginBottom :'32px',
        gap:'24px',
        [theme.breakpoints.down(1024)] : {
            gap:'10px',
            overflow : 'auto'
        }

    },
    ...commomStyles(theme),
    ...buttonGroupStyles(theme)
})

const MyBookingsWeb = withStyles(styles)(MyBookings)
export { MyBookingsWeb }
// Customizable Area End
