import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getAuthToken, handleScrollToTop } from "../../../components/src/utils";
import moment from "moment";
export interface TripType {
  id: string;
  type: string;
  attributes: {
      name: string;
      image: string;
  };
}

export interface TripData {
  data: TripType[];
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loggedin: boolean;
  travelBuddyList: Record<string, any>[];
  travelAgencyList: Record<string, any>[];
  cultureTrips: Record<string, any>[];
  natureTrips: Record<string, any>[];
  adventureTrips: Record<string, any>[];
  experienceTrips: Record<string, any>[];
  authToken: string;
  userProfileData: Record<string, any>;
  responeStatsModal: boolean;
  isSuccessIcon: boolean;
  modalMsg: string;
  searchString: string;
  upcomingTrip: any;
  confirmationNumber :string
  tripType: Array<TripType>;
  upComingDate: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      loggedin: false,
      travelAgencyList: [],
      travelBuddyList: [],
      cultureTrips: [],
      natureTrips: [],
      adventureTrips: [],
      experienceTrips: [],
      authToken: '',
      userProfileData: {},
      responeStatsModal: false,
      isSuccessIcon: false,
      modalMsg: "",
      searchString: "",
      upcomingTrip:{},
      confirmationNumber: "",
      tripType : [],
      upComingDate: ""
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if(message.id === getName(MessageEnum.NavigationPayLoadMessage)){
      const responseJson = message.getData(getName(MessageEnum.SessionResponseData));
      this.setState({
        responeStatsModal: responseJson.modalResponseObj.open,
        isSuccessIcon: responseJson.modalResponseObj.isSuccessIcon,
        modalMsg: responseJson.modalResponseObj.modalMsg,
      })
    }
    const apiResponseMessage = getName(MessageEnum.RestAPIResponceMessage);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    if (message.id === apiResponseMessage) {
      switch (apiRequestCallId) {
        case this.apitopTravelAgencyCallId:
          this.handleAgencyResponse(responseJson, errorReponse)
          break;
        case this.apitopTravelBuddyCallId:
          this.handleBuddyResponse(responseJson, errorReponse)
          break;
        case this.apiCultureTripCallId:
          this.handleCultureResponse(responseJson, errorReponse)
          break;
        case this.apiNatureTripCallId:
          this.handleNatureResponse(responseJson, errorReponse)
          break;
        case this.apiAdventureTripCallId:
          this.handleAdventureResponse(responseJson, errorReponse)
          break;
        case this.apiExperienceTripCallId:
          this.handleExperienceResponse(responseJson, errorReponse)
          break;
        case this.getUserProfileDataApiCall:
          this.handleUserProfileResponse(responseJson, errorReponse)
          break;
        case this.upcominTripsApiCallId:
          this.handleUpcomingTripResponse(responseJson, errorReponse)
           break;
           case this.getTripTypeApiCallId:
            this.getTripTypeApiSucessCallBack(responseJson)
             break;
        default:
          break;
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getTripTypeApiCallId : string = "";
  apitopTravelAgencyCallId: string = "";
  apitopTravelBuddyCallId: string = "";
  apiCultureTripCallId: string = "";
  apiNatureTripCallId: string = "";
  apiAdventureTripCallId: string = "";
  apiExperienceTripCallId: string = "";
  getUserProfileDataApiCall: string = '';
  upcominTripsApiCallId : string =''

  async componentDidMount() {
    super.componentDidMount();
    this.getTripTypeApi();
    this.getTopTravelAgencies();
    this.getTopTravelBuddies();
    this.getupcominTrip();

    [{ type: 'culture', callId: 'apiCultureTripCallId', instance: this },
    { type: 'nature', callId: 'apiNatureTripCallId', instance: this },
    { type: 'adventure', callId: 'apiAdventureTripCallId', instance: this },
    { type: 'experience', callId: 'apiExperienceTripCallId', instance: this }].forEach((item) => {
      this.getTripsByType(item);
    })
    const result = await getAuthToken()
    this.setState({
      loggedin: typeof result == 'string' ? true : false,
      authToken: result
    },()=>{ typeof result == 'string' && this.getUserProfileData()});
    handleScrollToTop()
  }

  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  handleAgencyResponse = (responseJson: any, errorReponse: any) => {
    if (responseJson && responseJson.data) {
      const data = responseJson.data.map((item: any) => {
        const { agency_logo, agency_display_name } = item.attributes
        return { ...item, attributes: { name: agency_display_name, image: agency_logo } }
      })
      this.setState({ travelAgencyList: data })
    }
  }

  handleBuddyResponse = (responseJson: any, errorReponse: any) => {
    if (responseJson && responseJson.data) {
      const data = responseJson.data.map((item: any) => {
        const { buddy_profile_photo, buddy_display_name } = item.attributes
        return { ...item, attributes: { name: buddy_display_name, image: buddy_profile_photo } }
      })
      this.setState({ travelBuddyList: data })
    }
  }

  handleCultureResponse = (responseJson: any, errorReponse: any) => {
    if (responseJson && responseJson.data) {
      this.setState({ cultureTrips: responseJson.data })
    }
  }

  handleNatureResponse = (responseJson: any, errorReponse: any) => {
    if (responseJson && responseJson.data) {
      this.setState({ natureTrips: responseJson.data })
    }
  }

  handleAdventureResponse = (responseJson: any, errorReponse: any) => {
    if (responseJson && responseJson.data) {
      this.setState({ adventureTrips: responseJson.data })
    }
  }

  handleExperienceResponse = (responseJson: any, errorReponse: any) => {
    if (responseJson && responseJson.data) {
      this.setState({ experienceTrips: responseJson.data })
    }
  }

  handleUserProfileResponse = (responseJson: any , errorReponse:any) => {
    if (responseJson && responseJson.data && responseJson.data.attributes) {
      this.setState({
        userProfileData: responseJson.data.attributes,
      })
    }
  }

  handleUpcomingTripResponse = (responseJson:any, errorReponse:any) => {
    if (responseJson) {
      const { confirmation_number,trip_date, upcoming_trip } = responseJson;
      this.setState({ upComingDate: trip_date, upcomingTrip: upcoming_trip, confirmationNumber: confirmation_number});
    } else{
      console.log(errorReponse)
    }
  }
  

  getUserProfileData(): boolean {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "Authorization": `Bearer ${this.state.authToken}`
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getUserProfileDataApiCall = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.customerProfileApi
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getTopTravelAgencies = () => {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apitopTravelAgencyCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.topTravelAgencies
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getTopTravelBuddies = () => {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apitopTravelBuddyCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.topTravelBuddies
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getTripsByType = ({ type, callId, instance }: { type: string, callId: string, instance: any }) => {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    instance[callId as keyof typeof instance] = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.tripByType + "?type=" + type + "&page=1&limit=10"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  /* istanbul ignore next */

  getupcominTrip =async() =>  {

    const authToken = await getAuthToken()
    const header = {
      "Authorization": `Bearer ${authToken}`
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.upcominTripsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.upcomingtrips
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }


  // Internal function
  closeResponseModalLP = () => {
    this.setState({
      responeStatsModal: false
    })
  }

  goToServiceProviderPageList = (type: string, params: string, value: string) => {
    if(type === "travelAgency") {
      this.props.navigation.navigate("TravelAgencyList");
    } else if(type === "travelBuddy") {
      this.props.navigation.navigate("TravelBuddyList");
    } else {
      this.goToAdvancedSearchPage(params, value)
    }
  }

  handleSearchString = (searchValue: string) => {
    this.setState({
      searchString: searchValue
    })
  }

  // goToAdvancedSearchPage = (params: string, value: string) => {
  //   this.props.navigation.history.push(`AdvancedSearch?${params}=${value}`)
  // }
  handleEnterPress = (event: any, params: string, value: string) => {
    if (event.key === 'Enter') {
      this.goToAdvancedSearchPage(params, value);
    }
  };
  goToAdvancedSearchPage = async (params: string, value: string) => {
    const queryParams = {
     [params]: value
    }
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "AdvancedSearch"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
      queryParams
    });
    message.addData(
      getName(MessageEnum.NavigationRaiseMessage),
      raiseMessage
    );
    this.send(message);
  }

  navigateToProviderDetailsPage = (type: string, id: string) => {
    const myType = type === "travel_buddy" ? "buddy" : "agency";
    this.props.navigation.navigate("ServiceProviderDetails", {
      type: myType,
      id: id,
    })
  }

  navigateToItenarary = () => this.props.navigation.navigate("PlanItinerary")

  landingPageApiCall = async (valueData: {
    contentType?: string;
    method?: string;
    endPoint?: string;
    body?: {};
    apiType?: string;
    type?: string;
  }) => {
    let { method, endPoint, body, apiType ,contentType} = valueData;
    const authToken = await getAuthToken()
    let header = {
      "Content-Type": contentType,
      "Authorization": `Bearer ${authToken}`
  };
    let landingPageRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    landingPageRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    landingPageRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    body &&
    landingPageRequestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        apiType === "" ? JSON.stringify(body) : body
      );
      landingPageRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(landingPageRequestMessage.id, landingPageRequestMessage);
    return landingPageRequestMessage.messageId;
  };

  getTripTypeApi = async () => {
    this.getTripTypeApiCallId = await this.landingPageApiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.tripTypeEndPoint,
    })
  };

  getTripTypeApiSucessCallBack = (response: TripData) => {
    if(response){
    this.setState({ tripType : response.data}, )
    }
  };

  changeDateFormate = (upComingDate: string) =>{
    return upComingDate?moment(this.state.upComingDate).format('dddd, MMMM D'): "Upcoming Date"
  }
  // Customizable Area End
}
