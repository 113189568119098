import React from 'react';
import {
    Box,
    Modal,
    Typography,
    Button
} from "@material-ui/core";
import { createTheme, ThemeProvider, makeStyles } from "@material-ui/core/styles";
import { Formik, Form, ErrorMessage } from 'formik';

import { Close } from "@material-ui/icons";
import EmptyStar from "./EmptyStar.web";
import CustomTextField from './CustomTextField';
import * as Yup from "yup";
import { primaryColor } from './theme';
import { useAuth } from '../../blocks/user-profile-basic/src/AuthContext.web';
import i18next from 'i18next';


const theme = createTheme({
    overrides: {
        MuiTypography: {
            gutterBottom: {
                marginBottom: '8px'
            }
        }
    },
    typography: {
        body1: {
            color: '#212426',
            fontFamily: 'Inter',
            fontStyle: "normal",
            fontWeight: 400,
            letterSpacing: 0,
            fontSize: '16px',
            lineHeight: '150%'
        }
    }
});

interface Props {
    reviewModal: boolean;
    addReviewRequest: (values: any) => void;
    closeAddReviewModal: () => void;
}

const validationSchema = Yup.object().shape({
    rating: Yup.number()
    .min(1, () => i18next.t('customer.tripDetails.ratingError'))
    .required(() => i18next.t('customer.tripDetails.ratingError')),
  
  title: Yup.string()
    .required(() => i18next.t('customer.tripDetails.pleaseWriteTitle')),

  desc: Yup.string()
    .required(() => i18next.t('customer.tripDetails.pleaseWriteAboutExperience'))
});

const AddReviewWeb: React.FC<Props> = ({
    reviewModal, 
    closeAddReviewModal,
    addReviewRequest
}) => {

    const classes = stylesAddReview();
    const {currentUser} = useAuth();
    const initialValues = {
        user_id: currentUser.id,
        rating: 0,
        title: '',
        desc: '',
    }


    return (
        <ThemeProvider theme={theme}>
            <Modal open={reviewModal}>
                <Box className={classes.paperMainWrappercum}>
                    <Box className={classes.modalEditContentcum}>
                        <Box className={classes.textAndCloseStylecum}>
                            <Typography className={classes.headingTxtcum}>{i18next.t('customer.tripDetails.writeAReview')}</Typography>
                            <Box onClick={() => closeAddReviewModal()} className={classes.closeButtoncum}><Close style={{ fontSize: '20px' }} /></Box>
                        </Box>
                        <Typography className={`${classes.bodyTxtcum}`}>{i18next.t('customer.tripDetails.reviewPublicNotice')}</Typography>
                        <Formik
                            enableReinitialize={true}
                            initialValues={initialValues}
                            test-id="submitButtonId"
                            onSubmit={addReviewRequest}
                            validationSchema={validationSchema}
                        >
                            {({ values, setFieldValue, errors, touched }) => {
                                console.log({ values })
                                const handleRating = (rating: number) => {
                                    setFieldValue('rating', rating)
                                }
                                return (
                                    <Form style={{ marginBottom: '0px' }}>
                                        <Box className={`${classes.distanceBtwncum}`}>
                                            <Typography className={classes.overAllText}>{i18next.t('customer.tripDetails.overallRating')}</Typography>
                                            <EmptyStar onSave={handleRating} />
                                            <ErrorMessage name='rating'>
                                                {(msg: string) => <span className={classes.requiredField}>{msg || i18next.t('customer.tripDetails.ratingError')}</span>}
                                            </ErrorMessage>
                                        </Box>
                                        <Box className={`${classes.distanceBtwncum}`}>
                                            <CustomTextField
                                                fieldTitle={i18next.t('customer.tripDetails.title')}
                                                placeholder={i18next.t('customer.tripDetails.enterTitle')}
                                                value={values.title}
                                                fieldName="title"
                                                onChange={setFieldValue}
                                                error={errors.title as string}
                                                touched={touched.title as boolean}
                                                onlyText={true}
                                            />
                                        </Box>
                                        <Box className={`${classes.distanceBtwncum}`}>
                                            <CustomTextField
                                                fieldTitle={i18next.t('customer.tripDetails.description')}
                                                placeholder={i18next.t('customer.tripDetails.describeYourExperience')}
                                                value={values.desc}
                                                fieldName="desc"
                                                onChange={setFieldValue}
                                                error={errors.desc as string}
                                                touched={touched.desc as boolean}
                                                multiline={true}
                                                maxRows={7}
                                                minRows={4}
                                                onlyText={true}
                                            />
                                        </Box>
                                        <Box
                                            className={`${classes.textAndCloseStylecum} ${classes.distanceBtwncum}`}
                                        >
                                            <Button
                                                className={classes.submitBtnStylecum}
                                                type="submit"
                                            >
                                                {i18next.t('customer.tripDetails.postReview')}
                                            </Button>
                                        </Box>
                                    </Form>
                                )
                            }}
                        </Formik>
                    </Box>
                </Box>
            </Modal>
        </ThemeProvider>
    );
};

const stylesAddReview = makeStyles((theme) => ({
    paperMainWrappercum: {
        position: 'absolute' as const,
        width: '451px',
        backgroundColor: theme.palette.background.paper,
        boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.18)',
        border: '1px solid #C3CAD9',
        borderRadius: '32px',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'initial' as const,
    },
    modalEditContentcum: {
        padding: '32px 32px 40px 32px',
        maxWidth: '100%',
        maxHeight: '100%',
        overflow: 'auto',
    },
    textAndCloseStylecum: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '20px'
    },
    headingTxtcum: {
        fontFamily: 'Inter',
        fontSize: '20px',
        fontWeight: 600,
        lineHeight: '121%',
        fontStyle: 'normal',
        color: '#212426',
    },
    overAllText: {
        fontFamily: 'Inter',
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '121%',
        fontStyle: 'normal',
        color: '#212426',
        marginBottom: '8px'
    },
    bodyTxtcum: {
        color: 'rgba(33, 36, 38, 0.80)',
        fontFamily: 'Inter',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '150%'
    },
    closeButtoncum: {
        cursor: 'pointer',
    },
    distanceBtwncum: {
        marginTop: '20px'
    },
    submitBtnStylecum: {
        backgroundColor: primaryColor,
        color: '#ffffff',
        textTransform: 'initial' as const,
        borderRadius: '49px',
        padding: '10px 50px',
        fontSize: '16px',
        fontFamily: "Inter",
        fontWeight: 400,
        width: '170px',
        height: '48px',
        lineHeight: '150%',
        whiteSpace: 'nowrap' as const,
        '&:hover': {
            backgroundColor: primaryColor,
            color: '#ffffff',
        }
    },
    requiredField: {
        color: '#f44336',
        fontFamily: "Inter",
        fontWeight: 400,
        fontSize: '12px'
    },
    [theme.breakpoints.down(768)]: {
        paperMainWrappercum: {
            left: '44%',
            width: '350px'
        }
    }

}));

export default AddReviewWeb;

